import { Auth } from '@aws-amplify/auth';
import axios from 'axios';
import _ from 'lodash';
import { WEBSITE_SETTINGS_PATH } from '../constants';
import {
    Contact,
    ComplianceReview,
    IAuthorizedReseller,
    IManagedUser,
    IReseller,
    ResellerDetails,
    ResellerItem,
    ReturnOrder,
    ReturnOrderFilter,
    ReturnOrderLineItem,
    ReturnOrderLineItemComplianceStatus,
    ReturnOrderListReturnOrder,
    ReturnOrdersListOutput,
    ResellerPriceList,
} from '../redux/types';
import { ClaimFilter, ClaimsListOutput, ClaimListClaim } from '../redux/types/claimList';
import { CodedError, UserData } from '../types';
import {
    ReturnOrderAuditHistoryInput,
    ReturnOrderAuditHistoryItem,
    ReturnOrderAuditHistoryNote,
    ReturnOrderAuditHistoryOutput,
} from '../types/ReturnOderAuditHistory';
import { Logger } from '../utils/logger';
import { SanitizeHtmlUtil } from '../utils/sanitize';
import { FetchClient } from './FetchClient';

const aws4 = require('aws4');

export interface HrpsApiSettings extends Record<string, string> {
    region: string;
    stage: string;
    hrpsApiId: string;
}

export class HrpsApiClient {
    private static async fetchSettings(): Promise<HrpsApiSettings> {
        return await FetchClient.fetch<HrpsApiSettings>(WEBSITE_SETTINGS_PATH);
    }

    public async createUser(user: UserData) {
        Logger.debug('Creating user:', user);

        return this.callHrps('createUser', {
            subject: {
                email: user.email,
                familyName: user.familyName,
                givenName: user.givenName,
                locale: user.locale,
                phoneNumber: user.phone ? user.phone : undefined,
                role: user.role,
                username: user.username,
                resellers: user.resellers.map((reseller) => ({ id: reseller })),
            },
        });
    }

    public async submitContact(contact: Contact) {
        Logger.debug('Create Contact', contact);
        const operation = contact.contactId ? 'editResellerContact' : 'createResellerContact';
        return this.callHrps(operation, {
            contact: {
                contactId: contact.contactId ? contact.contactId : undefined,
                resellerId: contact.resellerId,
                firstName: contact.firstName === '' ? undefined : contact.firstName,
                lastName: contact.lastName,
                notes: contact.notes === '' ? undefined : contact.notes,
                phone: contact.phone === '' ? undefined : contact.phone,
                cellPhone: contact.cellPhone === '' ? undefined : contact.cellPhone,
                email: contact.email,
                status: contact.status,
                contactType: contact.contactType,
                role: contact.role === '' ? undefined : contact.role,
                fax: contact.fax === '' ? undefined : contact.fax,
            },
        });
    }

    public async softDeleteContact(contact: Contact) {
        Logger.debug('Soft Delete Contact', contact);
        return this.callHrps('editResellerContact', {
            contact: {
                contactId: contact.contactId ? contact.contactId : undefined,
                resellerId: contact.resellerId,
                firstName: contact.firstName === '' ? undefined : contact.firstName,
                lastName: contact.lastName,
                notes: contact.notes === '' ? undefined : contact.notes,
                phone: contact.phone === '' ? undefined : contact.phone,
                cellPhone: contact.cellPhone === '' ? undefined : contact.cellPhone,
                email: contact.email,
                status: contact.status,
                contactType: 'Other',
                role: contact.role === '' ? undefined : contact.role,
                fax: contact.fax === '' ? undefined : contact.fax,
            },
        });
    }

    public async resendUserInvite(username: string) {
        Logger.debug('Resend user invite to the user:', username);

        return this.callHrps('resendUserInvite', {
            username: username,
        });
    }

    async updateUser(user: UserData) {
        Logger.debug('Update user:', user);

        return this.callHrps('editUser', {
            subject: {
                email: user.email,
                familyName: user.familyName,
                givenName: user.givenName,
                locale: user.locale,
                phoneNumber: user.phone ? user.phone : undefined,
                role: user.role,
                username: user.username,
                resellers: user.resellers.map((reseller) => ({ id: reseller })),
            },
        });
    }

    public async getAuthorizedResellers(): Promise<IAuthorizedReseller[]> {
        const data = {
            resellerIds: [],
        };
        return this.callHrps('getResellers', data)
            .then((response) => {
                const resellers = response.data.resellers as IReseller[];

                const authorizedResellers: IAuthorizedReseller[] = resellers.map((reseller) => ({
                    countryCode: reseller.countryCode,
                    name: reseller.name,
                    resellerId: reseller.resellerId,
                    status: reseller.status,
                    supportZeroCreditReturn: reseller.supportZeroCreditReturn,
                }));

                return authorizedResellers;
            })
            .catch((err) => {
                throw err;
            });
    }

    public async getResellerDetails(resellerIds: string[]): Promise<IReseller[]> {
        const data = {
            resellerIds,
        };

        return this.callHrps('getResellers', data)
            .then((response) => {
                return response.data.resellers as IReseller[];
            })
            .catch((error) => {
                Logger.debug(
                    'Error pulling reseller details for reseller:',
                    resellerIds,
                    ' with error: ',
                    error.message
                );
                throw error;
            });
    }

    public async getManagedUsers(username: string): Promise<IManagedUser[] | string> {
        const data = {
            userId: username,
        };

        let returnVal: IManagedUser[] | string = [];

        try {
            await this.callHrps('getManagedUsers', data).then(
                (response) => {
                    returnVal = response.data.managedUsers;
                },
                (error) => {
                    Logger.error(error);
                    returnVal = error.response.request.responseText;
                }
            );
        } catch (error) {
            returnVal = error;
        }
        return returnVal;
    }

    private async buildPostRequest(operation: string, data: Object) {
        const settings = await HrpsApiClient.fetchSettings();

        const path = `/${settings.stage}/${operation}`;

        const request = {
            host: `${settings.hrpsApiId}.execute-api.${settings.region}.amazonaws.com`,
            method: 'POST',
            url: `https://${settings.hrpsApiId}.execute-api.${settings.region}.amazonaws.com${path}`,
            data,
            body: JSON.stringify(data), // Note: body is used by aws4.sign
            path, // Note: path is used by aws4.sign
            headers: {
                'Content-Type': 'application/x-amz-json-1.1',
            },
        };

        return request;
    }

    private async signRequest(request: Object) {
        const credentials = await Auth.currentUserCredentials();

        const signedRequest = aws4.sign(request, {
            secretAccessKey: credentials.secretAccessKey,
            accessKeyId: credentials.accessKeyId,
            sessionToken: credentials.sessionToken,
        });

        // disabling the eslint rule for consistency with next line
        // eslint-disable-next-line dot-notation
        delete signedRequest.headers['Host'];
        delete signedRequest.headers['Content-Length'];

        return signedRequest;
    }

    public async callHrps(operation: string, requestData: Object) {
        const request = await this.buildPostRequest(operation, SanitizeHtmlUtil.sanitizeObject(requestData));

        const signedRequest = await this.signRequest(request);

        try {
            Logger.debug(`Calling HRPS operation ${operation}. Request: `, requestData);
            const response = await axios(signedRequest);
            Logger.debug(`Calling HRPS operation ${operation} succeeded. Response: `, response);
            return response;
        } catch ({ response }) {
            const stackTrace = response.data.exceptionStackTrace;
            const errorCode = response.headers['x-amzn-errortype']?.replace(/Exception$/, '') || 'InternalError';
            const { message, errorData } = response.data;

            const formattedMessage = message ? `Message: ${message}\n` : '';

            Logger.error(
                `Call to HRPS operation %c${operation}%c has failed.\nHTTP Code: ${response.status}.\n${formattedMessage}Error Code: ${errorCode}.\nError Data:`,
                'background-color: yellow;',
                '',
                errorData
            );
            if (stackTrace) {
                // eslint-disable-next-line no-console
                console.groupCollapsed('Server-side stack trace:');
                Logger.info(stackTrace);
                // eslint-disable-next-line no-console
                console.groupEnd();
            }

            throw new CodedError(message || '<messages are redacted in production>', errorCode, errorData);
        }
    }

    public async updateAccountStatus(username: string, status: { enabled: boolean }): Promise<string> {
        Logger.debug('Updating account status for user:', username, 'new status: ', status);
        const data = {
            username: username,
            doLock: !status.enabled,
        };

        return this.callHrps('lockUser', data)
            .then(() => {
                Logger.debug('Successfully changed account status for user:', username, 'new status: ', status);
                return 'Success';
            })
            .catch((error) => {
                Logger.debug(
                    'Failed to changed account status for user:',
                    username,
                    'new status: ',
                    status,
                    ' error: ',
                    error
                );
                return error;
            });
    }

    async resetPassword(username: string): Promise<string> {
        Logger.debug('Resetting password for user:', username);
        const data = {
            username: username,
        };

        return this.callHrps('resetPassword', data)
            .then(() => {
                return 'Success';
            })
            .catch((error) => {
                Logger.debug('Failed to reset password for user:', username, ' error: ', error);
                throw error;
            });
    }

    public async getClaims(
        resellerId: string,
        startAt: number,
        maxResults: number,
        sortOrder?: any,
        filter?: ClaimFilter
    ): Promise<ClaimsListOutput> {
        Logger.info('Calling getClaims', resellerId, startAt, maxResults, sortOrder, filter);
        const data = {
            resellerId: resellerId,
            startAtResult: startAt - 1,
            maxResults: maxResults,
            sortConditions: sortOrder,
            filter: filter,
        };
        Logger.debug('Passed data:', data);
        return this.callHrps('getResellerClaims', data)
            .then((response) => {
                const claims = response.data.claims as ClaimListClaim[];
                const totalResults = response.data.totalResults as number;
                Logger.debug('response data:', response.data);

                const output: ClaimsListOutput = {
                    claimsList: claims,
                    totalResults: totalResults,
                } as ClaimsListOutput;

                return output;
            })
            .catch((err) => {
                Logger.error('Failed to get claims for reseller:', resellerId, ' error: ', err);
                return {
                    claimsList: [],
                    totalResults: 0,
                };
            });
    }

    public async getReturnOrders(
        resellerId: string,
        startAt: number,
        maxResults: number,
        sortOrder?: any,
        filter?: ReturnOrderFilter
    ): Promise<ReturnOrdersListOutput> {
        const sortCondition = sortOrder || [];
        const searchFilters = filter || {};

        // if the search is for a Return Request Id do a direct pull
        const rmaIdRegEx = RegExp('R{2}[a-zA-Z0-9]{10}');
        if (filter?.searchValue && rmaIdRegEx.test(filter!.searchValue! as string)) {
            Logger.debug(`Getting Return Order: ${filter.searchValue} as search value is a RMA Id`);
            const data = {
                returnOrderId: filter!.searchValue!,
                resellerId,
            };

            return this.callHrps('getResellerReturnOrder', data)
                .then((response) => {
                    Logger.debug(response.data);
                    const returnOrders = [
                        {
                            billingAddress: response.data.returnOrder.billingAddress,
                            createdByUser: response.data.returnOrder.createdBy,
                            creationDate: response.data.returnOrder.creationDate,
                            referenceId: response.data.returnOrder.customerReferenceId,
                            resellerId: response.data.returnOrder.resellerId,
                            returnOrderId: response.data.returnOrder.returnOrderId,
                            returnOrderStatus:
                                response.data.returnOrder.status === 'DRAFT'
                                    ? 'DRAFTED'
                                    : response.data.returnOrder.status,
                            shippingAddress: response.data.returnOrder.shippingAddress,
                        },
                    ] as ReturnOrderListReturnOrder[];
                    const totalResults = 1;
                    Logger.debug('response data:', response.data);

                    const output: ReturnOrdersListOutput = {
                        returnOrdersList: returnOrders,
                        totalResults: totalResults,
                    } as ReturnOrdersListOutput;

                    return output;
                })
                .catch((error) => {
                    Logger.debug(`Error pulling return order with id:<${filter.searchValue}>, error: ${error.message}`);
                    throw error;
                });
        } else {
            const data = {
                resellerId: resellerId,
                startAtResult: startAt,
                maxResults: maxResults,
                sortConditions: sortCondition,
                filter: searchFilters,
            };
            Logger.debug('Passed data:', data);
            return this.callHrps('getResellerReturnOrders', data)
                .then((response) => {
                    const returnOrders = response.data.returnOrders as ReturnOrderListReturnOrder[];
                    const totalResults = response.data.totalResults as number;
                    Logger.debug('response data:', response.data);

                    const output: ReturnOrdersListOutput = {
                        returnOrdersList: returnOrders,
                        totalResults: totalResults,
                    } as ReturnOrdersListOutput;

                    return output;
                })
                .catch((err) => {
                    Logger.debug('Failed to get return orders for reseller:', resellerId, ' error: ', err);
                    throw err;
                });
        }
    }

    public async getReturnOrder(returnOrderId: string, resellerId: string): Promise<ReturnOrder> {
        Logger.debug('Getting Return Order: ', returnOrderId);
        const data = {
            returnOrderId: returnOrderId,
            resellerId,
        };

        return this.callHrps('getResellerReturnOrder', data)
            .then((response) => {
                Logger.debug(response.data);
                return {
                    returnOrderId: response.data.returnOrder.returnOrderId,
                    customerReferenceId: response.data.returnOrder.customerReferenceId,
                    shippingAddress: response.data.returnOrder.shippingAddress,
                    billingAddress: response.data.returnOrder.billingAddress,
                    contactEmailAddress: response.data.returnOrder.contactEmails,
                    returnType: response.data.returnOrder.returnType,
                    status: response.data.returnOrder.status,
                    ...(response.data.returnOrder.totalCharge && {
                        totalCharge: {
                            amountWithTaxes: response.data.returnOrder.totalCharge.amountWithTaxes,
                            currencyCode: response.data.returnOrder.totalCharge.currencyCode,
                        },
                    }),
                    createdBy: response.data.returnOrder.createdBy,
                    version: response.data.returnOrder.version,
                    lineItems: response.data.returnOrder.returnOrderLines.map(
                        (returnOrderLine: any, index: number) =>
                            ({
                                orderLineId: index,
                                lineItemId: returnOrderLine.orderLineItemId,
                                itemId: returnOrderLine.itemId,
                                requestedCount: returnOrderLine.requestedQuantity,
                                approvedCount: returnOrderLine.approvedQuantity,
                                estimatedCredit: returnOrderLine.estimatedCredit,
                                receivedCount: returnOrderLine.receivedQuantity,
                                status: returnOrderLine.status,
                                reason: returnOrderLine.denialReason,
                                complianceResolution: returnOrderLine.complianceResolution,
                                complianceReview: {
                                    complianceStatus: returnOrderLine.compliance
                                        .complianceStatus as ReturnOrderLineItemComplianceStatus,
                                    eligibleReturnQuantity: returnOrderLine.compliance.eligibleReturnQuantity,
                                } as ComplianceReview,
                                packageType: returnOrderLine.packageType,
                                packageQuantity: returnOrderLine.packageQuantity,
                            } as ReturnOrderLineItem)
                    ),
                } as ReturnOrder;
            })
            .catch((error) => {
                Logger.debug(`Error pulling return order with id:<${returnOrderId}>, error: ${error.message}`);
                throw error;
            });
    }

    public async getAdvancedResellerDetails(resellerId: string): Promise<ResellerDetails> {
        Logger.debug(`Getting details for reseller:<${resellerId}>`);

        const data = {
            resellerId,
        };

        return this.callHrps('getResellerDetails', data)
            .then((response) => {
                Logger.debug(`Received details:<${JSON.stringify(response.data.resellerDetails)}>`);
                return response.data.resellerDetails;
            })
            .catch((error) => {
                Logger.debug(
                    'Error pulling reseller details for reseller:',
                    resellerId,
                    ' with error: ',
                    error.message
                );
                throw error;
            });
    }

    public async getResellerItems(resellerId: string): Promise<ResellerItem[]> {
        Logger.debug(`Getting items for reseller:<${resellerId}>`);

        const data = {
            resellerId,
        };

        return this.callHrps('getResellerItems', data)
            .then((response) => {
                Logger.debug(`Received items:<${JSON.stringify(response.data.items)}>`);
                return response.data.items;
            })
            .catch((error) => {
                Logger.debug('Error pulling item details for reseller:', resellerId, ' with error: ', error.message);
                throw error;
            });
    }

    public async getReturnOrderAuditHistory(
        input: ReturnOrderAuditHistoryInput
    ): Promise<ReturnOrderAuditHistoryOutput> {
        Logger.debug('Getting Return Order Audit History: ', input.returnOrderId);

        return this.callHrps('getReturnOrderAuditHistory', input)
            .then((response) => {
                Logger.debug(response.data);
                return {
                    returnOrderAuditHistoryItems: response.data.returnOrderAuditRecords.map(
                        (auditHistoryLine: any) =>
                            ({
                                id: auditHistoryLine.entityId,
                                type: auditHistoryLine.entityType,
                                status: auditHistoryLine.status,
                                creationDate: auditHistoryLine.date,
                                note: auditHistoryLine.notes.map(
                                    (auditHistoryNote: any) =>
                                        ({
                                            tag: auditHistoryNote.tag,
                                            message: auditHistoryNote.message,
                                            date: auditHistoryNote.date,
                                        } as ReturnOrderAuditHistoryNote)
                                ),
                            } as ReturnOrderAuditHistoryItem)
                    ),
                } as ReturnOrderAuditHistoryOutput;
            })
            .catch((error) => {
                Logger.debug(
                    `Error pulling return order audit history with id:<${input.returnOrderId}>, error: ${error.message}`
                );
                throw error;
            });
    }

    public async getItemImageUrls(asins: string[]): Promise<Record<string, string>> {
        const uniqueAsins = _.uniq(asins);

        if (uniqueAsins.length === 0) {
            return {};
        }

        const data = {
            asins: uniqueAsins,
        };

        Logger.debug(`Getting item urls for asins:<${uniqueAsins}>`);
        return this.callHrps('getItemImageUrls', data)
            .then((response) => {
                Logger.debug(`Received items:<${JSON.stringify(response.data.asinImageUrlMap)}>`);
                return response.data.asinImageUrlMap;
            })
            .catch((error) => {
                Logger.debug(`Error pulling item image urls for asins <${asins}> with error: ${error.message}`);
                throw error;
            });
    }

    public async getItemsByItemIds(resellerId: string, itemIds: string[]): Promise<ResellerItem[]> {
        const uniqueItemIds = _.uniq(itemIds);

        if (uniqueItemIds.length === 0) {
            return [];
        }

        Logger.debug(`Getting items for reseller:<${resellerId}> by item Ids: <${uniqueItemIds}>`);

        const data = {
            resellerId: resellerId,
            itemIds: uniqueItemIds,
        };

        return this.callHrps('getItemsByItemIds', data)
            .then((response) => {
                Logger.debug(`Received items:<${JSON.stringify(response.data.items)}>`);
                return response.data.items;
            })
            .catch((error) => {
                Logger.debug(`Error pulling item details for reseller <${resellerId}> with error: ${error.message}`);
                throw error;
            });
    }

    public async getResellerPriceList(resellerId: string): Promise<ResellerPriceList> {
        Logger.debug(`Getting priceList for resellerId: ${resellerId}>`);
        const data = {
            resellerId,
        };

        return this.callHrps('getResellerPriceList', data)
            .then((response) => {
                Logger.debug(`Received PriceList:<${JSON.stringify(response.data)}>`);
                return response.data;
            })
            .catch((error) => {
                Logger.debug(`Error pulling pricelist for resesllerId: <${resellerId}> with error: ${error.message}`);
                throw error;
            });
    }
}
