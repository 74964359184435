// @ts-ignore
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import React from 'react';
import { WarningMessages } from '../../types';
import { TranslatedString, useTranslation } from '../blocks';

export interface UploadErrorDialogProps {
    label: TranslatedString;
    uploadWarnings: WarningMessages[];
    dataTestId?: string;
}

const UploadErrorDialog = ({ uploadWarnings }: UploadErrorDialogProps) => {
    const { t } = useTranslation('uploadErrorDialog');

    function formatMessage(errorCode: string, errorMessage: string): TranslatedString {
        const updatedMessage = [...errorMessage.matchAll(/<(.*?)>/g)];
        let params = {};
        updatedMessage.forEach((value, index) => {
            params = { ...params, [index]: value[1] };
        });
        const resultMessage = t(errorCode, params);
        return resultMessage;
    }

    return (
        <>
            {uploadWarnings ? (
                <Table headerRows={1}>
                    <TableRow>
                        <TableCell>{t('LineNo-ColumnHeader')}</TableCell>
                        <TableCell>{t('ErrorMessage-ColumnHeader')}</TableCell>
                    </TableRow>
                    {uploadWarnings
                        .sort((a, b) => (a.lineNo < b.lineNo ? -1 : 1))
                        .map((uploadWarning) => [
                            <TableRow key={`${uploadWarning.lineNo}_TR`}>
                                <TableCell>{t.cognate(uploadWarning.lineNo)}</TableCell>
                                <TableCell>{formatMessage(uploadWarning.warningCode, uploadWarning.message)}</TableCell>
                            </TableRow>,
                        ])}
                </Table>
            ) : undefined}
        </>
    );
};

export { UploadErrorDialog };
