import { AnyAction, MiddlewareAPI } from 'redux';
import { Logger } from '../../utils/logger';
import { storeUserAction, fetchAllUserActions } from '../../utils/userActionStorage';
import { CHANGE_USER_SESSION } from '../actions/userActions';

export const userActionPersistor = (store: MiddlewareAPI) => (next: (action: AnyAction) => any) => (
    action: AnyAction
) => {
    // persist actions to replay on login if a user context exists
    const currentUser = store.getState().userReducer.user;
    if (currentUser && action.replayOnLogin) {
        Logger.debug('{User Action Middleware} Persisting action:', action);
        storeUserAction(currentUser.username, action);
    }

    // fetch and execute all persisted actions upon introduction of a user context (login action)
    if (action.type === CHANGE_USER_SESSION && action.payload.isAuthenticated && action.payload.user) {
        const persistedActions = fetchAllUserActions(action.payload.user.username);
        persistedActions.forEach((action) => {
            Logger.debug('{User Action Middleware} Executing fetched action:', action);
            store.dispatch(action);
        });
    }

    return next(action);
};
