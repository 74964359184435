import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import React, { FC, FormEvent, ReactElement, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHrpsApi } from '../clients/useHrpsApi';
import { Button, useTranslation, WaitFor } from '../components/blocks';
import { Tile, usePageMessaging } from '../components/composites';
import { EUTermsAndConditions, USTermsAndConditions } from '../components/constructed';
import {
    DEFAULT_REGION,
    INITIAL_TERMS_AND_CONDITIONS,
    RegoinMap,
    Region,
    TermsAndConditionsComponent,
} from '../constants';
import {
    getUserTermsAndConditionsAcceptances,
    setUserTermsAndConditionsValid,
} from '../redux/actions/termsAndConditionsActionCreators';
import { RootState } from '../redux/types';
import { SiteMapPage } from '../types';
import { Logger } from '../utils/logger';
import { useSiteMapRouter } from '../utils/SiteMapRouter';

export const TermsAndConditionsRegionMap: Record<Region, TermsAndConditionsComponent> = {
    US: { id: 'usTermsAndConditions', version: '1.0' },
    EU: { id: 'euTermsAndConditions', version: '1.0' },
};

const TermsAndConditionsPage: FC<TermsAndConditionsPageProps> = ({
    reseller,
    userTermsAndConditionsAcceptances,
    getUserTermsAndConditionsAcceptances,
    setUserTermsAndConditionsValid,
}: TermsAndConditionsPageProps) => {
    const { t } = useTranslation(['termsAndConditionsPage', 'roles', 'validation', 'forms']);

    const [termsAndConditions, setTermsAndConditions] = useState(INITIAL_TERMS_AND_CONDITIONS);
    const [isPageBusy, setPageIsBusy] = useState(false);

    const { goto } = useSiteMapRouter();

    const { showSuccess, showError } = usePageMessaging();

    const {
        termsAndConditions: { addUserTermsAndConditionsAcceptanceApi },
    } = useHrpsApi();

    function getTermAndConditionsElement(id: string, country: string): ReactElement {
        switch (id) {
            case 'usTermsAndConditions':
                return <USTermsAndConditions />;
            case 'euTermsAndConditions':
                return <EUTermsAndConditions country={country} />;
            default:
                return <USTermsAndConditions />;
        }
    }

    useEffect(() => {
        async function loadCurrentTAndCData() {
            try {
                await getUserTermsAndConditionsAcceptances();
            } catch (error) {
                Logger.error('getUserTermsAndConditionsAcceptances error: ', error);
                showError(t('failedToGetTermsAndConditions-errorMessage'));
            }
        }

        async function setValid() {
            try {
                await setUserTermsAndConditionsValid(true);
            } catch (error) {
                Logger.error('setUserTermsAndConditionsValid error: ', error);
                showError(t('failedToSetUserTermsAndConditionsValid-errorMessage'));
            }
        }

        if (
            reseller &&
            termsAndConditions.status === 'Uninitialized' &&
            userTermsAndConditionsAcceptances.status === 'Loaded'
        ) {
            let region = RegoinMap[reseller.countryCode];
            let tAndCs = TermsAndConditionsRegionMap[region];
            if (!tAndCs) {
                tAndCs = TermsAndConditionsRegionMap[DEFAULT_REGION];
                region = DEFAULT_REGION;
            }
            const locatedTC = userTermsAndConditionsAcceptances.value?.find(
                (acceptance) =>
                    acceptance.termsAndConditionsId === tAndCs.id &&
                    acceptance.region === region &&
                    acceptance.version === tAndCs.version
            );
            if (locatedTC !== undefined) {
                setValid();
                goto(SiteMapPage.home);
            } else {
                setTermsAndConditions({
                    status: 'Loaded',
                    value: tAndCs,
                });
            }
        }
        if (userTermsAndConditionsAcceptances.status === 'Uninitialized') {
            loadCurrentTAndCData();
        }
    }, [
        getUserTermsAndConditionsAcceptances,
        goto,
        reseller,
        setUserTermsAndConditionsValid,
        showError,
        showSuccess,
        t,
        termsAndConditions.status,
        userTermsAndConditionsAcceptances,
    ]);

    async function submitForm(event: FormEvent) {
        event.preventDefault();
        setPageIsBusy(true);

        const now = new Date();
        const region = RegoinMap[reseller!.countryCode] ? RegoinMap[reseller!.countryCode] : DEFAULT_REGION;
        try {
            await addUserTermsAndConditionsAcceptanceApi({
                termsAndConditionsId: termsAndConditions.value!.id,
                version: termsAndConditions.value!.version,
                region: region,
                acceptanceDate: now.getTime(),
            });

            showSuccess(t('userTsAndCsAcceptWasCreated-successMessage'));
            await setUserTermsAndConditionsValid(true);

            goto(SiteMapPage.home);
            Logger.debug('successfully added Ts & Cs Acceptance ************');
        } catch (error) {
            Logger.debug(`failed  to add Ts and Cs Acceptance: ${error}`);
            showError(t('userTsAndCsAcceptWasCreated-errorMessage'));
            setPageIsBusy(false);
        }
    }

    return (
        <Row alignmentHorizontal={'left'} data-testid={'termsAndConditionsPage'}>
            <Tile title={t('termsAndConditions-pageTitle')} width={'90%'} data-testid={'termsAndConditionsPage_Tile'}>
                <WaitFor lazyValue={termsAndConditions}>
                    {(termsAndConditionsData) => (
                        <form onSubmit={submitForm}>
                            <Column width={'100%'}>
                                <Row>
                                    {getTermAndConditionsElement(termsAndConditionsData.id, reseller!.countryCode)}
                                </Row>
                                <Row alignmentHorizontal={'right'}>
                                    <Button
                                        type={'secondary'}
                                        onClick={() => goto(SiteMapPage.signOut)}
                                        data-testid={'termsAndConditionsPage.cancelButton'}
                                    >
                                        {t('forms:cancel-buttonLabel')}
                                    </Button>
                                    <Button
                                        type={'primary'}
                                        submit={true}
                                        disabled={isPageBusy}
                                        data-testid={'termsAndConditionsPage.acceptButton'}
                                    >
                                        {t('termsAndConditionsAccept-buttonLabel')}
                                    </Button>
                                </Row>
                            </Column>
                        </form>
                    )}
                </WaitFor>
            </Tile>
        </Row>
    );
};

const mapStateToProps = ({ resellerReducer, termsAndConditionsReducer }: RootState) => {
    return {
        reseller: resellerReducer.selectedReseller.value,
        userTermsAndConditionsAcceptances: termsAndConditionsReducer.userTermsAndConditionsAcceptances,
    };
};

const mapDispatchToProps = { getUserTermsAndConditionsAcceptances, setUserTermsAndConditionsValid };

const connector = connect(mapStateToProps, mapDispatchToProps);
export type TermsAndConditionsPageProps = ConnectedProps<typeof connector>;
export default connector(TermsAndConditionsPage);
