import Column from '@amzn/meridian/column';
import styled from '@emotion/styled';
import React from 'react';
import { useTranslation, Text } from '../components/blocks';
import { SignIn } from '../components/constructed';
import { RequestRegistrationDialog } from '../components/constructed/RequestRegistrationDialog';
import { color } from '../theme/colors';

const LightText = styled(Text)`
    color: ${color.gray[100]};
    font-weight: bold;
`;

const SignInPage = () => {
    const { t } = useTranslation('signIn');
    return (
        <Column width={'100%'}>
            <SignIn />
            <LightText>{t('signIn:access-message')}</LightText>
            <RequestRegistrationDialog label={t('signIn:request-access-button')} />
        </Column>
    );
};

export { SignInPage };
