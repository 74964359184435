import folderTokens from '@amzn/meridian-tokens/base/icon/folder';
import FileInput from '@amzn/meridian/file-input';
import Icon from '@amzn/meridian/icon';
import { css } from 'emotion';
import React from 'react';
import { TranslatedString } from '../../../blocks/localization';
import { MultilineFormEditorColumnFormat } from '../MultilineFormEditor';

const fileIconClass = css`
    height: 40px;
    padding-top: 5px;
`;

export const fileFormat = (
    onFileAttached: (attachedFiles: File[], lineIndex: number) => void,
    uploadButtonLabel: TranslatedString,
    type?: 'single' | 'multiple'
): MultilineFormEditorColumnFormat => ({
    render: (item, column, lineIndex) => {
        return (
            <FileInput
                onFileAttached={(attachedFiles) => onFileAttached(attachedFiles, lineIndex)}
                type={type}
                uploadButtonLabel={uploadButtonLabel}
                showDropZone={false}
            >
                <div className={fileIconClass}>
                    {`${item[column.sourceProperty]?.length ?? 0} `}
                    <Icon tokens={folderTokens} />
                </div>
            </FileInput>
        );
    },
});
