export type PageName = string;
export type TemplateName = string;

export const IssueMap: Record<PageName, TemplateName> = {
    '/account/change-password': 'userPasswordIssue',
    '/admin/users/create': 'userCreateIssue',
    '/admin/users/user/edit': 'userEditIssue',
    '/admin/users/list': 'userListIssue',
    '/claims/claims/view': 'claimIssue',
    '/claims/claims/upload': 'claimIssue',
    '/footer/featureRequest': 'featureRequestIssue',
    '/footer/feedback': 'feedbackIssue',
    '/notifications/notifications/list': 'notificationListIssue',
    '/resellers/selection': 'resellerSelectionIssue',
    '/returns/confirm/return-orders/dispute': 'returnOrderDisputeIssue',
    '/returns/return-orders/create': 'standardReturnOrderIssue',
    '/returns/return-orders/return-order/update': 'standardReturnOrderIssue',
    '/returns/return-orders/return-order/view': 'standardReturnOrderViewIssue',
    '/returns/return-orders/upload': 'returnOrderUploadIssue',
    '/returns/return-orders/list': 'returnOrderListIssue',
    '/account/change-reseller': 'resellerSelectionIssue',
};

export const allTemplates: string[] = Object.entries(IssueMap).map((value: string[]) => {
    return value[1];
});
