export enum SiteMapPage {
    home = '/',
    editUser = '/admin/users/user/edit',
    listUsers = '/admin/users/list',
    viewReturnOrder = '/returns/return-orders/return-order/view',
    updateDraftReturnOrder = '/returns/return-orders/return-order/update',
    pageNotFound = '/errors/page-not-found',
    changePassword = '/account/change-password',
    resetPassword = '/account/reset-password',
    signIn = '/account/sign-in',
    signOut = '/account/sign-out',
    selectReseller = '/account/select-reseller',
    listReturnOrders = '/returns/return-orders/list',
    createReturnOrder = '/returns/return-orders/create',
    confirmReturnOrder = '/returns/return-orders/confirm',
    uploadReturnOrder = '/returns/return-orders/upload',
    uploadClaim = '/claims/claims/upload',
    viewClaims = '/claims/claims/view',
    changeReseller = '/account/change-reseller',
    listNotifications = '/notifications/notifications/list',
    termsAndConditions = '/account/accept-termsAndConditions',
    listContacts = '/reseller/contacts/list',
    editContact = '/reseller/contacts/contact/edit',
    newsPage = '/news',
}
