import Badge from '@amzn/meridian/badge';
import Row from '@amzn/meridian/row';
import React, { FC } from 'react';
import { TranslatedString, Text } from '../blocks';

export interface BadgeBoxProps {
    label: TranslatedString;
    count: number;
}

const BadgeBox: FC<BadgeBoxProps> = ({ label, count }: BadgeBoxProps) => {
    return (
        <Row spacing={'none'}>
            <Text>{label}</Text>
            <Badge value={count} type={'neutral'} />
        </Row>
    );
};

export { BadgeBox };
