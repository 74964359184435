import Row from '@amzn/meridian/row';
import React, { FC } from 'react';
import { RmaStatus } from '../../redux/types';
import { Text, TranslatedString, useTranslation } from '../blocks/localization';
import {
    StatusPending,
    StatusInfo,
    StatusPositive,
    StatusInProgress,
    StatusNegative,
    StatusStopped,
    StatusWarning,
} from '../icons';

type StatusIconProps = {
    status: string;
};

export const ReturnOrderStatusIcon: FC<StatusIconProps> = ({ status }: StatusIconProps) => {
    const { t } = useTranslation('returnOrderStatus');
    const text: TranslatedString = t('returnOrderStatus-value', { context: status });
    const determineIcon = (status: string) => {
        switch (status) {
            case RmaStatus.CREATED:
                return <StatusPending />;
            case RmaStatus.COMPLETE:
            case RmaStatus.COMPLETED:
                return <StatusPositive />;
            case RmaStatus.AGED_OUT:
            case RmaStatus.EXPIRED:
                return <StatusNegative />;
            case RmaStatus.EDITED:
                return <StatusPending />;
            case RmaStatus.EDITING:
                return <StatusInProgress />;
            case RmaStatus.FORCE_CLOSED:
                return <StatusStopped />;
            case RmaStatus.PARTIALLY_RECEIVED:
                return <StatusInProgress />;
            case RmaStatus.RECEIVING:
                return <StatusInProgress />;
            case RmaStatus.RELEASED:
                return <StatusInfo />;
            case RmaStatus.REVIEWED:
                return <StatusPending />;
            case RmaStatus.RELEASING:
                return <StatusInProgress />;
            case RmaStatus.DRAFTED:
                return <StatusPending />;
            case RmaStatus.SUBMITTED:
                return <StatusPending />;
            case RmaStatus.DELETED:
                return <StatusStopped />;
            default:
                return <StatusWarning />;
        }
    };

    return (
        <Row width={'100%'}>
            {determineIcon(status)}
            <Text>{text}</Text>
        </Row>
    );
};
