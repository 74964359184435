import closeIcon from '@amzn/meridian-tokens/base/icon/close';
import Icon from '@amzn/meridian/icon';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { Button, Select, SelectOption, TranslatedString } from '../blocks';

export type ColumnFilterProps<T = any> = { dataTestId?: string } & {
    placeholder?: TranslatedString;
    label?: TranslatedString;
    values: T[];
    value: T;
    onChange: (newValue: T) => void;
};

export const ColumnFilter = ({ dataTestId, onChange, placeholder, label, value, values }: ColumnFilterProps) => {
    const [filterQuery, setFilterQuery] = useState<string>('');

    const availableValues = useMemo(() => {
        if (filterQuery.length > 0) {
            return _.filter(values, (item) => item.toLowerCase().includes(filterQuery.toLowerCase()));
        } else {
            return values;
        }
    }, [values, filterQuery]);

    return (
        <>
            <Select
                value={value}
                onChange={onChange}
                label={label}
                placeholder={placeholder}
                width={250}
                data-testid={dataTestId}
                searchQuery={filterQuery}
                onSearch={setFilterQuery}
            >
                {availableValues.map((value: string) => (
                    <SelectOption value={value} label={value} key={value} />
                ))}
            </Select>
            <Button type={'primary'} size={'small'} onClick={() => onChange('')} disabled={!value}>
                {(<Icon tokens={closeIcon} />) as any}
            </Button>
        </>
    );
};
