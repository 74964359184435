import { combineReducers } from 'redux';
import attachmentsReducer from './attachmentsReducer';
import { managedUserReducer } from './managedUserReducer';
import { resellerClaimListReducer } from './resellerClaimListReducer';
import { resellerReducer } from './resellerReducer';
import { resellerReturnOrderListReducer } from './resellerReturnOrderListReducer';
import { returnOrderAuditHistoryReducer } from './returnOrderAuditHistoryReducer';
import { returnOrderDraftReducer } from './returnOrderDraftReducer';
import { returnOrderProblemShelfReducer } from './returnOrderProblemShelfReducer';
import { returnOrderReducer } from './returnOrderReducer';
import { systemReducer } from './systemReducer';
import { termsAndConditionsReducer } from './termsAndConditionsReducer';
import { userReducer } from './userReducer';

export const rootReducer = combineReducers({
    systemReducer,
    resellerReducer,
    userReducer,
    managedUserReducer,
    returnOrderAuditHistoryReducer,
    returnOrderDraftReducer,
    returnOrderProblemShelfReducer,
    returnOrderReducer,
    resellerReturnOrderListReducer,
    resellerClaimListReducer,
    termsAndConditionsReducer,
    attachmentsReducer,
});
