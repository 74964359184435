import Column from '@amzn/meridian/column';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import React, { FC } from 'react';
import { SiteHelpCallback } from '../../../constants';
import { Text, useTranslation } from '../../blocks';
import { CreateContactHelp } from './CreateContactHelp';
import { CreateReturnOrderHelp } from './CreateReturnOrderHelp';
import { CreateUserHelp } from './CreateUserHelp';
import { FrequentlyAskedQuestionsHelp } from './FrequentlyAskedQuestions';
import { IneligibleReturnsHelp } from './IneligibleReturnsHelp';
import { LocatingCreditMemoHelp } from './LocatingCreditMemoHelp';
import { ShippingAndPackagingRequirementsHelp } from './ShippingAndPackagingRequirementsHelp';
import { UploadReturnOrderHelp } from './UploadReturnOrderHelp';
import { UserSetupHelp } from './UserSetupHelp';
import { ViewingClaimHelp } from './ViewingClaimHelp';
import { ViewingContactsHelp } from './ViewingContactsHelp';
import { ViewingReturnOrderHelp } from './ViewingReturnOrderHelp';

const IntroductionHelp: FC<SiteHelpCallback> = ({ callback }: SiteHelpCallback) => {
    const { t } = useTranslation(['introductionHelp', 'siteHelpPopover']);

    return (
        <Column spacing={'medium'} maxWidth={'25em'}>
            <Text type={'h200'} alignment={'center'}>
                {t('introductionHelp_Title')}
            </Text>
            <Text type={'b300'} alignment={'left'}>
                {t('introductionHelp_purpose')}
            </Text>
            <Row spacing={'medium'}>
                <Column width={'2%'} />
                <Column spacing={'xsmall'} width={'90%'}>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline1')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('introductionHelp_resellerAmazonCom')}
                        </Text>
                    </Row>
                    <Row spacing={'medium'}>
                        <Column width={'2%'} />
                        <Column spacing={'xsmall'}>
                            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                                <Text type={'b200'} alignment={'left'}>
                                    {t.cognate('•')}
                                </Text>
                                <Link
                                    onClick={() => {
                                        callback(UserSetupHelp);
                                    }}
                                >
                                    {t('introductionHelp_newUserSetup')}
                                </Link>
                            </Row>
                            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                                <Text type={'b200'} alignment={'left'}>
                                    {t.cognate('•')}
                                </Text>
                                <Link
                                    onClick={() => {
                                        callback(CreateUserHelp);
                                    }}
                                >
                                    {t('introductionHelp_createNewUser')}
                                </Link>
                            </Row>
                            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                                <Text type={'b200'} alignment={'left'}>
                                    {t.cognate('•')}
                                </Text>
                                <Link
                                    onClick={() => {
                                        callback(CreateReturnOrderHelp);
                                    }}
                                >
                                    {t('introductionHelp_createReturnOrder')}
                                </Link>
                            </Row>
                            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                                <Text type={'b200'} alignment={'left'}>
                                    {t.cognate('•')}
                                </Text>
                                <Link
                                    onClick={() => {
                                        callback(UploadReturnOrderHelp);
                                    }}
                                >
                                    {t('introductionHelp_uploadReturnOrder')}
                                </Link>
                            </Row>
                            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                                <Text type={'b200'} alignment={'left'}>
                                    {t.cognate('•')}
                                </Text>
                                <Link
                                    onClick={() => {
                                        callback(ViewingReturnOrderHelp);
                                    }}
                                >
                                    {t('introductionHelp_viewingReturnOrder')}
                                </Link>
                            </Row>
                            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                                <Text type={'b200'} alignment={'left'}>
                                    {t.cognate('•')}
                                </Text>
                                <Link
                                    onClick={() => {
                                        callback(CreateContactHelp);
                                    }}
                                >
                                    {t('introductionHelp_createContact')}
                                </Link>
                            </Row>
                            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                                <Text type={'b200'} alignment={'left'}>
                                    {t.cognate('•')}
                                </Text>
                                <Link
                                    onClick={() => {
                                        callback(ViewingContactsHelp);
                                    }}
                                >
                                    {t('introductionHelp_viewContacts')}
                                </Link>
                            </Row>
                            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                                <Text type={'b200'} alignment={'left'}>
                                    {t.cognate('•')}
                                </Text>
                                <Link
                                    onClick={() => {
                                        callback(LocatingCreditMemoHelp);
                                    }}
                                >
                                    {t('introductionHelp_locatingCreditMemo')}
                                </Link>
                            </Row>
                        </Column>
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline2')}
                        </Text>
                        <Link
                            onClick={() => {
                                callback(ShippingAndPackagingRequirementsHelp);
                            }}
                        >
                            {t('introductionHelp_shippingAndPackaging')}
                        </Link>
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline3')}
                        </Text>
                        <Link
                            onClick={() => {
                                callback(IneligibleReturnsHelp);
                            }}
                        >
                            {t('introductionHelp_ineligibleReturns')}
                        </Link>
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline3')}
                        </Text>
                        <Link
                            onClick={() => {
                                callback(ViewingClaimHelp);
                            }}
                        >
                            {t('introductionHelp_claims')}
                        </Link>
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline4')}
                        </Text>
                        <Link
                            onClick={() => {
                                callback(FrequentlyAskedQuestionsHelp);
                            }}
                        >
                            {t('introductionHelp_faq')}
                        </Link>
                    </Row>
                </Column>
            </Row>
            <Column spacing={'xsmall'}>
                <Text type={'b300'} alignment={'left'}>
                    {t('introductionHelp_contactInformation')}
                </Text>
                <Row />
                <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                    <Text type={'b200'} alignment={'left'}>
                        {t.cognate('Amazon:')}
                    </Text>
                    <Text>{t.cognate('amazon-devices-returns@amazon.com')}</Text>
                </Row>
                <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                    <Text type={'b200'} alignment={'left'}>
                        {t.cognate('Ring:')}
                    </Text>
                    <Text>{t.cognate('ring-returns@amazon.com')}</Text>
                </Row>
            </Column>
        </Column>
    );
};

export { IntroductionHelp };
