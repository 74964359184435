import Column from '@amzn/meridian/column';
import React, { FC } from 'react';
import { Checkbox, TranslatedString, useTranslation } from '../blocks/localization';
import { MultiColumnInfoBox } from '../blocks/MultiColumnInfoBoxTile';
import { LabeledInput, LabeledSearchableSelect, StateSelect, CountrySelect } from '.';

export type AddressFormErrors = {
    locationCode?: TranslatedString;
    locationType?: TranslatedString;
    city?: TranslatedString;
    state?: TranslatedString;
    country?: TranslatedString;
    zipCode?: TranslatedString;
    addressLine1?: TranslatedString;
};

type AddressFormProps = {
    locationCode: string;
    setLocationCode: React.Dispatch<React.SetStateAction<string>>;
    locationName: string;
    setLocationName: React.Dispatch<React.SetStateAction<string>>;
    locationType: string;
    setLocationType: React.Dispatch<React.SetStateAction<string>>;
    isPrimary: boolean;
    setIsPrimary: React.Dispatch<React.SetStateAction<boolean>>;
    addressLine1: string;
    setAddressLine1: React.Dispatch<React.SetStateAction<string>>;
    addressLine2: string;
    setAddressLine2: React.Dispatch<React.SetStateAction<string>>;
    addressLine3: string;
    setAddressLine3: React.Dispatch<React.SetStateAction<string>>;
    city: string;
    setCity: React.Dispatch<React.SetStateAction<string>>;
    state: string;
    setState: React.Dispatch<React.SetStateAction<string>>;
    country: string;
    setCountry: React.Dispatch<React.SetStateAction<string>>;
    zipCode: string;
    setZipCode: React.Dispatch<React.SetStateAction<string>>;
    formErrors: AddressFormErrors;
    editMode: boolean;
};

const AddressForm: FC<AddressFormProps> = ({
    locationCode,
    setLocationCode,
    locationName,
    setLocationName,
    locationType,
    setLocationType,
    isPrimary,
    setIsPrimary,
    addressLine1,
    setAddressLine1,
    addressLine2,
    setAddressLine2,
    addressLine3,
    setAddressLine3,
    city,
    setCity,
    state,
    setState,
    country,
    setCountry,
    zipCode,
    setZipCode,
    formErrors,
    editMode,
}) => {
    const { t } = useTranslation('addressForm');

    const locationTypeOptions = [
        { value: 'Unknown', label: t('unknown-selectOptionLabel') },
        { value: 'Warehouse', label: t('warehouse-selectOptionLabel') },
        { value: 'Store', label: t('store-selectOptionLabel') },
        { value: 'Distribution Center', label: t('distributionCenter-selectOptionLabel') },
        { value: 'Billing Office', label: t('billingOffice-selectOptionLabel') },
    ];

    const handleCountryChange = (newCountry: string) => {
        setState('');
        setCity('');
        setZipCode('');
        setCountry(newCountry);
    };

    const handleStateChange = (newState: string) => {
        setCity('');
        setZipCode('');
        setState(newState);
    };

    return (
        <MultiColumnInfoBox widths={['grid-6', 'grid-6']}>
            <Column>
                <LabeledInput
                    label={t('locationCode-fieldLabel')}
                    value={locationCode}
                    onChange={setLocationCode}
                    type={'text'}
                    error={formErrors.locationCode}
                    dataTestId={'addressForm.locationCode'}
                    isDisabled={editMode}
                />
                <LabeledInput
                    label={t('locationName-fieldLabel')}
                    value={locationName}
                    onChange={setLocationName}
                    type={'text'}
                    dataTestId={'addressForm.locationName'}
                    isOptional={true}
                />
                <LabeledSearchableSelect
                    label={t('locationType-fieldLabel')}
                    value={locationType}
                    selectOptions={locationTypeOptions}
                    onChange={setLocationType}
                    error={formErrors.locationType}
                    dataTestId={'addressForm.locationType'}
                />
                <Checkbox checked={isPrimary} onChange={setIsPrimary}>
                    {t('primary-checkboxLabel')}
                </Checkbox>
            </Column>
            <Column>
                <LabeledInput
                    label={t('addressLine1-fieldLabel')}
                    value={addressLine1}
                    onChange={setAddressLine1}
                    type={'text'}
                    error={formErrors.addressLine1}
                    dataTestId={'addressForm.addressLine1'}
                />
                <LabeledInput
                    label={t('addressLine2-fieldLabel')}
                    value={addressLine2}
                    onChange={setAddressLine2}
                    type={'text'}
                    dataTestId={'addressForm.addressLine2'}
                    isOptional={true}
                />
                <LabeledInput
                    label={t('addressLine3-fieldLabel')}
                    value={addressLine3}
                    onChange={setAddressLine3}
                    type={'text'}
                    dataTestId={'addressForm.addressLine3'}
                    isOptional={true}
                />
                <CountrySelect
                    value={country}
                    onChange={handleCountryChange}
                    error={formErrors.country}
                    dataTestId={'addressForm.country'}
                />
                <StateSelect
                    value={state}
                    onChange={handleStateChange}
                    selectedCountry={country}
                    error={formErrors.state}
                    dataTestId={'addressForm.state'}
                />
                <LabeledInput
                    label={t('city-fieldLabel')}
                    value={city}
                    onChange={setCity}
                    type={'text'}
                    error={formErrors.city}
                    dataTestId={'addressForm.city'}
                />
                <LabeledInput
                    label={t('zipCode-fieldLabel')}
                    value={zipCode}
                    onChange={setZipCode}
                    type={'text'}
                    error={formErrors.zipCode}
                    dataTestId={'addressForm.zipCode'}
                />
            </Column>
        </MultiColumnInfoBox>
    );
};

export { AddressForm };
