import { createAsyncThunk } from '@reduxjs/toolkit';
import { useHrpsApi } from '../../clients/useHrpsApi';
import { Attachment } from '../../types';
import { delay } from '../../utils/delay';

const {
    returnOrders: { getReturnOrderDocuments },
} = useHrpsApi();

export const fetchAttachmentsByReturnId = createAsyncThunk(
    'attachments/fetchByReturnId',
    async (options: { returnOrderId: string; delaySeconds?: number }) => {
        const response = await getReturnOrderDocuments(options.returnOrderId);
        if (options.delaySeconds) {
            await delay(options.delaySeconds * 1000);
        }
        return {
            returnOrderId: options.returnOrderId,
            attachments: response.map((document: any) => {
                return {
                    id: document.id as string,
                    status: 'Loaded',
                    lineItemId: (document.key2Value as string) || '',
                    url: document.presignedURL as string,
                    version: document.version,
                    type: document.type,
                    file: {
                        name: document.name as string,
                    },
                } as Attachment;
            }),
        };
    }
);
