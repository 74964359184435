import { Dispatch } from 'redux';
import { HrpsApiClient } from '../../clients';
import { COMPLIANCE_RESOLUTION, INITIAL_RETURN_ORDER_DRAFT_STATE } from '../../constants';
import { RETURN_TYPE } from '../../constants/returnType';
import { Logger } from '../../utils/logger';
import {
    CreateReturnOrderLineItem,
    ReturnOrderDraft,
    ReturnOrderDraftPersistence,
    ReturnOrderDraftState,
} from '../types';
import {
    RETURN_ORDER_DRAFT_CLEAR,
    RETURN_ORDER_DRAFT_GET,
    RETURN_ORDER_DRAFT_LOADED,
    RETURN_ORDER_DRAFT_PERSISTENCE_SET,
    RETURN_ORDER_DRAFT_SET,
    RETURN_ORDER_DRAFT_SHIPPING_ADDRESS_CHANGED,
    RETURN_ORDER_DRAFT_BILLING_ADDRESS_CHANGED,
    ReturnOrderDraftActions,
} from './returnOrderDraftActions';

export function getReturnOrderDraftLoading(): ReturnOrderDraftActions {
    return {
        type: RETURN_ORDER_DRAFT_GET,
        payload: {
            status: 'Loading',
            error: undefined,
            value: undefined,
        },
    };
}

export function getReturnOrderDraftLoaded(returnOrder: ReturnOrderDraft): ReturnOrderDraftActions {
    return {
        type: RETURN_ORDER_DRAFT_LOADED,
        payload: {
            status: 'Loaded',
            error: undefined,
            value: returnOrder,
        },
    };
}

export function getReturnOrderDraftFailed(error: string): ReturnOrderDraftActions {
    return {
        type: RETURN_ORDER_DRAFT_LOADED,
        payload: {
            status: 'Failed',
            error,
            value: undefined,
        },
    };
}

export function setReturnOrderDraftShippingAddress(shippingAddressLocationCode: string): ReturnOrderDraftActions {
    return {
        type: RETURN_ORDER_DRAFT_SHIPPING_ADDRESS_CHANGED,
        payload: shippingAddressLocationCode,
    };
}

export function setReturnOrderDraftBillingAddress(billingAddressLocationCode: string): ReturnOrderDraftActions {
    return {
        type: RETURN_ORDER_DRAFT_BILLING_ADDRESS_CHANGED,
        payload: billingAddressLocationCode,
    };
}

export function getReturnOrderDraft(returnOrderDraftId: string, resellerId: string) {
    return async (dispatch: Dispatch) => {
        dispatch(getReturnOrderDraftLoading());
        const hrpsApiClient = new HrpsApiClient();

        return hrpsApiClient
            .getReturnOrder(returnOrderDraftId, resellerId)
            .then((returnOrder) => {
                Logger.debug(`returned Return Order: `);
                Logger.debug(returnOrder);

                const returnOrderDraft: ReturnOrderDraft = {
                    returnOrderDraftId: returnOrder.returnOrderId,
                    referenceId: returnOrder.customerReferenceId,
                    contactEmailAddresses: returnOrder.contactEmailAddress,
                    returnType: returnOrder.returnType ? returnOrder.returnType : RETURN_TYPE.RETURN,
                    shippingLocationCode: returnOrder.shippingAddress,
                    billingLocationCode: returnOrder.billingAddress,
                    version: returnOrder.version,
                    creationDate: returnOrder.creationDate,
                    orderLines: returnOrder.lineItems.map((item, index) => {
                        return {
                            orderLineId: index,
                            lineItemId: item.lineItemId,
                            itemId: item.itemId,
                            externalSku: undefined,
                            // When the return_type is RETURN, we use the requestedCount, otherwise (for REFUSAL or BUYBACK) we use the number of packages.
                            quantity:
                                returnOrder.returnType && returnOrder.returnType !== RETURN_TYPE.RETURN
                                    ? item.packageQuantity || item.requestedCount
                                    : item.requestedCount,
                            approvedCount:
                                item.complianceResolution !== COMPLIANCE_RESOLUTION.NONE
                                    ? item.approvedCount
                                    : item.requestedCount,
                            reason: item.complianceResolution,
                            status: item.status,
                            complianceCalculationStatus: 'Complete',
                            packaging: item.packageType,
                            totalQuantity: item.requestedCount,
                        } as CreateReturnOrderLineItem;
                    }),
                } as ReturnOrderDraft;
                dispatch(getReturnOrderDraftLoaded(returnOrderDraft));
            })
            .catch((error) => {
                Logger.debug(`error getting draft return order: ${error}`);
                dispatch(getReturnOrderDraftFailed(error));
            });
    };
}

export function setReturnOrderDraft(returnOrderDraft: ReturnOrderDraft): ReturnOrderDraftActions {
    return {
        type: RETURN_ORDER_DRAFT_SET,
        payload: returnOrderDraft,
    };
}

export function setReturnOrderDraftPersistence(
    returnOrderDraftPersistence: ReturnOrderDraftPersistence
): ReturnOrderDraftActions {
    return {
        type: RETURN_ORDER_DRAFT_PERSISTENCE_SET,
        payload: returnOrderDraftPersistence,
    };
}

export function clearReturnOrderDraft(): { payload: ReturnOrderDraftState; type: string } {
    return {
        type: RETURN_ORDER_DRAFT_CLEAR,
        payload: INITIAL_RETURN_ORDER_DRAFT_STATE,
    };
}
