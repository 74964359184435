import { Logger } from './logger';

// assert that given object is neither null nor undefined.
function assertExists<T extends object | string>(value: T | undefined | null, message?: string): asserts value is T {
    if (value === null || value === undefined) {
        const assertionMessage = 'Assertion failed: given object does not exist.';
        Logger.error(assertionMessage, message);
        if (process.env.NODE_ENV === 'development') {
            // Assertion has failed in development environment.
            // The following line is intentional and is only enabled in dev mode.
            // eslint-disable-next-line no-debugger
            debugger;
        }
        // eslint-disable-next-line prefer-template
        throw new Error(`${assertionMessage}${message && ' ' + message}`);
    }
}

function assert(condition: boolean | (() => boolean), ...args: any[]): void {
    if (typeof condition === 'function') {
        let conditionAsFunctionResult: any = false;
        try {
            conditionAsFunctionResult = condition();
        } catch (e) {
            Logger.error('An exception was thrown while evaluating an assertion condition:\n', e);
        }
        (debug as { assert: (...args: any) => void }).assert(conditionAsFunctionResult, ...args);
    } else if (!condition) {
        Logger.error(...['Assertion failed:\n', ...args]);
        if (process.env.NODE_ENV === 'development') {
            // Assertion has failed in development environment.
            // The following line is intentional and is only enabled in dev mode.
            // eslint-disable-next-line no-debugger
            debugger;
        }
    }
}

const debug: {
    assert: (condition: boolean | (() => boolean), ...args: any[]) => void;
    assertExists: <T extends object | string>(value: T | undefined | null, message?: string) => asserts value is T;
} = {
    assert: assert,
    assertExists: assertExists,
};

export { debug as Debug };
