import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from '../blocks';

const Svg = styled.svg`
    width: 16px;
    height: 16px;
    color: #ff6666;
`;

export const Delete = () => {
    const { t } = useTranslation('createReturnOrderPage');
    return (
        <Svg width={'16px'} height={'16px'} aria-label={t('deleteOrderLine-buttonLabel')}>
            <g id={'icons'}>
                <circle stroke={'currentColor'} strokeWidth={'2'} fill={'none'} cx={'8'} cy={'8'} r={'7'} />
                <line
                    stroke={'currentColor'}
                    strokeWidth={'1.5'}
                    fill={'none'}
                    strokeLinecap={'round'}
                    x1={'5'}
                    y1={'5'}
                    x2={'11'}
                    y2={'11'}
                />
                <line
                    stroke={'currentColor'}
                    strokeWidth={'1.5'}
                    fill={'none'}
                    strokeLinecap={'round'}
                    x1={'11'}
                    y1={'5'}
                    x2={'5'}
                    y2={'11'}
                />
            </g>
        </Svg>
    );
};
