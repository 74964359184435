// Note: consoleErrorIndicator must be imported before any other console-dependent code.
import './utils/consoleErrorIndicator';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './app/App';
import { PageMessaging } from './components/composites';
import { Modal } from './components/composites/Modal';
import { store } from './redux';
import * as serviceWorker from './serviceWorker';
import '@amzn/meridian-tokens/base/font/amazon-ember.css';
import './i18n';
import './index.css';
import './utils/consoleWarningSuppression';
import { siteMap } from './siteMap';
import { Logger } from './utils/logger';
import { SiteMapRouter } from './utils/SiteMapRouter';

// noinspection SpellCheckingInspection
const unhandledRejection = (e: PromiseRejectionEvent) => {
    Logger.error('An unhandled rejection occurred:', e);
};
window.onunhandledrejection = unhandledRejection;

// FIXME: Suspense should be changed to waitFor or something similar for handling loading translations
ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Suspense fallback={'Loading...'}>
                <PageMessaging>
                    <Modal>
                        <Router>
                            <SiteMapRouter siteMap={siteMap}>
                                <App />
                            </SiteMapRouter>
                        </Router>
                    </Modal>
                </PageMessaging>
            </Suspense>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
