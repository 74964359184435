import userTokens from '@amzn/meridian-tokens/base/icon/user';
import Icon from '@amzn/meridian/icon';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Thumbnail from '@amzn/meridian/thumbnail';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { IUser } from '../../redux/types';
import { color } from '../../theme/colors';

const PlaceholderIcon = styled(Icon)`
    padding: 20px;
    border: 1px solid ${color.gray[500]};
    border-radius: 100%;
`;

export interface UserProps {
    user: IUser;
    dataTestId?: string;
}

export const userDisplayName = (user: IUser) => `${user.givenName} ${user.familyName}`;

const UserBox: FC<UserProps> = ({ user, dataTestId }: UserProps) => {
    return (
        <Row spacing={'medium'} widths={'fit'} data-testid={dataTestId}>
            {user.avatarUrl ? (
                <Thumbnail size={'small'} source={user.avatarUrl} />
            ) : (
                <PlaceholderIcon tokens={userTokens} />
            )}
            <Text>{userDisplayName(user)}</Text>
        </Row>
    );
};

export { UserBox };
