import Column from '@amzn/meridian/column';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import React, { FC } from 'react';
import loginPage from '../../../assets/loginPage.png';
import passwordResetPage from '../../../assets/passwordResetPage.png';
import { SiteHelpCallback } from '../../../constants';
import { ItemImage, Text, useTranslation } from '../../blocks';
import { IntroductionHelp } from './IntroductionHelp';

const FrequentlyAskedQuestionsHelp: FC<SiteHelpCallback> = ({ callback }: SiteHelpCallback) => {
    const { t } = useTranslation(['frequentlyAskedQuestionsHelp', 'siteHelpPopover']);

    return (
        <Column spacing={'medium'} maxWidth={'25em'}>
            <Row width={'100%'} widths={['85%', '15%']}>
                <Text type={'h200'} alignment={'center'}>
                    {t('frequentlyAskedQuestionsHelp_Title')}
                </Text>
                <Link
                    onClick={() => {
                        callback(IntroductionHelp);
                    }}
                >
                    {t('siteHelpPopover:siteHelpPopover_helpHomePage')}
                </Link>
            </Row>
            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                <Text type={'b200'} alignment={'left'}>
                    {t('frequentlyAskedQuestionsHelp_question')}
                </Text>
                <Text type={'b200'} alignment={'left'}>
                    {t('frequentlyAskedQuestionsHelp_receivedPartialCreditQuestion')}
                </Text>
            </Row>
            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                <Text type={'b200'} alignment={'left'}>
                    {t('frequentlyAskedQuestionsHelp_answer')}
                </Text>
                <Text type={'b200'} alignment={'left'}>
                    {t('frequentlyAskedQuestionsHelp_receiptOfEntireShipmentAnswer')}
                </Text>
            </Row>
            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                <Text type={'b200'} alignment={'left'}>
                    {t('frequentlyAskedQuestionsHelp_question')}
                </Text>
                <Text type={'b200'} alignment={'left'}>
                    {t('frequentlyAskedQuestionsHelp_unauthorizedProductQuestion')}
                </Text>
            </Row>
            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                <Text type={'b200'} alignment={'left'}>
                    {t('frequentlyAskedQuestionsHelp_answer')}
                </Text>
                <Text type={'b200'} alignment={'left'}>
                    {t('frequentlyAskedQuestionsHelp_unauthorizedProductAnswer')}
                </Text>
            </Row>
            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                <Text type={'b200'} alignment={'left'}>
                    {t('frequentlyAskedQuestionsHelp_question')}
                </Text>
                <Text type={'b200'} alignment={'left'}>
                    {t('frequentlyAskedQuestionsHelp_whoContactQuestion')}
                </Text>
            </Row>
            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                <Text type={'b200'} alignment={'left'}>
                    {t('frequentlyAskedQuestionsHelp_answer')}
                </Text>
                <Text type={'b200'} alignment={'left'}>
                    {t('frequentlyAskedQuestionsHelp_whoContactAnswer')}
                </Text>
            </Row>
            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                <Text type={'b200'} alignment={'left'}>
                    {t('frequentlyAskedQuestionsHelp_question')}
                </Text>
                <Text type={'b200'} alignment={'left'}>
                    {t('frequentlyAskedQuestionsHelp_creditQuicklyAndAccuratelyQuestion')}
                </Text>
            </Row>
            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                <Text type={'b200'} alignment={'left'}>
                    {t('frequentlyAskedQuestionsHelp_answer')}
                </Text>
                <Text type={'b200'} alignment={'left'}>
                    {t('frequentlyAskedQuestionsHelp_whoContactAnswer')}
                </Text>
            </Row>
            <Column spacing={'xsmall'}>
                <Row spacing={'small'}>
                    <Text>{t.cognate('')}</Text>
                    <Text type={'b300'} alignment={'left'}>
                        {t.cognate('✓')}
                    </Text>
                    <Text type={'b200'} alignment={'left'}>
                        {t('frequentlyAskedQuestionsHelp_creditQAA_Step1')}
                    </Text>
                </Row>
                <Row spacing={'small'}>
                    <Text>{t.cognate('')}</Text>
                    <Text type={'b300'} alignment={'left'}>
                        {t.cognate('✓')}
                    </Text>
                    <Text type={'b200'} alignment={'left'}>
                        {t('frequentlyAskedQuestionsHelp_creditQAA_Step2')}
                    </Text>
                </Row>
                <Row spacing={'small'}>
                    <Text>{t.cognate('')}</Text>
                    <Text type={'b300'} alignment={'left'}>
                        {t.cognate('✓')}
                    </Text>
                    <Text type={'b200'} alignment={'left'}>
                        {t('frequentlyAskedQuestionsHelp_creditQAA_Step3')}
                    </Text>
                </Row>
                <Row spacing={'small'}>
                    <Text>{t.cognate('')}</Text>
                    <Text type={'b300'} alignment={'left'}>
                        {t.cognate('✓')}
                    </Text>
                    <Text type={'b200'} alignment={'left'}>
                        {t('frequentlyAskedQuestionsHelp_creditQAA_Step4')}
                    </Text>
                </Row>
                <Row spacing={'small'}>
                    <Text>{t.cognate('')}</Text>
                    <Text type={'b300'} alignment={'left'}>
                        {t.cognate('✓')}
                    </Text>
                    <Text type={'b200'} alignment={'left'}>
                        {t('frequentlyAskedQuestionsHelp_creditQAA_Step5')}
                    </Text>
                </Row>
            </Column>
            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                <Text type={'b200'} alignment={'left'}>
                    {t('frequentlyAskedQuestionsHelp_question')}
                </Text>
                <Text type={'b200'} alignment={'left'}>
                    {t('frequentlyAskedQuestionsHelp_loginQuestion')}
                </Text>
            </Row>
            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                <Text type={'b200'} alignment={'left'}>
                    {t('frequentlyAskedQuestionsHelp_answer')}
                </Text>
                <Text type={'b200'} alignment={'left'}>
                    {t('frequentlyAskedQuestionsHelp_loginAnswer1')}
                </Text>
            </Row>
            <ItemImage src={loginPage} />
            <Text type={'b200'} alignment={'left'}>
                {t('frequentlyAskedQuestionsHelp_loginAnswer2')}
            </Text>
            <ItemImage src={passwordResetPage} />
            <Text type={'b200'} alignment={'left'}>
                {t('frequentlyAskedQuestionsHelp_loginAnswer3')}
            </Text>
        </Column>
    );
};

export { FrequentlyAskedQuestionsHelp };
