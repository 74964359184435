import infoIcon from '@amzn/meridian-tokens/base/icon/info';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import Loader from '@amzn/meridian/loader';
import { css } from 'emotion';
import React from 'react';
import { Button, Text, TranslatedString, useTranslation } from './localization';

interface TruncatedListItem {
    id: string;
    text: string;
}

interface TruncatedListProps {
    title: TranslatedString;
    items: TruncatedListItem[];
    limit: number;
    onItemClick: (item: TruncatedListItem) => void;
    onMoreClick: () => void;
    isLoading?: boolean;
}

const linkButton = css`
    padding: 0 !important;
    height: 20px !important;
`;

export const TruncatedList = ({ items, limit, onItemClick, onMoreClick, title, isLoading }: TruncatedListProps) => {
    const { t } = useTranslation('truncatedList');
    return (
        <Column alignmentHorizontal={'left'} spacing={'small'}>
            <Text type={'b400'}>
                {title}
                <Button
                    type={'icon'}
                    onClick={onMoreClick}
                    disabled={isLoading}
                    data-testid={'truncatedListMoreButton'}
                >
                    <Icon tokens={infoIcon}>{t('showFullList-truncatedListInfoIconTooltip')}</Icon>
                </Button>
            </Text>
            {isLoading && <Loader size={'small'} data-testid={'truncatedListLoader'} />}
            {!isLoading && (
                <>
                    {items.slice(0, limit).map((item) => (
                        <Button
                            type={'link'}
                            onClick={() => onItemClick(item)}
                            size={'small'}
                            key={item.id}
                            className={linkButton}
                            data-testid={`truncatedListItem[${item.id}]`}
                        >
                            {t.cognate(item.text)}
                        </Button>
                    ))}
                    {items.length > limit ? (
                        <Button type={'link'} onClick={onMoreClick} size={'small'} className={linkButton}>
                            {t('more-truncatedListMoreLinkText', {
                                count: items.length - limit,
                            })}
                        </Button>
                    ) : null}
                </>
            )}
        </Column>
    );
};
