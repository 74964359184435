import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import React, { FC } from 'react';
import { Alert, Input, TranslatedString, useTranslation } from '../blocks';

export interface InputProps {
    label: TranslatedString;
    isOptional?: boolean;
    value: string;
    onBlur?(value: string): void;
    onChange?(value: string): void;
    type?: 'number' | 'password' | 'time' | 'text' | 'date' | 'email' | 'search' | 'tel' | 'url';
    placeholder?: TranslatedString;
    error?: TranslatedString;
    isDisabled?: boolean;
    dataTestId?: string;
    autoFocus?: boolean;
    helpBox?: React.ReactNode;
    spellCheck?: boolean;
    useLabel?: boolean;
}

const LabeledInput: FC<InputProps> = ({
    label,
    value,
    onBlur,
    onChange,
    type,
    placeholder,
    error,
    isDisabled,
    dataTestId,
    isOptional,
    autoFocus,
    helpBox,
    spellCheck,
}: InputProps) => {
    const { t } = useTranslation('labeledInput');

    return (
        <Column spacing={'xsmall'}>
            <Row widths={['fill', 'fit']}>
                <Input
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    type={type}
                    placeholder={placeholder}
                    error={!!error}
                    disabled={isDisabled}
                    size={'small'}
                    data-testid={dataTestId}
                    autoFocus={autoFocus}
                    spellcheck={spellCheck}
                    suffix={isOptional ? t('optional-optionalFieldLabel') : undefined}
                    label={label}
                />
                {helpBox}
            </Row>
            <Row>
                {error && (
                    <div data-testid={dataTestId && `${dataTestId}.validationError`}>
                        <Alert type={'error'} size={'small'}>
                            {error}
                        </Alert>
                    </div>
                )}
            </Row>
        </Column>
    );
};

export { LabeledInput };
