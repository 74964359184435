import { AsynchronouslyLoadedValueStatus, IAsynchronouslyLoadedValue } from './asynchronouslyLoadedValue';

export const DEFAULT_ITEM_IMAGE_URL =
    'https://images-na.ssl-images-amazon.com/images/G/01/x-site/icons/no-img-sm._V192198896_.gif';

export const ASIN_MISSING_PLACEHOLDER = 'asin-missing-placeholder';

export interface IResellerState {
    selectedReseller: IAsynchronouslyLoadedValue<IAuthorizedReseller>;
    authorizedResellers: IAsynchronouslyLoadedValue<IAuthorizedReseller[]>;
    resellerDetails: IAsynchronouslyLoadedValue<ResellerDetails>;
    resellerItems: IAsynchronouslyLoadedValue<ResellerItem[]>;
    resellerPriceList: IAsynchronouslyLoadedValue<ResellerPriceList>;
    asinToImageUrls: IAsynchronouslyLoadedValue<AsinToImageUrlMap>;
    unmappedItemStatus: AsynchronouslyLoadedValueStatus;
}

export interface IReseller {
    countryCode: string;
    customerType: string;
    effectiveDate?: Date | null;
    name: string;
    parentResellerId?: string | null;
    resellerId: string;
    resellerType: string;
    status: string;
    terminationDate?: Date | null;
    amazonLegalEntityId: string;
    supportZeroCreditReturn: boolean;
}

export interface IAuthorizedReseller {
    countryCode: string;
    name: string;
    resellerId: string;
    status: string;
    supportZeroCreditReturn: boolean;
}

export interface ResellerDetails {
    resellerLocations: ResellerLocations;
    contacts: Contact[];
}

export interface ResellerLocations {
    locations: Location[];
    primaryShippingAddressLocationCode?: string;
    primaryBillingAddressLocationCode?: string;
}

export interface Location {
    id: number;
    code: string;
    name?: string;
    type: string;
    billingAddress?: Address;
    shippingAddress?: Address;
}

export interface Address {
    streetAddress: string[];
    city: string;
    state: string;
    country: string;
    zipCode: string;
}

export interface Contact {
    contactId: number;
    resellerId: string;
    firstName: string;
    lastName: string;
    notes: string;
    phone: string;
    email: string;
    status: string;
    contactType: string;
    role: string;
    cellPhone: string;
    fax: string;
}

export interface ResellerItem {
    itemId: string;
    externalSku: string;
    upc: string;
    asin: string;
    itemType: string;
    title: string;
    unitsPerCase?: number;
    unitsOnPallet?: number;
}

export interface ResellerPriceList {
    currencyCode: string;
    priceListItems: PriceListItem[];
}

export interface PriceListItem {
    itemId: string;
    basePrice: number;
}

export type AsinToImageUrlMap = Map<string, string>;
