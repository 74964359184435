import Box from '@amzn/meridian/box';
import Row from '@amzn/meridian/row';
import React, { FC, useCallback, useState } from 'react';
import { IAuthorizedReseller, TableData } from '../../redux/types';
import { Checkbox, ControlGroup, RadioButton, SearchField, Text, TranslatedString, useTranslation } from '../blocks';
import { PaginatedTable } from './PaginatedTable';

export interface ResellerTableProps {
    children?: React.ReactNode;
    availableResellers?: IAuthorizedReseller[];
    selectedReseller?: string;
    selectedResellers?: string[];
    onSelectionChange(fieldName: string): void;
    useRadioComponent?: boolean;
}

function cognate(value: string): TranslatedString {
    // as of now "translation marker" is just a type brand (a cast).
    return value as TranslatedString;
}

const ResellerTable: FC<ResellerTableProps> = ({
    availableResellers,
    selectedReseller,
    selectedResellers,
    onSelectionChange,
    useRadioComponent,
}: ResellerTableProps) => {
    const { t } = useTranslation(['userResellerTable']);
    const RESELLERS_PER_PAGE: number = 10;

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [sortColumn, setSortColumn] = useState<string>('username');
    const [sortDirection, setSortDirection] = useState<'ascending' | 'descending'>('ascending');
    const [curSearchValue, setCurSearchValue] = useState<string>('');

    const onPageClick = (value: string) => {
        setCurrentPage(parseInt(value));
    };

    const onSort = useCallback(({ sortColumn, sortDirection }) => {
        setSortColumn(sortColumn);
        setSortDirection(sortDirection);
    }, []);

    const onSearch = (value: string) => {
        setCurSearchValue(value);
        setCurrentPage(1);
    };

    function foundResellers(element: IAuthorizedReseller) {
        const lowerCurSearchValue = curSearchValue.toLowerCase();

        const found =
            curSearchValue &&
            (element.name.toLowerCase().includes(lowerCurSearchValue) ||
                element.resellerId.toLowerCase().includes(lowerCurSearchValue) ||
                element.countryCode.toLowerCase().includes(lowerCurSearchValue));

        return found;
    }

    const formatSortColumn = (reseller: IAuthorizedReseller) => {
        switch (sortColumn) {
            case 'id':
                return `${reseller.resellerId}`;
            case 'countryCode':
                return `${reseller.countryCode}, ${reseller.name}`;
            case 'name':
            default:
                return `${reseller.name}, ${reseller.resellerId}`;
        }
    };

    const formatData = (filteredResellers: IAuthorizedReseller[]) => {
        const compare = function (resellerA: IAuthorizedReseller, resellerB: IAuthorizedReseller) {
            const order = (a: IAuthorizedReseller, b: IAuthorizedReseller) =>
                (sortDirection === 'descending' ? -1 : 1) * formatSortColumn(a).localeCompare(formatSortColumn(b));
            return order(resellerA, resellerB);
        };

        const sortedResellers = filteredResellers.sort(compare);

        const startPos = (currentPage - 1) * RESELLERS_PER_PAGE;
        const endPos = currentPage * RESELLERS_PER_PAGE;

        const pageResellers: IAuthorizedReseller[] = sortedResellers.slice(startPos, endPos);

        const tableData = new TableData(pageResellers, [
            {
                title: t('resellerName-columnHeader'),
                uniqueId: 'name',
                format: (pageReseller: IAuthorizedReseller) => {
                    return (
                        <>
                            {!useRadioComponent && (
                                <ControlGroup
                                    onChange={onSelectionChange}
                                    value={selectedResellers}
                                    date-testid={`userResellerTable.resellers{pageReseller.resellerId}`}
                                >
                                    <Checkbox
                                        key={pageReseller.resellerId}
                                        value={pageReseller.resellerId}
                                        checked={
                                            selectedResellers?.find(
                                                (userReseller) => userReseller === pageReseller.resellerId
                                            ) !== undefined
                                        }
                                    >
                                        {cognate(pageReseller.name)}
                                    </Checkbox>
                                </ControlGroup>
                            )}
                            {useRadioComponent && (
                                <RadioButton
                                    key={pageReseller.resellerId}
                                    value={pageReseller.resellerId}
                                    checked={selectedReseller === pageReseller.resellerId}
                                    onChange={onSelectionChange}
                                    data-testid={`resellerListTable.radioButton.${pageReseller.resellerId}`}
                                >
                                    {t.cognate(pageReseller.name)}
                                </RadioButton>
                            )}
                        </>
                    );
                },
            },
            {
                title: t('resellerId-columnHeader'),
                uniqueId: 'id',
                format: 'resellerId',
            },
            { title: t('resellerCountryCode-columnHeader'), uniqueId: 'countryCode', format: 'countryCode' },
        ]);
        return tableData;
    };

    const allResellers: IAuthorizedReseller[] = availableResellers || [];
    const filteredResellers: IAuthorizedReseller[] = curSearchValue
        ? allResellers.filter(foundResellers)
        : allResellers;
    const remainder = filteredResellers.length % RESELLERS_PER_PAGE;
    const numberOfPages = (filteredResellers.length - remainder) / RESELLERS_PER_PAGE + (remainder > 0 ? 1 : 0);

    const data = formatData(filteredResellers);

    return (
        <PaginatedTable
            data={data}
            dataTestId={'resellerListTable'}
            currentPage={currentPage}
            numberOfPages={numberOfPages}
            onPageChange={onPageClick}
            onSort={onSort}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
        >
            <Box width={'100%'} spacingInset={'none'} data-testid={'resellerListTable.searchBox'}>
                <Row spacing={'small'}>
                    <SearchField
                        value={curSearchValue}
                        onChange={onSearch}
                        label={t('searchBar-placeholder')}
                        onSubmit={onSearch}
                        size={'medium'}
                        searchButton={false}
                        data-testid={'userResellerTable.searchField'}
                    />
                    <Text data-testid={'ResellerListSearchResultText'}>
                        {t('resellerMatches-searchHeader', { count: filteredResellers.length })}
                    </Text>
                </Row>
            </Box>
        </PaginatedTable>
    );
};

export { ResellerTable };
