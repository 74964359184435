import Column from '@amzn/meridian/column';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import React, { FC } from 'react';
import templateDownload from '../../../assets/templateDownload.png';
import uploadAndSubmit from '../../../assets/uploadAndSubmit.png';
import uploadReturnOrderNavigation from '../../../assets/uploadReturnOrderNavigation.png';
import { SiteHelpCallback } from '../../../constants';
import { ItemImage, Text, useTranslation } from '../../blocks';
import { IntroductionHelp } from './IntroductionHelp';

const UploadReturnOrderHelp: FC<SiteHelpCallback> = ({ callback }: SiteHelpCallback) => {
    const { t } = useTranslation(['uploadReturnOrderHelp', 'siteHelpPopover']);

    return (
        <Column spacing={'medium'} maxWidth={'25em'}>
            <Row width={'100%'} widths={['85%', '15%']}>
                <Text type={'h200'} alignment={'center'}>
                    {t('uploadReturnOrderHelp_Title')}
                </Text>
                <Link
                    onClick={() => {
                        callback(IntroductionHelp);
                    }}
                >
                    {t('siteHelpPopover:siteHelpPopover_helpHomePage')}
                </Link>
            </Row>
            <Text type={'b300'} alignment={'left'}>
                {t('uploadReturnOrderHelp_purpose')}
            </Text>
            <Row spacing={'medium'}>
                <Column width={'2%'} />
                <Column spacing={'xsmall'} width={'90%'}>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline1')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('uploadReturnOrderHelp_navigateToUploadReturnOrder')}
                        </Text>
                    </Row>
                    <Row>
                        <ItemImage src={uploadReturnOrderNavigation} />
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline2')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('uploadReturnOrderHelp_downloadTemplate')}
                        </Text>
                    </Row>
                    <Row>
                        <ItemImage src={templateDownload} />
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline3')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('uploadReturnOrderHelp_enterHeaderData')}
                        </Text>
                    </Row>
                    <Row spacing={'medium'}>
                        <Column width={'2%'} />
                        <Column spacing={'xsmall'}>
                            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                                <Text type={'b200'} alignment={'left'}>
                                    {t.cognate('•')}
                                </Text>
                                <Text type={'b200'} alignment={'left'}>
                                    {t('uploadReturnOrderHelp_inputReferenceId')}
                                </Text>
                            </Row>
                            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                                <Text type={'b200'} alignment={'left'}>
                                    {t.cognate('•')}
                                </Text>
                                <Text type={'b200'} alignment={'left'}>
                                    {t('uploadReturnOrderHelp_contactEmails')}
                                </Text>
                            </Row>
                            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                                <Text type={'b200'} alignment={'left'}>
                                    {t.cognate('•')}
                                </Text>
                                <Text type={'b200'} alignment={'left'}>
                                    {t('uploadReturnOrderHelp_locationCodes')}
                                </Text>
                            </Row>
                        </Column>
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline4')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('uploadReturnOrderHelp_enterLineItems')}
                        </Text>
                    </Row>
                    <Row spacing={'medium'}>
                        <Column width={'2%'} />
                        <Column spacing={'xsmall'}>
                            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                                <Text type={'b200'} alignment={'left'}>
                                    {t.cognate('•')}
                                </Text>
                                <Text type={'b200'} alignment={'left'}>
                                    {t('uploadReturnOrderHelp_selectAction')}
                                </Text>
                            </Row>
                            <Row spacing={'medium'}>
                                <Column width={'2%'} />
                                <Column spacing={'xsmall'}>
                                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                                        <Text type={'b200'} alignment={'left'}>
                                            {t.cognate('o')}
                                        </Text>
                                        <Text type={'b200'} alignment={'left'}>
                                            {t('uploadReturnOrderHelp_actionAccept')}
                                        </Text>
                                    </Row>
                                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                                        <Text type={'b200'} alignment={'left'}>
                                            {t.cognate('o')}
                                        </Text>
                                        <Text type={'b200'} alignment={'left'}>
                                            {t('uploadReturnOrderHelp_actionEligible')}
                                        </Text>
                                    </Row>
                                </Column>
                            </Row>
                        </Column>
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline5')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('uploadReturnOrderHelp_saveFile')}
                        </Text>
                    </Row>
                    <Row>
                        <ItemImage src={uploadAndSubmit} />
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline6')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('uploadReturnOrderHelp_editDraft')}
                        </Text>
                    </Row>
                </Column>
            </Row>
        </Column>
    );
};

export { UploadReturnOrderHelp };
