// @ts-ignore
import Column from '@amzn/meridian/column';
import Divider from '@amzn/meridian/divider';
import Link from '@amzn/meridian/link';
import Modal from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import Textarea from '@amzn/meridian/textarea';
import React, { useCallback, useState } from 'react';
import { useHrpsApi } from '../../clients/useHrpsApi';
import { IUser } from '../../redux/types';
import { Issue } from '../../types';
import { Logger } from '../../utils/logger';
import { Button, Text, TranslatedString, useTranslation } from '../blocks';
import { Tile, usePageMessaging } from '../composites';
import LabeledField from '../composites/LabeledField';

export interface IssueDetailDialogProps {
    label: TranslatedString;
    issue: Issue;
    resellerId: string;
    user: IUser;
    dataTestId?: string;
}

const IssueDetailDialog = ({ label, issue, resellerId, user, dataTestId }: IssueDetailDialogProps) => {
    const { t } = useTranslation('issueDetailsDialog');
    const [open, setOpen] = useState(false);
    const [correspondenceText, setCorrespondenceText] = useState('');
    const onClickButton = useCallback(() => setOpen(true), []);
    const onClose = useCallback(() => setOpen(false), []);
    const [pageIsBusy, setPageIsBusy] = useState(false);
    const { showError } = usePageMessaging();

    const {
        issues: { addCorrespondence },
    } = useHrpsApi();

    const addToCorrespondence = async () => {
        setPageIsBusy(true);

        addCorrespondence({
            resellerId,
            issueId: issue.id,
            correspondence: correspondenceText,
        })
            .then((addCorrespondenceOutput) => {
                issue.conversation.push({
                    id: addCorrespondenceOutput.correspondenceId,
                    author: user.username,
                    createDate: new Date(Date.now()),
                    lastUpdatedDate: new Date(Date.now()),
                    message: correspondenceText,
                });

                setCorrespondenceText('');
            })
            .catch((error) => {
                Logger.error(`Failed to add correspondence: ${error}`);
                showError(t(`addCorrespondence-errorMessage`));
            })
            .finally(() => setPageIsBusy(false));
    };

    return (
        <>
            <Link onClick={onClickButton} type={'primary'} data-testid={`${dataTestId}.link`}>
                <Text>{label}</Text>
            </Link>
            <Modal open={open} onClose={onClose} title={t('issueDetails-title')}>
                <Column spacing={'small'}>
                    <Tile title={t.cognate(issue.title)} width={'100%'} contentSpacingInset={'medium'}>
                        <LabeledField label={t('issueSubmitter-label')} text={t.cognate(issue.submitter)} />
                        <Divider size={'small'} spacingBefore={'small'} />
                        <LabeledField
                            label={t('issueDate-label')}
                            text={t('issueDate', { dateTime: issue.lastUpdatedDate })}
                        />
                        <Divider size={'small'} spacingBefore={'small'} />
                        <LabeledField
                            label={t('issueStatus-label')}
                            text={t('issueStatus-value', { context: issue.status.replace(/\s/g, '') })}
                        />
                        <Divider size={'small'} spacingBefore={'small'} />
                        <LabeledField
                            label={t('issueDescription-label')}
                            text={t.cognate(issue.description)}
                            split={true}
                        />
                    </Tile>
                    <Tile title={t('issueConversation-header')} width={'100%'} contentSpacingInset={'medium'}>
                        <Column spacing={'small'}>
                            {issue.status === 'Resolved' ? (
                                <Text>{t('issueResolvedNotice-text')}</Text>
                            ) : (
                                [
                                    <Textarea
                                        value={correspondenceText}
                                        label={t('addCorrespondence-label')}
                                        onChange={setCorrespondenceText}
                                        key={'addCorrespondence-textArea'}
                                        data-testid={'addCorrespondence-textArea'}
                                    />,
                                    <Row alignmentHorizontal={'right'} key={'addCorrespondence-button'}>
                                        <Button
                                            onClick={addToCorrespondence}
                                            disabled={correspondenceText.length <= 0 || pageIsBusy}
                                            data-testid={'addCorrespondence-button'}
                                        >
                                            {t('addCorrespondence-button')}
                                        </Button>
                                    </Row>,
                                ]
                            )}
                            {issue.conversation
                                .sort((a, b) => (a.createDate < b.createDate ? 1 : -1))
                                .map((message) => [
                                    <Divider size={'small'} spacingBefore={'small'} key={`${message.id}_divider`} />,
                                    <Row key={`${message.id}_message`} alignmentVertical={'top'}>
                                        <Column spacing={'xsmall'} alignmentHorizontal={'left'}>
                                            <Text>{t.cognate(message.author)}</Text>
                                            <Text color={'secondary'}>
                                                {t('issueDate', { dateTime: message.lastUpdatedDate })}
                                            </Text>
                                            {message.message.split('\n').map((str) => (
                                                <Text key={str}>{str as TranslatedString}</Text>
                                            ))}
                                        </Column>
                                    </Row>,
                                ])}
                        </Column>
                    </Tile>
                </Column>
            </Modal>
        </>
    );
};

export { IssueDetailDialog };
