import React from 'react';
import { TranslatedString } from '../../../blocks/localization';
import { SearchableSelect } from '../../../composites/SearchableSelect';
import { MultilineFormEditorColumnFormat } from '../MultilineFormEditor';

export const searchableSelectFormat = (options: {
    selectOptions: { value: string; label: TranslatedString }[];
    isDisabled?: boolean | ((item: any) => boolean);
    isVisible?: boolean | ((item: any) => boolean);
    label?: TranslatedString;
    placeholder?: TranslatedString;
    width?: number;
}): MultilineFormEditorColumnFormat => ({
    render: (item, column, lineIndex, onChange) => {
        let isDisabledValue: boolean = false;
        if (typeof options.isDisabled === 'boolean') {
            isDisabledValue = options.isDisabled;
        } else if (typeof options.isDisabled === 'function') {
            isDisabledValue = options.isDisabled(item);
        }

        let isVisibleValue: boolean = true;
        if (typeof options.isVisible === 'boolean') {
            isVisibleValue = options.isVisible;
        } else if (typeof options.isVisible === 'function') {
            isVisibleValue = options.isVisible(item);
        }
        return (
            <SearchableSelect
                value={item[column.sourceProperty]}
                onChange={onChange(column.sourceProperty, lineIndex)}
                selectOptions={options.selectOptions}
                isDisabled={isDisabledValue}
                placeholder={options.placeholder}
                label={options.label}
                width={options.width}
                isVisible={isVisibleValue}
            />
        );
    },
});
