import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from '../blocks';

const Svg = styled.svg`
    width: 32px;
    height: 32px;
    color: #66ff66;
`;

export const Delighted = () => {
    const { t } = useTranslation('delight');
    return (
        <Svg width={'32px'} height={'32px'} aria-label={t('delighted-iconLabel')}>
            <g id={'icons'}>
                <circle stroke={'#555555'} strokeWidth={'2'} fill={'none'} cx={'16'} cy={'16'} r={'15'} />
                <circle stroke={'black'} strokeWidth={'1'} fill={'currentColor'} cx={'16'} cy={'16'} r={'14'} />
                <line
                    stroke={'black'}
                    strokeWidth={'1.5'}
                    fill={'none'}
                    strokeLinecap={'round'}
                    x1={'11.5'}
                    y1={'10'}
                    x2={'11.5'}
                    y2={'16'}
                />
                <line
                    stroke={'black'}
                    strokeWidth={'1.5'}
                    fill={'none'}
                    strokeLinecap={'round'}
                    x1={'20.5'}
                    y1={'10'}
                    x2={'20.5'}
                    y2={'16'}
                />
                <path
                    stroke={'black'}
                    strokeWidth={'1.5'}
                    fill={'none'}
                    strokeLinecap={'round'}
                    d={'M 23,22 A 7.5,6 0 0 1 9,22'}
                />
            </g>
        </Svg>
    );
};
