import Column from '@amzn/meridian/column';
import Loader from '@amzn/meridian/loader';
import React from 'react';
import { Input, TranslatedString } from '../../../blocks/localization';
import { MultilineFormEditorColumnFormat } from '../MultilineFormEditor';

export const numberFormat = (options: {
    min?: number;
    max?: number;
    isDisabled?: boolean;
    placeholder?: TranslatedString;
    label?: TranslatedString;
    onBlur?: () => void;
    isLoading?: (item: any) => boolean;
}): MultilineFormEditorColumnFormat => ({
    render: (item, column, lineIndex, onChange) => {
        const isNotInRange = () => {
            if (
                (options.min !== undefined && item[column.sourceProperty] < options.min) ||
                (options.max !== undefined && item[column.sourceProperty] > options.max)
            ) {
                return true;
            }
            return false;
        };

        if (options.isLoading) {
            if (options.isLoading(item)) {
                return (
                    <Column alignmentHorizontal={'center'}>
                        <Loader size={'small'} />
                    </Column>
                );
            }
        }

        return (
            <Input
                value={item[column.sourceProperty]}
                onChange={onChange(column.sourceProperty, lineIndex)}
                type={'number'}
                disabled={options.isDisabled}
                error={isNotInRange()}
                aria-label={options.label}
                placeholder={options.placeholder}
                onBlur={options.onBlur}
            />
        );
    },
});
