import closeTokens from '@amzn/meridian-tokens/base/icon/close';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import Row from '@amzn/meridian/row';
import Sheet from '@amzn/meridian/sheet';
import React, { useEffect, useState } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { Button, ProtectedRoute } from '../components/blocks';
import { IntroductionHelp } from '../components/constructed/Help';
import { HelpPageComponent } from '../constants';
import { SiteMapPage } from '../types';
import { Debug } from '../utils/debug';
import { SiteMapRouterContext, SiteMapRouterContextType } from '../utils/SiteMapRouter';

export interface RoutesProps {
    open: any;
    setOpen: (value: boolean) => {};
    helpOpener?: () => {};
}

const Routes = ({ open, setOpen }: RoutesProps) => {
    const onClose = () => {
        setOpen(false);
    };

    function getCurrentHelpPage(id: HelpPageComponent) {
        const component = React.createElement(id, {
            callback: getCurrentHelpPage,
        });
        setCurrentHelpPage(component);
    }

    const initialComponent = React.createElement(IntroductionHelp, {
        callback: getCurrentHelpPage,
    });

    const [currentHelpPage, setCurrentHelpPage] = useState(initialComponent);

    return (
        <SiteMapRouterContext.Consumer>
            {(siteMapContext: SiteMapRouterContextType) => (
                <Switch>
                    {siteMapContext.pages.map((page: any) => {
                        return (
                            <ProtectedRoute exact={true} path={page.path} resource={page.id} key={page.id}>
                                {(props) => {
                                    let component: any;
                                    if (React.isValidElement(page.component)) {
                                        component = page.component;
                                    } else {
                                        component = React.createElement(page.component);
                                    }
                                    return (
                                        <Row widths={['fit', 'fill']} alignmentVertical={'top'}>
                                            <Sheet type={'push'} side={'left'} open={open} onClose={onClose}>
                                                <Column height={'fill'}>
                                                    <Row alignmentHorizontal={'right'}>
                                                        <Button onClick={onClose} type={'icon'}>
                                                            <Icon tokens={closeTokens}>{'Close sheet'}</Icon>
                                                        </Button>
                                                    </Row>
                                                    {currentHelpPage}
                                                </Column>
                                            </Sheet>
                                            {React.createElement(() => {
                                                // This useEffect usage is valid, since we are inside of an
                                                // anonymous component.
                                                // FIXME: replace with sitemap rules -- 3/20/22
                                                /* eslint-disable-next-line react-hooks/rules-of-hooks */
                                                useEffect(() => {
                                                    Debug.assertExists(props.match);
                                                    siteMapContext.setCurrentPage(page, props.match);
                                                }, []);
                                                return null;
                                            })}
                                            {component}
                                        </Row>
                                    );
                                }}
                            </ProtectedRoute>
                        );
                    })}
                    <Redirect to={siteMapContext.getPath(SiteMapPage.pageNotFound, {})}>{null}</Redirect>
                </Switch>
            )}
        </SiteMapRouterContext.Consumer>
    );
};

export { Routes };
