import i18next from 'i18next';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useHrpsApi } from '../../clients/useHrpsApi';
import {
    AttachedDocument,
    Location,
    ResellerLocations,
    ReturnOrderStatus,
    ReturnOrderType,
    TotalCharge,
} from '../../redux/types';
import { Debug } from '../../utils/debug';
import { Logger } from '../../utils/logger';
import { useDownloader } from '../../utils/useDownloader';
import { useTranslation, TruncatedList, Text } from '../blocks';
import { MultiColumnInfoBoxTile } from '../blocks/MultiColumnInfoBoxTile';
import { DocumentListPopup } from './DocumentListPopup';
import LabeledField from './LabeledField';
import { useModal } from './Modal';

const ReturnOrderInfoBox: FC<ReturnOrderInfoBoxProps> = ({
    returnOrderInfo,
    resellerLocations,
}: ReturnOrderInfoBoxProps) => {
    const { t } = useTranslation(['returnOrder']);

    const modal = useModal();
    const { download, downloadB64Object } = useDownloader();
    const [documents, setDocuments] = useState<AttachedDocument[] | undefined>(undefined);
    const [totalCharge, setTotalCharge] = useState<TotalCharge | undefined>(undefined);
    const [areDocumentsLoading, setAreDocumentsLoading] = useState(false);
    const [isTotalChargeLoading, setIsTotalChargeLoading] = useState(true);

    const {
        returnOrders: { getReturnOrderDocuments, getCreditMemoContent },
    } = useHrpsApi();

    const filterDocumentsForInfoBox = (documents: AttachedDocument[]) => {
        return documents.filter((document) => document.type !== 'ORDERLINE_IMAGE_UPLOAD');
    };

    const downloadCreditMemo = (creditMemoDocument: AttachedDocument) => {
        getCreditMemoContent({
            returnOrderId: returnOrderInfo.returnOrderId,
            receiptId: creditMemoDocument.id,
        }).then((creditMemo) => {
            downloadB64Object(creditMemoDocument.name, creditMemo.content, creditMemo.contentType);
        });
    };

    const downloadDocuments = async (documentIds: string[]) => {
        await getReturnOrderDocuments(returnOrderInfo.returnOrderId).then((response) => {
            setDocuments(filterDocumentsForInfoBox(response));
        });
        _.chain(documents)
            .keyBy('id')
            .pick(documentIds)
            .values()
            .value()
            .forEach((attachedDocument) => {
                Logger.debug(attachedDocument);
                if (attachedDocument.type === 'CREDIT_MEMO') {
                    downloadCreditMemo(attachedDocument);
                } else {
                    download(attachedDocument.presignedURL);
                }
            });
    };

    // get files
    useEffect(() => {
        (async () => {
            setAreDocumentsLoading(true);
            await getReturnOrderDocuments(returnOrderInfo.returnOrderId).then((response) => {
                setDocuments(filterDocumentsForInfoBox(response));
            });
            setAreDocumentsLoading(false);
        })();
    }, [getReturnOrderDocuments, returnOrderInfo.returnOrderId]);

    // get totalCharge
    useEffect(() => {
        setIsTotalChargeLoading(true);
        if (returnOrderInfo.totalCharge) {
            setTotalCharge(returnOrderInfo.totalCharge);
            setIsTotalChargeLoading(false);
        }
    }, [returnOrderInfo.totalCharge]);

    const showDocumentListPopup = () => {
        Debug.assertExists(documents);
        modal.prompt({
            title: t('documents-documentsPopupTitle', {
                count: documents.length,
            }),
            content: (
                <DocumentListPopup
                    documents={
                        documents.map((document) => ({
                            documentId: document.id,
                            name: document.name,
                            description: document.description,
                            uploadTimestamp: document.uploadedAt,
                        })) ?? []
                    }
                    onDocumentDownload={(documentId) => downloadDocuments([documentId])}
                />
            ),
        });
    };

    const showTotalEstimatedCreditPopup = () => {
        modal.prompt({
            title: t('totalCharge-PopupTitle'),
            content: (
                <Text tag={'ul'}>
                    <li>{t('estimatedReturnCreditHelpDetails')}</li>
                </Text>
            ),
        });
    };

    // todo this should be done somewhere central and with localization support
    const getAddress = (code: string, addressType: 'shippingAddress' | 'billingAddress', locations?: Location[]) => {
        if (!locations) return t.cognate(code);
        const location = locations.find((location) => location.code === code);
        if (!location) return t.cognate(code);
        const address = location[addressType];
        if (!address) return t.cognate(code);
        return t.cognate(
            `${address.streetAddress.join(', ')}, ${address.city}, ${address.state}, ${address.zipCode}, ${
                address.country
            }`
        );
    };

    return (
        <MultiColumnInfoBoxTile
            widths={['grid-4', 'grid-3', 'grid-2', 'grid-3']}
            title={t('referenceId-tileTitle', { referenceId: returnOrderInfo.customerReferenceId })}
        >
            <>
                <LabeledField
                    label={t('shippingAddress-fieldLabel')}
                    text={getAddress(returnOrderInfo.shippingAddress, 'shippingAddress', resellerLocations?.locations)}
                />
                <LabeledField
                    label={t('billingAddress-fieldLabel')}
                    text={getAddress(returnOrderInfo.billingAddress, 'billingAddress', resellerLocations?.locations)}
                />
            </>
            <>
                <LabeledField
                    label={t('contactEmailAddress-fieldLabel')}
                    text={t.cognate(returnOrderInfo.contactEmailAddress.join(', '))}
                />
                <LabeledField
                    label={t('returnType-fieldLabel')}
                    text={t('returnOrderType-value', { context: returnOrderInfo.returnType })}
                />
            </>
            <>
                <LabeledField
                    label={t('status-fieldLabel')}
                    text={t('returnOrderStatus-value', { context: returnOrderInfo.status })}
                />
                <LabeledField label={t('createdBy-fieldLabel')} text={t.cognate(returnOrderInfo.createdBy)} />
            </>
            <>
                <TruncatedList
                    title={
                        !areDocumentsLoading
                            ? t('documents-listLabel', { count: documents?.length ?? 0 })
                            : t('documentsLoading-listLabel')
                    }
                    items={documents?.map((document) => ({ id: document.id, text: document.name })) ?? []}
                    limit={4}
                    onItemClick={(document) => downloadDocuments([document.id])}
                    onMoreClick={showDocumentListPopup}
                    isLoading={areDocumentsLoading}
                />
                <LabeledField
                    info={true}
                    label={t('totalCharge-fieldLabel')}
                    onMoreClick={showTotalEstimatedCreditPopup}
                    text={
                        isTotalChargeLoading && !totalCharge
                            ? t('totalCharge-loading')
                            : i18next.t('totalCharge-value', {
                                  ns: 'returnOrder',
                                  totalCharge: new Intl.NumberFormat(i18next.language, {
                                      style: 'currency',
                                      currency: totalCharge?.currencyCode,
                                  }).format(Math.abs(Number(totalCharge?.amountWithTaxes))),
                              })
                    }
                />
            </>
        </MultiColumnInfoBoxTile>
    );
};

export type ReturnOrderInfoBoxProps = {
    returnOrderInfo: ReturnOrderInfo;
    resellerLocations?: ResellerLocations;
};

export type ReturnOrderInfo = {
    returnOrderId: string;
    customerReferenceId: string;
    shippingAddress: string;
    billingAddress: string;
    contactEmailAddress: string[];
    returnType: ReturnOrderType;
    totalCharge: TotalCharge;
    status: ReturnOrderStatus;
    createdBy: string;
};

export default ReturnOrderInfoBox;
