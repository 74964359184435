import Column from '@amzn/meridian/column';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import React, { FC } from 'react';
import contactTypeSelect from '../../../assets/contactTypeSelect.png';
import createContactNavigation from '../../../assets/createContactNavigation.png';
import submitContact from '../../../assets/submitContact.png';
import { SiteHelpCallback } from '../../../constants';
import { ItemImage, Text, useTranslation } from '../../blocks';
import { IntroductionHelp } from './IntroductionHelp';

const CreateContactHelp: FC<SiteHelpCallback> = ({ callback }: SiteHelpCallback) => {
    const { t } = useTranslation(['createNewContactHelp', 'siteHelpPopover']);

    return (
        <Column spacing={'medium'} maxWidth={'25em'}>
            <Row width={'100%'} widths={['85%', '15%']}>
                <Text type={'h200'} alignment={'center'}>
                    {t('createNewContactHelp_Title')}
                </Text>
                <Link
                    onClick={() => {
                        callback(IntroductionHelp);
                    }}
                >
                    {t('siteHelpPopover:siteHelpPopover_helpHomePage')}
                </Link>
            </Row>
            <Text type={'b300'} alignment={'left'}>
                {t('createNewContactHelp_purpose')}
            </Text>
            <Row spacing={'medium'}>
                <Column width={'2%'} />
                <Column spacing={'xsmall'} width={'90%'}>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline1')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('createNewContactHelp_navigateToCreateContact')}
                        </Text>
                    </Row>
                    <Row>
                        <ItemImage src={createContactNavigation} />
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline2')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('createNewContactHelp_crateContactOverview')}
                        </Text>
                    </Row>
                    <Row spacing={'medium'}>
                        <Column width={'2%'} />
                        <Column spacing={'xsmall'}>
                            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                                <Text type={'b200'} alignment={'left'}>
                                    {t.cognate('•')}
                                </Text>
                                <Text type={'b200'} alignment={'left'}>
                                    {t('createNewContactHelp_returnsType')}
                                </Text>
                            </Row>
                            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                                <Text type={'b200'} alignment={'left'}>
                                    {t.cognate('•')}
                                </Text>
                                <Text type={'b200'} alignment={'left'}>
                                    {t('createNewContactHelp_creditMemosType')}
                                </Text>
                            </Row>
                            <Row>
                                <ItemImage src={contactTypeSelect} />
                            </Row>
                        </Column>
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline3')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('createNewContactHelp_submit')}
                        </Text>
                    </Row>
                    <Row>
                        <ItemImage src={submitContact} />
                    </Row>
                </Column>
            </Row>
        </Column>
    );
};

export { CreateContactHelp };
