import Box from '@amzn/meridian/box';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import _ from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { HrpsApiClient } from '../clients';
import {
    BorderedColumn,
    Button,
    GreyBox,
    Link,
    SearchField,
    Text,
    useTranslation,
    WaitFor,
} from '../components/blocks';
import { usePageMessaging } from '../components/composites';
import { useModal } from '../components/composites/Modal';
import { PaginatedTable } from '../components/constructed';
import { clearResellerDetails, getResellerDetails } from '../redux/actions/resellerActionCreators';
import { Contact, IUser, RootState, TableData } from '../redux/types';
import { SiteMapPage } from '../types';
import { Logger } from '../utils/logger';
import { useSiteMapRouter } from '../utils/SiteMapRouter';

const ContactListPage: FC<ContactListPageProps> = ({
    resellerId,
    resellerDetails,
    getResellerDetails,
    clearResellerDetails,
    adminUser,
    IResellerState,
}: ContactListPageProps) => {
    const { t } = useTranslation(['contactListPage']);

    const { getPath } = useSiteMapRouter();

    const [isPageBusy, setPageIsBusy] = useState(false);

    const { showSuccess, showError } = usePageMessaging();

    useEffect(() => {
        if (IResellerState.resellerDetails.status === 'Uninitialized') {
            if (resellerId) {
                getResellerDetails(resellerId);
            }
        }
    }, [getResellerDetails, resellerDetails, resellerId, IResellerState, t]);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [sortColumn, setSortColumn] = useState<string>('name');
    const [sortDirection, setSortDirection] = useState<'ascending' | 'descending'>('ascending');
    const [curSearchValue, setCurSearchValue] = useState<string>('');

    const CONTACTS_PER_PAGE: number = 10;

    const onPageClick = (value: string) => {
        setCurrentPage(parseInt(value));
    };

    const onSort = useCallback(({ sortColumn, sortDirection }) => {
        setSortColumn(sortColumn);
        setSortDirection(sortDirection);
    }, []);

    const onSearch = (value: string) => {
        Logger.debug(`onSearch: ${JSON.stringify({ value })}`);
        setCurSearchValue(value);
        setCurrentPage(1);
    };

    function foundContacts(element: Contact) {
        const lowerCurSearchValue = curSearchValue.toLowerCase();

        const found =
            curSearchValue &&
            (element.firstName?.toLowerCase().includes(lowerCurSearchValue) ||
                element.lastName?.toLowerCase().includes(lowerCurSearchValue) ||
                element.email?.toLowerCase().includes(lowerCurSearchValue) ||
                element.phone?.includes(lowerCurSearchValue) ||
                element.cellPhone?.includes(lowerCurSearchValue) ||
                element.role?.toLowerCase().includes(lowerCurSearchValue) ||
                element.contactType?.toLowerCase().includes(lowerCurSearchValue));

        return found;
    }

    const formatSortColumn = (contact: Contact) => {
        switch (sortColumn) {
            case 'name':
                return `${contact.lastName}, ${contact.firstName}`;
            case 'role':
                return `${contact.role}`;
            case 'email':
                return `${contact.email}`;
            case 'phone':
                return `${contact.phone}`;
            case 'cellPhone':
                return `${contact.cellPhone}`;
            case 'contactType':
                return `${contact.contactType}`;
            default:
                return `${contact.firstName}, ${contact.lastName}`;
        }
    };

    const modal = useModal();
    function softDeleteContact(contactId: number) {
        const foundContact = resellerDetails.value!.contacts.find((contact) => contact.contactId === contactId);
        if (foundContact) {
            modal
                .confirm(
                    t('deleteContact-popupConfirmationTitle'),
                    t('validateDeleteContact-popupConfirmationMessage', {
                        firstName: foundContact?.firstName,
                        lastName: foundContact?.lastName,
                    }),
                    t('deleteContact-popupConfirmButtonLabel')
                )
                .applied(async () => {
                    setPageIsBusy(true);

                    const hrpsApiClient = new HrpsApiClient();
                    try {
                        await hrpsApiClient.softDeleteContact(foundContact);
                        showSuccess(t('contactWasDeleted-successMessage'));
                        clearResellerDetails();
                    } catch (error) {
                        Logger.debug(`deleteContact error ${error}`);
                        showError(t('failedToDeleteContact-errorMessage'));
                    } finally {
                        setPageIsBusy(false);
                    }
                });
        }
    }

    const formatData = (contacts: Contact[]) => {
        const compare = function (contactA: Contact, contactB: Contact) {
            const order = (a: Contact, b: Contact) =>
                (sortDirection === 'descending' ? -1 : 1) * formatSortColumn(a).localeCompare(formatSortColumn(b));
            return order(contactA, contactB);
        };

        const sortedContacts = contacts.sort(compare);

        const startPos = (currentPage - 1) * CONTACTS_PER_PAGE;
        const endPos = currentPage * CONTACTS_PER_PAGE;

        const pageContacts: Contact[] = sortedContacts.slice(startPos, endPos);

        const adminRoles = ['amazonAdmin', 'resellerAdmin'];
        const isAdminUser = adminUser !== undefined && _.intersection(adminUser.roles, adminRoles).length > 0;

        const adminTableData = isAdminUser
            ? [
                  {
                      title: t('action'),
                      uniqueId: 'action',
                      format: (pageContact: Contact) => {
                          return (
                              <Button
                                  type={'link'}
                                  onClick={() => softDeleteContact(pageContact.contactId)}
                                  disabled={isPageBusy}
                                  data-testid={'contactDeleteButton'}
                              >
                                  {t('contactListPage-deleteButton')}
                              </Button>
                          );
                      },
                  },
              ]
            : [];

        const tableData = new TableData(pageContacts, [
            {
                title: t('name'),
                uniqueId: 'name',
                format: (pageContact: Contact) => {
                    if (isAdminUser) {
                        return (
                            <Link
                                type={'primary'}
                                href={getPath(SiteMapPage.editContact, {
                                    contactId: pageContact.contactId,
                                })}
                            >
                                {t.cognate(`${pageContact.firstName} ${pageContact.lastName}`)}
                            </Link>
                        );
                    }
                    return <Text>{t.cognate(`${pageContact.firstName} ${pageContact.lastName}`)}</Text>;
                },
            },
            {
                title: t('role'),
                uniqueId: 'role',
                format: 'role',
            },
            {
                title: t('email'),
                uniqueId: 'email',
                format: 'email',
            },
            {
                title: t('phone'),
                uniqueId: 'phone',
                format: 'phone',
            },
            {
                title: t('cellPhone'),
                uniqueId: 'cellPhone',
                format: 'cellPhone',
            },
            {
                title: t('contactType'),
                uniqueId: 'contactType',
                format: 'contactType',
            },
            ...adminTableData,
        ]);
        return tableData;
    };

    const contactList: Contact[] = resellerDetails.value?.contacts || [];
    const filteredContacts: Contact[] = curSearchValue ? contactList.filter(foundContacts) : contactList;
    const remainder = filteredContacts.length % CONTACTS_PER_PAGE;
    const numberOfPages = (filteredContacts.length - remainder) / CONTACTS_PER_PAGE + (remainder > 0 ? 1 : 0);

    const data = formatData(filteredContacts);

    return (
        <BorderedColumn alignmentHorizontal={'center'} spacing={'none'}>
            <GreyBox type={'fill'} spacingInset={'small'} width={'100%'}>
                <Heading level={5} alignment={'left'} data-testid={'contactListPageHeader'}>
                    {t('contactListPage-pageHeading')}
                </Heading>
            </GreyBox>
            <WaitFor lazyValue={IResellerState.resellerDetails}>
                {() => (
                    <PaginatedTable
                        currentPage={currentPage}
                        data={data}
                        numberOfPages={numberOfPages}
                        onPageChange={onPageClick}
                        onSort={onSort}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        rowKey={(rowArray: string[], rowObject: Contact) => {
                            return rowObject.contactId.toString();
                        }}
                    >
                        <Box width={'80%'} spacingInset={'none'} data-testid={'UserListSearchBox'}>
                            <Row spacing={'small'}>
                                <SearchField
                                    value={curSearchValue}
                                    onChange={onSearch}
                                    label={t('searchBar-placeholder')}
                                    onSubmit={onSearch}
                                    size={'medium'}
                                    searchButton={false}
                                    data-testid={'contactListPage.searchField'}
                                />
                            </Row>
                        </Box>
                    </PaginatedTable>
                )}
            </WaitFor>
        </BorderedColumn>
    );
};

const mapStateToProps = ({ resellerReducer, userReducer }: RootState) => {
    return {
        resellerId: resellerReducer.selectedReseller.value!.resellerId,
        adminUser: userReducer.user as IUser,
        resellerDetails: resellerReducer.resellerDetails,
        IResellerState: resellerReducer,
    };
};

const mapDispatchToProps = { getResellerDetails, clearResellerDetails };

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ContactListPageProps = ConnectedProps<typeof connector>;
export default connector(ContactListPage);
