import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import React, { FC, FormEvent, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { HrpsApiClient } from '../clients';
import { Button, useTranslation } from '../components/blocks';
import { Tile, usePageMessaging } from '../components/composites';
import { clearManagedUser, clearManagedUsers } from '../redux/actions/managedUserActionCreators';
import { SiteMapPage } from '../types';
import { useSiteMapRouter } from '../utils/SiteMapRouter';
import { UserDetails, UserDetailsForm, UserDetailsFormApi } from './UserDetailsForm';

const CreateUserPage: FC<CreateUserPageProps> = ({ clearManagedUser, clearManagedUsers }: CreateUserPageProps) => {
    const { t } = useTranslation(['createUserPage', 'roles', 'validation', 'forms']);

    const [isPageBusy, setPageIsBusy] = useState(false);

    const userDetailsInitialValue: UserDetails = {
        username: '',
        givenName: '',
        familyName: '',
        email: '',
        phone: '',
        resellers: [],
        role: undefined,
    };

    const [userDetails, setUserDetails] = useState(userDetailsInitialValue);

    const userDetailsFormApiRef = useRef<UserDetailsFormApi>();

    const { goto } = useSiteMapRouter();

    const { showSuccess, showError } = usePageMessaging();

    async function submitForm(event: FormEvent) {
        event.preventDefault();

        if (userDetailsFormApiRef.current?.validateForm() === 'invalid') {
            return;
        }

        setPageIsBusy(true);

        const hrpsApiClient = new HrpsApiClient();
        try {
            await hrpsApiClient.createUser({
                ...userDetails,
                role: userDetails.role as string,
            });
            showSuccess(t('userWasCreated-successMessage'));
            setUserDetails(userDetailsInitialValue);
            if (userDetailsFormApiRef.current) {
                userDetailsFormApiRef.current.resetFormError();
            }
            clearManagedUsers();
            clearManagedUser();
            goto(SiteMapPage.listUsers);
        } catch (error) {
            if (error.code === 'UsernameUnavailable') {
                showError(t('unavailableUsername-errorMessage'));
            } else {
                showError(t('failedToCreateAUser-errorMessage'));
            }
        } finally {
            setPageIsBusy(false);
        }
    }

    return (
        <div data-testid={'createUserPage'}>
            <Tile title={t('createUser-pageTitle')} width={'100%'}>
                <form onSubmit={submitForm}>
                    <Column spacing={'medium'} spacingInset={'none'} width={'90%'}>
                        <UserDetailsForm
                            formApiRef={userDetailsFormApiRef}
                            userDetails={userDetails}
                            onUserDetailsChange={setUserDetails}
                            mode={'create'}
                        />
                        <Row alignmentHorizontal={'right'}>
                            <Button
                                type={'secondary'}
                                onClick={() => goto(SiteMapPage.listUsers)}
                                data-testid={'createUserPage.cancelButton'}
                            >
                                {t('forms:cancel-buttonLabel')}
                            </Button>
                            <Button
                                type={'primary'}
                                submit={true}
                                disabled={isPageBusy}
                                data-testid={'createUserPage.createUserButton'}
                            >
                                {t('createUser-buttonLabel')}
                            </Button>
                        </Row>
                    </Column>
                </form>
            </Tile>
        </div>
    );
};

const mapDispatchToProps = { clearManagedUser, clearManagedUsers };

const connector = connect(undefined, mapDispatchToProps);
export type CreateUserPageProps = ConnectedProps<typeof connector>;
export default connector(CreateUserPage);
