import React, { FC } from 'react';
import { COUNTRIES } from '../../constants/locations/countries';
import { TranslatedString, useTranslation } from '../blocks/localization';
import { LabeledSearchableSelect } from '.';

const CountrySelect: FC<CountrySelectProps> = ({ value, onChange, isDisabled, error, dataTestId }) => {
    const { t } = useTranslation('addressForm');

    const options = Object.entries(COUNTRIES).map(([countryCode, countryName]) => {
        return { value: countryCode, label: t.cognate(countryName) };
    });

    return (
        <LabeledSearchableSelect
            label={t('country-fieldLabel')}
            value={value}
            onChange={onChange}
            selectOptions={options}
            isDisabled={isDisabled}
            error={error}
            dataTestId={dataTestId}
        />
    );
};

type CountrySelectProps = {
    value: any;
    onChange?(value: string): void;
    isDisabled?: boolean;
    error?: TranslatedString;
    dataTestId?: string;
};

export { CountrySelect };
