import { COMPLIANCE_RESOLUTION } from '../../../../constants';
import { CreateReturnOrderLineItem } from '../../../../redux/types';
import { TranslatedString } from '../../../blocks';
import { MultilineFormEditorColumnFormat } from '../MultilineFormEditor';
import { selectFormat } from './selectFormat';

export const createReturnOrderPageLabelSelectFormat = (
    selectOptions: { value: string; label: TranslatedString }[],
    placeholder?: TranslatedString
): MultilineFormEditorColumnFormat => ({
    render: (item: CreateReturnOrderLineItem, column, lineIndex, onChange) => {
        return selectFormat({
            selectOptions: selectOptions.filter(
                (selectOption) => selectOption.value !== COMPLIANCE_RESOLUTION.TRIM || item.approvedCount !== 0
            ),
            isDisabled:
                item.complianceCalculationStatus !== 'Complete' ||
                item.eligibility === undefined ||
                item.quantity <= item.eligibility,
            placeholder: placeholder,
        }).render(item, column, lineIndex, onChange);
    },
});
