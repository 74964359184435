import folderTokens from '@amzn/meridian-tokens/base/icon/folder';
import Card from '@amzn/meridian/card';
import Heading from '@amzn/meridian/heading';
import Icon from '@amzn/meridian/icon';
import Modal from '@amzn/meridian/modal';
import Table, { TableCell, TableRow } from '@amzn/meridian/table';
import React, { FC, useState } from 'react';
import { AsynchronouslyLoadedValueStatus } from '../../redux/types';
import { Attachment } from '../../types';
import { Button, FileInput, Text, useTranslation } from '../blocks/localization';
import CardHeader from '../blocks/localization/CardHeader';

export type UploadImageModalProps =
    | {
          attachments: any;
          setAttachments: (...args: any) => void;
          lineItemId: string;
          disabled?: boolean;
          mode: 'upload';
          status?: AsynchronouslyLoadedValueStatus;
      }
    | {
          attachments: any;
          setAttachments?: never;
          lineItemId: string;
          disabled?: boolean;
          mode: 'view';
          status: AsynchronouslyLoadedValueStatus;
      };

export const UploadImageModal: FC<UploadImageModalProps> = ({
    attachments,
    setAttachments,
    lineItemId,
    disabled,
    mode,
    status,
}) => {
    const { t } = useTranslation('uploadImageModal');
    const [open, setOpen] = useState<boolean>(false);

    const numberOfFiles = attachments.value.filter((attachment: Attachment) => {
        return attachment.lineItemId.toString() === lineItemId.toString() && attachment.status !== 'Deleted';
    }).length;

    const handleFileAttach = (newFiles: File[]) => {
        const newAttachments = newFiles.map((newFile: File) => {
            return {
                file: newFile,
                lineItemId: lineItemId,
                status: 'New',
            };
        });
        setAttachments!([...attachments.value, ...newAttachments]);
    };

    const removeFile = (removedIndex: number) => {
        let updatedAttachments;
        if (attachments.value[removedIndex].status === 'Deleted') {
            return;
        } else if (attachments.value[removedIndex].status === 'Loaded') {
            updatedAttachments = attachments.value.map((attachment: Attachment) => {
                return {
                    ...attachment,
                };
            });
            updatedAttachments[removedIndex].status = 'Deleted';
        } else {
            updatedAttachments = attachments.value.filter((attachment: any, fileIndex: number) => {
                return fileIndex !== removedIndex;
            });
        }
        setAttachments!(updatedAttachments);
        setOpen(
            updatedAttachments.some((attachment: Attachment) => {
                return attachment.lineItemId.toString() === lineItemId.toString() && attachment.status !== 'Deleted';
            })
        );
    };

    const getFiles = () => {
        return attachments.value.map((attachment: any, index: number) => {
            return (
                attachment.lineItemId.toString() === lineItemId.toString() &&
                attachment.status !== 'Deleted' && (
                    <TableRow key={attachment.file.name + index.toString()}>
                        <TableCell>
                            <Card
                                imageSrc={attachment.url ?? URL.createObjectURL(attachment.file)}
                                imageSize={'contain'}
                                imageViewportBackgroundColor={'#FFFFFF'}
                                data-testid={`${attachment.file.name}-${index.toString()}`}
                            >
                                <CardHeader>
                                    <Heading level={5} type={'h500'}>
                                        {attachment.file.name}
                                    </Heading>
                                </CardHeader>
                                {mode === 'upload' && (
                                    <Button
                                        type={'secondary'}
                                        size={'small'}
                                        onClick={() => removeFile(index)}
                                        data-testid={'uploadImageModal.removeFileButton'}
                                    >
                                        {t('buttonLabel', { context: 'removeFile' })}
                                    </Button>
                                )}
                            </Card>
                        </TableCell>
                    </TableRow>
                )
            );
        });
    };

    return (
        <>
            {mode === 'upload' && numberOfFiles === 0 && (
                <FileInput
                    onFileAttached={handleFileAttach}
                    uploadButtonLabel={t('buttonLabel', { context: 'upload' })}
                    uploadButtonType={'link'}
                    accept={'image/*'}
                    type={'multiple'}
                    uploadButtonSize={'medium'}
                    showDropZone={false}
                    uploadButtonDisabled={disabled}
                />
            )}
            {mode === 'view' && numberOfFiles === 0 && status === 'Loaded' && (
                <Button
                    type={'link'}
                    disabled={true}
                    onClick={() => setOpen(true)}
                    data-testid={'uploadImageModal.viewMode.noAttachmentsButton'}
                >
                    {`${numberOfFiles}`}
                    <Icon tokens={folderTokens} />
                </Button>
            )}
            {numberOfFiles !== 0 && status === 'Loaded' && (
                <Button
                    type={'link'}
                    onClick={() => setOpen(true)}
                    data-testid={'uploadImageModal.viewMode.atLeastOneAttachmentButton'}
                >
                    {`${numberOfFiles}`}
                    <Icon tokens={folderTokens} />
                </Button>
            )}
            {mode === 'view' && (status === 'Uninitialized' || status === 'Loading') && (
                <Text>{t('loadingImages-columnValue')}</Text>
            )}
            {mode === 'view' && status === 'Failed' && <Text>{t('failedToLoadImages-columnValue')}</Text>}

            <Modal
                title={t('viewAttachments_title', { count: numberOfFiles })}
                open={open}
                onClose={() => setOpen(false)}
            >
                {mode === 'upload' && (
                    <FileInput
                        onFileAttached={handleFileAttach}
                        uploadButtonLabel={t('buttonLabel', { context: 'uploadMoreImages' })}
                        uploadButtonType={'link'}
                        accept={'image/*'}
                        type={'multiple'}
                        uploadButtonSize={'medium'}
                        showDropZone={false}
                    />
                )}

                <Table data-testid={'uploadImageModal.imageTable'}>{getFiles()}</Table>
            </Modal>
        </>
    );
};
