import axios from 'axios';
import { Logger } from '../utils/logger';

export class PutClient {
    static async put<T>(url: string, data: File): Promise<T> {
        // Send data to url
        Logger.debug(`Pushing file ${data.name} to url: ${url}`);
        const options = {
            headers: {
                'x-amz-server-side-encryption': 'AES256',
            },
        };
        return await axios.put(url, data, options);
    }
}
