import Button from '@amzn/meridian/button';
import Popover, { PopoverHeader } from '@amzn/meridian/popover';
// TODO: use localized components
import Text from '@amzn/meridian/text';
import React, { useRef, useState, useCallback } from 'react';

export interface PopoverCellProps {
    // TODO: use TranslatedString
    buttonLable: string;
    children?: React.ReactNode;
    dataTestId?: string;
    header?: string;
    text?: string;
}

const PopoverCell = ({ buttonLable, header, text, dataTestId, children }: PopoverCellProps) => {
    const buttonRef = useRef();
    const [open, setOpen] = useState(false);
    const onClickButton = useCallback(() => {
        setOpen(true);
    }, []);
    const onClose = useCallback(() => {
        setOpen(false);
    }, []);
    return (
        <>
            <Button
                onClick={onClickButton}
                type={'link'}
                ref={buttonRef}
                size={'small'}
                data-testid={`${dataTestId}-Button`}
            >
                {buttonLable}
            </Button>
            <Popover
                anchorNode={buttonRef.current}
                open={open}
                onClose={onClose}
                position={'bottom'}
                alignment={'center'}
                data-testid={`${dataTestId}-Popover`}
            >
                {header && (
                    <PopoverHeader closeLabel={'Close'} data-testid={`${dataTestId}-PopoverHeader`}>
                        {header}
                    </PopoverHeader>
                )}
                {children}
                {text && <Text>{text}</Text>}
            </Popover>
        </>
    );
};

export { PopoverCell };
