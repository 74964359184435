import i18next from 'i18next';
import React from 'react';
import { Debug } from '../../utils/debug';
import { ExternalLink, TranslatedString } from '../blocks';
import { ColumnFormat } from './SuperPaginatedTable';
import { UploadImageModal } from './UploadImageModal';

export const numberFormat = {
    sort: (value: number) => String(value).padStart(10),
};

export const yesNoFormat: ColumnFormat = {
    render: (value: boolean) => i18next.t('superPaginatedTable:yesNoFormat-value', { context: value ? 'yes' : 'no' }),
    sort: (value: boolean) => (value ? '1' : '0'),
};

export const textFormat: ColumnFormat = {
    render: (value: any) => `${value}`,
    search: (value: any) => `${value}`,
    sort: (value: any) => `${value}`,
};

export function externalLinkFormat(
    url: (value: any, item: any) => string,
    onItemClick: any,
    format?: ColumnFormat
): ColumnFormat {
    const actualFormat = { ...(format ?? textFormat) };
    return {
        ...actualFormat,
        render: (value: any, item: any) => {
            Debug.assertExists(actualFormat.render, 'format.render is required.');
            const content = actualFormat.render(value, item);
            Debug.assert(typeof content === 'string', 'Content of an external link must be a TranslatedString.');
            return (
                <ExternalLink href={url(value, item)} onClick={onItemClick}>
                    {content as TranslatedString}
                </ExternalLink>
            );
        },
    };
}

export function viewImageFormat(attachments: any): ColumnFormat {
    return {
        render: (lineItemId: string) => {
            return (
                <UploadImageModal
                    attachments={attachments}
                    lineItemId={lineItemId}
                    mode={'view'}
                    status={attachments.status}
                />
            );
        },
    };
}
