import { Action } from 'redux';
import {
    AsynchronouslyLoadedValueStatus,
    IAsynchronouslyLoadedValue,
    IAuthorizedReseller,
    IReseller,
    ResellerItem,
    ResellerDetails,
    AsinToImageUrlMap,
    Location,
    Contact,
    ResellerPriceList,
} from '../types';

export const AUTHORIZED_RESELLERS_CHANGED = 'authorizUserListedResellers/changed';
export const SELECTED_RESELLER_CHANGED = 'selectedReseller/changed';
export const RESELLER_DETAILS_CHANGED = 'resellerDetails/changed';
export const RESELLER_ITEMS_CHANGED = 'resellerItems/changed';
export const RESELLER_PRICELIST_CHANGED = 'resellerPriceList/changed';
export const ITEM_IMAGE_URLS_CHANGED = 'itemImageUrls/changed';
export const RESELLER_DETAILS_LOCATION_ADDED = 'location/added';
export const RESELLER_DETAILS_LOCATION_EDITED = 'location/edited';
export const RESELLER_DETAILS_CONTACT_ADDED = 'contact/added';
export const RESELLER_DETAILS_CONTACT_EDITED = 'contact/edited';
export const RESELLER_UNMAPPED_ITEMS_CLEAR = 'resellerUnmappedItems/clear';
export const RESELLER_UNMAPPED_ITEMS_GET = 'resellerUnmappedItems/get';
export const RESELLER_UNMAPPED_ITEMS_LOADED = 'resellerUnmappedItems/loaded';

export interface IChangeAuthorizedResellersAction extends Action {
    type: typeof AUTHORIZED_RESELLERS_CHANGED;
    payload: {
        status: AsynchronouslyLoadedValueStatus;
        error?: any;
        value?: IAuthorizedReseller[];
    };
}

export interface IChangeSelectedResellerAction extends Action {
    type: typeof SELECTED_RESELLER_CHANGED;
    payload: {
        status: AsynchronouslyLoadedValueStatus;
        error?: any;
        value?: IReseller;
    };
    replayOnLogin?: boolean;
    timeToLiveInDays?: number;
}

export interface ChangeResellerDetailsAction extends Action {
    type: typeof RESELLER_DETAILS_CHANGED;
    payload: IAsynchronouslyLoadedValue<ResellerDetails>;
}

export interface ChangeResellerItemsAction extends Action {
    type: typeof RESELLER_ITEMS_CHANGED;
    payload: IAsynchronouslyLoadedValue<ResellerItem[]>;
}

export interface ChangeResellerPriceListAction extends Action {
    type: typeof RESELLER_PRICELIST_CHANGED;
    payload: IAsynchronouslyLoadedValue<ResellerPriceList>;
}

export interface ChangeItemImageUrlsAction extends Action {
    type: typeof ITEM_IMAGE_URLS_CHANGED;
    payload: IAsynchronouslyLoadedValue<AsinToImageUrlMap>;
}

export interface ResellerDetailsAddLocationAction extends Action {
    type: typeof RESELLER_DETAILS_LOCATION_ADDED;
    payload: Location;
}

export interface ResellerDetailsEditLocationAction extends Action {
    type: typeof RESELLER_DETAILS_LOCATION_EDITED;
    payload: Location;
}

export interface ResellerDetailsAddContactAction extends Action {
    type: typeof RESELLER_DETAILS_CONTACT_ADDED;
    payload: Contact;
}

export interface ResellerDetailsEditContactAction extends Action {
    type: typeof RESELLER_DETAILS_CONTACT_EDITED;
    payload: Contact;
}
export interface ResellerUnmappedItemsClearAction extends Action {
    type: typeof RESELLER_UNMAPPED_ITEMS_CLEAR;
}

export interface ResellerUnmappedItemsGetAction extends Action {
    type: typeof RESELLER_UNMAPPED_ITEMS_GET;
}

export interface ResellerUnmappedItemsLoadedAction extends Action {
    type: typeof RESELLER_UNMAPPED_ITEMS_LOADED;
    payload: {
        error?: string;
        value: ResellerItem[];
    };
}

export type ResellerActions =
    | IChangeAuthorizedResellersAction
    | IChangeSelectedResellerAction
    | ChangeResellerDetailsAction
    | ChangeResellerItemsAction
    | ChangeResellerPriceListAction
    | ChangeItemImageUrlsAction
    | ResellerDetailsAddLocationAction
    | ResellerDetailsEditLocationAction
    | ResellerDetailsAddContactAction
    | ResellerDetailsEditContactAction
    | ResellerUnmappedItemsGetAction
    | ResellerUnmappedItemsLoadedAction
    | ResellerUnmappedItemsClearAction;
