import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import React, { FC } from 'react';
import { TranslatedString, Text, useTranslation } from '../blocks';
import { PopoverCell } from '../constructed';

export interface TruncatedTextProps {
    text: TranslatedString;
    maxWidth?: number;
    dataTestId?: string;
}

const TruncatedText: FC<TruncatedTextProps> = ({ text, maxWidth, dataTestId }: TruncatedTextProps) => {
    const { t } = useTranslation('returnOrdersListPage');

    if (maxWidth && text.length > maxWidth) {
        const trancatedString = text.substr(0, maxWidth);
        return (
            <Row spacing={'none'} data-testid={dataTestId}>
                <Text data-testid={`${dataTestId}-Text`}>{t.cognate(trancatedString)}</Text>
                <PopoverCell buttonLable={'…'} data-testid={`${dataTestId}-PopoverCell`}>
                    <Column alignmentHorizontal={'left'} alignmentVertical={'top'} spacing={'xxsmall'}>
                        <Text type={'b200'} data-testid={`${dataTestId}-Full Text`}>
                            {text}
                        </Text>
                    </Column>
                </PopoverCell>
            </Row>
        );
    } else {
        return <Text data-testid={dataTestId}>{text}</Text>;
    }
};

export { TruncatedText };
