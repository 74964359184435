import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import React, { FC, useCallback, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHrpsApi } from '../../../clients/useHrpsApi';
import {
    resellerDetailsLocationAdded,
    resellerDetailsLocationEdited,
} from '../../../redux/actions/resellerActionCreators';
import {
    setReturnOrderDraftBillingAddress,
    setReturnOrderDraftShippingAddress,
} from '../../../redux/actions/returnOrderDraftActionCreators';
import { RootState } from '../../../redux/types';
import { Button, TranslatedString, useTranslation } from '../../blocks/localization';
import { AddressForm, usePageMessaging } from '../../composites';
import { useAddressFormValidator } from './useAddressFormValidator';

type ModifyAddressPopupProps = {
    title: TranslatedString;
    linkText: TranslatedString;
    type: 'billing' | 'shipping';
    mode: 'add' | 'edit';
    selectedLocationCode?: string;
} & ConnectedProps<typeof connector>;

const SHIPPING_ADDRESS = 'Shipping Address';
const BILLING_ADDRESS = 'Billing Address';

const ModifyAddressPopup: FC<ModifyAddressPopupProps> = ({
    title,
    linkText,
    type,
    mode,
    selectedLocationCode,
    resellerId,
    resellerLocations,
    resellerDetailsLocationAdded,
    setReturnOrderDraftShippingAddress,
    setReturnOrderDraftBillingAddress,
    resellerDetailsLocationEdited,
}) => {
    const [locationId, setLocationId] = useState<number>();
    const [locationCode, setLocationCode] = useState('');
    const [locationName, setLocationName] = useState('');
    const [locationType, setLocationType] = useState('');
    const [isPrimary, setIsPrimary] = useState(false);
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [addressLine3, setAddressLine3] = useState('');
    const [isBusy, setIsBusy] = useState(false);
    const [open, setOpen] = useState(false);
    const [showFormErrors, setShowFormErrors] = useState(false);

    const { t } = useTranslation('modifyAddressPopup');

    const { showSuccess, showError } = usePageMessaging();

    const {
        locations: { addLocation, editLocation },
    } = useHrpsApi();

    const { formIsValid, formErrors } = useAddressFormValidator({
        locationCode,
        locationType,
        city,
        state,
        country,
        zipCode,
        addressLine1,
    });

    const showErrors = useCallback(() => {
        if (showFormErrors) {
            return formErrors;
        } else {
            return {};
        }
    }, [formErrors, showFormErrors]);

    const resetFields = () => {
        setLocationCode('');
        setLocationName('');
        setLocationType('');
        setIsPrimary(false);
        setCity('');
        setState('');
        setCountry('');
        setAddressLine1('');
        setAddressLine2('');
        setAddressLine3('');
        setZipCode('');
        setShowFormErrors(false);
    };

    const setInitialFields = () => {
        resetFields();
        if (mode === 'edit' && resellerLocations) {
            const selectedLocation = resellerLocations.locations.find(
                (location) => location.code === selectedLocationCode
            );

            const selectedAddress =
                type === 'shipping' ? selectedLocation?.shippingAddress : selectedLocation?.billingAddress;

            if (selectedLocation) {
                setLocationId(selectedLocation.id);
                setLocationCode(selectedLocation.code);
                setLocationType(selectedLocation.type);

                if (selectedLocation.name) {
                    setLocationName(selectedLocation.name);
                }

                setCity(selectedAddress!.city);
                setState(selectedAddress!.state);
                setCountry(selectedAddress!.country);
                setZipCode(selectedAddress!.zipCode);
                setAddressLine1(selectedAddress!.streetAddress[0]);

                if (selectedAddress!.streetAddress.length > 1) {
                    setAddressLine2(selectedAddress!.streetAddress[1]);
                }

                if (selectedAddress!.streetAddress.length > 2) {
                    setAddressLine3(selectedAddress!.streetAddress[2]);
                }

                if (
                    selectedLocation.code === resellerLocations.primaryShippingAddressLocationCode ||
                    selectedLocation.code === resellerLocations.primaryBillingAddressLocationCode
                ) {
                    setIsPrimary(true);
                }
            }
        }
    };

    const submitForm = async () => {
        if (!formIsValid) {
            setShowFormErrors(true);
            return;
        }

        const input = {
            locationCode,
            locationName: locationName ?? undefined,
            locationType,
            address: {
                streetAddress: [addressLine1, addressLine2, addressLine3].filter(Boolean),
                city,
                state,
                country,
                zipCode,
            },
            addressType: type === 'shipping' ? SHIPPING_ADDRESS : BILLING_ADDRESS,
            primary: isPrimary,
        };

        setIsBusy(true);
        try {
            if (mode === 'edit') {
                await editLocation({ ...input, locationId: locationId! });

                resellerDetailsLocationEdited({
                    id: locationId!,
                    code: input.locationCode,
                    name: input.locationName,
                    type: input.locationType,
                    billingAddress: input.addressType === BILLING_ADDRESS ? input.address : undefined,
                    shippingAddress: input.addressType === SHIPPING_ADDRESS ? input.address : undefined,
                });

                showSuccess(t('editAddressSuccess-successMessage'));
            } else {
                const addLocationOutput = await addLocation({ ...input, resellerId });

                resellerDetailsLocationAdded({
                    id: addLocationOutput.locationId,
                    code: input.locationCode,
                    name: input.locationName,
                    type: input.locationType,
                    billingAddress: input.addressType === BILLING_ADDRESS ? input.address : undefined,
                    shippingAddress: input.addressType === SHIPPING_ADDRESS ? input.address : undefined,
                });

                showSuccess(t('addAddressSuccess-successMessage'));
            }

            if (input.addressType === SHIPPING_ADDRESS) {
                setReturnOrderDraftShippingAddress(input.locationCode);
            } else {
                setReturnOrderDraftBillingAddress(input.locationCode);
            }

            setOpen(false);
        } catch (error) {
            if (mode === 'edit') {
                showError(t('editAddressFailed-failureMessage'));
            } else {
                showError(t('addAddressFailed-failureMessage'));
            }
        } finally {
            setIsBusy(false);
        }
    };

    return (
        <>
            <Button type={'link'} onClick={() => setOpen(true)}>
                {linkText}
            </Button>
            <Modal title={title} open={open} onOpen={() => setInitialFields()}>
                <AddressForm
                    locationCode={locationCode}
                    setLocationCode={setLocationCode}
                    locationName={locationName}
                    setLocationName={setLocationName}
                    locationType={locationType}
                    setLocationType={setLocationType}
                    isPrimary={isPrimary}
                    setIsPrimary={setIsPrimary}
                    addressLine1={addressLine1}
                    setAddressLine1={setAddressLine1}
                    addressLine2={addressLine2}
                    setAddressLine2={setAddressLine2}
                    addressLine3={addressLine3}
                    setAddressLine3={setAddressLine3}
                    city={city}
                    setCity={setCity}
                    state={state}
                    setState={setState}
                    country={country}
                    setCountry={setCountry}
                    zipCode={zipCode}
                    setZipCode={setZipCode}
                    formErrors={showErrors()}
                    editMode={mode === 'edit'}
                />
                <ModalFooter>
                    <Row alignmentHorizontal={'right'} widths={'fit'}>
                        <Button type={'secondary'} size={'small'} disabled={isBusy} onClick={() => setOpen(false)}>
                            {t('cancel-buttonLabel')}
                        </Button>
                        <Button type={'primary'} size={'small'} isBusy={isBusy} onClick={submitForm}>
                            {mode === 'add' ? t('addAddress-buttonLabel') : t('saveAddress-buttonLabel')}
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>
        </>
    );
};

const mapStateToProps = ({ resellerReducer }: RootState) => {
    return {
        resellerId: resellerReducer.selectedReseller.value!.resellerId,
        resellerLocations: resellerReducer.resellerDetails.value?.resellerLocations,
    };
};

const mapDispatchToProps = {
    resellerDetailsLocationAdded,
    resellerDetailsLocationEdited,
    setReturnOrderDraftShippingAddress,
    setReturnOrderDraftBillingAddress,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const connectedModifyAddressPopup = connector(ModifyAddressPopup);
export { connectedModifyAddressPopup as ModifyAddressPopup };
