// TODO: fix the warning - not sure what to use for a row key, and data row cell key. -- @rvg
/* eslint-disable */
import React from 'react';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import styled from '@emotion/styled';
import { color } from '../../theme/colors';
import { TableData } from '../../redux/types';

const BorderedTable = styled(Table)`
    border: 1px solid ${color.gray[300]};
    box-shadow: 0px 0px 3px ${color.gray[300]};
`;

export interface TableProps<T> {
    data: TableData<T, keyof T>;
}

// T must extend any https://github.com/Microsoft/TypeScript/issues/4922
export const DynamicTable = <T extends any>({ data }: TableProps<T>) => {
    return (
        <BorderedTable headerRows={1} showDividers={true}>
            <TableRow>
                {data.getHeaders().map((header) => {
                    return (
                        <TableCell highlight={true} key={header.uniqueId}>
                            {header.title}
                        </TableCell>
                    );
                })}
            </TableRow>
            {data.getRows().map((row) => {
                return (
                    <TableRow highlightOnHover={true}>
                        {row.map((v) => {
                            return <TableCell>{v}</TableCell>;
                        })}
                    </TableRow>
                );
            })}
        </BorderedTable>
    );
};
