import { default as MeridianTab } from '@amzn/meridian/tab';
import React, { FC, PropsWithChildren } from 'react';
import { TranslatedString } from './localization';

const Tab: FC<TabProps> = ({ label, tabId, dataTestId }) => {
    /* TODO: As part of our Meridian upgrade SIM, add 'id' to meridian tab for accessibility, also add aria-owns
        in the tab group with the ids for each tab: https://i.amazon.com/issues/Bender-7961 */
    return (
        <MeridianTab value={tabId} data-testid={dataTestId}>
            {label}
        </MeridianTab>
    );
};

export type TabProps = PropsWithChildren<{
    label: TranslatedString;
    tabId: string;
    dataTestId?: string;
}>;
export { Tab };
