import {
    IAsynchronouslyLoadedValue,
    IAuthorizedReseller,
    IManagedUser,
    IManagedUsersState,
    IResellerState,
    ReturnOrderState,
    ReturnOrderListState,
    ISystemState,
    IUserState,
    TableSortDirection,
    ReturnOrderAuditHistoryState,
    ReturnOrderDraftState,
    ReturnOrderProblemShelfState,
    DEFAULT_ITEM_IMAGE_URL,
    ASIN_MISSING_PLACEHOLDER,
    ReturnOrderDraft,
    ClaimTableSortDirection,
    ClaimListState,
} from '../redux/types';
import { IUserTermsAndConditionsAcceptancesState } from '../redux/types/userTermsAndConditionsAcceptance';
import { SiteMapPage } from '../types';
import {
    APPLICATION_BACKGROUND_IMAGE_URL,
    APPLICATION_IMAGE_URL,
    APPLICATION_SUB_NAME,
    DEFAULT_STAGE,
} from './application';
import { roles } from './roles';
import { RESELLER_TABLE_DATA } from './tableData';

export const INITIAL_SYSTEM_STATE: ISystemState = {
    roles: roles,
    applicationImgUrl: APPLICATION_IMAGE_URL,
    applicationBackgroundImgUrl: APPLICATION_BACKGROUND_IMAGE_URL,
    applicationSubName: APPLICATION_SUB_NAME,
    resellerTableData: RESELLER_TABLE_DATA,
    stage: DEFAULT_STAGE,
    redirectURL: SiteMapPage.home,
};

export const INITIAL_RESELLER_STATE: IResellerState = {
    selectedReseller: {
        status: 'Uninitialized',
    } as IAsynchronouslyLoadedValue<IAuthorizedReseller>,
    authorizedResellers: {
        status: 'Uninitialized',
    } as IAsynchronouslyLoadedValue<IAuthorizedReseller[]>,
    resellerDetails: {
        status: 'Uninitialized',
    },
    resellerItems: {
        status: 'Uninitialized',
    },
    resellerPriceList: {
        status: 'Uninitialized',
    },
    unmappedItemStatus: 'Uninitialized',
    asinToImageUrls: {
        status: 'Loaded',
        value: new Map<string, string>([[ASIN_MISSING_PLACEHOLDER, DEFAULT_ITEM_IMAGE_URL]]),
    },
};

export const INITIAL_USER_STATE: IUserState = {
    user: undefined,
    isAuthenticated: false,
    expireTime: 0,
};

export const INITIAL_MANAGED_USERS_STATE: IManagedUsersState = {
    managedUsers: {
        status: 'Uninitialized',
    } as IAsynchronouslyLoadedValue<IManagedUser[]>,
    selectedManagedUser: {
        status: 'Uninitialized',
    } as IAsynchronouslyLoadedValue<IManagedUser>,
};

export const INITIAL_RETURN_ORDER_DRAFT_STATE: ReturnOrderDraftState = {
    returnOrderDraft: {
        status: 'Uninitialized',
    } as IAsynchronouslyLoadedValue<ReturnOrderDraft>,
};

export const INITIAL_RETURN_ORDER_STATE: ReturnOrderState = {
    returnOrder: {
        status: 'Uninitialized',
    },
};

export const INITIAL_RETURN_ORDER_AUDIT_HISTORY_STATE: ReturnOrderAuditHistoryState = {
    returnOrderAuditHistory: {
        status: 'Uninitialized',
    },
};

export const INITIAL_RETURN_ORDER_PROBLEM_SHELF_STATE: ReturnOrderProblemShelfState = {
    returnOrderProblemShelf: {
        status: 'Uninitialized',
    },
};

export const INITIAL_RETURN_ORDER_LIST_STATE: ReturnOrderListState = {
    currentPage: 1,
    query: '',
    sortColumn: 'creationDate',
    sortDirection: TableSortDirection.descending,
    dateRange: [],
    startAt: 0,
    currentRecordNumber: 0,
    totalRecords: 0,
    returnOrderData: {
        value: undefined,
        status: 'Uninitialized',
        error: undefined,
    },
};

export const INITIAL_CLAIM_LIST_STATE: ClaimListState = {
    currentPage: 1,
    query: '',
    sortColumn: 'creationDate',
    sortDirection: ClaimTableSortDirection.descending,
    dateRange: [],
    startAt: 0,
    currentRecordNumber: 0,
    totalRecords: 0,
    claimListData: {
        value: undefined,
        status: 'Uninitialized',
        error: undefined,
    },
};

export const INITIAL_USER_TERMS_AND_CONDITIONS_ACCEPTANCE: IUserTermsAndConditionsAcceptancesState = {
    userTermsAndConditionsAcceptances: {
        value: undefined,
        status: 'Uninitialized',
        error: undefined,
    },
    validUserTermsAndConditions: false,
};
