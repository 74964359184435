import { CognitoUserSession } from 'amazon-cognito-identity-js';
import i18n from 'i18next';
import { Action, Dispatch } from 'redux';
import { AmplifyClient } from '../../clients';
import { cognitoGroupsToRoleIds } from '../../utils/security';
import { IUserState } from '../types';

export const CHANGE_USER_SESSION = 'user/changeSession';
interface IUserChangeSessionAction extends Action {
    type: typeof CHANGE_USER_SESSION;
    payload: IUserState;
}

// FIXME: encapsulate session -> payload data-mapping within AmplifyClient or cognito util file - 3/18/21
export const changeUserSession = (session: CognitoUserSession) => ({
    type: CHANGE_USER_SESSION,
    payload: {
        user: {
            familyName: session.getIdToken().payload.family_name,
            givenName: session.getIdToken().payload.given_name,
            email: session.getIdToken().payload.email,
            locale: session.getIdToken().payload.locale,
            roles: cognitoGroupsToRoleIds(session.getAccessToken().payload['cognito:groups']),
            username: session.getIdToken().payload['cognito:username'] as string,
        },
        isAuthenticated: session.isValid(),
        expireTime: session.getAccessToken().getExpiration(),
    },
});

export const CLEAR_USER_SESSION = 'user/clearSession';
interface IUserClearSessionAction extends Action {
    type: typeof CLEAR_USER_SESSION;
}

export const clearUserSession = () => ({
    type: CLEAR_USER_SESSION,
});

export const updateUserSession = () => (dispatch: Dispatch) =>
    AmplifyClient.userSession()
        .then((session) => {
            dispatch(changeUserSession(session));
            const { locale } = session.getIdToken().payload;
            if (locale) i18n.changeLanguage(locale);
        })
        .catch(() => dispatch(clearUserSession()));

export type UserActions = IUserChangeSessionAction | IUserClearSessionAction;
