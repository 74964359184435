import { Reducer } from 'redux';
import { INITIAL_SYSTEM_STATE } from '../../constants';
import { SYSTEM_REDIRECT_URL_LOAD, SYSTEM_STAGE_LOAD, SystemActions } from '../actions/systemActions';
import { ISystemState } from '../types';

export const systemReducer: Reducer<ISystemState, SystemActions> = (
    state = INITIAL_SYSTEM_STATE,
    action
): ISystemState => {
    switch (action.type) {
        case SYSTEM_STAGE_LOAD:
            return {
                ...state,
                stage: action.payload.stage,
                applicationBackgroundImgUrl: action.payload.applicationBackgroundImgUrl,
            };
        case SYSTEM_REDIRECT_URL_LOAD:
            return {
                ...state,
                redirectURL: action.payload.redirectURL,
            };
        default:
            return state;
    }
};
