import _ from 'lodash';
import React, { FunctionComponentElement, PropsWithChildren, ReactNode, useContext, useMemo, useState } from 'react';
import { useHistory, match as RouteMatch } from 'react-router-dom';
import { TranslatedString, TranslationFunction, useTranslation } from '../components/blocks';
import { SiteMapPage } from '../types/siteMapPage';
import { Debug } from './debug';
import { Logger } from './logger';

export type Breadcrumb = { title: TranslatedString; link: string };
export type BreadcrumbBuilder = (params: Record<string, any>, t: TranslationFunction) => Breadcrumb;

export type Page = {
    path: string;
    component: ReactNode | FunctionComponentElement<any>;
    id: string;
    breadcrumbs: BreadcrumbBuilder[];
};

export type SiteMapRouterContextType = {
    pages: Page[];
    setCurrentPage: (page: Page | undefined, match: RouteMatch | undefined) => void;
    breadcrumbs: Breadcrumb[];
    cancel: () => void;
    goto: (pageId: SiteMapPage, params?: Record<string, any>) => void;
    getPath: (pageId: SiteMapPage, params?: Record<string, any>) => string;
    currentPage: string;
};

export const SiteMapRouterContext = React.createContext<SiteMapRouterContextType>({
    pages: [],
    // eslint-disable-next-line unused-imports/no-unused-vars
    setCurrentPage: (page: Page | undefined, match: RouteMatch | undefined) => {},
    breadcrumbs: [],
    cancel: () => {},
    // eslint-disable-next-line unused-imports/no-unused-vars
    goto: (pageId, params) => {},
    // eslint-disable-next-line unused-imports/no-unused-vars
    getPath: (pageId, params) => '',
    currentPage: '',
});

export type ResourcePage = {
    action: string;
    component: ReactNode | FunctionComponentElement<any>;
    title: TranslatedString | ((t: TranslationFunction, params: any) => TranslatedString);
};

export type Resource = {
    name: string;
    param?: string;
    path?: string;
    breadcrumbAction?: string;
    pages?: ResourcePage[];
    resources?: Resource[];
};

export type Area = {
    name: string;
    resources: Resource[];
};

export type SiteMap = { areas: Area[] };

type BuildPagesForResourceContext = {
    area: string;
    resourceId: string[];
    path: string[];
    breadcrumbs: BreadcrumbBuilder[];
};

function buildPageId(area: string, action: string, resourceId: string[]) {
    return `/${_.without(['', area, ...resourceId, action], '').join('/')}`;
}

function buildPagePath(area: string, action: string, resourcePath: string[]) {
    return `/${_.without(['', area, action, ...resourcePath], '').join('/')}`;
}

function buildConcretePath(pagePath: string, params: Record<string, any> = {}) {
    return pagePath.replace(/:([^/]*)/g, function replacer(match, group1) {
        Debug.assert(
            params[group1],
            'Parameter used in a path was not supplied. Path:',
            pagePath,
            ', missing parameter:',
            group1
        );
        return params[group1];
    });
}

function breadcrumbBuilderFactory(
    pagePath: string,
    title: TranslatedString | ((t: TranslationFunction, params?: any) => TranslatedString)
) {
    return function builder(params: Record<string, any>, t: TranslationFunction) {
        return {
            title: _.isFunction(title) ? title(t, params) : title,
            link: buildConcretePath(pagePath, params),
        };
    };
}

function buildPagesForResource(resource: Resource, context: BuildPagesForResourceContext) {
    const resourceId = resource.name ? [...context.resourceId, resource.name] : [...context.resourceId];
    const resourcePath: string[] = resource.param
        ? [...context.path, resource.name, `:${resource.param}`]
        : [...context.path, resource.name];
    let ownPages: Page[] = [];
    if (resource.pages) {
        ownPages = resource.pages.map((page) => {
            const ownPagePath = buildPagePath(context.area, page.action, resourcePath);
            return {
                path: ownPagePath,
                component: page.component,
                breadcrumbs: [...context.breadcrumbs, breadcrumbBuilderFactory(ownPagePath, page.title)],
                id: buildPageId(context.area, page.action, resourceId),
            };
        });
    }
    const childPages: Page[] = [];
    if (resource.resources) {
        const breadcrumbs = [...context.breadcrumbs];
        const defaultPage = resource.breadcrumbAction && _.find(resource.pages, { action: resource.breadcrumbAction });
        if (defaultPage) {
            breadcrumbs.push(
                breadcrumbBuilderFactory(
                    buildPagePath(context.area, defaultPage.action, resourcePath),
                    defaultPage.title
                )
            );
        }
        // eslint-disable-next-line no-param-reassign
        context = {
            area: context.area,
            resourceId: resourceId,
            path: resourcePath,
            breadcrumbs: breadcrumbs,
        };
        childPages.push(
            ..._.flatten(resource.resources.map((childResource) => buildPagesForResource(childResource, context)))
        );
    }
    return [...ownPages, ...childPages];
}

export function buildPagesFromSiteMap(siteMap: SiteMap): Page[] {
    const pages: Page[] = _.flatten(
        siteMap.areas.map((area) => {
            const areaPages: Page[] = _.flatten(
                area.resources.map((resource) => {
                    const context: BuildPagesForResourceContext = {
                        area: area.name,
                        resourceId: [],
                        breadcrumbs: [],
                        path: [],
                    };
                    const areaResourcePages: Page[] = buildPagesForResource(resource, context);
                    return areaResourcePages;
                })
            );
            return areaPages;
        })
    );

    return pages;
}

export const useSiteMapRouter: () => SiteMapRouterContextType = () => {
    return useContext<SiteMapRouterContextType>(SiteMapRouterContext);
};

export const SiteMapRouter = (props: PropsWithChildren<{ siteMap: SiteMap }>) => {
    const { t } = useTranslation('breadcrumbs');
    const { siteMap, children } = props;

    const pages: Page[] = useMemo(() => buildPagesFromSiteMap(siteMap), [siteMap]);

    const history = useHistory();

    function goto(pageId: SiteMapPage, params?: Record<string, any>) {
        history.push(getPath(pageId, params));
    }

    function cancel() {
        // TODO: set history.state.root to true when page is rendered, then use that
        // to determine if we are at the "root of our portal history".
        // if we are at the root (e.g. you refreshed the page while on the cancel-able page)
        // then use breadcrumb navigation
        history.goBack();
    }

    const [currentPage, setCurrentPage] = useState<Page | undefined>(undefined);
    const [routeMatch, setRouteMatch] = useState<RouteMatch | undefined>(undefined);

    const breadcrumbs = useMemo(() => {
        let breadcrumbs: Breadcrumb[];
        if (currentPage && routeMatch) {
            breadcrumbs = currentPage.breadcrumbs.map((breadcrumb) => breadcrumb(routeMatch.params, t));
        } else {
            breadcrumbs = [];
        }
        Logger.debug('{SiteMap}', 'Recomputing breadcrumbs. New breadcrumbs:', breadcrumbs);
        return breadcrumbs;
    }, [currentPage, routeMatch, t]);

    function onPageChange(newCurrentPage: Page | undefined, newRouteMatch: RouteMatch | undefined) {
        Logger.debug('New current page:', newCurrentPage, 'match:', newRouteMatch);
        if (
            ((currentPage && currentPage.path) || currentPage) !==
            ((newCurrentPage && newCurrentPage.path) || newCurrentPage)
        ) {
            setCurrentPage(newCurrentPage);
        }

        if (!_.isEqual(routeMatch, newRouteMatch)) {
            setRouteMatch(newRouteMatch);
        }
    }

    function getPath(pageId: SiteMapPage, params?: Record<string, any>) {
        const path = _.find(pages, { id: pageId })?.path;
        Debug.assertExists(path, `{SiteMapRouter} Couldn't find a page with given id: <${pageId}>.`);
        return buildConcretePath(path, params);
    }

    return (
        <SiteMapRouterContext.Provider
            value={{
                pages,
                setCurrentPage: onPageChange,
                breadcrumbs,
                cancel,
                goto,
                getPath,
                currentPage: currentPage?.id || '',
            }}
        >
            {children}
        </SiteMapRouterContext.Provider>
    );
};
