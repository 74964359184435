import { IAsynchronouslyLoadedValue } from './asynchronouslyLoadedValue';

export type ReturnOrderListState = {
    currentPage: number;
    query: string;
    sortColumn: string;
    sortDirection: TableSortDirection;
    dateRange: string[];
    startAt: number;
    currentRecordNumber: number;
    totalRecords: number | undefined;
    returnOrderData: IAsynchronouslyLoadedValue<ReturnOrderListReturnOrder[]>;
};

export type ReturnOrderListReturnOrder = {
    billingAddress: string;
    createdByUser: string;
    creationDate: number;
    referenceId: string;
    resellerId: string;
    returnOrderId: string;
    returnOrderStatus: string;
    shippingAddress: string;
};

export type ReturnOrderFilter = {
    searchValue?: String;
    creationDateStart?: number;
    creationDateEnd?: number;
};

export type SortAttribute = {
    key: string;
    value: string;
};

export type ReturnOrdersListOutput = {
    returnOrdersList: ReturnOrderListReturnOrder[];
    totalResults: number;
};

export enum TableSortDirection {
    ascending = 'ascending',
    descending = 'descending',
}
