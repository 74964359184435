import { RoleId } from '../types/role';

export type ResourceId = string;
export type ResourceType = 'page' | 'role';
export type Permission = { resourceId: ResourceId; type?: ResourceType };
export type RolePermissions = { includesRoles?: RoleId[]; permissions?: Permission[] };

// Note: When adding in new resourceId path strings (non-wildcard permissions),
// be sure to match with the paths defined on siteMapPage.ts. Navbar paths are
// formatted differently and may not match up, resulting in 'Permission Denied'

export const rolesPermissions: Record<RoleId, RolePermissions> = {
    resellerAdmin: {
        includesRoles: ['resellerCreator', 'claimCreator'],
        permissions: [
            { resourceId: '/reseller/*', type: 'page' },
            { resourceId: '/claims/*', type: 'page' },
            { resourceId: '/admin/*', type: 'page' },
            { resourceId: 'resellerAdmin', type: 'role' },
            { resourceId: 'resellerCreator', type: 'role' },
            { resourceId: 'resellerViewer', type: 'role' },
            { resourceId: 'claimCreator', type: 'role' },
            { resourceId: 'claimViewer', type: 'role' },
        ],
    },
    resellerCreator: {
        includesRoles: ['resellerViewer'],
        permissions: [
            { resourceId: '/returns/*', type: 'page' },
            { resourceId: '/reseller/*', type: 'page' },
        ],
    },
    resellerViewer: {
        includesRoles: ['authenticatedUser'],
        permissions: [
            { resourceId: '/reseller/contacts/list', type: 'page' },
            { resourceId: '/returns/return-orders/list', type: 'page' },
            { resourceId: '/returns/return-orders/return-order/view', type: 'page' },
            { resourceId: '/notifications/*', type: 'page' },
        ],
    },
    claimCreator: {
        includesRoles: ['claimViewer'],
        permissions: [{ resourceId: '/claims/*', type: 'page' }],
    },
    claimViewer: {
        includesRoles: ['authenticatedUser'],
        permissions: [
            { resourceId: '/claims/claims/view', type: 'page' },
            { resourceId: '/notifications/*', type: 'page' },
        ],
    },
    amazonAdmin: {
        includesRoles: ['resellerAdmin'],
        permissions: [
            { resourceId: '/*', type: 'page' },
            { resourceId: 'amazonAdmin', type: 'role' },
            { resourceId: 'amazonCreator', type: 'role' },
            { resourceId: '/claims/*', type: 'page' },
            { resourceId: 'claimCreator', type: 'role' },
            { resourceId: 'claimViewer', type: 'role' },
        ],
    },
    amazonCreator: {
        includesRoles: ['claimCreator', 'resellerCreator'],
    },
    authenticatedUser: {
        includesRoles: ['unauthenticatedUser'],
        permissions: [
            { resourceId: '/account/*', type: 'page' },
            { resourceId: '/news/*', type: 'page' },
        ],
    },
    unauthenticatedUser: {
        includesRoles: [],
        permissions: [
            { resourceId: '/account/change-password', type: 'page' },
            { resourceId: '/account/reset-password', type: 'page' },
            { resourceId: '/account/sign-in', type: 'page' },
            { resourceId: '/', type: 'page' },
            { resourceId: '/errors/*', type: 'page' },
        ],
    },
};
