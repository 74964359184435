import Column from '@amzn/meridian/column';
import { FileDetails } from '@amzn/meridian/file-input';
import { FileDetailsFile } from '@amzn/meridian/file-input/file-details';
import Row from '@amzn/meridian/row';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { PutClient } from '../clients';
import { useHrpsApi } from '../clients/useHrpsApi';
import { Heading, Link, useTranslation } from '../components/blocks';
import { Text, Button, FileInput } from '../components/blocks/localization';
import { Tile, usePageMessaging } from '../components/composites';
import { DocumentListPopup } from '../components/composites/DocumentListPopup';
import { useModal } from '../components/composites/Modal';
import { UploadErrorDialog } from '../components/constructed';
import { UPLOAD_STATUS } from '../constants/uploadStatus';
import { AttachedDocument, RootState } from '../redux/types';
import { SiteMapPage } from '../types';
import { ProcessedReturnOrderStatus, ProcessReturnOrderUploadOutput } from '../types/ProcessReturnOrderUpload';
import { delay } from '../utils/delay';
import { Logger } from '../utils/logger';
import { useSiteMapRouter } from '../utils/SiteMapRouter';
import { useDownloader } from '../utils/useDownloader';

const UploadReturnOrderPage: FC<UploadReturnOrderPageProps> = ({ resellerId }) => {
    const { t } = useTranslation('uploadReturnOrderPage');
    const { cancel } = useSiteMapRouter();
    const { showSuccess, showError } = usePageMessaging();
    const modal = useModal();
    const { download } = useDownloader();
    const { getPath } = useSiteMapRouter();

    const [isPageBusy, setIsPageBusy] = useState(false);
    const [progress, setProgress] = useState(0);
    const [file, setFile] = useState<File | undefined>(undefined);
    const [processResults, setProcessResults] = useState<ProcessedReturnOrderStatus[]>([]);
    const [templates, setTemplates] = useState<AttachedDocument[]>([]);

    const {
        returnOrders: { requestReturnOrderUploadUrl, processReturnOrderUpload, getReturnOrderUploadTemplates },
    } = useHrpsApi();

    const loadTemplates = useCallback(async () => {
        const result = await getReturnOrderUploadTemplates();
        setTemplates(result.templates);
    }, [getReturnOrderUploadTemplates]);

    useEffect(() => {
        if (templates.length === 0) {
            loadTemplates();
        }
    }, [loadTemplates, templates.length]);

    const uploadReturnOrder = async () => {
        if (!file) return;

        setIsPageBusy(true);

        const uploadReturnOrdersResult = await requestReturnOrderUploadUrl({
            resellerId,
            filename: file.name,
            mimeType: file.type,
        });

        // upload file
        try {
            setProgress(0);
            Logger.debug(`uploading File: ${uploadReturnOrdersResult.url}`);
            await PutClient.put(uploadReturnOrdersResult.url, file);
            Logger.debug(`File Uploaded Successfully`);
            setProgress(33);
            // between uploading the file and processing it. HDSS needs time to move the file
            // bit of a hack right now.  Will move to retry promise to retry a few times.
            await delay(3000);
            setProgress(66);
            setProcessResults(await submitReturnOrder(uploadReturnOrdersResult.id));
            setProgress(100);
            showSuccess(t('uploadReturnOrder-successMessage'));
        } catch (error) {
            Logger.debug(`failed to upload file with error: ${error}`);
            showError(t('uploadReturnOrder-errorMessage'));
        } finally {
            setFile(undefined);
            setProgress(0);
            setIsPageBusy(false);
        }
    };

    const submitReturnOrder = async (uploadedReturnOrdersFileId: string): Promise<ProcessedReturnOrderStatus[]> => {
        const processReturnOrderResult = await submitReturnOrderRetry(uploadedReturnOrdersFileId, 10);
        // Failed uploads that return a returnOrderId should be treated as DRAFTED by the UI.
        return processReturnOrderResult.uploadedRequests.map((uploadResult) => {
            const formattedUploadResult = uploadResult;
            if (uploadResult.returnOrderId && uploadResult.status === UPLOAD_STATUS.FAILED) {
                formattedUploadResult.status = UPLOAD_STATUS.DRAFTED;
            }
            return formattedUploadResult;
        });
    };

    const delayPeriod = 250;
    const submitReturnOrderRetry = async (
        uploadedReturnOrdersFileId: string,
        retryCount: number,
        delayMultiplier = 1
    ): Promise<ProcessReturnOrderUploadOutput> => {
        try {
            const results = await processReturnOrderUpload({
                resellerId,
                uploadId: uploadedReturnOrdersFileId,
            });
            return results;
        } catch (e) {
            if (e.errorCode !== 'UploadNotAvailable' || retryCount === 0) {
                Logger.debug(`error code ${e} not retryable throwing`);
                return Promise.reject(e);
            }
            await delay(delayPeriod * delayMultiplier);
            return submitReturnOrderRetry(uploadedReturnOrdersFileId, retryCount - 1, delayMultiplier + 1);
        }
    };

    const downloadTemplate = async (templateId: string) => {
        const template = templates.find((template) => template.id === templateId);
        if (template) {
            download(template.presignedURL);
        } else {
            Logger.error(`template id <${templateId}> not found`);
        }
    };

    const showDocumentListPopup = () => {
        modal.prompt({
            title: t('uploadTemplates-documentsPopupTitle'),
            content: (
                <DocumentListPopup
                    documents={templates.map((template) => ({
                        documentId: template.id,
                        name: template.name,
                        description: template.description,
                        uploadTimestamp: template.uploadedAt,
                    }))}
                    onDocumentDownload={(templateId) => downloadTemplate(templateId)}
                />
            ),
        });
    };

    return (
        <Column>
            <Row>
                <Heading level={4}>{t('uploadReturnOrder-pageTitle')}</Heading>
            </Row>
            <Row>
                <Text>{t('uploadReturnOrder-instruction')}</Text>
            </Row>
            <Row>
                <Link onClick={() => showDocumentListPopup()}>{t('downloadTemplates-buttonLabel')}</Link>
            </Row>
            <Row>
                <Tile title={t('uploadReturnOrder-tileTitle')} width={'100%'}>
                    <Column>
                        <FileInput
                            uploadButtonLabel={t('chooseFile-buttonLabel')}
                            uploadButtonDisabled={isPageBusy}
                            accept={'.csv,.txt,.xlsx'}
                            onFileAttached={(acceptedFiles) => {
                                Logger.debug(acceptedFiles);
                                setFile(acceptedFiles[0]);
                            }}
                        >
                            {file && (
                                <FileDetails
                                    file={(file as unknown) as FileDetailsFile}
                                    uploadPercentage={progress}
                                    key={((file as unknown) as FileDetailsFile).name}
                                    uploadComplete={progress === 100}
                                />
                            )}
                        </FileInput>
                        <Text>{t('chooseFile-buttonHint')}</Text>
                    </Column>
                </Tile>
            </Row>
            <Row>
                <Button type={'secondary'} onClick={cancel}>
                    {t('cancel-buttonLabel')}
                </Button>
                <Button type={'primary'} isBusy={isPageBusy} disabled={!file} onClick={() => uploadReturnOrder()}>
                    {t('submit-buttonLabel')}
                </Button>
            </Row>
            {processResults.length ? (
                <Row>
                    <Tile
                        width={'100%'}
                        title={t('processUploadedReturnOrdersResults-tableHeading')}
                        contentSpacingInset={'none'}
                    >
                        <Table headerRows={1}>
                            <TableRow>
                                <TableCell>{t('uploadedCustomerReferenceId-columnHeader')}</TableCell>
                                <TableCell>{t('uploadedReturnOrder-columnHeader')}</TableCell>
                                <TableCell>{t('uploadedReturnOrderStatus-columnHeader')}</TableCell>
                                <TableCell>{t('uploadedReturnOrderMessage-columnHeader')}</TableCell>
                            </TableRow>
                            {processResults
                                .sort((a, b) => (a.customerReferenceId < b.customerReferenceId ? -1 : 1))
                                .map((processResult) => [
                                    <TableRow key={`${t.cognate(processResult.customerReferenceId)}_row`} open={true}>
                                        <TableCell>
                                            {!processResult.returnOrderId ? (
                                                t.cognate(processResult.customerReferenceId)
                                            ) : (
                                                <Link
                                                    type={'primary'}
                                                    href={
                                                        processResult.status === UPLOAD_STATUS.DRAFTED
                                                            ? getPath(SiteMapPage.updateDraftReturnOrder, {
                                                                  returnOrderId: processResult.returnOrderId,
                                                              })
                                                            : getPath(SiteMapPage.viewReturnOrder, {
                                                                  returnOrderId: processResult.returnOrderId,
                                                              })
                                                    }
                                                    key={`${processResult.customerReferenceId} - ${processResult.returnOrderId} - Link`}
                                                >
                                                    {t.cognate(processResult.customerReferenceId)}
                                                </Link>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {!processResult.returnOrderId
                                                ? t.cognate(' ')
                                                : t.cognate(processResult.returnOrderId!)}
                                        </TableCell>
                                        <TableCell>
                                            {t('processedReturnOrderStatus', { context: processResult.status })}
                                        </TableCell>
                                        <TableCell>
                                            <Text>
                                                {!processResult.errorCode
                                                    ? t.cognate(' ')
                                                    : t('processedReturnOrderMessage', {
                                                          context: processResult.errorCode,
                                                      })}
                                            </Text>
                                            {processResult.status === UPLOAD_STATUS.DRAFTED && (
                                                <Text>{t('uploadedReturnOrder-draftNotice')}</Text>
                                            )}
                                        </TableCell>
                                        {processResult.warnings && processResult.warnings.length > 0 ? (
                                            <TableRow>
                                                <TableCell columnSpan={4}>
                                                    <UploadErrorDialog
                                                        key={`${processResult.customerReferenceId} - ${processResult.customerReferenceId} - uploadErrorDialog`}
                                                        label={t.cognate(processResult.customerReferenceId)}
                                                        uploadWarnings={processResult.warnings}
                                                        dataTestId={processResult.customerReferenceId}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ) : undefined}
                                    </TableRow>,
                                ])}
                        </Table>
                    </Tile>
                </Row>
            ) : undefined}
        </Column>
    );
};

const mapStateToProps = ({ resellerReducer }: RootState) => {
    return {
        resellerId: resellerReducer.selectedReseller.value!.resellerId,
    };
};

const connector = connect(mapStateToProps);
export type UploadReturnOrderPageProps = ConnectedProps<typeof connector>;
export default connector(UploadReturnOrderPage);
