export class CodedError<TErrorCode extends string = string> extends Error {
    code: TErrorCode;

    data?: any;

    constructor(message: string, code: TErrorCode, data?: any) {
        super(message);
        this.code = code;
        this.data = data;
    }
}
