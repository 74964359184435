import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from '../blocks';

const Svg = styled.svg`
    width: 16px;
    height: 16px;
    color: red;
`;

export const StatusNegative = () => {
    const { t } = useTranslation('common');
    return (
        <Svg width={'16px'} height={'16px'} aria-label={t('icon-statusNegativeLabel')}>
            <g id={'icon'}>
                <g>
                    <path
                        fill={'currentColor'}
                        d={
                            'M13.7,2.3C12.1,0.8,10.1,0,8,0S3.9,0.8,2.3,2.3C0.8,3.9,0,5.9,0,8s0.8,4.1,2.3,5.7C3.9,15.2,5.9,16,8,16 s4.1-0.8,5.7-2.3C15.2,12.1,16,10.1,16,8S15.2,3.9,13.7,2.3z M12.2,12.2C11.1,13.4,9.6,14,8,14s-3.1-0.6-4.2-1.8S2,9.6,2,8 s0.6-3.1,1.8-4.2S6.4,2,8,2s3.1,0.6,4.2,1.8S14,6.4,14,8S13.4,11.1,12.2,12.2z'
                        }
                    />
                    <polygon
                        fill={'currentColor'}
                        points={
                            '10.1,4.5 8,6.6 5.9,4.5 4.5,5.9 6.6,8 4.5,10.1 5.9,11.5 8,9.4 10.1,11.5 11.5,10.1 9.4,8 11.5,5.9 '
                        }
                    />
                </g>
            </g>
        </Svg>
    );
};
