import AppLayout from '@amzn/meridian/app-layout';
import Column from '@amzn/meridian/column';
import Theme from '@amzn/meridian/theme';
import { Hub } from '@aws-amplify/core';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AmplifyClient } from '../clients';
import { InactivityTimer, UnauthenticatedAppLayout } from '../components/blocks';
import SiteNotifications from '../components/composites/SiteNotifications';
import { Breadcrumbs, Footer, Navbar } from '../components/constructed';
import { AmplifyConfigurator } from '../config/AmplifyConfigurator';
import { TermsAndConditionsPage } from '../pages';
import { getStage, setRedirectURL } from '../redux/actions/systemActionCreators';
import { updateUserSession } from '../redux/actions/userActions';
import { RootState } from '../redux/types';
import { Routes } from '../routes';
import { THEME } from '../theme';
import { Logger } from '../utils/logger';
import { URLHelper } from '../utils/URLHelper';
import { Container } from './style';

const App = ({
    userState,
    selectedReseller,
    managedUsers,
    selectedUser,
    returnOrder,
    returnOrderDraft,
    validUserTermsAndConditions,
    stage,
    getStage,
    setRedirectURL,
}: AppProps) => {
    const dispatch = useDispatch();
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [open, setOpen] = useState(false);

    const location = useLocation();
    const redirectURL = URLHelper.toRelativeURL(location);

    function setOpenState(value: boolean): boolean {
        setOpen(value);
        return value;
    }

    useEffect(() => {
        if (stage.status === 'Uninitialized') {
            getStage();
        }
        AmplifyConfigurator.configure().then(
            async () => {
                Logger.debug('amplify configured');

                if (
                    // if we have an explicit federated sign in flag
                    (URLHelper.getQueryParam('federate') === 'true' ||
                        // or the weird error we get on the first sign in that creates/links the user
                        URLHelper.getQueryParam('error_description')?.startsWith(
                            'Already found an entry for username HRPCognitoFederate-'
                        )) &&
                    !userState.isAuthenticated &&
                    !URLHelper.getQueryParam('code')
                ) {
                    // triggers a Hub auth signIn event on success
                    await AmplifyClient.federatedSignIn();
                } else {
                    // attempt to get user session with current browser session tokens
                    await dispatch(updateUserSession());
                }

                setIsAuthenticating(false);
            },
            () => Logger.debug('amplify configuration failed')
        );
    }, [dispatch, getStage, stage.status, userState.isAuthenticated]);

    const reportUserSessionUpdate = async (event: string, data: any) => {
        Logger.debug(`auth event:<${event}>`);
        Logger.debug(`auth data:<${JSON.stringify(data)}>`);
        await dispatch(updateUserSession());
    };

    useEffect(() => {
        Hub.listen('auth', async ({ payload: { event, data } }) => {
            switch (event) {
                case 'signIn':
                case 'signOut': {
                    await reportUserSessionUpdate(event, data);
                    break;
                }
                default:
                // do nothing
            }
        });
    });

    const data = {
        userState,
        managedUsers,
        selectedReseller,
        selectedUser,
        returnOrder,
        returnOrderDraft,
    };

    if (isAuthenticating) {
        setRedirectURL(redirectURL);
        return <div>{'Loading...'}</div>;
    } else if (userState.isAuthenticated && selectedReseller) {
        if (validUserTermsAndConditions) {
            return (
                <InactivityTimer onSessionExpiration={() => AmplifyClient.signOut()}>
                    <AppLayout headerComponent={Navbar} footerComponent={Footer}>
                        <Theme tokens={THEME.darkTokens}>
                            <Navbar />
                        </Theme>
                        <Theme tokens={THEME.lightTokens}>
                            <Column heights={['5%', '5%', 'fill']}>
                                <SiteNotifications />
                                <Breadcrumbs />
                                <Container>
                                    <Column
                                        width={'fill'}
                                        alignmentVertical={'top'}
                                        alignmentHorizontal={'center'}
                                        minHeight={'45em'}
                                    >
                                        <Routes open={open} setOpen={setOpenState} />
                                    </Column>
                                </Container>
                            </Column>
                        </Theme>
                        <Theme tokens={THEME.darkTokens}>
                            <Footer
                                user={userState.user}
                                reseller={selectedReseller}
                                data={data}
                                dataTestId={'Application-Footer'}
                                stage={stage.value}
                                helpOpener={setOpenState}
                            />
                        </Theme>
                    </AppLayout>
                </InactivityTimer>
            );
        } else {
            return (
                <Theme tokens={THEME.lightTokens}>
                    <UnauthenticatedAppLayout>
                        <TermsAndConditionsPage />
                    </UnauthenticatedAppLayout>
                </Theme>
            );
        }
    } else {
        return (
            <Theme tokens={THEME.lightTokens}>
                <UnauthenticatedAppLayout>
                    <Routes open={open} setOpen={setOpenState} />
                </UnauthenticatedAppLayout>
            </Theme>
        );
    }
};

const mapDispatchToProps = {
    getStage,
    setRedirectURL,
};

const mapStateToProps = ({
    userReducer,
    resellerReducer,
    managedUserReducer,
    returnOrderReducer,
    returnOrderDraftReducer,
    systemReducer,
    termsAndConditionsReducer,
}: RootState) => {
    return {
        userState: userReducer,
        selectedReseller: resellerReducer.selectedReseller.value,
        managedUsers: managedUserReducer.managedUsers.value,
        selectedUser: managedUserReducer.selectedManagedUser.value,
        returnOrder: returnOrderReducer.returnOrder.value,
        returnOrderDraft: returnOrderDraftReducer.returnOrderDraft.value,
        stage: systemReducer.stage,
        validUserTermsAndConditions: termsAndConditionsReducer.validUserTermsAndConditions,
        redirectURL: systemReducer.redirectURL,
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type AppProps = ConnectedProps<typeof connector>;

export default connector(App);
