import React, { ReactElement, useEffect, useRef } from 'react';
import { Debug } from './debug';

export type PropsWithTestId<T> = T & { dataTestId?: string };

export function withTestId<T extends { ref?: React.Ref<any> }>(Component: (props: T) => ReactElement | null) {
    return React.forwardRef<any, PropsWithTestId<T>>((props, ref) => {
        // At least SearchField Meridian component does not handle ref assignment via a callback.
        // Bug: https://issues.amazon.com/issues/MRDN-4339
        Debug.assert(
            !ref || Object.prototype.hasOwnProperty.call(ref, 'current'),
            '`ref`, when given, must be a `MutableRefObject` reference.'
        );

        const localRef = useRef<any>();
        const { dataTestId } = props;
        const computedRef = ref ?? localRef;

        useEffect(() => {
            if (localRef.current && dataTestId) {
                localRef.current.setAttribute('data-testid', dataTestId);
            }
        }, [dataTestId]);

        return <Component ref={computedRef} {...props} />;
    });
}
