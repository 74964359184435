import Link from '@amzn/meridian/link';
import Popover, { PopoverHeader } from '@amzn/meridian/popover';
import { PopoverPosition } from '@amzn/meridian/popover/popover';
import Row from '@amzn/meridian/row';
import Table, { TableCell, TableRow } from '@amzn/meridian/table';
import Text from '@amzn/meridian/text';
import React, { useCallback, useRef, useState } from 'react';
import { Alert, Input, TranslatedString, useTranslation } from '../blocks/localization';
import { TruncatedText } from '../composites';
import { Add, Delete, Edit } from '../icons';

export interface MultilineFieldEntryProps {
    dataTestId?: string;
    label?: TranslatedString;
    error?: TranslatedString;
    infoMessage?: TranslatedString;
    placeholderText?: TranslatedString;
    value?: string[];
    delimiter?: string;
    onChange(value: string[]): void;
    maxWidth?: string;
    position?: PopoverPosition;
}

const MultilineFieldEntry = ({
    value,
    label,
    error,
    infoMessage,
    placeholderText,
    maxWidth,
    dataTestId,
    position,
    delimiter,
    onChange,
}: MultilineFieldEntryProps) => {
    const buttonRef = useRef();
    const [open, setOpen] = useState(false);
    const onClickButton = () => setOpen(true);
    const onClose = () => {
        setOpen(false);
    };
    const selectedDelimiter: string = delimiter !== undefined ? delimiter : ';';
    const valueArray: string[] = value !== undefined ? [...value] : [];
    const [resultValue, setResultValue] = useState(valueArray);
    const [addedValue, setAddedValue] = useState('');
    const { t } = useTranslation(['multilineFieldEntry']);

    const onDeleteButton = (deleteItem: string) => {
        const curValue = [...resultValue];
        const index = curValue.indexOf(deleteItem);
        if (index > -1) {
            curValue.splice(index, 1);
        }
        setResultValue([...curValue]);
        onChange([...curValue]);
    };

    const onAddButton = () => {
        const curValue = [...resultValue];
        const newEmails = addedValue
            .split(selectedDelimiter)
            .map((item) => item.trim())
            .filter((item) => item);
        setResultValue([...curValue, ...newEmails]);
        setAddedValue('');
        onChange([...curValue, ...newEmails]);
    };

    const formatInputString = useCallback(() => {
        return valueArray.join('; ');
    }, [valueArray]);

    const tableRows = Object.values(valueArray).map((item) => {
        return (
            <TableRow key={item}>
                <TableCell>
                    <Text type={'b100'}>{item}</Text>
                </TableCell>
                <TableCell>
                    <Link
                        onClick={() => {
                            onDeleteButton(item);
                        }}
                        type={'secondary'}
                        data-testid={`${dataTestId}.${item}.deletelink`}
                    >
                        <Delete />
                    </Link>
                </TableCell>
            </TableRow>
        );
    });

    return (
        <>
            {label && <Text>{label}</Text>}
            <Row>
                {valueArray.length > 0 && (
                    <TruncatedText
                        text={t.cognate(formatInputString())}
                        maxWidth={75}
                        dataTestId={`${dataTestId}.emailText`}
                    />
                )}
                {valueArray.length === 0 && placeholderText && (
                    <Text color={'secondary'} data-testid={`${dataTestId}.emailPlaceholder`}>
                        {placeholderText}
                    </Text>
                )}
                <Link onClick={onClickButton} ref={buttonRef} type={'primary'} data-testid={`${dataTestId}.link`}>
                    <Row spacing={'xxsmall'}>
                        <Edit />
                        <Text>{`(${valueArray.length})`}</Text>
                    </Row>
                </Link>
            </Row>
            {error && (
                <Row>
                    <div data-testid={dataTestId && `${dataTestId}.validationError`}>
                        <Alert type={'error'} size={'small'}>
                            {error}
                        </Alert>
                    </div>
                </Row>
            )}
            <Popover
                anchorNode={buttonRef.current}
                open={open}
                onClose={onClose}
                position={position || 'right'}
                maxWidth={maxWidth || '30em'}
                data-testid={`${dataTestId}.popover`}
            >
                {label && (
                    <PopoverHeader closeLabel={'Close'} data-testid={`${dataTestId}.popoverHeader`}>
                        {label}
                    </PopoverHeader>
                )}
                <Table headerRows={0} showDividers={false} spacing={'small'}>
                    {tableRows}
                    <TableRow key={'newValue'}>
                        <TableCell>
                            <Input
                                value={addedValue}
                                onChange={setAddedValue}
                                label={t('add-entryPlaceholder')}
                                size={'small'}
                                data-testid={`${dataTestId}.addInput`}
                            />
                        </TableCell>
                        <TableCell>
                            <Link onClick={onAddButton} type={'secondary'} data-testid={`${dataTestId}.addlink`}>
                                <Add />
                            </Link>
                        </TableCell>
                    </TableRow>
                </Table>
                {infoMessage && (
                    <Row>
                        <Alert type={'informational'} size={'small'}>
                            <Text color={'secondary'}>{infoMessage}</Text>
                        </Alert>
                    </Row>
                )}
            </Popover>
        </>
    );
};

export { MultilineFieldEntry };
