import { Dispatch } from 'redux';
import { useHrpsApi } from '../../clients/useHrpsApi';
import { ReturnOrderProblemShelfItem } from '../../types/ReturnOrderProblemShelf';
import { Logger } from '../../utils/logger';
import { RETURN_ORDER_PROBLEM_SHELF_GET, RETURN_ORDER_PROBLEM_SHELF_LOADED } from './returnOrderProblemShelfActions';

const returnOrderProblemShelfLoading = () => ({
    type: RETURN_ORDER_PROBLEM_SHELF_GET,
    payload: {
        status: 'Loading',
    },
});

const returnOrderProblemShelfLoaded = (returnOrderProblemShelf: ReturnOrderProblemShelfItem[]) => ({
    type: RETURN_ORDER_PROBLEM_SHELF_LOADED,
    payload: {
        status: 'Loaded',
        value: returnOrderProblemShelf,
    },
});

const returnOrderProblemShelfFailed = (error: any) => ({
    type: RETURN_ORDER_PROBLEM_SHELF_LOADED,
    payload: {
        status: 'Failed',
        error,
    },
});

export function getReturnOrderProblemShelf(returnOrderId: string) {
    return async (dispatch: Dispatch) => {
        dispatch(returnOrderProblemShelfLoading());
        const {
            returnOrders: { getReturnOrderProblemShelf },
        } = useHrpsApi();
        const problemShelfInput = {
            rmaId: returnOrderId,
        };
        return getReturnOrderProblemShelf(problemShelfInput)
            .then((returnOrderProblemShelf) => {
                dispatch(returnOrderProblemShelfLoaded(returnOrderProblemShelf.problemShelfItems));
            })
            .catch((error) => {
                Logger.debug(`error getting problem shelf: ${error}`);
                dispatch(returnOrderProblemShelfFailed(error));
            });
    };
}
