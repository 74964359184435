import { Reducer } from 'redux';
import { INITIAL_CLAIM_LIST_STATE } from '../../constants';
import {
    CLAIM_LIST_CLEAR,
    CLAIM_LIST_DATE_RANGE_CHANGE,
    CLAIM_LIST_ERROR,
    CLAIM_LIST_GET,
    CLAIM_LIST_LOADED,
    CLAIM_LIST_LOAD_MORE_DATA,
    CLAIM_LIST_PAGE_CHANGE,
    CLAIM_LIST_QUERY_CHANGE,
    CLAIM_LIST_SORT_CHANGE,
    ClaimListActions,
} from '../actions/claimListActions';
import { ClaimListState } from '../types';

export const resellerClaimListReducer: Reducer<ClaimListState, ClaimListActions> = (
    state = INITIAL_CLAIM_LIST_STATE,
    action
): ClaimListState => {
    switch (action.type) {
        case CLAIM_LIST_SORT_CHANGE:
            return {
                ...state,
                currentPage: 1,
                sortColumn: action.payload.sortColumn,
                sortDirection: action.payload.sortDirection,
                startAt: 0,
                currentRecordNumber: 0,
                claimListData: {
                    value: undefined,
                    status: 'Uninitialized',
                    error: undefined,
                },
            };
        case CLAIM_LIST_QUERY_CHANGE:
            return {
                ...state,
                currentPage: 1,
                query: action.payload.query,
                startAt: 0,
                currentRecordNumber: 0,
                claimListData: {
                    value: undefined,
                    status: 'Uninitialized',
                    error: undefined,
                },
            };
        case CLAIM_LIST_DATE_RANGE_CHANGE:
            return {
                ...state,
                dateRange: action.payload.dateRange,
                startAt: 0,
                currentRecordNumber: 0,
                claimListData: {
                    value: undefined,
                    status: 'Uninitialized',
                    error: undefined,
                },
            };
        case CLAIM_LIST_LOADED:
            return {
                ...state,
                totalRecords: action.payload.totalResults,
                claimListData: {
                    value: action.payload.claimsList,
                    status: 'Loaded',
                    error: undefined,
                },
            };
        case CLAIM_LIST_GET:
            return {
                ...state,
                totalRecords: 0,
                claimListData: {
                    value: undefined,
                    status: 'Loading',
                    error: undefined,
                },
            };
        case CLAIM_LIST_ERROR:
            return {
                ...state,
                totalRecords: 0,
                claimListData: {
                    value: undefined,
                    status: 'Failed',
                    error: action.payload.error,
                },
            };
        case CLAIM_LIST_CLEAR:
            return {
                ...state,
                totalRecords: 0,
                claimListData: {
                    value: undefined,
                    status: 'Uninitialized',
                    error: undefined,
                },
            };
        case CLAIM_LIST_PAGE_CHANGE:
            return {
                ...state,
                currentPage: action.payload.currentPage,
                currentRecordNumber: action.payload.currentRecordNumber,
            };
        case CLAIM_LIST_LOAD_MORE_DATA:
            return {
                ...state,
                currentPage: action.payload.currentPage,
                startAt: action.payload.currentRecordNumber,
                currentRecordNumber: action.payload.currentRecordNumber,
                claimListData: {
                    value: undefined,
                    status: 'Uninitialized',
                    error: undefined,
                },
            };
        default:
            return state;
    }
};
