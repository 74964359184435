import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import React, { FC, FormEvent, useState } from 'react';
import { connect } from 'react-redux';
import { AmplifyClient, ChangePasswordError } from '../../clients/AmplifyClient';
import { RootState } from '../../redux/types';
import { SiteMapPage } from '../../types';
import { Logger } from '../../utils/logger';
import { useSiteMapRouter } from '../../utils/SiteMapRouter';
import { useTranslation, Button, Text, TranslatedString } from '../blocks';
import { LabeledInput } from '../composites';
import { usePageMessaging } from '../composites/PageMessaging';
import { HelpPopover } from './HelpPopover';

interface ChangePasswordProps {
    isAuthenticated: boolean;
    username?: string;
    code?: string;
    customCancel?: () => void;
}

const ChangePassword: FC<ChangePasswordProps> = ({ isAuthenticated, username, code, customCancel }) => {
    const { t } = useTranslation(['changePassword', 'validation', 'forms']);
    const { goto, cancel } = useSiteMapRouter();

    const [oldPassword, setOldPassword] = useState('');
    const [oldPasswordRequiredError, setOldPasswordRequiredError] = useState(false);
    const [oldPasswordIncorrectError, setOldPasswordIncorrectError] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordRequiredError, setNewPasswordRequiredError] = useState(false);
    const [newPasswordRequirementsError, setNewPasswordRequirementsError] = useState(false);
    const [newPasswordAgain, setNewPasswordAgain] = useState('');
    const [newPasswordAgainRequiredError, setNewPasswordAgainRequiredError] = useState(false);
    const [newPasswordMatchError, setNewPasswordMatchError] = useState(false);
    const [verificationCode, setVerificationCode] = useState(code || '');
    const [verificationCodeRequiredError, setVerificationCodeRequiredError] = useState(false);
    const [verificationCodeInvalidError, setVerificationCodeInvalidError] = useState(false);
    const [verificationCodeExpiredError, setVerificationCodeExpiredError] = useState(false);

    const handleOldPasswordChange = (value: string) => {
        setOldPasswordIncorrectError(false);
        setOldPasswordRequiredError(!value && isAuthenticated);
        setOldPassword(value);
    };

    const handleNewPasswordChange = (value: string) => {
        setNewPasswordMatchError(false);
        setNewPasswordRequirementsError(false);
        setNewPasswordRequiredError(!value);
        setNewPassword(value);
    };

    const handleNewPasswordAgainChange = (value: string) => {
        setNewPasswordMatchError(false);
        setNewPasswordAgainRequiredError(!value);
        setNewPasswordAgain(value);
    };

    const handleVerificationCodeChange = (value: string) => {
        setVerificationCodeInvalidError(false);
        setVerificationCodeExpiredError(false);
        setVerificationCodeRequiredError(!value);
        setVerificationCode(value);
    };

    const pageMessaging = usePageMessaging();

    const changePassword = (oldPassword: string, newPassword: string) => {
        AmplifyClient.changePassword(oldPassword, newPassword)
            .then(() => {
                pageMessaging.showSuccess(t('changePassword:password-updated'));
                cancel();
            })
            .catch((error) => {
                switch (error) {
                    case ChangePasswordError.Authentication:
                        setOldPasswordIncorrectError(true);
                        break;
                    case ChangePasswordError.PasswordPolicy:
                        setNewPasswordRequirementsError(true);
                        break;
                    default:
                        Logger.error('change password error: ', error);
                        pageMessaging.showError(t('changePassword:change-password-error'));
                        break;
                }
            });
    };

    const completeNewPassword = (newPassword: string) => {
        // triggers a Hub auth signIn event on success
        AmplifyClient.completeNewPassword(newPassword)
            .then(() => {
                pageMessaging.showSuccess(t('changePassword:password-updated'));
                goto(SiteMapPage.selectReseller);
            })
            .catch((error) => {
                switch (error) {
                    case ChangePasswordError.PasswordPolicy:
                        setNewPasswordRequirementsError(true);
                        break;
                    default:
                        Logger.error('change password error: ', error);
                        pageMessaging.showError(t('changePassword:change-password-error'));
                        break;
                }
            });
    };

    const replaceForgottenPassword = (username: string, code: string, newPassword: string) => {
        AmplifyClient.forgotPasswordSubmit(username, code, newPassword)
            .then(() => {
                pageMessaging.showSuccess(t('changePassword:password-updated'));
                goto(SiteMapPage.signIn);
            })
            .catch((error) => {
                switch (error) {
                    case ChangePasswordError.CodeMismatch:
                        setVerificationCodeInvalidError(true);
                        break;
                    case ChangePasswordError.PasswordPolicy:
                        setNewPasswordRequirementsError(true);
                        break;
                    case ChangePasswordError.ExpiredCode:
                        setVerificationCodeExpiredError(true);
                        break;
                    default:
                        Logger.error('change password error: ', error);
                        pageMessaging.showError(t('changePassword:change-password-error'));
                        break;
                }
            });
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setOldPasswordIncorrectError(false);
        setOldPasswordRequiredError(!oldPassword && isAuthenticated);
        setNewPasswordRequiredError(!newPassword);
        setNewPasswordAgainRequiredError(!newPasswordAgain);
        setVerificationCodeRequiredError(!verificationCode);
        if (newPassword && newPasswordAgain) {
            if (newPassword !== newPasswordAgain) {
                setNewPasswordMatchError(true);
            } else if ((oldPassword || !isAuthenticated) && (verificationCode || !username)) {
                if (isAuthenticated) {
                    changePassword(oldPassword, newPassword);
                } else if (username) {
                    replaceForgottenPassword(username, verificationCode, newPassword);
                } else {
                    completeNewPassword(newPassword);
                }
            }
        }
    };

    const determineOldPasswordError = (): TranslatedString | undefined => {
        if (oldPasswordRequiredError) {
            return t('validation:required-field-alert', {
                label: t('changePassword:old-password'),
            });
        }

        if (oldPasswordIncorrectError) {
            return t('changePassword:old-password-incorrect-error');
        }
        return undefined;
    };

    const determineVerificationCodeError = (): TranslatedString | undefined => {
        if (verificationCodeRequiredError) {
            return t('validation:required-field-alert', {
                label: t('changePassword:verification-code'),
            });
        }

        if (verificationCodeInvalidError) {
            return t('changePassword:verification-code-incorrect-error');
        }
        if (verificationCodeExpiredError) {
            return t('changePassword:verification-code-expired-error');
        }
        return undefined;
    };

    const determineNewPasswordError = (): TranslatedString | undefined => {
        if (newPasswordRequiredError) {
            return t('validation:required-field-alert', { label: t('changePassword:new-password') });
        }

        if (newPasswordRequirementsError) {
            return t('changePassword:new-password-requirements-error');
        }
        return undefined;
    };

    const determineNewPasswordAgainError = (): TranslatedString | undefined => {
        if (newPasswordAgainRequiredError) {
            return t('validation:required-field-alert', {
                label: t('changePassword:new-password-again'),
            });
        }

        if (newPasswordMatchError) {
            return t('changePassword:new-password-match-error');
        }
        return undefined;
    };

    return (
        <form onSubmit={handleSubmit} data-testid={'changePasswordForm'}>
            <Column spacing={'medium'} spacingInset={'none'}>
                {isAuthenticated && (
                    <LabeledInput
                        label={t('changePassword:old-password')}
                        value={oldPassword}
                        onChange={handleOldPasswordChange}
                        type={'password'}
                        error={determineOldPasswordError()}
                        dataTestId={'oldPasswordInput'}
                        spellCheck={false}
                    />
                )}
                {username && (
                    <>
                        <Column spacing={'small'}>
                            <Row widths={'fill'}>
                                <Text>{t('forms:username-fieldLabel')}</Text>
                            </Row>
                            <Row widths={'fill'} data-testid={'usernameText'}>
                                {username}
                            </Row>
                        </Column>
                        <LabeledInput
                            label={t('changePassword:verification-code')}
                            value={verificationCode}
                            onChange={handleVerificationCodeChange}
                            type={'text'}
                            error={determineVerificationCodeError()}
                            dataTestId={'verificationCodeInput'}
                        />
                    </>
                )}
                <LabeledInput
                    label={t('changePassword:new-password')}
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    type={'password'}
                    error={determineNewPasswordError()}
                    dataTestId={'newPasswordInput'}
                    helpBox={
                        <HelpPopover header={t('changePassword:passwordHelpHeader')}>
                            <Text tag={'ul'}>
                                <li>{t('changePassword:minimumLength-requirement', { minCharLimit: '8' })}</li>
                                <li>{t('changePassword:character-requirement')}</li>
                            </Text>
                        </HelpPopover>
                    }
                    spellCheck={false}
                />
                <LabeledInput
                    label={t('changePassword:new-password-again')}
                    value={newPasswordAgain}
                    onChange={handleNewPasswordAgainChange}
                    type={'password'}
                    error={determineNewPasswordAgainError()}
                    dataTestId={'newPasswordAgainInput'}
                    spellCheck={false}
                />
                <Row>
                    <Button type={'primary'} submit={true} data-testid={'submitButton'}>
                        {t('changePassword:submit-button')}
                    </Button>
                    {(isAuthenticated || username) && (
                        <Button type={'secondary'} onClick={customCancel ?? cancel} data-testid={'cancelButton'}>
                            {t('forms:cancel-buttonLabel')}
                        </Button>
                    )}
                </Row>
            </Column>
        </form>
    );
};

const mapStateToProps = ({ userReducer }: RootState) => {
    return {
        isAuthenticated: userReducer.isAuthenticated,
    };
};

const connector = connect(mapStateToProps);

export default connector(ChangePassword);
