import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';

interface ConditionalRedirectProps {
    condition: boolean;
    to: string;
}

const ConditionalRedirect: FC<ConditionalRedirectProps> = ({ condition, to, children }) => {
    return condition ? <Redirect push={true} to={to} /> : <>{children}</>;
};

export { ConditionalRedirect };
