import React from 'react';

type TableDataAccessor<T> = (row: T) => string | any[] | React.ReactNode;

// TODO: enforce headers to be translated strings? difficult with test data
export interface ColumnLayout<T, K extends keyof T> {
    title: string | React.ReactNode;
    uniqueId: string;
    format: K | TableDataAccessor<T>;
    maxWidth?: number;
    help?: React.ReactNode;
}

export class TableData<T, K extends keyof T> {
    rows: T[];

    layout: ColumnLayout<T, K>[];

    constructor(rows: T[], layout?: ColumnLayout<T, K>[]) {
        this.rows = rows;
        if (layout === undefined) {
            this.layout = TableData.headersFor<T, K>(rows[0]);
        } else {
            this.layout = layout;
        }
    }

    getHeaders(): ColumnLayout<T, K>[] {
        return this.layout;
    }

    mapRows(iterator: (fields: string[], rowData: T) => any) {
        return this.rows.map((rowData, i) => iterator(this.getRow(i), rowData));
    }

    getRows(): string[][] {
        return this.rows.map((_, i) => this.getRow(i));
    }

    getRow(i: number): string[] {
        return this.layout
            .map((column) => column.format)
            .map((attr) => {
                if (typeof attr === 'string') {
                    return (this.rows[i] as any)[attr];
                } else {
                    return (attr as TableDataAccessor<T>)(this.rows[i]);
                }
            });
    }

    static headersFor<T, K extends keyof T>(obj: T): ColumnLayout<T, K>[] {
        const objectKeys = Object.keys(obj);
        const layout: ColumnLayout<T, K>[] = objectKeys?.map((attr) => {
            const columnLayout: ColumnLayout<T, K> = {
                title: attr,
                uniqueId: attr,
                format: attr as K,
            };
            return columnLayout;
        });

        return layout;
    }
}

export interface TableDataUser {
    username: string;
    name: string;
    email: string;
    phone: string;
    resellers: string[];
    enabled: boolean;
    role: string;
}

export interface TableDataReseller {
    reseller: string;
    store: string;
    email: string;
    phone: string;
}
