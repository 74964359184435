import { Reducer } from 'redux';
import { INITIAL_RETURN_ORDER_DRAFT_STATE } from '../../constants';
import { Logger } from '../../utils/logger';
import {
    RETURN_ORDER_DRAFT_BILLING_ADDRESS_CHANGED,
    RETURN_ORDER_DRAFT_CLEAR,
    RETURN_ORDER_DRAFT_GET,
    RETURN_ORDER_DRAFT_LOADED,
    RETURN_ORDER_DRAFT_PERSISTENCE_SET,
    RETURN_ORDER_DRAFT_SET,
    RETURN_ORDER_DRAFT_SHIPPING_ADDRESS_CHANGED,
    ReturnOrderDraftActions,
} from '../actions/returnOrderDraftActions';
import { ReturnOrderDraftState } from '../types';

export const returnOrderDraftReducer: Reducer<ReturnOrderDraftState, ReturnOrderDraftActions> = (
    state = INITIAL_RETURN_ORDER_DRAFT_STATE,
    action
): ReturnOrderDraftState => {
    Logger.debug(`returnOrderDraftReducer called with action: ${action} ${action.type}`);
    switch (action.type) {
        case RETURN_ORDER_DRAFT_GET:
            return {
                ...state,
                returnOrderDraft: {
                    value: action.payload.value,
                    error: action.payload.error,
                    status: action.payload.status,
                },
            };
        case RETURN_ORDER_DRAFT_LOADED:
            return {
                ...state,
                returnOrderDraft: {
                    value: action.payload.value,
                    error: action.payload.error,
                    status: action.payload.status,
                },
            };
        case RETURN_ORDER_DRAFT_SET:
            Logger.debug(`RETURN_ORDER_DRAFT_SET payload:`);
            Logger.debug(action.payload);
            return {
                ...state,
                returnOrderDraft: {
                    value: action.payload,
                    error: undefined,
                    status: 'Loaded',
                },
            };
        case RETURN_ORDER_DRAFT_PERSISTENCE_SET:
            return {
                ...state,
                returnOrderDraft: {
                    error: state.returnOrderDraft.error,
                    value: state.returnOrderDraft.value
                        ? {
                              ...state.returnOrderDraft.value,
                              returnOrderDraftId: action.payload.returnOrderDraftId,
                              version: action.payload.version,
                          }
                        : undefined,
                    status: state.returnOrderDraft.status,
                },
            };
        case RETURN_ORDER_DRAFT_CLEAR:
            return {
                ...state,
                returnOrderDraft: {
                    error: undefined,
                    status: 'Uninitialized',
                    value: action.payload.value,
                },
            };
        case RETURN_ORDER_DRAFT_SHIPPING_ADDRESS_CHANGED:
            return {
                ...state,
                returnOrderDraft: {
                    ...state.returnOrderDraft,
                    value: state.returnOrderDraft.value
                        ? {
                              ...state.returnOrderDraft.value,
                              shippingLocationCode: action.payload,
                          }
                        : undefined,
                },
            };
        case RETURN_ORDER_DRAFT_BILLING_ADDRESS_CHANGED:
            return {
                ...state,
                returnOrderDraft: {
                    ...state.returnOrderDraft,
                    value: state.returnOrderDraft.value
                        ? {
                              ...state.returnOrderDraft.value,
                              billingLocationCode: action.payload,
                          }
                        : undefined,
                },
            };
        default:
            return state;
    }
};
