import { AddLocationInput, AddLocationOutput } from '../types/AddLocation';
import { EditLocationInput } from '../types/EditLocation';
import { Logger } from '../utils/logger';
import { HrpsApiClient } from '.';

export const addLocation = async (addLocationInput: AddLocationInput): Promise<AddLocationOutput> => {
    return await new HrpsApiClient()
        .callHrps('addLocation', addLocationInput)
        .then((response: { data: any }) => {
            Logger.debug('Successfully added location:', addLocationInput.locationCode);
            return response.data;
        })
        .catch((error: any) => {
            Logger.debug('Failed to add location:', addLocationInput.locationCode, ' with error: ', error);
            throw error;
        });
};

export const editLocation = async (editLocationInput: EditLocationInput) => {
    return await new HrpsApiClient()
        .callHrps('editLocation', editLocationInput)
        .then((response) => {
            Logger.debug('Successfully edited location:', editLocationInput.locationCode);
            return response.data;
        })
        .catch((error) => {
            Logger.debug('Failed to edit location:', editLocationInput.locationCode, ' with error: ', error);
            throw error;
        });
};
