import {
    AddCorrespondenceInput,
    AddCorrespondenceOutput,
    CreateIssueInput,
    CreateIssueOutput,
    GetIssuesInput,
    GetIssuesOutput,
    Issue,
    IssueMessage,
} from '../types';
import { Logger } from '../utils/logger';
import { HrpsApiClient } from '.';

export const createIssue = async (createIssueInput: CreateIssueInput): Promise<CreateIssueOutput> => {
    return await new HrpsApiClient()
        .callHrps('createIssue', createIssueInput)
        .then((response: { data: any }) => {
            Logger.debug(`Successfully created Issue: ${response.data.issueId}`);
            return response.data;
        })
        .catch((error: any) => {
            Logger.debug(`Failed to create issue: ${createIssueInput} with error: ${error}`);
            throw error;
        });
};

export const getIssues = async (getIssuesInput: GetIssuesInput): Promise<GetIssuesOutput> => {
    return await new HrpsApiClient()
        .callHrps('getIssues', getIssuesInput)
        .then((response) => {
            Logger.debug(`Successfully retrieved issues for reseller: ${getIssuesInput.resellerId}`);
            return {
                issues: response.data.issues.map((row: any) => {
                    return {
                        id: row.id,
                        title: row.title,
                        description: row.description,
                        status: row.status,
                        submitter: row.submitter,
                        createDate: new Date(row.createDate * 1000),
                        lastUpdatedDate: new Date(row.lastUpdatedDate * 1000),
                        conversation: row.conversation.map((convRow: any) => {
                            return {
                                id: convRow.id,
                                message: convRow.message,
                                author: convRow.author,
                                createDate: new Date(convRow.createDate * 1000),
                                lastUpdatedDate: new Date(convRow.lastUpdatedDate * 1000),
                            } as IssueMessage;
                        }),
                    } as Issue;
                }),
            };
        })
        .catch((error) => {
            Logger.debug(`Failed to get issues for reseller: ${getIssuesInput.resellerId} with error: ${error}`);
            throw error;
        });
};

export const addCorrespondence = async (
    addCorrespondenceInput: AddCorrespondenceInput
): Promise<AddCorrespondenceOutput> => {
    return await new HrpsApiClient()
        .callHrps('addCorrespondence', addCorrespondenceInput)
        .then((response) => {
            Logger.debug(
                `Successfully added correspondence: ${response.data.correspondenceId} to issue: ${addCorrespondenceInput.issueId}`
            );
            return response.data;
        })
        .catch((error) => {
            Logger.debug(
                `Failed to add correspondence to issue: ${addCorrespondenceInput.issueId} with error: ${error}`
            );
            throw error;
        });
};
