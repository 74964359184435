import React, { FC, useCallback, useEffect, useRef } from 'react';
import { Logger } from '../../utils/logger';
import { ExpiringSessionCookieStorage } from '../../utils/storage';
import { usePageMessaging } from '../composites/PageMessaging';
import { useTranslation } from '.';

const SESSION_INACTIVITY_TIMEOUT_IN_MINUTES = 20;
const DEBOUNCE_DELAY_IN_SECONDS = 5;
const ACTIVITY_EVENTS = [
    'mousemove',
    'keydown',
    'wheel',
    'DOMMouseScroll',
    'mousewheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'MSPointerDown',
    'MSPointerMove',
    'visibilitychange',
];

interface InactivityTimerProps {
    onSessionExpiration: () => void;
    timeoutInMs?: number;
    debounceDelayInMs?: number;
}

const InactivityTimer: FC<InactivityTimerProps> = ({
    onSessionExpiration,
    timeoutInMs = SESSION_INACTIVITY_TIMEOUT_IN_MINUTES * 60000,
    debounceDelayInMs = DEBOUNCE_DELAY_IN_SECONDS * 1000,
    children,
}) => {
    const { t } = useTranslation(['signIn']);
    const pageMessaging = usePageMessaging();

    const rootDivRef = useRef<HTMLDivElement>(null);
    const timeoutTimerRef = useRef<number | undefined>(undefined);
    const debounceRef = useRef<number>(Date.now() + debounceDelayInMs);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function handleTimeout() {
        const timeToSessionExpiration = ExpiringSessionCookieStorage.timeToSessionExpiration();
        if (timeToSessionExpiration <= 0) {
            onSessionExpiration();
            pageMessaging.showError(t('inactivity-error', { count: timeoutInMs / 60000 }));
            Logger.debug('Timed out.');
        } else {
            resetTimer(timeToSessionExpiration);
        }
    }

    function clearTimer() {
        if (timeoutTimerRef.current) {
            clearTimeout(timeoutTimerRef.current);
            timeoutTimerRef.current = undefined;
        }
    }

    const resetTimer = useCallback(
        (timeRemaining: number) => {
            clearTimer();
            timeoutTimerRef.current = window.setTimeout(handleTimeout, timeRemaining);
            Logger.debug('Timeout was reset.');
        },
        [handleTimeout]
    );

    const resetSessionExpiration = useCallback(() => {
        resetTimer(timeoutInMs);
        ExpiringSessionCookieStorage.refreshSessionExpiration(timeoutInMs);
    }, [resetTimer, timeoutInMs]);

    const onEvent = useCallback(() => {
        if (Date.now() >= debounceRef.current) {
            debounceRef.current = Date.now() + debounceDelayInMs;
            resetSessionExpiration();
        }
    }, [debounceDelayInMs, resetSessionExpiration]);

    useEffect(() => {
        Logger.debug('DIV ref changed. Initializing timer. Element:', rootDivRef.current);
        ACTIVITY_EVENTS.forEach((event) => {
            rootDivRef.current!.addEventListener(event, onEvent);
        });
        resetSessionExpiration();
        const rootDivRefValue = rootDivRef.current;
        return () => {
            clearTimer();
            ACTIVITY_EVENTS.forEach((event) => {
                rootDivRefValue!.removeEventListener(event, onEvent);
            });
        };
    }, [onEvent, resetSessionExpiration, rootDivRef]);

    return (
        <div ref={rootDivRef} data-testid={'rootElement'}>
            {children}
        </div>
    );
};

export { InactivityTimer };
