import { Dispatch, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { HrpsApiClient } from '../../clients';
import { IManagedUser, RootState } from '../types';
import {
    MANAGED_USER_GET,
    MANAGED_USER_LOADED,
    MANAGED_USERS_GET,
    MANAGED_USERS_LOADED,
    ManagedUserActions,
} from './managedUserActions';

export function getManagedUsersLoading(): ManagedUserActions {
    return {
        type: MANAGED_USERS_GET,
        payload: {
            status: 'Loading',
            error: undefined,
            managedUsers: [] as IManagedUser[],
        },
    };
}

export function clearManagedUsers(): ManagedUserActions {
    return {
        type: MANAGED_USERS_GET,
        payload: {
            status: 'Uninitialized',
            error: undefined,
            managedUsers: [] as IManagedUser[],
        },
    };
}

export function getManagedUsersLoaded(managedUsers: IManagedUser[]): ManagedUserActions {
    return {
        type: MANAGED_USERS_LOADED,
        payload: {
            status: 'Loaded',
            error: undefined,
            managedUsers: managedUsers,
        },
    };
}

export function getManagedUsersFailed(error: any): ManagedUserActions {
    return {
        type: MANAGED_USERS_LOADED,
        payload: {
            status: 'Failed',
            error,
            managedUsers: [],
        },
    };
}

export function getManagedUsers(username: string) {
    return (dispatch: Dispatch) => {
        const hrpsApiClient = new HrpsApiClient();
        dispatch(getManagedUsersLoading());
        return hrpsApiClient
            .getManagedUsers(username)
            .then(async (managedUsers) => {
                if (typeof managedUsers === 'string') {
                    dispatch(getManagedUsersFailed(managedUsers));
                } else {
                    dispatch(getManagedUsersLoaded(managedUsers));
                }
            })
            .catch((err) => {
                dispatch(getManagedUsersFailed(err));
            });
    };
}

export function getManagedUserLoading(): ManagedUserActions {
    return {
        type: MANAGED_USER_GET,
        payload: {
            status: 'Loading',
            error: undefined,
            managedUser: undefined,
        },
    };
}

export function clearManagedUser(): ManagedUserActions {
    return {
        type: MANAGED_USER_GET,
        payload: {
            status: 'Uninitialized',
            error: undefined,
            managedUser: undefined,
        },
    };
}

export function getManagedUserLoaded(managedUser: IManagedUser): ManagedUserActions {
    return {
        type: MANAGED_USER_LOADED,
        payload: {
            status: 'Loaded',
            error: undefined,
            managedUser: managedUser,
        },
    };
}

export function getManagedUserFailed(error: any): ManagedUserActions {
    return {
        type: MANAGED_USER_LOADED,
        payload: {
            status: 'Failed',
            error,
            managedUser: undefined,
        },
    };
}

export function getManagedUser(username: string) {
    return (dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState: () => any) => {
        dispatch(getManagedUserLoading());

        const { managedUsers } = getState().managedUserReducer;
        const user = managedUsers.value?.find((curUser: IManagedUser) => curUser.username === username);
        if (user) {
            dispatch(getManagedUserLoaded(user));
        } else {
            dispatch(getManagedUsers(getState().userReducer.user.username)).then(() =>
                dispatch(getManagedUser(username))
            );
        }
    };
}
