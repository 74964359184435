import {
    AddUserTermsAndConditionsAcceptanceInput,
    AddUserTermsAndConditionsAcceptanceOutput,
    GetUserTermsAndConditionsAcceptancesOutput,
} from '../types';
import { Logger } from '../utils/logger';
import { HrpsApiClient } from './HrpsApiClient';

export async function addUserTermsAndConditionsAcceptanceApi(
    input: AddUserTermsAndConditionsAcceptanceInput
): Promise<AddUserTermsAndConditionsAcceptanceOutput> {
    return await new HrpsApiClient()
        .callHrps('addUserTermsAndConditionsAcceptance', input)
        .then((response) => {
            Logger.debug(`Added terms and conditions with this response: ${response}`);
            return {} as AddUserTermsAndConditionsAcceptanceOutput;
        })
        .catch((error) => {
            Logger.debug(
                `Error added user terms and conditions for a region: ${input.termsAndConditionsId}, with error ${error.message}`
            );
            throw error;
        });
}

export async function getUserTermsAndConditionsAcceptancesApi(): Promise<GetUserTermsAndConditionsAcceptancesOutput> {
    return await new HrpsApiClient()
        .callHrps('getUserTermsAndConditionsAcceptances', {})
        .then((response) => {
            return {
                userTermsAndConditionsAcceptance: response.data.acceptances,
            } as GetUserTermsAndConditionsAcceptancesOutput;
        })
        .catch((error) => {
            Logger.debug(`Error getting user terms and conditions, with error ${error.message}`);
            throw error;
        });
}
