import styled from '@emotion/styled';
import React from 'react';

const Div = styled.div`
    white-space: nowrap;
`;

type ClickableProps = {
    children?: any;
    onClick: (params: any) => void;
};

const Clickable = ({ onClick, children }: ClickableProps) => {
    return <Div onClick={onClick}>{children}</Div>;
};

export { Clickable };
