import Column from '@amzn/meridian/column';
import Divider from '@amzn/meridian/divider';
import Modal from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import Select, { SelectOption } from '@amzn/meridian/select';
import Table, { TableCell, TableRow } from '@amzn/meridian/table';
import Textarea from '@amzn/meridian/textarea';
import React, { useCallback, useState } from 'react';
import { Logger } from '../../utils/logger';
import { Button, Text, TranslatedString, useTranslation } from '../blocks';

export interface RequestRegistrationDialogProps {
    label: TranslatedString;
    dataTestId?: string;
}

const RequestRegistrationDialog = ({ label, dataTestId }: RequestRegistrationDialogProps) => {
    const { t } = useTranslation('requestRegistrationDialog');
    const [open, setOpen] = useState(false);
    const onClickButton = useCallback(() => {
        Logger.debug('Opening registration dialog.');
        setOpen(true);
    }, []);
    const onClose = useCallback(() => setOpen(false), []);
    const userTypes = [
        { label: t('requestRegistration-requestTypeReturns'), value: 'Returns' },
        { label: t('requestRegistration-requestTypeClaims'), value: 'Seller Claims' },
        { label: t('requestRegistration-requestTypeAdmin'), value: 'Admin' },
    ];
    const [selectedUserType, setSelectedUserType] = useState([]);

    return (
        <Row widths={['fit', 'fill']}>
            <Button onClick={onClickButton} data-testid={`${dataTestId}.link`}>
                <Text color={'inverted'}>{label}</Text>
            </Button>
            <Modal open={open} onClose={onClose} title={label}>
                <Column spacing={'small'}>
                    <Text type={'h100'}>{t('requestRegistration-requestAccessSummary')}</Text>
                    <Table headerRows={0} showDividers={false} data-testid={'actionHelpTable'} spacing={'small'}>
                        <TableRow>
                            <TableCell width={'5%'}>
                                <Text type={'h100'}>{t.cognate('1')}</Text>
                            </TableCell>
                            <TableCell>
                                <Text>{t('requestRegistration-requestAccessStep1')}</Text>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell width={'5%'}>
                                <Text type={'h100'}>{t.cognate('2')}</Text>
                            </TableCell>
                            <TableCell>
                                <Text>{t('requestRegistration-requestAccessStep2')}</Text>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell width={'5%'}>
                                <Text type={'h100'}>{t.cognate('3')}</Text>
                            </TableCell>
                            <TableCell>
                                <Text>{t('requestRegistration-requestAccessStep3')}</Text>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell width={'5%'}>
                                <Text type={'h100'}>{t.cognate('4')}</Text>
                            </TableCell>
                            <TableCell>
                                <Text>{t('requestRegistration-requestAccessStep4')}</Text>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell width={'5%'}>
                                <Text type={'h100'}>{t.cognate('5')}</Text>
                            </TableCell>
                            <TableCell>
                                <Text>{t('requestRegistration-requestAccessStep5')}</Text>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell width={'5%'}>
                                <Text type={'h100'}>{t.cognate('6')}</Text>
                            </TableCell>
                            <TableCell>
                                <Text>{t('requestRegistration-requestAccessStep6')}</Text>
                            </TableCell>
                        </TableRow>
                    </Table>
                    <Divider size={'small'} spacingBefore={'small'} />
                    <Column width={'100%'} spacing={'small'} spacingInset={'none'}>
                        <Select
                            value={selectedUserType}
                            onChange={(e) => {
                                setSelectedUserType(e);
                            }}
                            width={'100%'}
                            placeholder={t('requestRegistration-requestTypePlaceholder')}
                            label={t('requestRegistration-requestTypeLabel')}
                        >
                            {userTypes.map((userType) => {
                                return (
                                    <SelectOption key={userType.value} value={userType.value} label={userType.label} />
                                );
                            })}
                        </Select>
                    </Column>
                    {selectedUserType && selectedUserType.length > 0 && (
                        <Column spacing={'small'}>
                            <Textarea
                                value={t('requestRegistration-sendToEmailAddress')}
                                label={t('requestRegistration-sendToEmailAddressLabel')}
                                rows={1}
                                onChange={() => {}}
                            />
                            <Textarea
                                value={`${t('requestRegistration-subject')}-${selectedUserType}`}
                                label={t('requestRegistration-subjectLabel')}
                                rows={1}
                                onChange={() => {}}
                            />
                            <Textarea
                                value={t('requestRegistration-body')}
                                label={t('requestRegistration-bodyLabel')}
                                onChange={() => {}}
                            />
                        </Column>
                    )}
                </Column>
            </Modal>
        </Row>
    );
};

export { RequestRegistrationDialog };
