import styled from '@emotion/styled';
import React from 'react';

const Svg = styled.svg`
    width: 16px;
    height: 16px;
    color: black;
`;

export const Image = () => (
    <Svg width={'16px'} height={'16px'}>
        <g id={'icons'}>
            <rect stroke={'currentColor'} fill={'none'} width={'16'} height={'16'} x={'0'} y={'0'} ry={'0.5'} />
            <path
                stroke={'currentColor'}
                strokeWidth={'1'}
                fill={'none'}
                d={
                    'M 0.5,15 C 1,13 3,10.5 4,10 5,9 5,9 5,10 6,11 6,6 7,11.5 7,11.5 7,11 8,9.5 9,8 10,6 11,5 12,4 12.25,4 13,4 c 0,0 0.5,0.5 1,2 .5,1 1.5,3 2,4 .5,1 .5,1.25 .5,2 0,.75 0,2 0,3 0,.5 0,.6 -3,.5 -2,0 -7,0 -9,0 -2.5,0 -2.5,0 -2.6,0'
                }
            />
            <circle stroke={'currentColor'} strokeWidth={'1'} fill={'none'} cx={'5'} cy={'5'} r={'2'} />
        </g>
    </Svg>
);
