import { Action } from 'redux';
import { ReturnOrderAuditHistoryItem } from '../../types/ReturnOderAuditHistory';
import { IAsynchronouslyLoadedValue } from '../types';

export const RETURN_ORDER_AUDIT_HISTORY_CLEAR = 'returnOrderAuditHistory/clear';
export const RETURN_ORDER_AUDIT_HISTORY_GET = 'returnOrderAuditHistory/get';
export const RETURN_ORDER_AUDIT_HISTORY_LOADED = 'returnOrderAuditHistory/loaded';

export interface ReturnOrderAuditHistoryGetAction extends Action {
    type: typeof RETURN_ORDER_AUDIT_HISTORY_GET;
    payload: IAsynchronouslyLoadedValue<ReturnOrderAuditHistoryItem[]>;
}

export interface ReturnOrderAuditHistoryLoadedAction extends Action {
    type: typeof RETURN_ORDER_AUDIT_HISTORY_LOADED;
    payload: IAsynchronouslyLoadedValue<ReturnOrderAuditHistoryItem[]>;
}

export interface ReturnOrderAuditHistoryClearAction extends Action {
    type: typeof RETURN_ORDER_AUDIT_HISTORY_CLEAR;
    payload: IAsynchronouslyLoadedValue<ReturnOrderAuditHistoryItem[]>;
}

export type ReturnOrderAuditHistoryActions =
    | ReturnOrderAuditHistoryGetAction
    | ReturnOrderAuditHistoryLoadedAction
    | ReturnOrderAuditHistoryClearAction;
