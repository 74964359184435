import React, { Suspense } from 'react';

const withSuspense = <T extends {}>(WrappedComponent: React.ComponentType<T>) => {
    const SuspenseWrapper = (props: T) => {
        return (
            <Suspense fallback={'loading...'}>
                <WrappedComponent {...props} />
            </Suspense>
        );
    };

    return SuspenseWrapper;
};

export { withSuspense };
