import {
    GetClaimUploadTemplatesOutput,
    RequestClaimDocumentUrlInput,
    RequestClaimDocumentUrlOutput,
    ProcessClaimUploadInput,
    ProcessClaimUploadOutput,
    RequestClaimUploadUrlInput,
    RequestClaimUploadUrlOutput,
} from '../types';
import { Logger } from '../utils/logger';
import { HrpsApiClient } from './HrpsApiClient';

export const getClaimDocumentUrl = async (
    requestClaimDocumentUrlInput: RequestClaimDocumentUrlInput
): Promise<RequestClaimDocumentUrlOutput> => {
    Logger.info('Requesting Document', requestClaimDocumentUrlInput);
    return await new HrpsApiClient()
        .callHrps('getClaimDocumentUrl', requestClaimDocumentUrlInput)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            Logger.debug('Error getting claim document: ', error.message);
            return { url: error.message };
        });
};

export const requestClaimUploadUrl = async (
    requestClaimUploadUrlInput: RequestClaimUploadUrlInput
): Promise<RequestClaimUploadUrlOutput> => {
    Logger.info('Requesting Upload Url', requestClaimUploadUrlInput);
    return await new HrpsApiClient()
        .callHrps('requestClaimUploadUrl', requestClaimUploadUrlInput)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            Logger.debug('Error getting claim upload URL: ', error.message);
            throw error;
        });
};

export const processClaimUpload = async (
    processClaimUploadInput: ProcessClaimUploadInput
): Promise<ProcessClaimUploadOutput> => {
    Logger.info('Processing File', processClaimUploadInput);
    return await new HrpsApiClient()
        .callHrps('processClaimUpload', processClaimUploadInput)
        .then((response) => {
            Logger.debug(response.data);
            return response.data;
        })
        .catch((error) => {
            Logger.debug('Error processing claim upload: ', error.message);
            throw error;
        });
};

export const getClaimUploadTemplates = async (): Promise<GetClaimUploadTemplatesOutput> => {
    Logger.info('Calling getClaimUploadTemplates');
    return await new HrpsApiClient()
        .callHrps('getClaimUploadTemplates', {})
        .then((response) => {
            Logger.debug(response.data);
            return {
                templates: response.data.ClaimUploadTemplates.map((rouTemplate: any) => {
                    return {
                        id: rouTemplate.id,
                        type: rouTemplate.type,
                        name: rouTemplate.title,
                        description: rouTemplate.description,
                        uploadedAt: new Date(rouTemplate.uploadedAt * 1000),
                        uploadedBy: rouTemplate.uploadedBy,
                        presignedURL: rouTemplate.url,
                        countryCode: rouTemplate.countryCode,
                    };
                }),
            } as GetClaimUploadTemplatesOutput;
        })
        .catch((error) => {
            Logger.debug('Error getting claim Upload Template: ', error.message);
            throw error;
        });
};
