import { Dispatch } from 'redux';
import { HrpsApiClient } from '../../clients';
import { ReturnOrderAuditHistoryInput, ReturnOrderAuditHistoryItem } from '../../types/ReturnOderAuditHistory';
import { Logger } from '../../utils/logger';
import {
    RETURN_ORDER_AUDIT_HISTORY_GET,
    RETURN_ORDER_AUDIT_HISTORY_LOADED,
    ReturnOrderAuditHistoryActions,
} from './returnOrderAuditHistoryActions';

export function getReturnOrderAuditHistoryLoading(): ReturnOrderAuditHistoryActions {
    return {
        type: RETURN_ORDER_AUDIT_HISTORY_GET,
        payload: {
            status: 'Loading',
            error: undefined,
            value: undefined,
        },
    };
}

export function getReturnOrderAuditHistoryLoaded(
    returnOrderAuditHistoryList: ReturnOrderAuditHistoryItem[]
): ReturnOrderAuditHistoryActions {
    return {
        type: RETURN_ORDER_AUDIT_HISTORY_LOADED,
        payload: {
            status: 'Loaded',
            error: undefined,
            value: returnOrderAuditHistoryList,
        },
    };
}

export function getReturnOrderAuditHistoryFailed(error: any): ReturnOrderAuditHistoryActions {
    return {
        type: RETURN_ORDER_AUDIT_HISTORY_LOADED,
        payload: {
            status: 'Failed',
            error,
            value: undefined,
        },
    };
}

export function getReturnOrderAuditHistory(returnOrderId: string, resellerId: string) {
    return async (dispatch: Dispatch) => {
        dispatch(getReturnOrderAuditHistoryLoading());
        const hrpsApiClient = new HrpsApiClient();

        const auditHistoryInput: ReturnOrderAuditHistoryInput = {
            returnOrderId,
            resellerId,
        };
        return hrpsApiClient
            .getReturnOrderAuditHistory(auditHistoryInput)
            .then((returnOrderAuditHistory) => {
                dispatch(getReturnOrderAuditHistoryLoaded(returnOrderAuditHistory.returnOrderAuditHistoryItems));
            })
            .catch((error) => {
                Logger.debug(`error getting Audit History Records: ${error}`);
                dispatch(getReturnOrderAuditHistoryFailed(error));
            });
    };
}
