import Row from '@amzn/meridian/row';
import React, { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { SelectReseller } from '../components/constructed';
import { RootState } from '../redux/types';

const AdminPage: FC<AdminPageProps> = () => {
    return (
        <>
            <Row>
                <SelectReseller />
            </Row>
        </>
    );
};

const mapStateToProps = ({ systemReducer }: RootState) => {
    return {
        resellerTableData: systemReducer.resellerTableData,
    };
};

const connector = connect(mapStateToProps);
export type AdminPageProps = ConnectedProps<typeof connector>;
export default connector(AdminPage);
