import { Reducer } from 'redux';
import { INITIAL_RESELLER_STATE } from '../../constants';
import {
    AUTHORIZED_RESELLERS_CHANGED,
    ITEM_IMAGE_URLS_CHANGED,
    RESELLER_DETAILS_CHANGED,
    RESELLER_DETAILS_LOCATION_ADDED,
    RESELLER_DETAILS_LOCATION_EDITED,
    RESELLER_ITEMS_CHANGED,
    RESELLER_PRICELIST_CHANGED,
    RESELLER_UNMAPPED_ITEMS_CLEAR,
    RESELLER_UNMAPPED_ITEMS_GET,
    RESELLER_UNMAPPED_ITEMS_LOADED,
    ResellerActions,
    SELECTED_RESELLER_CHANGED,
} from '../actions/resellerActions';
import { IAsynchronouslyLoadedValue, IAuthorizedReseller, IResellerState } from '../types';

export const resellerReducer: Reducer<IResellerState, ResellerActions> = (
    state = INITIAL_RESELLER_STATE,
    action
): IResellerState => {
    switch (action.type) {
        case AUTHORIZED_RESELLERS_CHANGED:
            return {
                ...state,
                authorizedResellers: action.payload as IAsynchronouslyLoadedValue<IAuthorizedReseller[]>,
            };
        case SELECTED_RESELLER_CHANGED:
            return {
                ...state,
                selectedReseller: action.payload as IAsynchronouslyLoadedValue<IAuthorizedReseller>,
            };
        case RESELLER_DETAILS_CHANGED:
            return {
                ...state,
                resellerDetails: action.payload,
            };
        case RESELLER_PRICELIST_CHANGED:
            return {
                ...state,
                resellerPriceList: action.payload,
            };
        case RESELLER_ITEMS_CHANGED:
            return {
                ...state,
                resellerItems: action.payload,
            };
        case RESELLER_UNMAPPED_ITEMS_CLEAR:
            return {
                ...state,
                unmappedItemStatus: 'Uninitialized',
            };
        case RESELLER_UNMAPPED_ITEMS_GET:
            return {
                ...state,
                unmappedItemStatus: 'Loading',
            };
        case RESELLER_UNMAPPED_ITEMS_LOADED:
            return {
                ...state,
                resellerItems: {
                    ...state.resellerItems,
                    value: [...state.resellerItems.value!, ...action.payload.value],
                },
                unmappedItemStatus: 'Loaded',
            };
        case ITEM_IMAGE_URLS_CHANGED:
            return {
                ...state,
                asinToImageUrls: action.payload,
            };
        case RESELLER_DETAILS_LOCATION_ADDED:
            return {
                ...state,
                resellerDetails: {
                    ...state.resellerDetails,
                    value: {
                        contacts: state.resellerDetails.value!.contacts,
                        resellerLocations: {
                            ...state.resellerDetails.value!.resellerLocations,
                            locations: [...state.resellerDetails.value!.resellerLocations.locations, action.payload],
                        },
                    },
                },
            };
        case RESELLER_DETAILS_LOCATION_EDITED:
            return {
                ...state,
                resellerDetails: {
                    ...state.resellerDetails,
                    value: {
                        contacts: state.resellerDetails.value!.contacts,
                        resellerLocations: {
                            ...state.resellerDetails.value!.resellerLocations,
                            locations: state.resellerDetails.value!.resellerLocations.locations.map((location) => {
                                if (location.id === action.payload.id) {
                                    return action.payload;
                                } else {
                                    return location;
                                }
                            }),
                        },
                    },
                },
            };
        default:
            return state;
    }
};
