import caretDownTokens from '@amzn/meridian-tokens/base/icon/caret-down';
import Masthead, { MastheadTitle } from '@amzn/meridian/masthead';
import Row from '@amzn/meridian/row';
import styled from '@emotion/styled';
import _ from 'lodash';
import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { APPLICATION_NAME, NEWS_ARTICLES } from '../../constants';
import { RootState } from '../../redux/types';
import { IMenuItem, locales, SiteMapPage } from '../../types';
import { useSiteMapRouter } from '../../utils/SiteMapRouter';
import { Clickable, TranslatedString, Text, useTranslation, VerticalDivider } from '../blocks';
import { BadgeBox, RPMenu, UserBox } from '../composites';
import { Language } from '../icons';
import { Delight } from '.';

const Logo = styled.img`
    width: 30px;
    margin-right: 10em;
`;

const ApplicationName = styled.div`
    margin-right: 10em;
`;

const ApplicationSubName = styled(Text)`
    margin-right: 10em;
    font-weight: 200;
`;

const ApplicationRow = styled(Row)`
    display: inline-flex;
`;

type NavbarProps = ConnectedProps<typeof connector>;

const Navbar = ({
    user,
    applicationImgUrl,
    applicationSubName,
    notificationCount,
    selectedReseller,
    authorizedResellers,
}: NavbarProps) => {
    const { t } = useTranslation(['pageTitles', 'languages', 'newsPage']);
    const adminRoles = ['amazonAdmin', 'resellerAdmin'];
    const claimsRoles = ['amazonAdmin', 'claimViewer', 'claimCreator', 'amazonCreator', 'resellerAdmin'];

    const newNews = () => {
        return NEWS_ARTICLES.map((article) => {
            const currDateTime = new Date().getTime();
            return article.startDate < currDateTime && currDateTime < article.endDate;
        }).filter((value) => {
            return value === true;
        }).length;
    };

    const menu: IMenuItem[] = [
        {
            linkId: 'admin',
            path: '/admin',
            linkTitle: t('admin'),
            icon: caretDownTokens,
            dropdown: [
                {
                    linkId: 'createUser',
                    path: '/admin/create/users',
                    linkTitle: t('createUser'),
                },
                {
                    linkId: 'userList',
                    path: '/admin/list/users',
                    linkTitle: t('userList'),
                },
            ],
        },
        {
            linkId: 'returns',
            path: '/returns',
            linkTitle: t('returns'),
            icon: caretDownTokens,
            dropdown: [
                {
                    linkId: 'createReturn',
                    path: '/returns/create/return-orders',
                    linkTitle: t('createReturn'),
                },
                {
                    linkId: 'uploadReturn',
                    path: '/returns/upload/return-orders',
                    linkTitle: t('uploadReturn'),
                },
                {
                    linkId: 'viewReturns',
                    path: '/returns/list/return-orders',
                    linkTitle: t('viewReturns'),
                },
            ],
        },
        {
            linkId: 'claims',
            path: '/claims',
            linkTitle: t('claims'),
            icon: caretDownTokens,
            dropdown: [
                {
                    linkId: 'uploadClaim',
                    path: '/claims/upload/claims',
                    linkTitle: t('uploadClaim'),
                },
                {
                    linkId: 'viewClaims',
                    path: '/claims/view/claims',
                    linkTitle: t('viewClaims'),
                },
            ],
        },
        {
            linkId: 'notifications',
            path: '/notifications/list/notifications',
            linkTitle: t('notifications'),
        },
        {
            linkId: 'news',
            path: '/news/list/news',
            linkTitle: t('news'),
        },
        {
            linkId: 'user',
            path: '/user',
            linkTitle: t('user'),
            dropdown: [
                {
                    linkId: 'signOut',
                    path: '/account/sign-out',
                    linkTitle: t('signOut'),
                },
                {
                    linkId: 'changePassword',
                    path: '/account/change-password',
                    linkTitle: t('changePassword'),
                },
            ],
        },
        {
            linkId: 'changeLanguage',
            path: '/account/change-language',
            icon: Language,
            dropdown: locales.map((key) => {
                return {
                    linkId: key,
                    path: `/account/change-language/${key}`,
                    linkTitle: t('languages:locale', { context: key }),
                };
            }),
        },
    ];

    const resellerDropdownItem: IMenuItem = {
        linkId: 'reseller',
        path: '/reseller',
        linkTitle: (selectedReseller?.value?.name ?? applicationSubName) as TranslatedString,
        icon: caretDownTokens,
        dropdown: [
            ...(_.intersection(user?.roles, adminRoles).length > 0
                ? [
                      {
                          linkId: 'createContact',
                          path: '/reseller/create/contacts',
                          linkTitle: t('createContact'),
                      },
                  ]
                : []),
            {
                linkId: 'contactList',
                path: '/reseller/list/contacts',
                linkTitle: t('viewContacts'),
            },
            ...((authorizedResellers?.value?.length ?? 0) > 1
                ? [
                      {
                          linkId: 'selectReseller',
                          path: '/account/change-reseller',
                          linkTitle: t('selectReseller'),
                      },
                  ]
                : []),
        ],
    };

    const determineCurrentTab = () => {
        if (window.location.pathname.startsWith(resellerDropdownItem.path)) {
            return resellerDropdownItem;
        }
        return menu.filter((i) => window.location.pathname.startsWith(i.path))[0] || menu[0];
    };

    const [currentTab, setCurrentTab] = useState(determineCurrentTab());
    const { goto } = useSiteMapRouter();

    const updateCurrentTab = () => {
        setCurrentTab(determineCurrentTab());
    };

    const onMastheadClick = () => {
        if (authorizedResellers.value?.length === 1) {
            goto(SiteMapPage.home);
        } else {
            goto(SiteMapPage.changeReseller);
        }

        updateCurrentTab();
    };

    return (
        <Masthead size={'medium'} aria-label={t('navigation')}>
            <MastheadTitle href={'#'} onClick={onMastheadClick}>
                <Row data-testid={'navBar.masthead'}>
                    <Logo src={applicationImgUrl} alt={'Navigate Home'} />
                    <ApplicationName>{APPLICATION_NAME}</ApplicationName>
                    <VerticalDivider />
                    <Clickable
                        key={resellerDropdownItem.linkId}
                        onClick={(event) => updateCurrentTab && event.stopPropagation()}
                    >
                        <RPMenu
                            key={`${resellerDropdownItem.linkId}RPMenu`}
                            isSelected={currentTab.linkId === resellerDropdownItem.linkId}
                            includePadding={false}
                            item={resellerDropdownItem}
                        >
                            <ApplicationRow>
                                <ApplicationSubName data-testid={'navBar.resellerDropdown'}>
                                    {(selectedReseller?.value?.name ?? applicationSubName) as TranslatedString}
                                </ApplicationSubName>
                            </ApplicationRow>
                        </RPMenu>
                    </Clickable>
                </Row>
            </MastheadTitle>
            {menu.map((item) => {
                // FIXME: placeholder for hiding admin tab until SiteMap refactor -- 3/18/22
                if (item.linkId === 'admin' && user && _.intersection(user.roles, adminRoles).length === 0) {
                    return null;
                }
                // Hide claims roles until they are ready for external use
                if (item.linkId === 'claims' && user && _.intersection(user.roles, claimsRoles).length === 0) {
                    return null;
                }
                let specialItemContent = null;
                if (item.linkId === 'notifications' && notificationCount > 0) {
                    specialItemContent = <BadgeBox label={t('notifications')} count={notificationCount} />;
                } else if (item.linkId === 'user' && user) {
                    specialItemContent = <UserBox user={user} dataTestId={'navBar.userBox'} />;
                } else if (item.linkId === 'news') {
                    specialItemContent = <BadgeBox label={t('news')} count={newNews()} />;
                }
                return (
                    <Clickable key={item.linkId} onClick={updateCurrentTab}>
                        <RPMenu
                            key={`${item.linkId}RPMenu`}
                            isSelected={currentTab.linkId === item.linkId}
                            includePadding={true}
                            item={item}
                        >
                            {specialItemContent}
                        </RPMenu>
                    </Clickable>
                );
            })}
            {user && selectedReseller.value && (
                <Delight
                    username={user.username}
                    resellerId={selectedReseller.value.resellerId}
                    dataTestId={'delight'}
                />
            )}
        </Masthead>
    );
};

const mapStateToProps = ({ systemReducer, userReducer, resellerReducer }: RootState) => {
    return {
        user: userReducer.user,
        applicationSubName: systemReducer.applicationSubName,
        applicationImgUrl: systemReducer.applicationImgUrl,
        notificationCount: 0,
        selectedReseller: resellerReducer.selectedReseller,
        authorizedResellers: resellerReducer.authorizedResellers,
        stage: systemReducer.stage.value,
    };
};

const connector = connect(mapStateToProps);

export default connector(Navbar);
