import { Action } from 'redux';
import {
    IAsynchronouslyLoadedValue,
    ReturnOrderListReturnOrder,
    ReturnOrdersListOutput,
    TableSortDirection,
} from '../types';

export const RETURN_ORDER_LIST_CLEAR = 'returnOrderList/clear';
export const RETURN_ORDER_LIST_ERROR = 'returnOrderList/error';
export const RETURN_ORDER_LIST_GET = 'returnOrderList/get';
export const RETURN_ORDER_LIST_LOADED = 'returnOrderList/loaded';
export const RETURN_ORDER_LIST_SORT_CHANGE = 'returnOrderList/sortChange';
export const RETURN_ORDER_LIST_QUERY_CHANGE = 'returnOrderList/queryChange';
export const RETURN_ORDER_LIST_DATE_RANGE_CHANGE = 'returnOrderList/dateRangeChange';
export const RETURN_ORDER_LIST_PAGE_CHANGE = 'returnOrderList/pageChange';
export const RETURN_ORDER_LIST_LOAD_MORE_DATA = 'returnOrderList/loadMoreData';

export interface ReturnOrderListGetAction extends Action {
    type: typeof RETURN_ORDER_LIST_GET;
    payload: IAsynchronouslyLoadedValue<ReturnOrderListReturnOrder>;
}

export interface ReturnOrderListLoadedAction extends Action {
    type: typeof RETURN_ORDER_LIST_LOADED;
    payload: ReturnOrdersListOutput;
}

export interface ReturnOrderListErrorAction extends Action {
    type: typeof RETURN_ORDER_LIST_ERROR;
    payload: IAsynchronouslyLoadedValue<ReturnOrderListReturnOrder>;
}

export interface ReturnOrderListClearAction extends Action {
    type: typeof RETURN_ORDER_LIST_CLEAR;
    payload: IAsynchronouslyLoadedValue<ReturnOrderListReturnOrder>;
}

export interface ReturnOrderListSortAction extends Action {
    type: typeof RETURN_ORDER_LIST_SORT_CHANGE;
    payload: {
        sortColumn: string;
        sortDirection: TableSortDirection;
    };
}

export interface ReturnOrderListQueryAction extends Action {
    type: typeof RETURN_ORDER_LIST_QUERY_CHANGE;
    payload: {
        query: string;
    };
}

export interface ReturnOrderListDateRangeAction extends Action {
    type: typeof RETURN_ORDER_LIST_DATE_RANGE_CHANGE;
    payload: {
        dateRange: string[];
    };
}

export interface ReturnOrderListPageAction extends Action {
    type: typeof RETURN_ORDER_LIST_PAGE_CHANGE;
    payload: {
        currentPage: number;
        currentRecordNumber: number;
    };
}

export interface ReturnOrderListLoadMoreDataAction extends Action {
    type: typeof RETURN_ORDER_LIST_LOAD_MORE_DATA;
    payload: {
        currentPage: number;
        currentRecordNumber: number;
    };
}

export type ReturnOrderListActions =
    | ReturnOrderListGetAction
    | ReturnOrderListLoadedAction
    | ReturnOrderListErrorAction
    | ReturnOrderListClearAction
    | ReturnOrderListSortAction
    | ReturnOrderListQueryAction
    | ReturnOrderListDateRangeAction
    | ReturnOrderListPageAction
    | ReturnOrderListLoadMoreDataAction;
