import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import { SelectOption } from '@amzn/meridian/select';
import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router-dom';
import { HrpsApiClient } from '../clients';
import { Alert, Select, TranslatedString, useTranslation } from '../components/blocks';
import { LabeledInput, Tile, usePageMessaging } from '../components/composites';
import { clearResellerDetails, getResellerDetails } from '../redux/actions/resellerActionCreators';
import { Contact, RootState } from '../redux/types';
import { SiteMapPage } from '../types';
import { Logger } from '../utils/logger';
import { useSiteMapRouter } from '../utils/SiteMapRouter';

type ContactFormProps = {} & ConnectedProps<typeof connector>;

type ContactFields = keyof Contact;

export type ContactFormErrors = {
    lastName?: TranslatedString;
    email?: TranslatedString;
    contactType?: TranslatedString;
};

const CONTACT_FORM_ERRORS_INITIAL_STATE = {
    lastName: undefined,
    email: undefined,
    contactType: undefined,
} as ContactFormErrors;

const ContactForm: FC<ContactFormProps> = ({
    reseller,
    resellerDetails,
    clearResellerDetails,
    getResellerDetails,
}: ContactFormProps) => {
    const { contactId } = useParams<{ contactId?: string }>();

    const myContact: Contact = {
        contactId: 0,
        status: 'Active',
        resellerId: reseller.value!.resellerId,
        firstName: '',
        lastName: '',
        role: '',
        email: '',
        phone: '',
        cellPhone: '',
        fax: '',
        notes: '',
        contactType: '',
    };

    const [contact, setContact] = useState(myContact);

    const [formErrors, setFormErrors] = useState(CONTACT_FORM_ERRORS_INITIAL_STATE);

    const [isPageBusy, setPageIsBusy] = useState(false);

    const { goto } = useSiteMapRouter();

    const { t } = useTranslation(['contactForm', 'forms']);

    const { showSuccess, showError } = usePageMessaging();

    const title = !contactId ? t('createContact-pageTitle') : t('editContact-pageTitle');

    const contactTypes = ['Credit Memos', 'Returns'];

    const resellerId = reseller.value?.resellerId;

    const [contactList, setContactList] = useState(resellerDetails.value ? resellerDetails.value.contacts : []);

    useEffect(() => {
        if (resellerDetails.status === 'Uninitialized') {
            if (resellerId) {
                getResellerDetails(resellerId);
            }
            if (resellerDetails.value) {
                setContactList(resellerDetails.value.contacts);
            } else {
                setContactList([]);
            }
        }
    }, [getResellerDetails, resellerDetails, resellerId, t, contactList]);

    const getContact = useCallback(
        (contactId) => {
            if (contactId) {
                try {
                    const tempContact = contactList.find(
                        (curContact: Contact) => curContact.contactId.toString() === contactId
                    );
                    if (tempContact) {
                        setContact(tempContact);
                    }
                } catch (error) {
                    Logger.debug(`getContact error ${error}`);
                    showError(t('failedToGetContact-errorMessage'));
                } finally {
                    setPageIsBusy(false);
                }
            }
        },
        [contactList, showError, t]
    );

    useEffect(() => {
        (async () => {
            Logger.debug('getContact: ', contactId);
            getContact(contactId);
        })();
    }, [contactId, getContact]);

    function contactFormValidator(): { formIsValid: boolean; formErrors: ContactFormErrors } {
        const formErrors: ContactFormErrors = {
            lastName: contact.lastName ? undefined : t('lastNameRequired-errorMessage'),
            email: contact.email ? undefined : t('emailRequired-errorMessage'),
            contactType: contact.contactType ? undefined : t('contactTypeRequired-errorMessage'),
        };

        const formIsValid: boolean = Object.values(formErrors).every((error) => !error);

        return { formIsValid, formErrors };
    }

    const submitForm = async (event: FormEvent) => {
        event.preventDefault();
        const { formIsValid, formErrors } = contactFormValidator();
        if (formIsValid) {
            setPageIsBusy(true);

            const hrpsApiClient = new HrpsApiClient();
            try {
                Logger.debug(`contact value is ${contact}`);
                await hrpsApiClient.submitContact({
                    ...contact,
                });
                showSuccess(t('contactWasCreated-successMessage'));
                clearResellerDetails();
                goto(SiteMapPage.listContacts);
            } catch (error) {
                Logger.debug(`submitContact error ${error}`);
                showError(t('failedToCreateContact-errorMessage'));
            } finally {
                setPageIsBusy(false);
            }
        } else {
            setFormErrors(formErrors);
        }
    };

    function handleFieldChange(fieldName: ContactFields) {
        return (value: any): void => {
            setContact({ ...contact, [fieldName]: value });
        };
    }

    return (
        <Tile title={title} width={'45em'}>
            <form onSubmit={submitForm}>
                <Column spacing={'medium'} spacingInset={'none'} width={'90%'}>
                    <LabeledInput
                        label={t('contact-firstName')}
                        value={contact.firstName!}
                        onChange={handleFieldChange('firstName')}
                        type={'text'}
                        isOptional={true}
                        dataTestId={'contactForm.firstName'}
                    />
                    <LabeledInput
                        label={t('contact-lastName')}
                        value={contact.lastName!}
                        onChange={handleFieldChange('lastName')}
                        type={'text'}
                        error={formErrors.lastName}
                        dataTestId={'contactForm.lastName'}
                    />
                    <LabeledInput
                        label={t('contact-role')}
                        value={contact.role!}
                        onChange={handleFieldChange('role')}
                        type={'text'}
                        isOptional={true}
                        dataTestId={'contactForm.role'}
                    />
                    <LabeledInput
                        label={t('contact-workPhone')}
                        value={contact.phone!}
                        onChange={handleFieldChange('phone')}
                        type={'text'}
                        isOptional={true}
                        dataTestId={'contactForm.workPhone'}
                    />
                    <LabeledInput
                        label={t('contact-fax')}
                        value={contact.fax!}
                        onChange={handleFieldChange('fax')}
                        type={'text'}
                        isOptional={true}
                        dataTestId={'contactForm.fax'}
                    />
                    <LabeledInput
                        label={t('contact-email')}
                        value={contact.email!}
                        onChange={handleFieldChange('email')}
                        type={'text'}
                        error={formErrors.email}
                        dataTestId={'contactForm.email'}
                    />
                    <LabeledInput
                        label={t('contact-cellPhone')}
                        value={contact.cellPhone!}
                        onChange={handleFieldChange('cellPhone')}
                        type={'text'}
                        isOptional={true}
                        dataTestId={'contactForm.cellPhone'}
                    />

                    <Column>
                        <Row>
                            <Select
                                value={contact.contactType}
                                onChange={handleFieldChange('contactType')}
                                width={'100%'}
                                placeholder={t('selectAContactType-fieldPlaceholder')}
                                data-testid={'contactForm.contactTypeSelect'}
                                label={t('contactType-fieldLabel')}
                            >
                                {contactTypes.map((contactType) => {
                                    const label: TranslatedString = t.expression(
                                        `${contactType === 'Credit Memos' ? 'CreditMemos' : contactType}`
                                    );
                                    return <SelectOption key={contactType} value={contactType} label={label} />;
                                })}
                            </Select>
                        </Row>
                        <Row>
                            {formErrors.contactType && (
                                <div data-testid={'contactForm.contactTypeSelectError'}>
                                    <Alert type={'error'} size={'small'}>
                                        {formErrors.contactType}
                                    </Alert>
                                </div>
                            )}
                        </Row>
                    </Column>
                    <Row alignmentHorizontal={'right'}>
                        <Button
                            type={'secondary'}
                            disabled={isPageBusy}
                            onClick={() => goto(SiteMapPage.listContacts)}
                            data-testid={'contactForm.cancelButton'}
                        >
                            {t('forms:cancel-buttonLabel')}
                        </Button>
                        <Button
                            type={'primary'}
                            disabled={isPageBusy}
                            submit={true}
                            data-testid={'contactForm.submitContactButton'}
                        >
                            {t('submitContact-buttonLabel')}
                        </Button>{' '}
                    </Row>
                </Column>
            </form>
        </Tile>
    );
};

const mapStateToProps = ({
    userReducer: { user },
    resellerReducer: { selectedReseller, resellerDetails },
}: RootState) => {
    return {
        user: user,
        reseller: selectedReseller,
        resellerDetails: resellerDetails,
    };
};

const mapDispatchToProps = { clearResellerDetails, getResellerDetails };

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(ContactForm);
