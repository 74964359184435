import { Dispatch } from 'redux';
import { HrpsApiClient } from '../../clients';
import { ReturnOrder } from '../types';
import { getUnmappedItemsClear } from './resellerActionCreators';
import { RETURN_ORDER_GET, RETURN_ORDER_LOADED, ReturnOrderActions } from './returnOrderActions';

export function getReturnOrderLoading(): ReturnOrderActions {
    return {
        type: RETURN_ORDER_GET,
        payload: {
            status: 'Loading',
            error: undefined,
            value: undefined,
        },
    };
}

export function getReturnOrderLoaded(returnOrder: ReturnOrder): ReturnOrderActions {
    return {
        type: RETURN_ORDER_LOADED,
        payload: {
            status: 'Loaded',
            error: undefined,
            value: returnOrder,
        },
    };
}

export function getReturnOrderFailed(error: any): ReturnOrderActions {
    return {
        type: RETURN_ORDER_LOADED,
        payload: {
            status: 'Failed',
            error,
            value: undefined,
        },
    };
}

export function getReturnOrder(returnOrderId: string, resellerId: string) {
    return (dispatch: Dispatch) => {
        const hrpsApiClient = new HrpsApiClient();
        dispatch(getUnmappedItemsClear());
        dispatch(getReturnOrderLoading());
        return hrpsApiClient
            .getReturnOrder(returnOrderId, resellerId)
            .then((returnOrder) => {
                dispatch(getReturnOrderLoaded(returnOrder));
            })
            .catch((err) => {
                dispatch(getReturnOrderFailed(err));
            });
    };
}
