import infoIcon from '@amzn/meridian-tokens/base/icon/info';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import React, { FC } from 'react';
import { Button, Text, TranslatedString, useTranslation } from '../blocks';

const LabeledField: FC<LabeledFieldProps> = ({ label, text, split, info, onMoreClick }) => {
    const { t } = useTranslation(['returnOrder']);
    return (
        <Column width={'100%'} spacing={'small'} spacingInset={'none'}>
            <Text type={'b400'}>
                {label}
                {info && (
                    <Button type={'icon'} onClick={onMoreClick} data-testid={'truncatedListMoreButton'}>
                        <Icon tokens={infoIcon}>{t('showFullList-truncatedListInfoIconTooltip')}</Icon>
                    </Button>
                )}
            </Text>
            {split ? (
                text.split('\n').map((str) => (
                    <Text type={'b300'} key={str}>
                        {str as TranslatedString}
                    </Text>
                ))
            ) : (
                <Text type={'b300'} tag={'p'}>
                    {text}
                </Text>
            )}
        </Column>
    );
};

export type LabeledFieldProps = {
    label: TranslatedString;
    text: TranslatedString;
    split?: boolean;
    info?: boolean;
    onMoreClick?: () => void;
};

export default LabeledField;
