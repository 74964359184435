import MeridianLink from '@amzn/meridian/link';
import { LinkPropsWithRef } from '@amzn/meridian/link/link';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { localizeWithRef } from './localize';

/**
 * Html5Link implements HTML5 navigation behavior (changing window.location.ref without reloading the page).
 * Note: The HTML5 behavior will be disabled if onClick value is provided.
 */
const Html5Link = React.forwardRef<any, LinkPropsWithRef>((props, ref) => {
    const history = useHistory();
    return <MeridianLink {...props} ref={ref} onClick={props.onClick || history.push} />;
});

const LocalizedHtml5Link = localizeWithRef(Html5Link, ['children']);

export default LocalizedHtml5Link;
