import { Dispatch } from 'redux';
import { HrpsApiClient } from '../../clients';
import { DEFAULT_DATE_RANGE_DAYS } from '../../constants/tableData';
import { Logger } from '../../utils/logger';
import { ClaimFilter, ClaimTableSortDirection, ClaimsListOutput } from '../types';
import {
    CLAIM_LIST_CLEAR,
    CLAIM_LIST_DATE_RANGE_CHANGE,
    CLAIM_LIST_ERROR,
    CLAIM_LIST_GET,
    CLAIM_LIST_LOAD_MORE_DATA,
    CLAIM_LIST_LOADED,
    CLAIM_LIST_PAGE_CHANGE,
    CLAIM_LIST_QUERY_CHANGE,
    CLAIM_LIST_SORT_CHANGE,
    ClaimListActions,
} from './claimListActions';

export function getClaimListLoading(): ClaimListActions {
    return {
        type: CLAIM_LIST_GET,
        payload: {
            status: 'Loading',
            error: undefined,
            value: undefined,
        },
    };
}

export function getClaimListLoaded(claim: ClaimsListOutput): ClaimListActions {
    return {
        type: CLAIM_LIST_LOADED,
        payload: {
            totalResults: claim.totalResults,
            claimsList: claim.claimsList,
        },
    };
}

export function getClaimListFailed(error: any): ClaimListActions {
    return {
        type: CLAIM_LIST_ERROR,
        payload: {
            status: 'Failed',
            error,
            value: undefined,
        },
    };
}

export function claimChangePage(page: number, newStart: number): ClaimListActions {
    return {
        type: CLAIM_LIST_PAGE_CHANGE,
        payload: {
            currentPage: page,
            currentRecordNumber: newStart,
        },
    };
}

export function claimUpdateQuery(query: string): ClaimListActions {
    return {
        type: CLAIM_LIST_QUERY_CHANGE,
        payload: {
            query,
        },
    };
}

export function claimUpdateSortChange(sortColumn: string, sortDirection: ClaimTableSortDirection): ClaimListActions {
    return {
        type: CLAIM_LIST_SORT_CHANGE,
        payload: {
            sortColumn,
            sortDirection,
        },
    };
}

export function claimUpdateDateRange(dateRange: string[]): ClaimListActions {
    return {
        type: CLAIM_LIST_DATE_RANGE_CHANGE,
        payload: {
            dateRange,
        },
    };
}

export function claimLoadMoreData(currentPage: number, currentRecordNumber: number): ClaimListActions {
    return {
        type: CLAIM_LIST_LOAD_MORE_DATA,
        payload: {
            currentPage,
            currentRecordNumber,
        },
    };
}

export function clearClaimList(): ClaimListActions {
    return {
        type: CLAIM_LIST_CLEAR,
        payload: {
            status: 'Uninitialized',
            error: undefined,
            value: undefined,
        },
    };
}

// Temporarily returning mocked data since backend is not ready
export function getClaimList(
    resellerId: string,
    startAt: number,
    maxResults: number,
    sortColumn: string,
    sortDirection: 'ascending' | 'descending',
    dateRange?: string[],
    query?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(getClaimListLoading());
        const hrpsApiClient = new HrpsApiClient();
        buildSortColumn(sortColumn, sortDirection);
        buildSearchFilter(dateRange, query);
        return hrpsApiClient
            .getClaims(
                resellerId,
                startAt,
                maxResults,
                buildSortColumn(sortColumn, sortDirection),
                buildSearchFilter(dateRange, query)
            )
            .then((claimData: ClaimsListOutput) => {
                dispatch(getClaimListLoaded(claimData));
            })
            .catch((err) => {
                dispatch(getClaimListFailed(err));
            });
    };
}

function buildSortColumn(sortColumn: string, sortDirection: 'ascending' | 'descending') {
    Logger.debug(
        '{claimListActionCreators}',
        `In buildSortColumn SortColumn: ${sortColumn} sortDirection: ${sortDirection}`
    );
    const direction = sortDirection === 'ascending' ? 'ASCENDING' : 'DESCENDING';
    switch (sortColumn) {
        case 'resellerClaimNumber':
            return {
                RESELLER_CLAIM_NUMBER: direction,
            };
        case 'status':
            return {
                STATUS: direction,
            };
        case 'createdByUser':
            return {
                CREATED_BY_USER: direction,
            };
        case 'submissionDate':
        default:
            return {
                CREATION_DATE: direction,
            };
    }
}

function buildSearchFilter(dateRange?: string[], query?: string): ClaimFilter {
    const startDate: number =
        dateRange && dateRange[0]
            ? new Date(dateRange[0]).getTime()
            : new Date(new Date().setDate(new Date().getDate() - DEFAULT_DATE_RANGE_DAYS)).getTime();
    const endDate: number = dateRange && dateRange[1] ? new Date(dateRange[1]).getTime() : new Date().getTime();
    const filter: ClaimFilter = {
        searchValueMap:
            query && query !== ''
                ? {
                      resellerClaimNumber: [query],
                  }
                : undefined,
        dateRangeSearchMap: {
            submissionDate: {
                fromDate: startDate / 1000,
                toDate: endDate / 1000,
            },
        },
    };
    return filter;
}
