import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from '../blocks';

const Svg = styled.svg`
    width: 16px;
    height: 16px;
    color: red;
`;

export const StatusWarning = () => {
    const { t } = useTranslation('common');
    return (
        <Svg width={'16px'} height={'16px'} aria-label={t('icon-statusWaringLabel')}>
            <g id={'icon'}>
                <g>
                    <path
                        fill={'currentColor'}
                        d={
                            'M15.9,14.6l-7-14c-0.3-0.7-1.5-0.7-1.8,0l-7,14c-0.2,0.3-0.1,0.7,0,1C0.3,15.8,0.7,16,1,16h14 c0.3,0,0.7-0.2,0.9-0.5C16,15.2,16,14.9,15.9,14.6z M2.6,14L8,3.2L13.4,14H2.6z'
                        }
                    />
                    <polygon fill={'currentColor'} points={'7,11 7,13 9,13 9,11 '} />
                    <rect x={'7'} y={'6'} fill={'currentColor'} width={'2'} height={'4'} />
                </g>
            </g>
        </Svg>
    );
};
