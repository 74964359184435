import _ from 'lodash';

export type Schema<T> = {
    [P in keyof T]?: 'date' | undefined | Schema<T[P]>;
};

function map(input: any, schema: any): any {
    return _.mapValues(input, (propValue: any, propKey: string) => {
        if (schema[propKey] === undefined) {
            return propValue;
        } else if (schema[propKey] === 'date') {
            return new Date(propValue * 1000);
        } else {
            return map(propValue, schema[propKey]);
        }
    });
}

export function buildObjectMapper<T, R>(schema: Schema<T>): (input: T) => R {
    return (input) => map(input, schema);
}
