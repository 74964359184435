import Column from '@amzn/meridian/column';
import React from 'react';
import { ItemImage, TranslatedString } from '../../../blocks';
import { Text } from '../../../blocks/localization';
import { ItemImagePopover } from '../../ItemImagePopover';
import { MultilineFormEditorColumnFormat } from '../MultilineFormEditor';

export const imagePopupFormat = (
    imageUrlCallback: (sku: string) => { url: string; title: string } | undefined
): MultilineFormEditorColumnFormat => ({
    render: (item, column) => {
        const itemData = imageUrlCallback(item[column.sourceProperty] ? item[column.sourceProperty] : '');
        return (
            <ItemImagePopover
                thumbnailUrl={itemData?.url}
                header={itemData?.title as TranslatedString}
                dataTestId={`${item[column.sourceProperty]}-itemImagePopover`}
            >
                <Column alignmentHorizontal={'left'} alignmentVertical={'top'} spacing={'xxsmall'}>
                    <Text>{item[column.sourceProperty]}</Text>
                    <ItemImage src={itemData?.url} data-testid={`${item[column.sourceProperty]}-Image`} />
                </Column>
            </ItemImagePopover>
        );
    },
});
