import styled from '@emotion/styled';
import React from 'react';

const Divider = styled.div<DividerProps>`
    display: inline-block;
    border-left: ${(props: DividerProps) => `${props.thickness}px ${props.lineStyle} ${props.lineColor}`};
    height: 25px;
    margin: 0 10px;
`;

type DividerProps = {
    thickness?: number;
    lineColor?: string;
    lineStyle?: string;
};

const VerticalDivider = ({ thickness = 2, lineColor = 'white', lineStyle = 'solid' }: DividerProps) => {
    return <Divider thickness={thickness} lineColor={lineColor} lineStyle={lineStyle} />;
};

export { VerticalDivider };
