import { Action } from 'redux';
import { UserTermsAndConditionsAcceptance } from '../../types';

export const USER_TERMS_AND_CONDITIONS_LOADED = 'userTermsAndConditions/Loaded';
export const USER_TERMS_AND_CONDITIONS_LOADING = 'userTermsAndConditions/Loading';
export const USER_TERMS_AND_CONDITIONS_FAILED = 'userTermsAndConditions/Failed';
export const USER_TERMS_AND_CONDITIONS_VALIDATED = 'userTermsAndConditions/Validated';
export const USER_TERMS_AND_CONDITIONS_CLEAR = 'userTermsAndConditions/Clear';

export interface UserTermsAndConditionsLoadedAction extends Action {
    type: typeof USER_TERMS_AND_CONDITIONS_LOADED;
    payload: {
        value: UserTermsAndConditionsAcceptance[];
    };
}

export interface UserTermsAndConditionsLoadingAction extends Action {
    type: typeof USER_TERMS_AND_CONDITIONS_LOADING;
}

export interface UserTermsAndConditionsValidatedAction extends Action {
    type: typeof USER_TERMS_AND_CONDITIONS_VALIDATED;
    payload: boolean;
}

export interface UserTermsAndConditionsFailedAction extends Action {
    type: typeof USER_TERMS_AND_CONDITIONS_FAILED;
    payload: {
        error: string;
    };
}

export interface UserTermsAndConditionsClearAction extends Action {
    type: typeof USER_TERMS_AND_CONDITIONS_CLEAR;
}

export type TermsAndConditionsActions =
    | UserTermsAndConditionsLoadedAction
    | UserTermsAndConditionsLoadingAction
    | UserTermsAndConditionsFailedAction
    | UserTermsAndConditionsValidatedAction
    | UserTermsAndConditionsClearAction;
