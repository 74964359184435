/* eslint-disable react-hooks/exhaustive-deps */
// TODO: use localized components
import Column from '@amzn/meridian/column';
import Pagination from '@amzn/meridian/pagination';
import Table, { TableActionBar, TableCell, TableRow } from '@amzn/meridian/table';
import Text from '@amzn/meridian/text';
import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import { TableData } from '../../redux/types';
import { color } from '../../theme/colors';
import { Debug } from '../../utils/debug';
import { PopoverCell } from './PopoverCell';

export type RowKeyBuilderFunction<T> = (rowArray: string[], rowObject: T) => string;

export interface PaginatedTableProps<T> {
    children?: React.ReactNode;
    currentPage: number;
    data: TableData<T, keyof T>;
    dataTestId?: string;
    numberOfPages: number;
    onPageChange(value: string): void;
    onSort(sort: { sortColumn: string; sortDirection: string }): void;
    sortColumn?: string;
    sortDirection?: string;
    rowKey?: string | RowKeyBuilderFunction<T>;
}

const BorderedTable = styled(Table)`
    border: 1px solid ${color.gray[300]};
    box-shadow: 0px -10px 0px ${color.gray[100]};
    th[id^='help'] {
        padding-left: 0;
    }
`;

const PaginatedTable = <T extends any>({
    data,
    currentPage,
    numberOfPages,
    onPageChange,
    onSort,
    sortColumn,
    sortDirection,
    dataTestId,
    children,
    rowKey,
}: PaginatedTableProps<T>) => {
    const rowKeyBuilder: RowKeyBuilderFunction<T> = useMemo<RowKeyBuilderFunction<T>>(() => {
        if (typeof rowKey === 'string') {
            return (rowArray: string[], rowObject: T) => {
                const key = (rowObject[rowKey as keyof T] as unknown) as string;
                Debug.assert(key !== undefined, 'Invalid row key. Key:', key, 'rowKey:', rowKey);
                return key;
            };
        } else if (typeof rowKey === 'function') {
            return rowKey as RowKeyBuilderFunction<T>;
        } else {
            return (rowArray: string[]) => {
                return rowArray[0];
            };
        }
    }, [rowKey]);

    return (
        <BorderedTable
            headerRows={1}
            sortColumn={sortColumn}
            sortDirection={sortDirection === 'ascending' ? 'ascending' : 'descending'}
            onSort={onSort}
            showDividers={true}
            data-testid={dataTestId}
            spacing={'small'}
        >
            <TableActionBar data-testid={`${dataTestId}-TableActionBar`}>
                {children}
                <Pagination
                    currentPage={currentPage}
                    numberOfPages={numberOfPages}
                    onChange={onPageChange}
                    showSkipArrows={true}
                    data-testid={`${dataTestId}-Pagination`}
                />
            </TableActionBar>
            <TableRow data-testid={`${dataTestId}-HeaderTableRow`}>
                {data.getHeaders().map((header) => {
                    if (React.isValidElement(header)) {
                        return (
                            <TableCell
                                sortColumn={header.uniqueId}
                                highlight={true}
                                data-testid={header.uniqueId}
                                key={header.uniqueId}
                            >
                                {header}
                            </TableCell>
                        );
                    } else {
                        return [
                            <TableCell
                                sortColumn={
                                    !header.title || !header.title.toString().trim() ? undefined : header.uniqueId
                                }
                                highlight={true}
                                data-testid={header.uniqueId}
                                key={header.uniqueId}
                                width={header.help ? 1 : undefined}
                            >
                                {header.title}
                            </TableCell>,
                            header.help ? (
                                <TableCell
                                    id={`help-${header.uniqueId}`}
                                    key={`help-${header.uniqueId}`}
                                    highlight={true}
                                    css={{ width: 1000 }}
                                >
                                    {header.help}
                                </TableCell>
                            ) : null,
                        ].flat();
                    }
                })}
            </TableRow>
            {data.mapRows((row, rowData) => {
                const rowKey = rowKeyBuilder(row, rowData);
                return (
                    <TableRow highlightOnHover={true} key={rowKey} data-testid={`${dataTestId}-TableRow`}>
                        {row.map((field, columnIndex) => {
                            const cellKey = String(columnIndex);
                            if (React.isValidElement(field)) {
                                return (
                                    <TableCell
                                        data-testid={`${dataTestId}-TableCell`}
                                        key={cellKey}
                                        columnSpan={data.getHeaders()[columnIndex].help ? 2 : 1}
                                    >
                                        {field}
                                    </TableCell>
                                );
                            }
                            const header = data.getHeaders()[columnIndex];
                            const maxWidth = header ? header.maxWidth : undefined;
                            let fieldStringValue: string = typeof field === 'string' ? field : '';
                            if (Array.isArray(field)) {
                                const fieldArray: any[] = field;
                                fieldStringValue = fieldArray.join('; ');
                            }

                            if (maxWidth && fieldStringValue.length > maxWidth) {
                                return (
                                    <TableCell
                                        data-testid={`${dataTestId}-TableCell`}
                                        key={cellKey}
                                        columnSpan={data.getHeaders()[columnIndex].help ? 2 : 1}
                                    >
                                        {fieldStringValue.substr(0, maxWidth)}
                                        <PopoverCell buttonLable={'…'}>
                                            <Column
                                                alignmentHorizontal={'left'}
                                                alignmentVertical={'top'}
                                                spacing={'xxsmall'}
                                            >
                                                {Array.isArray(field) &&
                                                    field.map((item) => {
                                                        return (
                                                            <Text type={'b200'} key={item}>
                                                                {item}
                                                            </Text>
                                                        );
                                                    })}
                                                {typeof field === 'string' && (
                                                    <Text type={'b200'} key={cellKey}>
                                                        {fieldStringValue}
                                                    </Text>
                                                )}
                                            </Column>
                                        </PopoverCell>
                                    </TableCell>
                                );
                            }
                            return (
                                <TableCell
                                    data-testid={`${dataTestId}-TableCell`}
                                    key={cellKey}
                                    columnSpan={data.getHeaders()[columnIndex].help ? 2 : 1}
                                >
                                    {fieldStringValue}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                );
            })}
        </BorderedTable>
    );
};

export { PaginatedTable };
