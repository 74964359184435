import { useTranslation } from '../../blocks/localization';
import { AddressFormErrors } from '../../composites';

export interface AddressFormValidatedFields {
    locationCode: string;
    locationType: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
    addressLine1: string;
}

export const useAddressFormValidator = (fields: AddressFormValidatedFields) => {
    const { t } = useTranslation(['addressForm', 'validation']);

    const formErrors: AddressFormErrors = {
        locationCode:
            fields.locationCode && fields.locationCode.trim().length > 1
                ? undefined
                : t('locationCodeRequired-errorMessage'),
        locationType: fields.locationType ? undefined : t('locationTypeRequired-errorMessage'),
        city: fields.city ? undefined : t('cityRequired-errorMessage'),
        state: fields.state ? undefined : t('administrativeDivisionRequired-errorMessage', { context: fields.country }),
        country: fields.country ? undefined : t('countryRequired-errorMessage'),
        zipCode: fields.zipCode ? undefined : t('zipCodeRequired-errorMessage'),
        addressLine1: fields.addressLine1 ? undefined : t('addressLine1Required-errorMessage'),
    };

    const formIsValid: boolean = Object.values(formErrors).every((error) => !error);

    return { formIsValid, formErrors };
};
