import React from 'react';
import { UploadImageModal } from '../../index';
import { MultilineFormEditorColumnFormat } from '../MultilineFormEditor';

export const uploadImageFormat = (options: {
    attachments: any;
    setAttachments: (...args: any) => void;
    disabled?: boolean;
}): MultilineFormEditorColumnFormat => ({
    render: (item) => {
        return (
            <UploadImageModal
                attachments={options.attachments}
                setAttachments={options.setAttachments}
                lineItemId={item.lineItemId}
                disabled={options.disabled}
                status={options.attachments.status}
                mode={'upload'}
            />
        );
    },
});
