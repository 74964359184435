import { Dispatch } from 'redux';
import { FetchClient } from '../../clients';
import {
    APPLICATION_BACKGROUND_BETA_IMAGE_URL,
    APPLICATION_BACKGROUND_GAMMA_IMAGE_URL,
    APPLICATION_BACKGROUND_IMAGE_URL,
    WEBSITE_SETTINGS_PATH,
} from '../../constants';
import { SiteMapPage } from '../../types';
import { clearManagedUser, clearManagedUsers } from './managedUserActionCreators';
import { clearAuthorizedResellers, clearSelectedReseller } from './resellerActionCreators';
import {
    SYSTEM_REDIRECT_URL_LOAD,
    SYSTEM_STAGE_LOAD,
    SystemRedirectURLLoadAction,
    SystemStageLoadAction,
} from './systemActions';

interface StageSettings extends Record<string, string> {
    stage: string;
}

export function clearData() {
    return (dispatch: Dispatch) => {
        dispatch(clearAuthorizedResellers());
        dispatch(clearSelectedReseller());
        dispatch(clearManagedUsers());
        dispatch(clearManagedUser());
    };
}

export function loadingStageSetting(): SystemStageLoadAction {
    return {
        type: SYSTEM_STAGE_LOAD,
        payload: {
            stage: {
                status: 'Loading',
                error: undefined,
                value: undefined,
            },
            applicationBackgroundImgUrl: APPLICATION_BACKGROUND_IMAGE_URL,
        },
    };
}

export function clearStageSetting(): SystemStageLoadAction {
    return {
        type: SYSTEM_STAGE_LOAD,
        payload: {
            stage: {
                status: 'Uninitialized',
                error: undefined,
                value: undefined,
            },
            applicationBackgroundImgUrl: APPLICATION_BACKGROUND_IMAGE_URL,
        },
    };
}

export function loadedStageSetting(stage: string): SystemStageLoadAction {
    let imageUrl = APPLICATION_BACKGROUND_IMAGE_URL;
    if (stage === 'Beta') {
        imageUrl = APPLICATION_BACKGROUND_BETA_IMAGE_URL;
    } else if (stage === 'Gamma') {
        imageUrl = APPLICATION_BACKGROUND_GAMMA_IMAGE_URL;
    }
    return {
        type: SYSTEM_STAGE_LOAD,
        payload: {
            stage: {
                status: 'Loading',
                error: undefined,
                value: stage,
            },
            applicationBackgroundImgUrl: imageUrl,
        },
    };
}

export function errorLoadingStageSetting(error: string): SystemStageLoadAction {
    return {
        type: SYSTEM_STAGE_LOAD,
        payload: {
            stage: {
                status: 'Loading',
                error: error,
                value: undefined,
            },
            applicationBackgroundImgUrl: APPLICATION_BACKGROUND_IMAGE_URL,
        },
    };
}

export function setRedirectURL(url: string): SystemRedirectURLLoadAction {
    return {
        type: SYSTEM_REDIRECT_URL_LOAD,
        payload: {
            redirectURL: url,
        },
    };
}

export function clearRedirectURL(): SystemRedirectURLLoadAction {
    return {
        type: SYSTEM_REDIRECT_URL_LOAD,
        payload: {
            redirectURL: SiteMapPage.home,
        },
    };
}

export function getStage() {
    return (dispatch: Dispatch) => {
        dispatch(loadingStageSetting());
        return FetchClient.fetch<StageSettings>(WEBSITE_SETTINGS_PATH)
            .then((stageSetting: StageSettings) => {
                dispatch(loadedStageSetting(stageSetting.stage));
            })
            .catch((err) => {
                dispatch(errorLoadingStageSetting(err));
            });
    };
}
