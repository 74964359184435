export const color = {
    squidInk: '#232F3E',
    transparent: 'rgba(0, 0, 0, 0)',
    gray: {
        0: '#FFFFFF',
        50: '#F9FAFA',
        100: '#F0F1F2',
        200: '#E7E9E9',
        300: '#BBC0C1',
        400: '#949C9C',
        500: '#6C7778',
        600: '#485050',
        700: '#252829',
        800: '#0B0C0C',
        // NOTE: gray "muted" sounds a bit redundant, but we're sticking with the
        // term "muted" to remain consistent with the muted color variations in
        // other ramps (i.e. blue, orange, and teal). Like those muted colors, these
        // colors were added to support nuanced disabled states.
        muted: {
            200: '#ECEDED',
            300: '#ABB0B5',
            600: '#5B5F5F',
            700: '#323838',
        },
        translucent: {
            light: {
                50: 'rgba(255, 255, 255, 0.16)', // gray-0 @ 16% opacity
                100: 'rgba(255, 255, 255, 0.22)', // gray-0 @ 22% opacity
                200: 'rgba(255, 255, 255, 0.36)', // gray-0 @ 36% opacity
            },
            mid: {
                500: 'rgba(108, 119, 120, 0.5)', // gray-500 @ 50% opacity
            },
            dark: {
                50: 'rgba(11, 12, 12, 0.04)', // gray-800 @ 4% opacity
                100: 'rgba(11, 12, 12, 0.1)', // gray-800 @ 10% opacity
                200: 'rgba(11, 12, 12, 0.16)', // gray-800 @ 16% opacity
                300: 'rgba(11, 12, 12, 0.26)', // gray-800 @ 26% opacity
                500: 'rgba(11, 12, 12, 0.5)', // gray-800 @ 50% opacity
            },
        },
    },
    blue: {
        50: '#E5F6FB',
        100: '#B9E8F7',
        200: '#7CCEE9',
        300: '#47C2EC',
        400: '#00A8E1',
        500: '#077398',
        600: '#055D7B',
        700: '#03384B',
        800: '#01222D',
        muted: {
            100: '#C1DEE8',
            200: '#90C5D7',
            300: '#86A4AF',
            400: '#204B5A',
            500: '#24586A',
            600: '#232f3e',
        },
    },
    orange: {
        50: '#FFF3F0',
        100: '#FFDAD1',
        200: '#FFA28A',
        300: '#FF714D',
        400: '#FF6138',
        500: '#CB330B',
        600: '#932306',
        700: '#4F1203',
        800: '#310B02',
        muted: {
            100: '#F5CCC2',
            200: '#EFAD9C',
            300: '#BE968C',
            400: '#99351B',
            500: '#8A3823',
            600: '#55190A',
        },
    },
    teal: {
        50: '#E6F9FB',
        100: '#B5EBF3',
        200: '#6ED9EA',
        300: '#2DD4ED',
        400: '#0AABC4',
        500: '#067586',
        600: '#045C69',
        700: '#023138',
        800: '#01171B',
        muted: {
            100: '#C0DFE5',
            200: '#8AC6CF',
            300: '#8AA6AD',
            400: '#164D55',
            500: '#185862',
            600: '#13181C',
        },
    },
    green: {
        100: '#DCF3A0',
        300: '#C3EB5E',
        400: '#ADE422',
        500: '#98C91E',
    },
    red: {
        100: '#F9D5E2',
        300: '#FF7494',
        400: '#CC0C39',
        500: '#AD0A30',
    },
    yellow: {
        100: '#FEF2B8',
        300: '#FCDE4D',
        400: '#FAD000',
        500: '#DCB700',
    },
    pink: {
        100: '#FFBBD3',
        300: '#FF7DAB',
        400: '#FF5A94',
        500: '#E31F64',
    },
    purple: {
        100: '#E9C6E4',
        300: '#E989DA',
        400: '#EB64D7',
        500: '#8C297D',
    },
    turquoise: {
        100: '#BDE8E4',
        300: '#8AE2D9',
        400: '#36C2B4',
        500: '#12857A',
    },
    tangerine: {
        100: '#FFD094',
        300: '#FFB352',
        400: '#FF8F00',
        500: '#DA7F0B',
    },
};
