import Box from '@amzn/meridian/box';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import styled from '@emotion/styled';
import React from 'react';
import { color } from '../../theme/colors';
import { IMenuItem } from '../../types';

interface StyledBoxProps {
    includePadding: boolean;
}

export const StyledBox = styled(Box)<StyledBoxProps>`
    &:hover {
        cursor: pointer;
    }
    padding-left: ${(props) => (props.includePadding ? '20px' : '0')};
    padding-right: ${(props) => (props.includePadding ? '10px' : '0')};
`;

const ColoredText = styled(Text)<{ isSelected?: Boolean }>`
    color: ${(props) => (props.isSelected ? color.blue[400] : color.gray[0])};
    &:hover {
        color: ${color.blue[400]};
    }
`;

export interface MenuItemProps {
    includePadding: boolean;
    menuItem?: IMenuItem;
    isSelected?: Boolean;
    children?: React.ReactNode;
}

const RPMenuItem = React.forwardRef<undefined, MenuItemProps>(
    ({ includePadding, menuItem, isSelected, children }, ref) => {
        return (
            <StyledBox includePadding={includePadding}>
                <Row alignmentVertical={'center'}>
                    <ColoredText isSelected={isSelected} ref={ref} tag={'span'}>
                        {children || menuItem?.linkTitle}
                    </ColoredText>
                </Row>
            </StyledBox>
        );
    }
);

export { RPMenuItem };
