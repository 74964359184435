import MeridianColumn from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import { IAsynchronouslyLoadedValue } from '../../redux/types';
import { color } from '../../theme/colors';
import { ReturnOrderAuditHistoryItem, ReturnOrderAuditHistoryNote } from '../../types/ReturnOderAuditHistory';
import { Logger } from '../../utils/logger';
import { TranslatedString, useTranslation } from '../blocks';
import { ColumnFormat, SuperPaginatedTable, SuperPaginatedTableColumn } from '../constructed/SuperPaginatedTable';

const BorderedColumn = styled(MeridianColumn)`
    border: 1px solid ${color.gray[300]};
    box-shadow: 0px 0px 3px ${color.gray[300]};
`;

export type ReturnOrderAuditHistoryProps = {
    auditItems: IAsynchronouslyLoadedValue<ReturnOrderAuditHistoryItem[]>;
};

type FormattedAuditHistoryItem = {
    auditItemKey: string;
    type: string;
    status: string;
    creationDate: Date;
    note: ReturnOrderAuditHistoryNote[];
};

const ReturnOrderAuditHistory = ({ auditItems }: ReturnOrderAuditHistoryProps) => {
    const { t } = useTranslation('returnOrderAuditHistory');

    const noteFormat = (getText: (notes: ReturnOrderAuditHistoryNote[]) => TranslatedString[]) => ({
        render: (notes: ReturnOrderAuditHistoryNote[]) => {
            const notesString = getText(notes);
            return (
                <MeridianColumn>
                    {notesString.map((note) => {
                        return <Row key={note}>{note}</Row>;
                    })}
                </MeridianColumn>
            );
        },
        search: (notes: ReturnOrderAuditHistoryNote[]) => {
            return getText(notes).join(' ');
        },
        sort: (notes: ReturnOrderAuditHistoryNote[]) => {
            return getText(notes).join(' ');
        },
    });

    const itemFormat = (getText: (itemId: string) => TranslatedString) => ({
        render: getText,
    });

    const dateFormat = (getDate: (date: Date) => TranslatedString) => ({
        render: getDate,
    });

    const typeFormat: ColumnFormat = useMemo(() => {
        return itemFormat((type: string) => {
            return t('auditHistoryType', { context: type });
        });
    }, [t]);

    const statusFormat: ColumnFormat = useMemo(() => {
        return itemFormat((status: string) => {
            return t('auditHistoryStatus', { context: status });
        });
    }, [t]);

    const notesFormat: ColumnFormat = useMemo(() => {
        return noteFormat((notes: ReturnOrderAuditHistoryNote[]): TranslatedString[] => {
            const notesString: TranslatedString[] = notes
                ? notes.map((note: ReturnOrderAuditHistoryNote) => {
                      const date = new Date(note.date * 1000);
                      Logger.debug(`Date is: ${date}`);
                      return `${t('auditNoteTag', { context: note.tag })} ${t('auditHistoryNoteDateSep')} ${t(
                          'auditHistoryDate-fieldValue',
                          {
                              dateTime: date,
                          }
                      )}` as TranslatedString;
                  })
                : ([] as TranslatedString[]);
            return notesString;
        });
    }, [t]);

    const createDateFormat: ColumnFormat = useMemo(() => {
        return dateFormat((date: Date) => {
            return t('auditHistoryDate-fieldValue', { dateTime: date });
        });
    }, [t]);

    function getFormattedAuditItems(): FormattedAuditHistoryItem[] {
        if (auditItems.status === 'Loaded') {
            return auditItems.value?.map((item) => {
                return {
                    auditItemKey: item.id + item.type + item.status + item.creationDate + item.note,
                    type: item.type,
                    status: item.status,
                    creationDate: new Date(item.creationDate * 1000),
                    note: item.note,
                } as FormattedAuditHistoryItem;
            }) as FormattedAuditHistoryItem[];
        } else {
            return [] as FormattedAuditHistoryItem[];
        }
    }

    const columns: SuperPaginatedTableColumn[] = [
        {
            sourceProperty: 'auditItemKey',
            title: t.cognate('auditItemKey'),
            isKey: true,
            isVisible: false,
        },
        {
            sourceProperty: 'type',
            title: t('auditHistoryType-columnHeader'),
            format: typeFormat,
        },
        {
            sourceProperty: 'status',
            title: t('auditHistoryStatus-columnHeader'),
            format: statusFormat,
        },
        {
            sourceProperty: 'creationDate',
            title: t('creationDate-columnHeader'),
            defaultSort: 'ascending',
            format: createDateFormat,
        },
        {
            sourceProperty: 'note',
            title: t('auditHistoryNote-columnHeader'),
            format: notesFormat,
        },
    ];

    return (
        <BorderedColumn alignmentHorizontal={'center'} spacing={'none'}>
            <SuperPaginatedTable
                search={[{ columns: ['status', 'creationDate', 'note', 'type'], matchWholeString: false }]}
                data={getFormattedAuditItems()}
                layout={{
                    pageSize: 10,
                    searchPlaceholder: t('findResources-searchBoxPlaceholder'),
                    columns: columns,
                }}
            />
        </BorderedColumn>
    );
};

export { ReturnOrderAuditHistory };
