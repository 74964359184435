import styled from '@emotion/styled';
import React from 'react';

const Svg = styled.svg`
    width: 32px;
    height: 32px;
    color: #ffff66;
`;

export const Satisfied = () => (
    <Svg width={'32px'} height={'32px'}>
        <g id={'icons'}>
            <circle stroke={'#555555'} strokeWidth={'2'} fill={'none'} cx={'16'} cy={'16'} r={'15'} />
            <circle stroke={'black'} strokeWidth={'1'} fill={'currentColor'} cx={'16'} cy={'16'} r={'14'} />
            <line
                stroke={'black'}
                strokeWidth={'1.5'}
                fill={'none'}
                strokeLinecap={'round'}
                x1={'11.5'}
                y1={'10'}
                x2={'11.5'}
                y2={'16'}
            />
            <line
                stroke={'black'}
                strokeWidth={'1.5'}
                fill={'none'}
                strokeLinecap={'round'}
                x1={'20.5'}
                y1={'10'}
                x2={'20.5'}
                y2={'16'}
            />
            <path
                stroke={'black'}
                strokeWidth={'1.5'}
                fill={'none'}
                strokeLinecap={'round'}
                d={'M 23,22 A 0,0 0 0 1 9,22'}
            />
        </g>
    </Svg>
);
