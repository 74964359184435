import Row from '@amzn/meridian/row';
import styled from '@emotion/styled';
import React from 'react';
import { IssueMap } from '../../constants';
import { IAuthorizedReseller, IUser } from '../../redux/types';
import { color } from '../../theme/colors';
import { useSiteMapRouter } from '../../utils/SiteMapRouter';
import { Link, useTranslation } from '../blocks';
import { CreateNotificationPopover } from './CreateNotificationPopover';

const FooterRow = styled(Row)`
    clear: both;
    position: relative;
    height: 2em;
    color: white;
    margin: auto;
    align-content: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: ${color.blue.muted[600]};
`;

export interface FooterProps {
    dataTestId?: string;
    user?: IUser;
    reseller?: IAuthorizedReseller;
    data?: any;
    stage?: string;
    helpOpener: (value: boolean) => {};
}

const Footer = ({ user, reseller, data, dataTestId, helpOpener }: FooterProps) => {
    const { t } = useTranslation('footer');
    const username = user?.username || t('unknownUsername');
    const resellerId = reseller?.resellerId || t('unknownResellerId');
    const { currentPage } = useSiteMapRouter();
    const issueTemplate = IssueMap[currentPage];

    return (
        <FooterRow alignmentHorizontal={'left'} spacing={'xlarge'} spacingInset={'medium'}>
            <Link
                type={'primary'}
                onClick={() => {
                    helpOpener(true);
                }}
            >
                {t('needHelp-link')}
            </Link>
            <CreateNotificationPopover
                header={t('requestAFeature-popoverHeader')}
                label={t('requestAFeature-link')}
                username={username}
                resellerId={resellerId}
                dataTestId={`${dataTestId}-requestFeaturePopover`}
                template={IssueMap['/footer/featureRequest']}
            />
            <CreateNotificationPopover
                header={t('createTroubleTicket-popoverHeader')}
                label={t('createTroubleTicket-link')}
                username={username}
                resellerId={resellerId}
                dataTestId={`${dataTestId}-createTroubleTicketPopover`}
                template={issueTemplate}
                pageData={data}
            />
            <CreateNotificationPopover
                header={t('sendFeedback-popoverHeader')}
                label={t('sendFeedback-link')}
                username={username}
                resellerId={resellerId}
                dataTestId={`${dataTestId}-sendFeedbackPopover`}
                template={IssueMap['/footer/feedback']}
            />
        </FooterRow>
    );
};

export { Footer };
