export const useDownloader = () => {
    return {
        download(url: string) {
            window.open(url, '_blank');
        },
        downloadB64Object(name: string, content: string, contentType: string) {
            // tricky way of decoding b64 preserving binary data
            const byteCharacters = atob(content);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i += 1) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const documentBlob = new Blob([byteArray], {
                type: contentType,
            });
            // trigger a client download
            const a = document.createElement('a');
            a.hidden = true;
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(documentBlob);
            a.href = url;
            a.download = name;
            a.click();
            window.URL.revokeObjectURL(url);
        },
    };
};
