import { IAsynchronouslyLoadedValue } from '../redux/types';

export type CountryCode = string;
export type Region = string;
export type TermsAndConditionsComponent = {
    id: string;
    version: string;
};
export type CountryCodes = CountryCode[];
export type Regions = Region[];
export const DEFAULT_REGION = 'US';

export const RegoinMap: Record<CountryCode, Region> = {
    US: 'US',
    GB: 'EU',
    AT: 'EU',
    BE: 'EU',
    BG: 'EU',
    HR: 'EU',
    CY: 'EU',
    CZ: 'EU',
    DK: 'EU',
    EE: 'EU',
    FI: 'EU',
    FR: 'EU',
    DE: 'EU',
    GR: 'EU',
    HU: 'EU',
    IE: 'EU',
    IT: 'EU',
    LV: 'EU',
    LT: 'EU',
    LU: 'EU',
    MT: 'EU',
    NL: 'EU',
    PL: 'EU',
    PT: 'EU',
    RO: 'EU',
    SK: 'EU',
    SI: 'EU',
    ES: 'EU',
    SE: 'EU',
};

export const INITIAL_TERMS_AND_CONDITIONS: IAsynchronouslyLoadedValue<TermsAndConditionsComponent> = {
    status: 'Uninitialized',
    value: undefined,
    error: undefined,
};
