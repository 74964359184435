// This file wraps some console methods to suppress certain messages (such as warnings coming from external
// dependencies we have no control over) to reduce noise in the console.
/* eslint-disable no-console */

// Want to use _ for _consoleWarningSuppressionApplied to avoid possible collisions.
/* eslint-disable no-underscore-dangle */

if ((console as any)._consoleWarningSuppressionApplied) {
    console.warn('consoleWarningSupression module has been included more than once. Ignoring.');
} else {
    const consoleMethods: ('debug' | 'warn' | 'info' | 'log' | 'error')[] = ['warn', 'error'];

    // This is a list of messages that should be suppressed.
    // Arguments are either exact strings or a predicate (to use for includes or startsWith, for example).
    const suppressedMessages = [
        // Meridian uses findDOMNode which has been deprecated.
        // Here is a ticket: https://sim.amazon.com/issues/MRDN-3899
        {
            method: 'error',
            args: [
                'Warning: %s is deprecated in StrictMode. %s was passed an instance of %s which is inside StrictMode.' +
                    ' Instead, add a ref directly to the element you want to reference.' +
                    ' Learn more about using refs safely here: https://fb.me/react-strict-mode-find-node%s',
                'findDOMNode',
                'findDOMNode',
                (value: string) => ['OptionListItem', 'FocusTrap', 'PopoverController'].includes(value),
            ],
        },
    ];

    consoleMethods.forEach((method) => {
        const originalMethod = console[method] as (...data: any[]) => void;
        (console as any)[method] = (...data: any[]) => {
            const isSuppressed = suppressedMessages.some(
                (sup) =>
                    method === sup.method &&
                    data.length >= sup.args.length &&
                    sup.args.every((arg, index) => (typeof arg === 'function' ? arg(data[index]) : arg === data[index]))
            );

            if (!isSuppressed) {
                originalMethod.apply(console, data);
            } else {
                // Uncomment to see number of suppressed messages.
                // console.count("suppressedMessagesCount");
            }
        };
    });

    (console as any)._consoleWarningSuppressionApplied = true;
}

// Note: this is to keep typescript happy, otherwise it complains about file not being a real module.
export {};
