import { Action } from 'redux';
import { Stage } from '../types';

export const NO_PAYLOAD = 'No Payload';
export const SYSTEM_STAGE_LOAD = 'systemStage/load';

export const SYSTEM_REDIRECT_URL_LOAD = 'systemRedirectURL/load';

export interface INoPayloadAction extends Action {
    type: typeof NO_PAYLOAD;
}

export interface SystemStageLoadAction extends Action {
    type: typeof SYSTEM_STAGE_LOAD;
    payload: {
        stage: Stage;
        applicationBackgroundImgUrl: string;
    };
}

export interface SystemRedirectURLLoadAction extends Action {
    type: typeof SYSTEM_REDIRECT_URL_LOAD;
    payload: {
        redirectURL: string;
    };
}

export type SystemActions = INoPayloadAction | SystemStageLoadAction | SystemRedirectURLLoadAction;
