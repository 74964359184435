import { GetResellerReturnTimeWindowsInput, GetResellerReturnTimeWindowsOutput } from '../types';
import { Logger } from '../utils/logger';
import { HrpsApiClient } from './HrpsApiClient';

export const getResellerReturnTimeWindows = async (
    input: GetResellerReturnTimeWindowsInput
): Promise<GetResellerReturnTimeWindowsOutput> => {
    return await new HrpsApiClient()
        .callHrps('getResellerReturnTimeWindows', input)
        .then((response) => {
            return response.data as GetResellerReturnTimeWindowsOutput;
        })
        .catch((error) => {
            Logger.debug(
                `Error getting reseller return time windows for reseller id: ${input.resellerId}, with error ${error.message}`
            );
            throw error;
        });
};
