import styled from '@emotion/styled';
import React from 'react';

const Svg = styled.svg`
    width: 16px;
    height: 16px;
    color: grey;
`;

export const Camera = () => (
    <Svg width={'16px'} height={'16px'}>
        <g id={'icons'}>
            <path
                stroke={'currentColor'}
                strokeWidth={'.5'}
                fill={'none'}
                d={'M 1,2 5,2 6,1 9,1 10,2 14,2 14,10 1,10 Z'}
            />
            <circle stroke={'currentColor'} strokeWidth={'.5'} fill={'none'} cx={'7.5'} cy={'6.25'} r={'3'} />
            <circle stroke={'currentColor'} strokeWidth={'.5'} fill={'none'} cx={'7.5'} cy={'6.25'} r={'2.25'} />
            <rect stroke={'none'} fill={'currentColor'} width={'2.5'} height={'1'} x={'2.25'} y={'3'} ry={'0.25'} />
        </g>
    </Svg>
);
