import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import React, { FC, FormEvent, useState } from 'react';
import { AmplifyClient, ResetPasswordError } from '../clients';
import { useTranslation, TranslatedString, Button, Link, Text, Input, Alert } from '../components/blocks';
import { usePageMessaging, Tile } from '../components/composites';
import { ChangePassword, RequestPasswordHelpDialog } from '../components/constructed';
import { Logger } from '../utils/logger';
import { useSiteMapRouter } from '../utils/SiteMapRouter';
import { URLHelper } from '../utils/URLHelper';

export type ResetPasswordMode = 'requestCode' | 'submitCode';

const ResetPasswordPage: FC = () => {
    const { t } = useTranslation(['resetPasswordPage', 'pageTitles', 'forms', 'validation']);
    const pageMessaging = usePageMessaging();
    const { cancel } = useSiteMapRouter();
    const urlUsername = URLHelper.getQueryParam('username');

    const [pageIsBusy, setPageIsBusy] = useState(false);
    const [mode, setMode] = useState<ResetPasswordMode>(urlUsername ? 'submitCode' : 'requestCode');
    const [username, setUsername] = useState<string>(urlUsername ?? '');
    const [usernameError, setUsernameError] = useState<TranslatedString | undefined>(undefined);

    const handleUsernameChange = (username: string) => {
        setUsername(username);
        if (!username) {
            setUsernameError(t('validation:required-field-alert', { label: t('forms:username-fieldLabel') }));
        } else {
            setUsernameError(undefined);
        }
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (!username) {
            setUsernameError(t('validation:required-field-alert', { label: t('forms:username-fieldLabel') }));
        } else if (!usernameError) {
            setPageIsBusy(true);
            Logger.debug('Form content:', username);
            await AmplifyClient.forgotPassword(username)
                .then((data) => {
                    Logger.debug(data);
                    pageMessaging.showSuccess(t('requestSubmitted-successText'));
                    setMode('submitCode');
                })
                .catch((error) => {
                    switch (error) {
                        case ResetPasswordError.InvalidUsername:
                            setUsernameError(t('invalidUsername-alertText'));
                            break;
                        case ResetPasswordError.NotAuthorizedException:
                            pageMessaging.showError(t('notAuthorizedException-alertText'));
                            break;
                        default:
                            Logger.error('reset password error:', error);
                            pageMessaging.showError(t('unknownError-alertText'));
                            break;
                    }
                });
            setPageIsBusy(false);
        }
    };

    const handleChangePasswordLinkClick = () => {
        if (!username) {
            setUsernameError(t('specialRequiredUsername-alertText'));
        } else {
            setMode('submitCode');
        }
    };

    return (
        <Tile title={t('pageTitles:resetPassword')} width={'100%'} headingLevel={2}>
            {mode === 'requestCode' && (
                <form onSubmit={handleSubmit} data-testid={'resetPasswordForm'}>
                    <Column spacing={'medium'} spacingInset={'none none large'}>
                        <Row widths={'50%'}>
                            <Column spacing={'xsmall'}>
                                <Input
                                    value={username}
                                    onChange={handleUsernameChange}
                                    type={'text'}
                                    error={!!usernameError}
                                    size={'small'}
                                    data-testid={'resetPasswordForm.username'}
                                    label={t('forms:username-fieldLabel')}
                                />
                                <Row>
                                    {usernameError && (
                                        <div data-testid={'resetPasswordForm.username.validationError'}>
                                            <Alert type={'error'} size={'small'}>
                                                {usernameError}
                                            </Alert>
                                        </div>
                                    )}
                                </Row>
                                <RequestPasswordHelpDialog
                                    label={t('forgotUsername-linkText')}
                                    usernameIssue={true}
                                    dataTestId={'resetPasswordForm-forgotUsernamePopover'}
                                />
                            </Column>
                        </Row>
                        <Row>
                            <Button
                                type={'primary'}
                                submit={true}
                                disabled={pageIsBusy}
                                data-testid={'resetPasswordPage.submitButton'}
                            >
                                {t('submit-buttonLabel')}
                            </Button>
                            <Button type={'secondary'} onClick={cancel} data-testid={'resetPasswordPage.cancelButton'}>
                                {t('forms:cancel-buttonLabel')}
                            </Button>
                        </Row>
                        <Column spacing={'small'}>
                            <Column spacing={'none'}>
                                <Text>{t('changePassword-informationText')}</Text>
                                <Link
                                    type={'secondary'}
                                    onClick={handleChangePasswordLinkClick}
                                    data-testid={'resetPasswordForm.changePasswordLink'}
                                >
                                    {t('changePassword-linkText')}
                                </Link>
                            </Column>
                            <Column spacing={'none'}>
                                <Text>{t('reportAnIssue-informationText')}</Text>
                                <RequestPasswordHelpDialog
                                    label={t('reportAnIssue-linkText')}
                                    dataTestId={'resetPasswordForm.reportAnIssueLink'}
                                />
                            </Column>
                        </Column>
                    </Column>
                </form>
            )}
            {mode === 'submitCode' && (
                <ChangePassword
                    username={username}
                    code={URLHelper.getQueryParam('verification') ?? undefined}
                    customCancel={() => setMode('requestCode')}
                />
            )}
        </Tile>
    );
};

export { ResetPasswordPage };
