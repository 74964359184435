import Box from '@amzn/meridian/box';
import Link from '@amzn/meridian/link';
import Table, { TableCell, TableRow } from '@amzn/meridian/table';
import { TableCellWidth } from '@amzn/meridian/table/table-cell';
import { css } from 'emotion';
import _ from 'lodash';
import React, { FC, ReactElement } from 'react';
import { Debug } from '../../../utils/debug';
import { Button, TranslatedString, useTranslation } from '../../blocks/localization';
import { Tile } from '../../composites';
import { Delete } from '../../icons';

export type MultilineFormEditorColumnFormat<T = any> = {
    render: (
        item: T,
        column: MultilineFormEditorColumn,
        index: number,
        onChange: (fieldName: string, lineIndex: number) => (newValue: T) => void
    ) => ReactElement | null;
};

export type MultilineFormEditorColumn = {
    title: TranslatedString;
    columnHeader?: React.ReactNode;
    isKey?: boolean;
    width?: TableCellWidth;
    sourceProperty: string;
} & (
    | { isVisible?: boolean; format: MultilineFormEditorColumnFormat }
    | { isVisible: false; format?: MultilineFormEditorColumnFormat }
);

export interface MultilineFormEditorProps<T = any> {
    title: TranslatedString;
    maxHeight?: number;
    columns: MultilineFormEditorColumn[];
    items: T[];
    onItemChange: (items: T[], changedIndex?: number, changedField?: string) => void;
    onAddRowClick?: () => void;
    addRowButtonLabel?: TranslatedString;
    onDeleteRowClick?: (items: T[], index: number) => void;
    deleteRowButtonLabel?: TranslatedString;
    newItemBuilder: () => T;
    dataTestId?: string;
}

const scrollClass = css`
    overflow-y: auto;
`;

const MultilineFormEditor: FC<MultilineFormEditorProps> = ({
    title,
    maxHeight,
    columns,
    items,
    onItemChange,
    onAddRowClick,
    addRowButtonLabel,
    onDeleteRowClick,
    deleteRowButtonLabel,
    newItemBuilder,
    dataTestId,
}: MultilineFormEditorProps) => {
    const keyColumn = _.find(columns, 'isKey');
    Debug.assertExists(keyColumn);
    const keyProperty = keyColumn.sourceProperty;

    const addRow = () => {
        if (onAddRowClick) {
            onAddRowClick();
        } else {
            onItemChange([...items, newItemBuilder()]);
        }
    };

    const removeRow = (indexToRemove: number) => {
        if (onDeleteRowClick) {
            onDeleteRowClick(items, indexToRemove);
        } else {
            onItemChange(items.filter((_, i) => i !== indexToRemove));
        }
    };

    function handleItemFieldChange(fieldName: string, lineIndex: number) {
        return (value: any): void => {
            onItemChange(
                items.map((item, currIndex) => (currIndex === lineIndex ? { ...item, [fieldName]: value } : item)),
                lineIndex,
                fieldName
            );
        };
    }

    const { t } = useTranslation('createReturnOrderPage');
    return (
        <Tile title={title} width={'100%'} dataTestId={dataTestId}>
            {addRowButtonLabel && (
                <Button type={'link'} onClick={addRow} data-testid={dataTestId && `${dataTestId}.addRowButton`}>
                    {addRowButtonLabel}
                </Button>
            )}
            <Box className={scrollClass} spacingInset={'small'} width={'100%'} maxHeight={maxHeight}>
                <Table className={scrollClass} headerRows={1} spacing={'small'} fixHeaderRows={true}>
                    <TableRow data-testid={dataTestId && `${dataTestId}.tableHeaderRow`}>
                        {columns
                            .filter((column) => column.isVisible ?? true)
                            .map((column) => (
                                <TableCell
                                    key={column.title}
                                    width={column.width}
                                    data-testid={dataTestId && `${dataTestId}.column[${column.title}].headerCell`}
                                >
                                    {column.columnHeader}
                                    {!column.columnHeader && column.title}
                                </TableCell>
                            ))}
                    </TableRow>
                    {items.map((item, lineIndex) => (
                        <TableRow
                            key={item[keyProperty]}
                            data-testid={dataTestId && `${dataTestId}.row[${item[keyProperty]}]`}
                        >
                            {columns
                                .filter((column) => column.isVisible ?? true)
                                .map((column) => (
                                    <TableCell
                                        key={item[keyProperty] + column.title}
                                        data-testid={
                                            dataTestId &&
                                            `${dataTestId}.row[${item[keyProperty]}].column[${column.title}].valueCell`
                                        }
                                    >
                                        {column.format!.render(item, column, lineIndex, handleItemFieldChange)}
                                    </TableCell>
                                ))}
                            <TableCell>
                                {deleteRowButtonLabel ? (
                                    <Button
                                        type={'link'}
                                        onClick={() => removeRow(lineIndex)}
                                        data-testid={
                                            dataTestId && `${dataTestId}.row[${item[keyProperty]}].deleteRowButton`
                                        }
                                    >
                                        {deleteRowButtonLabel}
                                    </Button>
                                ) : (
                                    <Link
                                        onClick={() => removeRow(lineIndex)}
                                        type={'secondary'}
                                        data-testid={
                                            dataTestId && `${dataTestId}.row[${item[keyProperty]}].deleteRowButton`
                                        }
                                        aria-label={t('deleteOrderLine-buttonLabel')}
                                    >
                                        <Delete />
                                    </Link>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </Table>
            </Box>
        </Tile>
    );
};

export { MultilineFormEditor };
