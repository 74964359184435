import Row from '@amzn/meridian/row';
import styled from '@emotion/styled';
import React, { FC } from 'react';

const Link = styled.div`
    color: #0047ab;
    cursor: pointer;
`;

type ReferenceCellProps = {
    translatedText: string;
    fileId: string;
    callback: Function;
};

export const ClaimReferenceCell: FC<ReferenceCellProps> = ({
    translatedText,
    fileId,
    callback,
}: ReferenceCellProps) => {
    return (
        <Row width={'100%'}>
            <Link onClick={() => callback(fileId)}>{translatedText}</Link>
        </Row>
    );
};
