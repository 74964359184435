import Column from '@amzn/meridian/column';
import Loader from '@amzn/meridian/loader';
import React from 'react';
import { Text, TranslatedString } from '../../../blocks/localization';
import { MultilineFormEditorColumnFormat } from '../MultilineFormEditor';

export const textFormat = (options: {
    isLoading?: (item: any) => boolean;
    alignment?: 'center' | 'end' | 'justify' | 'left' | 'right' | 'start' | undefined;
}): MultilineFormEditorColumnFormat => ({
    render: (item, column) => {
        if (options.isLoading) {
            if (options.isLoading(item)) {
                return (
                    <Column alignmentHorizontal={'center'}>
                        <Loader size={'small'} />
                    </Column>
                );
            }
        }

        const alignment = options.alignment ? options.alignment : 'center';
        let value = '' as TranslatedString;
        if (item[column.sourceProperty] !== undefined) {
            value = item[column.sourceProperty];
        }

        return <Text alignment={alignment}>{value}</Text>;
    },
});
