import { Action } from 'redux';
import { AsynchronouslyLoadedValueStatus, IManagedUser } from '../types';

export const MANAGED_USERS_CLEAR = 'managedUsers/clear';
export const MANAGED_USERS_GET = 'managedUsers/get';
export const MANAGED_USER_GET = 'managedUser/get';
export const MANAGED_USERS_LOADED = 'managedUsers/loaded';
export const MANAGED_USER_LOADED = 'managedUser/loaded';

export interface IManagedUsersGetAction extends Action {
    type: typeof MANAGED_USERS_GET;
    payload: {
        status: AsynchronouslyLoadedValueStatus;
        error?: any;
        managedUsers: IManagedUser[];
    };
}

export interface IManagedUsersLoadedAction extends Action {
    type: typeof MANAGED_USERS_LOADED;
    payload: {
        status: AsynchronouslyLoadedValueStatus;
        error?: any;
        managedUsers: IManagedUser[];
    };
}

export interface IManagedUserGetAction extends Action {
    type: typeof MANAGED_USER_GET;
    payload: {
        status: AsynchronouslyLoadedValueStatus;
        error?: any;
        managedUser?: IManagedUser;
    };
}

export interface IManagedUserLoadedAction extends Action {
    type: typeof MANAGED_USER_LOADED;
    payload: {
        status: AsynchronouslyLoadedValueStatus;
        error?: any;
        managedUser?: IManagedUser;
    };
}

export interface IManagedUserClearAction extends Action {
    type: typeof MANAGED_USERS_CLEAR;
    payload: {
        status: AsynchronouslyLoadedValueStatus;
        error?: any;
        managedUsers: IManagedUser[];
    };
}

export type ManagedUserActions =
    | IManagedUsersGetAction
    | IManagedUsersLoadedAction
    | IManagedUserGetAction
    | IManagedUserLoadedAction
    | IManagedUserClearAction;
