import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { IAsynchronouslyLoadedValue } from '../../redux/types';
import { Logger } from '../../utils/logger';

type WaitForProps<T extends any> = { lazyValue: IAsynchronouslyLoadedValue<T>; children: (value: T) => ReactElement };

const WaitFor = <T extends any>({ lazyValue, children }: WaitForProps<T>): ReactElement => {
    const { t } = useTranslation('waitFor');

    switch (lazyValue.status) {
        case 'Uninitialized':
            return <span />;
        case 'Loading':
            return <span>{t('loading-placeholder')}</span>;
        case 'Loaded':
            return children(lazyValue.value as T);
        case 'Failed':
            return <span>{t('failed-placeholder')}</span>;
        default:
            Logger.debug(`Unexpected status of: ${lazyValue.status}`);
            throw new Error(`Unexpected status: <${lazyValue.status}>.`);
    }
};

export { WaitFor };
