import MeridianColumn from '@amzn/meridian/column';
import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import { IAsynchronouslyLoadedValue, ResellerItem } from '../../redux/types';
import { color } from '../../theme/colors';
import { ReturnOrderProblemShelfItem } from '../../types/ReturnOrderProblemShelf';
import { useTranslation } from '../blocks';
import { ColumnFormat, SuperPaginatedTable, SuperPaginatedTableColumn } from '../constructed/SuperPaginatedTable';

export type ReturnOrderProblemShelfProps = {
    returnOrderProblemShelf: IAsynchronouslyLoadedValue<ReturnOrderProblemShelfItem[]>;
    resellerItems: ResellerItem[];
};

const BorderedColumn = styled(MeridianColumn)`
    border: 1px solid ${color.gray[300]};
    box-shadow: 0px 0px 3px ${color.gray[300]};
`;

const ReturnOrderProblemShelf = ({ returnOrderProblemShelf, resellerItems }: ReturnOrderProblemShelfProps) => {
    const { t } = useTranslation('returnOrderProblemShelf');

    const itemMap = useMemo(() => new Map(resellerItems.map((item) => [item.asin, item])), [resellerItems]);

    const dateFormat: ColumnFormat = useMemo(() => {
        return {
            render: (inDate: Date) => {
                return t('dateFormat', { dateTime: inDate });
            },
        };
    }, [t]);

    const formattedProblemShelfItems = useMemo(() => {
        const problemShelfItems = returnOrderProblemShelf.value;
        return problemShelfItems
            ? problemShelfItems.map((problemShelfItem) => {
                  const itemDetails = itemMap.get(problemShelfItem.asin);
                  const externalSku = itemDetails?.externalSku;
                  const upc = itemDetails?.upc;
                  const asin = itemDetails?.asin;
                  const title = itemDetails?.title;
                  const creationDate = new Date(problemShelfItem.createdDateTime * 1000);
                  return {
                      externalSku: externalSku ? t.cognate(externalSku) : t('externalSku-value_missing'),
                      upc: upc ? t.cognate(upc) : t('upc-value_missing'),
                      asin: asin ? t.cognate(asin) : t('asin-value_missing'),
                      title: title ? t.cognate(title) : t('title-value_missing'),
                      deviceDisposition: problemShelfItem.deviceDisposition,
                      overageStatus: problemShelfItem.overageStatus ? problemShelfItem.overageStatus : ' ',
                      originalShipmentId: problemShelfItem.originalShipmentId
                          ? problemShelfItem.originalShipmentId
                          : ' ',
                      createdDateTime: creationDate,
                  };
              })
            : [];
    }, [returnOrderProblemShelf, itemMap, t]);

    const columns: SuperPaginatedTableColumn[] = [
        {
            sourceProperty: 'externalSku',
            title: t('externalSku-columnHeader'),
        },
        {
            sourceProperty: 'upc',
            title: t('upc-columnHeader'),
        },
        {
            sourceProperty: 'asin',
            title: t('asin-columnHeader'),
        },
        {
            sourceProperty: 'title',
            title: t('title-columnHeader'),
        },
        {
            sourceProperty: 'deviceDisposition',
            title: t('deviceDisposition-columnHeader'),
        },
        {
            sourceProperty: 'overageStatus',
            title: t('overageStatus-columnHeader'),
        },
        {
            sourceProperty: 'originalShipmentId',
            title: t('originalShipmentId-columnHeader'),
        },
        {
            sourceProperty: 'createdDateTime',
            title: t('createdDateTime-columnHeader'),
            format: dateFormat,
        },
    ];

    return (
        <BorderedColumn alignmentHorizontal={'center'} spacing={'none'}>
            <SuperPaginatedTable
                data={formattedProblemShelfItems}
                layout={{
                    pageSize: 10,
                    searchPlaceholder: t('findResources-searchBoxPlaceholder'),
                    columns,
                }}
            />
        </BorderedColumn>
    );
};

export { ReturnOrderProblemShelf };
