import styled from '@emotion/styled';
import React from 'react';

const Svg = styled.svg`
    width: 16px;
    height: 16px;
    color: #45c445;
`;

export const Add = () => (
    <Svg width={'16px'} height={'16px'}>
        <g id={'icons'}>
            <circle stroke={'currentColor'} strokeWidth={'2'} fill={'none'} cx={'8'} cy={'8'} r={'7'} />
            <line
                stroke={'currentColor'}
                strokeWidth={'1.5'}
                fill={'none'}
                strokeLinecap={'round'}
                x1={'8'}
                y1={'4'}
                x2={'8'}
                y2={'12'}
            />
            <line
                stroke={'currentColor'}
                strokeWidth={'1.5'}
                fill={'none'}
                strokeLinecap={'round'}
                x1={'4'}
                y1={'8'}
                x2={'12'}
                y2={'8'}
            />
        </g>
    </Svg>
);
