import Column from '@amzn/meridian/column';
import Loader from '@amzn/meridian/loader';
import Row from '@amzn/meridian/row';
import { SelectOption } from '@amzn/meridian/select';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Alert, Checkbox, Select, Text, TranslatedString, useTranslation } from '../components/blocks';
import { LabeledInput, usePageMessaging } from '../components/composites';
import { ResellerTable, HelpTablePopover, TableRowItem, HelpPopover } from '../components/constructed';
import { ALL_RESELLERS, INTERNAL_ROLE_IDS } from '../constants';
import { getAuthorizedResellers } from '../redux/actions/resellerActionCreators';
import { IAuthorizedReseller, RootState } from '../redux/types';
import { locales, RoleId, SiteMapPage } from '../types';
import { Logger } from '../utils/logger';
import { getAllPermissions } from '../utils/security';
import { useSiteMapRouter } from '../utils/SiteMapRouter';

export type UserDetails = {
    username: string;
    givenName: string;
    familyName: string;
    email: string;
    locale?: string;
    phone?: string;
    resellers: string[];
    role?: string;
};
type UserDetailsFields = keyof UserDetails;
type UserDetailsErrors = { [P in UserDetailsFields]?: ValidationResult };

export interface UserDetailsFormApi {
    validateForm: () => 'valid' | 'invalid';
    resetFormError: () => void;
}

type UserDetailsFormProps = {
    userDetails: UserDetails;
    onUserDetailsChange: (userDetails: UserDetails) => void;
    formApiRef: React.MutableRefObject<UserDetailsFormApi | undefined>;
    mode: 'edit' | 'create';
} & ConnectedProps<typeof connector>;

// Validation result contains either an error message as a TranslatedString,
// or it is undefined (which indicates success).
const noError = undefined;
type ValidationResult = TranslatedString | typeof noError;
type ValidationFunction = (fieldName: string, value: any) => ValidationResult;

// eslint-disable-next-line max-lines-per-function
const UserDetailsForm: FC<UserDetailsFormProps> = ({
    userDetails,
    formApiRef,
    onUserDetailsChange,
    availableRoles,
    availableResellers,
    getAuthorizedResellers,
    mode,
    user,
}: UserDetailsFormProps) => {
    const { t } = useTranslation(['userDetailsForm', 'roles', 'validation', 'forms', 'selectResellerPage', 'locales']);
    const isInternalUser = () => userDetails.role && INTERNAL_ROLE_IDS.includes(userDetails.role);

    const [allResellersIsChecked, setAllResellersIsChecked] = useState(userDetails.resellers.includes('*'));

    // eslint-disable-next-line no-param-reassign
    formApiRef.current = {
        validateForm() {
            return isFormValid() ? 'valid' : 'invalid';
        },
        resetFormError() {
            return setErrors({});
        },
    };

    const [errors, setErrors] = useState<UserDetailsErrors>({});
    const pageMessaging = usePageMessaging();
    const { goto } = useSiteMapRouter();
    const roleDescriptions: Array<TableRowItem> = Object.values(availableRoles).map((roleId) => {
        return {
            type: t.expression(`roles:${roleId}`),
            description: t.expression(`roles:${roleId}-description`),
        };
    });

    useEffect(() => {
        switch (availableResellers.status) {
            case 'Uninitialized':
                getAuthorizedResellers();
                break;
            // FIXME: error-handling copied from SelectReseller; generalized solution preferred -- 3/25/22
            case 'Failed':
                Logger.error('getAuthorizedResellers error: ', availableResellers.error);
                if (availableResellers.error?.code === 'NoAuthorizedResellers') {
                    pageMessaging.showError(t('selectResellerPage:noAuthorizedResellers-errorMessage'));
                    goto(SiteMapPage.signOut);
                } else {
                    pageMessaging.showError(t('selectResellerPage:failedToRetrieveResellers-errorMessage'));
                }
                break;
            default:
            // Do Nothing
        }
    }, [availableResellers, getAuthorizedResellers, goto, pageMessaging, t]);

    const validateFieldInput = (required?: boolean, innerValidation?: ValidationFunction): ValidationFunction => {
        return (fieldName: string, value: any) => {
            if (value && innerValidation) {
                return innerValidation(fieldName, value);
            } else if (!required || value) {
                return noError;
            } else {
                return t('validation:required-field-alert', {
                    label: t.expression(`${fieldName}-fieldLabel`),
                });
            }
        };
    };

    const pattern = (
        pattern: RegExp,
        messageTemplate: string,
        templateOptions?: Map<string, TranslatedString>
    ): ValidationFunction => {
        return (fieldName: string, value: string): ValidationResult => {
            return pattern.test(value)
                ? noError
                : t(messageTemplate, { ...templateOptions, label: t.expression(`${fieldName}-fieldLabel`) });
        };
    };

    const nonEmptyCollection = (errorMessage: TranslatedString): ValidationFunction => {
        return (fieldName: string, collection?: any[]) => (collection?.length ? noError : errorMessage);
    };

    const rules: Record<UserDetailsFields, ValidationFunction> = {
        username: validateFieldInput(true, pattern(/^[\S]{1,128}$/, 'validation:fieldInput-validationError')),
        givenName: validateFieldInput(true),
        familyName: validateFieldInput(true),
        email: validateFieldInput(true, pattern(/^[\S]+@[\S]+$/, 'validation:fieldInput-validationError')),
        locale: validateFieldInput(true),
        phone: validateFieldInput(false, pattern(/^\+[0-9]{7,16}$/, t('validation:phoneNumber-validationError'))),
        resellers: nonEmptyCollection(t('atLeastOneResellerIsRequired-fieldError')),
        role: validateFieldInput(true),
    };

    function validateField(fieldName: UserDetailsFields, fieldValue: any): ValidationResult {
        const fieldRule = rules[fieldName];
        return fieldRule ? fieldRule(fieldName, fieldValue) : noError;
    }

    function isFormValid(): boolean {
        const errors = _.chain(userDetails)
            .mapValues((fieldValue, fieldKey) => validateField(fieldKey as UserDetailsFields, fieldValue))
            .pickBy(_.isString)
            .value();
        setErrors(errors);
        return _.isEmpty(errors);
    }

    function determineAvailableResellers(): IAuthorizedReseller[] {
        return [...(availableResellers?.value ? availableResellers.value : [])];
    }

    function handleFieldChange(fieldName: UserDetailsFields) {
        if (fieldName === 'role' && !isInternalUser()) {
            _.remove(userDetails.resellers, (id) => id === ALL_RESELLERS.resellerId);
        }
        return (value: any): void => {
            onUserDetailsChange({ ...userDetails, [fieldName]: value });
            if (errors[fieldName] !== noError) {
                const validationResult = validateField(fieldName, value);
                setErrors((currentErrors) => ({ ...currentErrors, [fieldName]: validationResult }));
            }
        };
    }

    function validateOnBlur(fieldName: UserDetailsFields) {
        return (event: any): void => {
            const validationResult = validateField(fieldName, event.target.value);
            setErrors((currentErrors) => ({ ...currentErrors, [fieldName]: validationResult }));
        };
    }

    const onAllResellersCheckboxClick = () => {
        if (allResellersIsChecked) {
            setAllResellersIsChecked(false);
            onUserDetailsChange({ ...userDetails, resellers: [] });
        } else {
            setAllResellersIsChecked(true);
            onUserDetailsChange({ ...userDetails, resellers: ['*'] });
        }
    };

    return (
        <>
            {mode === 'edit' && (
                <Column spacing={'small'}>
                    <Row widths={'fill'}>
                        <Text>{t('forms:username-fieldLabel')}</Text>
                    </Row>
                    <Row widths={'fill'}>{userDetails.username}</Row>
                </Column>
            )}
            {mode === 'create' && (
                <LabeledInput
                    label={t('forms:username-fieldLabel')}
                    value={userDetails.username}
                    onBlur={validateOnBlur('username')}
                    onChange={handleFieldChange('username')}
                    type={'text'}
                    error={errors.username}
                    dataTestId={'userDetailsForm.username'}
                    helpBox={
                        <HelpPopover header={t('forms:usernameHelpHeader')}>
                            <Text tag={'ul'}>
                                <li>
                                    {t('forms:length-fieldRequirement', {
                                        fieldName: t('forms:username-fieldLabel'),
                                        minCharLimit: '1',
                                        maxCharLimit: '128',
                                    })}
                                </li>
                                <li>
                                    {t('forms:noWhiteSpace-fieldRequirement', {
                                        fieldName: t('forms:username-fieldLabel'),
                                    })}
                                </li>
                            </Text>
                        </HelpPopover>
                    }
                />
            )}
            <LabeledInput
                label={t('givenName-fieldLabel')}
                value={userDetails.givenName}
                onBlur={validateOnBlur('givenName')}
                onChange={handleFieldChange('givenName')}
                type={'text'}
                error={errors.givenName}
                dataTestId={'userDetailsForm.givenName'}
            />

            <LabeledInput
                label={t('familyName-fieldLabel')}
                value={userDetails.familyName}
                onBlur={validateOnBlur('familyName')}
                onChange={handleFieldChange('familyName')}
                type={'text'}
                error={errors.familyName}
                dataTestId={'userDetailsForm.familyName'}
            />
            <LabeledInput
                label={t('email-fieldLabel')}
                value={userDetails.email}
                onBlur={validateOnBlur('email')}
                onChange={handleFieldChange('email')}
                type={'email'}
                error={errors.email}
                dataTestId={'userDetailsForm.email'}
            />
            <LabeledInput
                label={t('phone-fieldLabel')}
                value={userDetails.phone ?? ''}
                onBlur={validateOnBlur('phone')}
                onChange={(value) => handleFieldChange('phone')(value === '' ? null : value)}
                type={'tel'}
                error={errors.phone}
                dataTestId={'userDetailsForm.phone'}
                isOptional={true}
            />
            <Select
                label={t('locale-fieldLabel')}
                value={userDetails.locale ?? user?.locale ?? 'en-US'}
                onChange={handleFieldChange('locale')}
                width={'100%'}
            >
                {locales.map((locale) => {
                    const label: TranslatedString = t('locales:locale', { context: locale });
                    return <SelectOption key={locale} value={locale} label={label} />;
                })}
            </Select>
            <Column spacing={'small'}>
                <Row widths={['fill', 'fit']}>
                    <Select
                        label={t('role-fieldLabel')}
                        value={userDetails.role}
                        onChange={handleFieldChange('role')}
                        width={'100%'}
                        placeholder={t('selectARole-fieldPlaceholder')}
                    >
                        {Object.values(availableRoles).map((roleId) => {
                            const label: TranslatedString = t.expression(`roles:${roleId}`);
                            return <SelectOption key={roleId} value={roleId} label={label} />;
                        })}
                    </Select>
                    <HelpTablePopover header={t('roles:userRoleHelpHeader')} tableRowItems={roleDescriptions} />
                </Row>
                <Row>
                    <div data-testid={'userDetailsForm.role.validationError'}>
                        {errors.role && (
                            <Alert type={'error'} size={'small'}>
                                {errors.role}
                            </Alert>
                        )}
                    </div>
                </Row>
            </Column>
            <Column spacing={'medium'}>
                <Row>
                    <Text>{t('reseller-fieldLabel')}</Text>
                </Row>
                <>
                    {isInternalUser() && (
                        <Checkbox value={'*'} checked={allResellersIsChecked} onChange={onAllResellersCheckboxClick}>
                            {t('allResellers-checkboxLabel')}
                        </Checkbox>
                    )}
                </>
                <>
                    {allResellersIsChecked || (
                        <Row>
                            {availableResellers.status === 'Loading' ? (
                                <Loader size={'medium'} type={'circular'} />
                            ) : (
                                <ResellerTable
                                    availableResellers={determineAvailableResellers()}
                                    selectedResellers={userDetails.resellers}
                                    onSelectionChange={handleFieldChange('resellers')}
                                />
                            )}
                        </Row>
                    )}
                </>
                <Row>
                    <div data-testid={'userDetailsForm.resellers.validationError'}>
                        {errors.resellers && (
                            <Alert type={'error'} size={'small'}>
                                {errors.resellers}
                            </Alert>
                        )}
                    </div>
                </Row>
            </Column>
        </>
    );
};

const mapStateToProps = ({ resellerReducer: { authorizedResellers: resellers }, userReducer: { user } }: RootState) => {
    return {
        availableRoles: _.chain(getAllPermissions(user!.roles, 'role')).map('resourceId').uniq().value() as RoleId[],
        availableResellers: resellers,
        user: user,
    };
};

const mapDispatchToProps = { getAuthorizedResellers };

const connector = connect(mapStateToProps, mapDispatchToProps);
const connectedUserDetailsForm = connector(UserDetailsForm);
export { connectedUserDetailsForm as UserDetailsForm };
