import { Action } from 'redux';
import { IAsynchronouslyLoadedValue, ReturnOrderDraft, ReturnOrderDraftPersistence } from '../types';

export const RETURN_ORDER_DRAFT_GET = 'returnOrderDraft/get';
export const RETURN_ORDER_DRAFT_LOADED = 'returnOrderDraft/loaded';
export const RETURN_ORDER_DRAFT_SET = 'returnOrderDraft/set';
export const RETURN_ORDER_DRAFT_PERSISTENCE_SET = 'returnOrderDraftId/set';
export const RETURN_ORDER_DRAFT_CLEAR = 'returnOrderDraft/clear';
export const RETURN_ORDER_DRAFT_SHIPPING_ADDRESS_CHANGED = 'returnOrderDraftShippingAddress/changed';
export const RETURN_ORDER_DRAFT_BILLING_ADDRESS_CHANGED = 'returnOrderDraftBillingAddress/changed';

export interface ReturnOrderDraftGetAction extends Action {
    type: typeof RETURN_ORDER_DRAFT_GET;
    payload: IAsynchronouslyLoadedValue<ReturnOrderDraft>;
}

export interface ReturnOrderDraftLoadedAction extends Action {
    type: typeof RETURN_ORDER_DRAFT_LOADED;
    payload: IAsynchronouslyLoadedValue<ReturnOrderDraft>;
}

export interface ReturnOrderDraftSetAction extends Action {
    type: typeof RETURN_ORDER_DRAFT_SET;
    payload: ReturnOrderDraft;
}

export interface ReturnOrderDraftIdSetAction extends Action {
    type: typeof RETURN_ORDER_DRAFT_PERSISTENCE_SET;
    payload: ReturnOrderDraftPersistence;
}

export interface ReturnOrderDraftClearAction extends Action {
    type: typeof RETURN_ORDER_DRAFT_CLEAR;
    payload: IAsynchronouslyLoadedValue<ReturnOrderDraft>;
}

export interface ReturnOrderDraftChangeShippingAddressAction extends Action {
    type: typeof RETURN_ORDER_DRAFT_SHIPPING_ADDRESS_CHANGED;
    payload: string;
}

export interface ReturnOrderDraftChangeBillingAddressAction extends Action {
    type: typeof RETURN_ORDER_DRAFT_BILLING_ADDRESS_CHANGED;
    payload: string;
}

export type ReturnOrderDraftActions =
    | ReturnOrderDraftGetAction
    | ReturnOrderDraftLoadedAction
    | ReturnOrderDraftSetAction
    | ReturnOrderDraftIdSetAction
    | ReturnOrderDraftClearAction
    | ReturnOrderDraftChangeShippingAddressAction
    | ReturnOrderDraftChangeBillingAddressAction;
