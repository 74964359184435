// Note: This is a wrapper for browser's console API. This is the only
// file where console access is allowed.
/* eslint-disable no-console */

type ConsoleMethod = (message?: any, ...optionalParams: any[]) => void;

enum LoggingLevel {
    TRACE = 0,
    DEBUG = 1,
    INFO = 2,
    WARN = 3,
    ERROR = 4,
}

const SeverityMap: Record<string, { consoleMethod: ConsoleMethod; level: LoggingLevel }> = {
    trace: { consoleMethod: console.debug, level: LoggingLevel.TRACE },
    debug: { consoleMethod: console.debug, level: LoggingLevel.DEBUG },
    info: { consoleMethod: console.info, level: LoggingLevel.INFO },
    warn: { consoleMethod: console.warn, level: LoggingLevel.WARN },
    error: { consoleMethod: console.error, level: LoggingLevel.ERROR },
};

type LoggerType = { [key in keyof typeof SeverityMap]: ConsoleMethod };

const DevLoggingLevel = LoggingLevel.TRACE;
const ProdLoggingLevel = LoggingLevel.ERROR;

// Creates a new object with the same keys and values transformed using given valueMapper function.
function mapObjectValues<TFromValue, TToValue>(
    fromObject: Record<string, TFromValue>,
    valueMapper: (fromValue: TFromValue) => TToValue
) {
    return Object.fromEntries(
        Object.entries(fromObject).map(([key, fromValue]) => {
            return [key, valueMapper(fromValue)];
        })
    );
}

const logger: LoggerType = (mapObjectValues(
    SeverityMap,
    ({ level, consoleMethod }) => (message?: any, ...optionalParams: any[]) => {
        const loggingLevel = process.env.NODE_ENV === 'development' ? DevLoggingLevel : ProdLoggingLevel;
        if (level >= loggingLevel) {
            consoleMethod.apply(console, [message, ...optionalParams]);
        }
    }
) as unknown) as LoggerType;

// TODO: should this be Logger or logger?
export { logger as Logger };
