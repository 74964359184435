import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import { SelectOption } from '@amzn/meridian/select';
import styled from '@emotion/styled';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link, SearchField, Select, Text, TranslatedString, useTranslation } from '../components/blocks';
import { usePageMessaging } from '../components/composites';
import { HelpTablePopover, PaginatedTable } from '../components/constructed';
import { BLANK_RESELLERS, USERS_PER_PAGE } from '../constants';
import { getManagedUsers } from '../redux/actions/managedUserActionCreators';
import { getAuthorizedResellers } from '../redux/actions/resellerActionCreators';
import { IAuthorizedReseller, IManagedUser, IUser, RootState, TableData } from '../redux/types';
import { color } from '../theme/colors';
import { IRole, SiteMapPage } from '../types';
import { Logger } from '../utils/logger';
import { useSiteMapRouter } from '../utils/SiteMapRouter';

const BorderedColumn = styled(Column)`
    border: 1px solid ${color.gray[300]};
    box-shadow: 0px 0px 3px ${color.gray[300]};
`;

const GreyBox = styled(Box)`
    box-shadow: 0px 0px 3px ${color.gray[300]};
`;

// FIXME: Refactor this function to make it smaller. -- 3/31/22
// eslint-disable-next-line max-lines-per-function
const UserListPage: FC<UserListPageProps> = ({
    authorizedResellers,
    validRoles,
    adminUser,
    managedUsers,
    status,
    error,
    getManagedUsers,
    getAuthorizedResellers,
}: UserListPageProps) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [sortColumn, setSortColumn] = useState<string>('username');
    const [sortDirection, setSortDirection] = useState<string>('ascending');
    const [curSearchValue, setCurSearchValue] = useState<string>('');
    const [curSelectedReseller, setCurSelectedReseller] = useState<string>('');

    const { t } = useTranslation(['userListPage', 'roles', 'selectResellerPage']);

    const { showError } = usePageMessaging();

    const { getPath, goto } = useSiteMapRouter();

    const getUserList = useCallback(
        (username: string) => {
            Logger.debug('getUserList:', { username });

            const fetchUsers = async () => {
                getManagedUsers(username);
            };
            fetchUsers();
        },
        [getManagedUsers]
    );

    const userStatusHelpItems = [
        {
            type: t('userStatus-columnValue_UNKNOWN'),
            description: t('userStatus-description_UNKNOWN'),
        },
        {
            type: t('userStatus-columnValue_ARCHIVED'),
            description: t('userStatus-description_ARCHIVED'),
        },
        {
            type: t('userStatus-columnValue_CONFIRMED'),
            description: t('userStatus-description_CONFIRMED'),
        },
        {
            type: t('userStatus-columnValue_FORCE_CHANGE_PASSWORD'),
            description: t('userStatus-description_FORCE_CHANGE_PASSWORD'),
        },
        {
            type: t('userStatus-columnValue_COMPROMISED'),
            description: t('userStatus-description_COMPROMISED'),
        },
        {
            type: t('userStatus-columnValue_UNCONFIRMED'),
            description: t('userStatus-description_UNCONFIRMED'),
        },
        {
            type: t('userStatus-columnValue_RESET_REQUIRED'),
            description: t('userStatus-description_RESET_REQUIRED'),
        },
    ];

    useEffect(() => {
        switch (authorizedResellers.status) {
            case 'Uninitialized':
                getAuthorizedResellers();
                break;
            // FIXME: error-handling copied from SelectReseller; generalized solution preferred -- 3/25/22
            case 'Failed':
                Logger.error('getAuthorizedResellers error: ', authorizedResellers.error);
                if (authorizedResellers.error?.code === 'NoAuthorizedResellers') {
                    showError(t('selectResellerPage:noAuthorizedResellers-errorMessage'));
                    goto(SiteMapPage.signOut);
                } else {
                    showError(t('selectResellerPage:failedToRetrieveResellers-errorMessage'));
                }
                break;
            default:
            // do nothing
        }
        if (adminUser) {
            switch (status) {
                case 'Uninitialized':
                    getUserList(adminUser.username);
                    break;
                case 'Failed':
                    Logger.error('getUserList error: ', error);
                    if (error?.code === 'ZeroResellersForUser') {
                        showError(t('failedToLoadManagedUser-invalidUserMessage'));
                    } else {
                        showError(t('failedToLoadManagedUser-errorMessage'));
                    }
                    break;
                default:
                // do nothing
            }
        }
        return () => {};
    }, [
        authorizedResellers,
        validRoles,
        adminUser,
        managedUsers,
        status,
        error,
        getAuthorizedResellers,
        showError,
        t,
        goto,
        getUserList,
    ]);

    const onPageClick = (value: string) => {
        Logger.debug(`onPageClick PageNumber: ${JSON.stringify({ value })}`);
        setCurrentPage(parseInt(value));
    };

    const onSort = useCallback(({ sortColumn, sortDirection }) => {
        setSortColumn(sortColumn);
        setSortDirection(sortDirection);
    }, []);

    const onSearch = (value: string) => {
        Logger.debug(`onSearch: ${JSON.stringify({ value })}`);
        setCurSearchValue(value);
        setCurrentPage(1);
    };

    const selectedResellerChanged = (value: string) => {
        Logger.debug(`selectedResellerChanged: ${JSON.stringify({ value })}`);
        setCurSelectedReseller(value);
        setCurrentPage(1);
    };

    function foundUsers(element: IManagedUser) {
        const lowerCurSearchValue = curSearchValue.toLowerCase();
        const resellersFoundWithString = element.resellers.find((reseller) => reseller.id === curSelectedReseller);

        const found =
            (curSearchValue &&
                (element.username.toLowerCase().includes(lowerCurSearchValue) ||
                    element.givenName?.toLowerCase().includes(lowerCurSearchValue) ||
                    element.familyName?.toLowerCase().includes(lowerCurSearchValue) ||
                    element.email?.toLowerCase().includes(lowerCurSearchValue) ||
                    t('userStatus-columnValue', { context: element.userStatus })
                        ?.toLowerCase()
                        .includes(lowerCurSearchValue) ||
                    element.role?.toLowerCase().includes(lowerCurSearchValue) ||
                    (element.enabled ? t('userList-yes') : t('userList-no'))
                        .toLowerCase()
                        .includes(lowerCurSearchValue))) ||
            resellersFoundWithString;

        return found;
    }

    const formatSortColumn = (user: IManagedUser) => {
        switch (sortColumn) {
            case 'name':
                return `${user.familyName}, ${user.givenName}, ${user.username}`;
            case 'email':
                return `${user.email}, ${user.username}`;
            case 'userStatus':
                return `${t('userStatus-columnValue', { context: user.userStatus })}, ${user.username}`;
            case 'resellers':
                return `${user.resellers.join(', ')}, ${user.username}`;
            case 'enabled':
                return `${user.enabled}, ${user.username}`;
            case 'role':
                return `${user.role}, ${user.username}`;
            case 'username':
            default:
                return `${user.username}`;
        }
    };

    const formatData = (filteredUsers: IManagedUser[]) => {
        const filteredUsersChecked = Array.isArray(filteredUsers) ? filteredUsers : [];
        Logger.debug(`Called formatData to process this user information: ${JSON.stringify({ managedUsers })}`);
        const compare = function (userA: IManagedUser, userB: IManagedUser) {
            const order = sortDirection === 'descending' ? -1 : 1;
            if (formatSortColumn(userA) > formatSortColumn(userB)) {
                return order * 1;
            }
            if (formatSortColumn(userA) < formatSortColumn(userB)) {
                return order * -1;
            }
            return 0;
        };

        const sortedUsers = filteredUsersChecked.sort(compare);

        const startPos = (currentPage - 1) * USERS_PER_PAGE;
        const endPos = currentPage * USERS_PER_PAGE;

        const pageUsers: IManagedUser[] = sortedUsers.slice(startPos, endPos);

        const tableData = new TableData(pageUsers, [
            {
                title: t('username'),
                uniqueId: 'username',
                format: (pageUsers: IManagedUser) => {
                    return (
                        <Link type={'primary'} href={getPath(SiteMapPage.editUser, { username: pageUsers.username })}>
                            {t.cognate(pageUsers.username)}
                        </Link>
                    );
                },
            },
            {
                title: t('name'),
                uniqueId: 'name',
                format: (pageUsers: IManagedUser) =>
                    t('userList-nameFormat', { familyName: pageUsers.familyName, givenName: pageUsers.givenName }),
            },
            { title: t('email'), uniqueId: 'email', format: 'email' },
            {
                title: t('userStatus-columnHeader'),
                help: (
                    <HelpTablePopover
                        header={t('userStatusHelpItemsHelpHeader')}
                        dataTestId={'userStatusHelpPopover'}
                        tableRowItems={userStatusHelpItems}
                    />
                ),
                uniqueId: 'userStatus',
                format: (pageUser: IManagedUser) => t('userStatus-columnValue', { context: pageUser.userStatus }),
            },
            {
                title: t('resellers'),
                uniqueId: 'resellers',
                format: (pageUsers: IManagedUser) => formatResellers(pageUsers.resellers),
                maxWidth: 40,
            },
            {
                title: t('enabled'),
                uniqueId: 'enabled',
                format: (pageUser: IManagedUser) => (pageUser.enabled ? t('userList-yes') : t('userList-no')),
            },
            {
                title: t('role'),
                uniqueId: 'role',
                format: (pageUsers: IManagedUser) => formatRole(pageUsers.role),
                maxWidth: 40,
            },
        ]);
        return tableData;
    };

    const formatRole = (roleId: string) => {
        return validRoles[roleId] ? t(`roles:${roleId}`) : roleId;
    };

    function sortResellers(): IAuthorizedReseller[] {
        const resCompare = function (resellerA: IAuthorizedReseller, resellerB: IAuthorizedReseller) {
            if (
                `${resellerA.name}, ${resellerA.resellerId}`.toLowerCase() >
                `${resellerB.name}, ${resellerB.resellerId}`.toLowerCase()
            ) {
                return 1;
            }
            if (
                `${resellerA.name}, ${resellerA.resellerId}`.toLowerCase() <
                `${resellerB.name}, ${resellerB.resellerId}`.toLowerCase()
            ) {
                return -1;
            }
            return 0;
        };
        const authResellers: IAuthorizedReseller[] = authorizedResellers?.value ? authorizedResellers.value : [];

        const sortedResellers = authResellers.sort(resCompare);

        return [...BLANK_RESELLERS, ...sortedResellers];
    }

    const sortedResellers: IAuthorizedReseller[] = sortResellers();

    const formatResellers = (resellers: any[]) => {
        const resellerNames = resellers.map((userReseller) => {
            const reseller = sortedResellers.find((curReseller) => curReseller.resellerId === userReseller.id);
            return reseller?.name ?? userReseller.id;
        });
        return resellerNames;
    };

    const managedUsersList: IManagedUser[] = managedUsers || [];
    const filteredUsers: IManagedUser[] =
        curSearchValue || curSelectedReseller ? managedUsersList.filter(foundUsers) : managedUsersList;
    const remainder = filteredUsers.length % USERS_PER_PAGE;
    const numberOfPages = (filteredUsers.length - remainder) / USERS_PER_PAGE + (remainder > 0 ? 1 : 0);

    const data = formatData(filteredUsers);

    const errorMsg: TranslatedString = error ? t('userList.retrieveErrorMsg') : t.cognate('');
    const pageHeader = `${t('user-list', { count: managedUsersList.length })} ${
        status === 'Loading' || status === 'Uninitialized' ? t('loading') : ''
    }`;

    return (
        <BorderedColumn alignmentHorizontal={'center'} spacing={'none'}>
            <GreyBox type={'fill'} spacingInset={'small'} width={'100%'}>
                <Heading level={5} alignment={'left'} data-testid={'userListPageHeader'}>
                    {pageHeader}
                </Heading>
                <Text>{errorMsg}</Text>
            </GreyBox>
            <PaginatedTable
                data={data}
                dataTestId={'userListTable'}
                currentPage={currentPage}
                numberOfPages={numberOfPages}
                onPageChange={onPageClick}
                onSort={onSort}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                rowKey={(rowArray: string[], rowObject: IManagedUser) => {
                    return rowObject.username;
                }}
            >
                <Box width={'80%'} spacingInset={'none'} data-testid={'UserListSearchBox'}>
                    <Row spacing={'small'}>
                        <SearchField
                            value={curSearchValue}
                            onChange={onSearch}
                            label={t('searchBar-placeholder')}
                            onSubmit={onSearch}
                            size={'medium'}
                            searchButton={false}
                            data-testid={'userList.searchField'}
                        />
                        <Select
                            value={curSelectedReseller}
                            onChange={selectedResellerChanged}
                            label={t('selectReseller-placeholder')}
                            width={300}
                            data-testid={'UserListSearchResellerSelect'}
                        >
                            {sortedResellers.map((reseller: IAuthorizedReseller) => (
                                <SelectOption
                                    value={reseller.resellerId}
                                    label={reseller.name}
                                    key={reseller.resellerId}
                                />
                            ))}
                        </Select>
                        <Text data-testid={'UserListSearchResultText'}>
                            {t('matches', { count: filteredUsers.length })}
                        </Text>
                    </Row>
                </Box>
            </PaginatedTable>
        </BorderedColumn>
    );
};

const mapStateToProps = ({ resellerReducer, systemReducer, userReducer, managedUserReducer }: RootState) => {
    return {
        authorizedResellers: resellerReducer.authorizedResellers,
        validRoles: systemReducer.roles as Record<string, IRole>,
        adminUser: userReducer.user as IUser,
        managedUsers: managedUserReducer.managedUsers.value,
        status: managedUserReducer.managedUsers.status,
        error: managedUserReducer.managedUsers.error,
    };
};

const mapDispatchToProps = { getManagedUsers, getAuthorizedResellers };

const connector = connect(mapStateToProps, mapDispatchToProps);
export type UserListPageProps = ConnectedProps<typeof connector>;
export default connector(UserListPage);
