import { Location } from 'history/index';
import _ from 'lodash';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteProps } from 'react-router';
import { Redirect, Route as ReactRoute, useLocation } from 'react-router-dom';
import { RootState } from '../../redux/types';
import { Debug } from '../../utils/debug';
import { Logger } from '../../utils/logger';
import { checkPermission, PermissionCheckResult } from '../../utils/security';
import { URLHelper } from '../../utils/URLHelper';

type ProtectedRouteProps = ConnectedProtectedRouteProps &
    RouteProps & {
        signInPath?: string;
        permissionDeniedPath?: string;
        path: string | string[];
        resource?: string;
    };

const DEFAULT_SIGN_IN_PATH = '/account/sign-in';
const DEFAULT_PERMISSION_DENIED_PATH = '/errors/access-denied';

function getRouteContent<T>(
    permissionCheckResult: PermissionCheckResult,
    location: Location<unknown>,
    signInPath: string,
    permissionDeniedPath: string,
    children: T
) {
    switch (permissionCheckResult) {
        case 'signIn':
            return (
                <Redirect
                    to={{
                        pathname: signInPath,
                        search: `redirect=${encodeURIComponent(URLHelper.toRelativeURL(location))}`,
                    }}
                    push={true}
                />
            );
        case 'permissionDenied':
            return <Redirect to={permissionDeniedPath} push={true} />;
        case 'allowed':
            return children;
        default:
            Logger.error(`Unexpected permission check result: <${permissionCheckResult}>.`);
            return 'permissionDenied';
    }
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
    const location = useLocation();
    const { children, path, permissionDeniedPath, resource, signInPath, user } = props;

    Debug.assert(
        () => !Array.isArray(props.path) || props.path.length > 0,
        'ProtectedRoute path property must be either a non-empty array, or a string.'
    );

    Debug.assert(() => !user || (user.roles && user.roles.length > 0), "User's roles must be a non-empty array.");

    const resourceIds: string[] = resource ? [resource] : _.flatten([path]);
    const permissionCheckResult = checkPermission(resourceIds, user, 'page');
    Logger.debug(
        '[Authorization]',
        'Permission check performed. User:',
        user,
        'resource ids:',
        resourceIds,
        'result:',
        permissionCheckResult
    );

    const routeContent = getRouteContent(
        permissionCheckResult,
        location,
        signInPath || DEFAULT_SIGN_IN_PATH,
        permissionDeniedPath || DEFAULT_PERMISSION_DENIED_PATH,
        children
    );
    return React.createElement(ReactRoute, props, routeContent);
};

const mapStateToProps = ({ userReducer: { user } }: RootState) => ({
    user,
});

const connector = connect(mapStateToProps);
type ConnectedProtectedRouteProps = ConnectedProps<typeof connector>;
const ConnectedProtectedRoute = connector(ProtectedRoute);

export { ConnectedProtectedRoute as ProtectedRoute };
