import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Divider from '@amzn/meridian/divider';
import Loader from '@amzn/meridian/loader';
import Popover, { PopoverHeader } from '@amzn/meridian/popover';
import Row from '@amzn/meridian/row';
import React, { useEffect, useRef, useState } from 'react';
import { useHrpsApi } from '../../clients/useHrpsApi';
import { useSiteMapRouter } from '../../utils/SiteMapRouter';
import { Button, useTranslation, Heading } from '../blocks/localization';
import { usePageMessaging } from '../composites';
import { Delighted, Disappointed, Satisfied } from '../icons';
import { DelightAdditionalFeedbackPopover } from './DelightAdditionalFeedbackPopover';

export interface DelightProps {
    username: string;
    resellerId: string;
    dataTestId?: string;
}

type SentimentType = 'DELIGHTED' | 'SATISFIED' | 'DISAPPOINTED';

const Delight = ({ username, resellerId, dataTestId }: DelightProps) => {
    const { t } = useTranslation('delight');
    const buttonRef = useRef();
    const [isSentimentSubmitted, setIsSentimentSubmitted] = useState(false);
    const [issueId, setIssueId] = useState<string>();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { issues } = useHrpsApi();
    const { showSuccess, showError } = usePageMessaging();
    const { currentPage } = useSiteMapRouter();

    useEffect(() => {
        setIsSentimentSubmitted(false);
    }, [currentPage]);

    const onClickButton = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
        setIssueId(undefined);
    };

    async function submitSentiment(sentiment: SentimentType) {
        setIsLoading(true);
        try {
            const createIssueOutput = await issues.createIssue({
                resellerId: resellerId,
                issueBody: `Submitted by user: ${username}\nSubmitted from page: ${currentPage}`,
                issueType: 'DELIGHT_ISSUE',
                sentiment: sentiment,
            });
            setIssueId(createIssueOutput.issueId);
            setIsSentimentSubmitted(true);
            showSuccess(t('submitFeedback-successMessage'));
        } catch {
            showError(t('submitFeedback-errorMessage'));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <Button
                onClick={onClickButton}
                ref={buttonRef}
                type={'icon'}
                data-testid={`${dataTestId}.openPopoverButton`}
                label={t('provideFeedback-buttonLabel')}
            >
                <Box>
                    <Delighted />
                    <Disappointed />
                </Box>
            </Button>
            <Popover
                anchorNode={buttonRef.current}
                open={open}
                onClose={onClose}
                position={'bottom'}
                alignment={'right'}
                data-testid={`${dataTestId}.popover`}
            >
                <PopoverHeader closeLabel={'Close'} data-testid={`${dataTestId}.popoverHeader`}>
                    {t('popover-headerText')}
                </PopoverHeader>
                <Column spacing={'medium'} spacingInset={'small'}>
                    <Divider />
                    {!isSentimentSubmitted && !isLoading && (
                        <>
                            <Heading level={6}>{t('iconSelect-headingText')}</Heading>
                            <Row spacing={'large'}>
                                <Column alignmentHorizontal={'center'}>
                                    <Button
                                        type={'icon'}
                                        onClick={() => submitSentiment('DELIGHTED')}
                                        data-testid={`${dataTestId}.delightedButton`}
                                    >
                                        <Delighted />
                                    </Button>
                                    {t('delighted-iconLabel')}
                                </Column>
                                <Column alignmentHorizontal={'center'}>
                                    <Button
                                        type={'icon'}
                                        onClick={() => submitSentiment('SATISFIED')}
                                        data-testid={`${dataTestId}.satisfiedButton`}
                                    >
                                        <Satisfied />
                                    </Button>
                                    {t('satisfied-iconLabel')}
                                </Column>
                                <Column alignmentHorizontal={'center'}>
                                    <Button
                                        type={'icon'}
                                        onClick={() => submitSentiment('DISAPPOINTED')}
                                        data-testid={`${dataTestId}.disappointedButton`}
                                    >
                                        <Disappointed />
                                    </Button>
                                    {t('disappointed-iconLabel')}
                                </Column>
                            </Row>
                        </>
                    )}
                    {isSentimentSubmitted && !isLoading && (
                        <Column spacing={'medium'}>
                            <Heading level={6}>{t('thankYou-headingText')}</Heading>
                            <DelightAdditionalFeedbackPopover
                                label={t('additionalFeedback-linkText')}
                                resellerId={resellerId}
                                username={username}
                                issueId={issueId!}
                                dataTestId={`${dataTestId}.additionalFeedbackPopover`}
                            />
                        </Column>
                    )}
                    {isLoading && (
                        <Column alignmentHorizontal={'center'}>
                            <Loader size={'small'} />
                        </Column>
                    )}
                </Column>
            </Popover>
        </>
    );
};

export { Delight };
