import { Reducer } from 'redux';
import { INITIAL_USER_STATE } from '../../constants';
import { CHANGE_USER_SESSION, CLEAR_USER_SESSION, UserActions } from '../actions/userActions';
import { IUserState } from '../types';

export const userReducer: Reducer<IUserState, UserActions> = (state = INITIAL_USER_STATE, action): IUserState => {
    switch (action.type) {
        case CHANGE_USER_SESSION:
            return {
                ...state,
                ...action.payload,
            };
        case CLEAR_USER_SESSION:
            return INITIAL_USER_STATE;
        default:
            return state;
    }
};
