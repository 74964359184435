import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import { RowProps, RowWidths } from '@amzn/meridian/row/row';
import styled from '@emotion/styled';
import React, { FC, PropsWithChildren } from 'react';
import { Tile } from '../composites';
import { TranslatedString } from './localization';

export type MultiColumnInfoBoxTileProps = { title: TranslatedString; widths?: RowWidths };

/**
 * Arranges given children in columns separated by a divider in a tile with a given title.
 */
export const MultiColumnInfoBoxTile: FC<MultiColumnInfoBoxTileProps> = ({ title, widths, children }) => {
    return (
        <Tile contentSpacingInset={'medium'} title={title} width={'100%'}>
            <MultiColumnInfoBox widths={widths}>{children}</MultiColumnInfoBox>
        </Tile>
    );
};

const VerticalDivider = styled.div`
    height: 100%;
    border-left: solid 1px lightgray;
`;

export type MultiColumnInfoBoxProps = Pick<RowProps, 'widths'>;

export const MultiColumnInfoBox: FC<PropsWithChildren<MultiColumnInfoBoxProps>> = (props) => {
    const { children } = props;
    const childrenCount = React.Children.count(children);
    const content = React.Children.map(children, (child, index) => {
        const childColumn = <Column spacingInset={'medium'}>{child}</Column>;
        return index < childrenCount - 1 ? (
            <Column>
                <Row width={'100%'} height={'100%'} widths={['fill', 'fit']} alignmentVertical={'stretch'}>
                    {childColumn}
                    <VerticalDivider />
                </Row>
            </Column>
        ) : (
            childColumn
        );
    });

    return (
        <Row {...props} alignmentVertical={'stretch'} spacing={'medium'} width={'100%'}>
            {content}
        </Row>
    );
};
