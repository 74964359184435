import Column from '@amzn/meridian/column';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import badInteriorPackaging from '../../../assets/badInteriorPackaging.png';
import badPackaging1 from '../../../assets/badPackaging1.png';
import badPackaging2 from '../../../assets/badPackaging2.png';
import checkMark from '../../../assets/checkMark.png';
import properInteriorPackaging from '../../../assets/properInteriorPackaging.png';
import properPackaging from '../../../assets/properPackaging.png';
import redX from '../../../assets/redX.png';
import { SiteHelpCallback } from '../../../constants';
import { ItemImage, Text, useTranslation } from '../../blocks';
import { IntroductionHelp } from './IntroductionHelp';

const Logo = styled.img`
    width: 30px;
    margin-right: 10em;
`;

const PackageImage = styled.img`
    object-fit: contain;
    height: 300px;
    margin-right: 10em;
`;

const ShippingAndPackagingRequirementsHelp: FC<SiteHelpCallback> = ({ callback }: SiteHelpCallback) => {
    const { t } = useTranslation(['shippingAndPackagingRequirementsHelp', 'siteHelpPopover']);

    return (
        <Column spacing={'medium'} maxWidth={'25em'}>
            <Row width={'100%'} widths={['85%', '15%']}>
                <Text type={'h200'} alignment={'center'}>
                    {t('shippingAndPackagingRequirementsHelp_Title')}
                </Text>
                <Link
                    onClick={() => {
                        callback(IntroductionHelp);
                    }}
                >
                    {t('siteHelpPopover:siteHelpPopover_helpHomePage')}
                </Link>
            </Row>
            <Text type={'b300'} alignment={'left'}>
                {t('shippingAndPackagingRequirementsHelp_introduction')}
            </Text>
            <Text type={'b300'} alignment={'left'}>
                {t('shippingAndPackagingRequirementsHelp_introParagraphOne')}
            </Text>
            <Text type={'b300'} alignment={'left'}>
                {t('shippingAndPackagingRequirementsHelp_introParagraphTwo')}
            </Text>
            <Row spacing={'medium'}>
                <Column width={'2%'} />
                <Column spacing={'xsmall'} width={'90%'}>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline1')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('shippingAndPackagingRequirementsHelp_consolidate')}
                        </Text>
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline2')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('shippingAndPackagingRequirementsHelp_printEmail')}
                        </Text>
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline3')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('shippingAndPackagingRequirementsHelp_labelCarton')}
                        </Text>
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline4')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('shippingAndPackagingRequirementsHelp_shipToLocation')}
                        </Text>
                    </Row>
                    <Text type={'b200'} alignment={'left'}>
                        {t('shippingAndPackagingRequirementsHelp_exteriorExample')}
                    </Text>
                    <Row
                        spacing={'small'}
                        alignmentVertical={'top'}
                        alignmentHorizontal={'left'}
                        widths={['50%', '50%']}
                    >
                        <Row widths={['30%', '70%']}>
                            <Column heights={['20%', '80%']}>
                                <Logo src={checkMark} />
                                <Text type={'b200'} alignment={'left'}>
                                    {t('shippingAndPackagingRequirementsHelp_properPackaging')}
                                </Text>
                            </Column>
                            <ItemImage src={properPackaging} />
                        </Row>
                        <Column heights={['50%', '50%']}>
                            <Row widths={['30%', '70%']}>
                                <Column>
                                    <Logo src={redX} />
                                    <Text type={'b200'} alignment={'left'}>
                                        {t('shippingAndPackagingRequirementsHelp_badPackaging')}
                                    </Text>
                                </Column>
                                <ItemImage src={badPackaging1} />
                            </Row>
                            <Row widths={['30%', '70%']}>
                                <Column>
                                    <Logo src={redX} />
                                    <Text type={'b200'} alignment={'left'}>
                                        {t('shippingAndPackagingRequirementsHelp_badPackaging')}
                                    </Text>
                                </Column>
                                <ItemImage src={badPackaging2} />
                            </Row>
                        </Column>
                    </Row>
                    <Text type={'b200'} alignment={'left'}>
                        {t('shippingAndPackagingRequirementsHelp_interiorExample')}
                    </Text>
                    <Row widths={['20%', '10%', '70%']}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('shippingAndPackagingRequirementsHelp_properInteriorPackaging')}
                        </Text>
                        <Logo src={checkMark} />
                        <PackageImage src={properInteriorPackaging} />
                    </Row>
                    <Row widths={['20%', '10%', '70%']}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('shippingAndPackagingRequirementsHelp_badInteriorPackaging')}
                        </Text>
                        <Logo src={redX} />
                        <PackageImage src={badInteriorPackaging} />
                    </Row>
                </Column>
            </Row>
        </Column>
    );
};

export { ShippingAndPackagingRequirementsHelp };
