import Column from '@amzn/meridian/column';
import Divider from '@amzn/meridian/divider';
import Modal from '@amzn/meridian/modal';
import Table, { TableCell, TableRow } from '@amzn/meridian/table';
import Textarea from '@amzn/meridian/textarea';
import React, { useCallback, useState } from 'react';
import { Logger } from '../../utils/logger';
import { Link, Text, TranslatedString, useTranslation } from '../blocks';

export interface RequestPasswordHelpDialogProps {
    label: TranslatedString;
    usernameIssue?: boolean;
    dataTestId?: string;
}

const RequestPasswordHelpDialog = ({ label, usernameIssue, dataTestId }: RequestPasswordHelpDialogProps) => {
    const { t } = useTranslation('requestPasswordHelpDialog');
    const [open, setOpen] = useState(false);
    const onClickButton = useCallback(() => {
        Logger.debug('Opening registration dialog.');
        setOpen(true);
    }, []);
    const onClose = useCallback(() => setOpen(false), []);
    const isUsernameIssue = usernameIssue || false;

    return (
        <>
            <Link onClick={onClickButton} type={'secondary'} data-testid={`${dataTestId}.link`}>
                {label}
            </Link>
            <Modal open={open} onClose={onClose} title={label}>
                <Column spacing={'small'}>
                    <Text type={'h100'}>{t('requestPasswordHelp-passwordHelpSummary')}</Text>
                    <Table headerRows={0} showDividers={false} data-testid={'actionHelpTable'} spacing={'small'}>
                        <TableRow>
                            <TableCell width={'5%'}>
                                <Text type={'h100'}>{t.cognate('1')}</Text>
                            </TableCell>
                            <TableCell>
                                <Text>{t('requestPasswordHelp-passwordHelpStep1')}</Text>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell width={'5%'}>
                                <Text type={'h100'}>{t.cognate('2')}</Text>
                            </TableCell>
                            <TableCell>
                                <Text>{t('requestPasswordHelp-passwordHelpStep2')}</Text>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell width={'5%'}>
                                <Text type={'h100'}>{t.cognate('3')}</Text>
                            </TableCell>
                            <TableCell>
                                <Text>{t('requestPasswordHelp-passwordHelpStep3')}</Text>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell width={'5%'}>
                                <Text type={'h100'}>{t.cognate('4')}</Text>
                            </TableCell>
                            <TableCell>
                                <Text>{t('requestPasswordHelp-passwordHelpStep4')}</Text>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell width={'5%'}>
                                <Text type={'h100'}>{t.cognate('5')}</Text>
                            </TableCell>
                            <TableCell>
                                <Text>{t('requestPasswordHelp-passwordHelpStep5')}</Text>
                            </TableCell>
                        </TableRow>
                    </Table>
                    <Divider size={'small'} spacingBefore={'small'} />
                    <Column width={'100%'} spacing={'small'} spacingInset={'none'}>
                        <Textarea
                            label={t('requestPasswordHelp-sendToEmailAddressLabel')}
                            value={t('requestPasswordHelp-sendToEmailAddress')}
                            rows={1}
                            onChange={() => {}}
                        />
                    </Column>
                    <Column width={'100%'} spacing={'small'} spacingInset={'none'}>
                        <Textarea
                            label={t('requestPasswordHelp-subjectLabel')}
                            value={
                                isUsernameIssue ? t('requestUsernameHelp-subject') : t('requestPasswordHelp-subject')
                            }
                            rows={1}
                            onChange={() => {}}
                        />
                    </Column>
                    <Column width={'100%'} spacing={'small'} spacingInset={'none'}>
                        <Textarea
                            label={t('requestPasswordHelp-bodyLabel')}
                            value={t('requestPasswordHelp-body')}
                            onChange={() => {}}
                        />
                    </Column>
                </Column>
            </Modal>
        </>
    );
};

export { RequestPasswordHelpDialog };
