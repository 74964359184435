import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from '../blocks';

const Svg = styled.svg`
    width: 16px;
    height: 16px;
    color: green;
`;

export const StatusStopped = () => {
    const { t } = useTranslation('common');
    return (
        <Svg width={'16px'} height={'16px'} aria-label={t('icon-statusStoppedLabel')}>
            <g id={'icon'}>
                <g>
                    <circle fill={'none'} stroke={'currentColor'} strokeWidth={'2px'} cx={'8'} cy={'8'} r={'7'} />
                    <line
                        fill={'none'}
                        stroke={'currentColor'}
                        strokeWidth={'2px'}
                        x1={'11'}
                        y1={'8'}
                        x2={'5'}
                        y2={'8'}
                    />
                </g>
            </g>
        </Svg>
    );
};
