import React from 'react';
import { Text, useTranslation } from '../../components/blocks';
import { HelpPopover } from '../../components/constructed';

const ReturnOrderTypeHelpPopover = () => {
    const { t } = useTranslation('createReturnOrderPage');
    return (
        <HelpPopover header={t('returnOrderType-helpHeader')}>
            <Text tag={'ul'}>
                <li>{t('returnOrderType-returnExplanation')}</li>
                <li>{t('returnOrderType-buybackExplanation')}</li>
                <li>{t('returnOrderType-refusalExplanation')}</li>
            </Text>
        </HelpPopover>
    );
};

export { ReturnOrderTypeHelpPopover };
