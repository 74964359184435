import Column from '@amzn/meridian/column';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import React, { FC } from 'react';
import mailImage from '../../../assets/WelcomeLetterImage.png';
import { SiteHelpCallback } from '../../../constants';
import { ItemImage, Text, useTranslation } from '../../blocks';
import { IntroductionHelp } from './IntroductionHelp';

const UserSetupHelp: FC<SiteHelpCallback> = ({ callback }: SiteHelpCallback) => {
    const { t } = useTranslation(['userSetupHelp', 'siteHelpPopover']);

    return (
        <Column spacing={'medium'} maxWidth={'25em'}>
            <Row width={'100%'} widths={['85%', '15%']}>
                <Text type={'h200'} alignment={'center'}>
                    {t('userSetupHelp_Title')}
                </Text>
                <Link
                    onClick={() => {
                        callback(IntroductionHelp);
                    }}
                >
                    {t('siteHelpPopover:siteHelpPopover_helpHomePage')}
                </Link>
            </Row>
            <Text type={'b300'} alignment={'left'}>
                {t('userSetupHelp_purpose')}
            </Text>
            <Row spacing={'medium'}>
                <Column width={'2%'} />
                <Column spacing={'xsmall'} width={'90%'}>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline1')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('userSetupHelp_accountManagerInvite')}
                        </Text>
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline2')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('userSetupHelp_inviteEmailReceive')}
                        </Text>
                    </Row>
                    <Row>
                        <ItemImage src={mailImage} />
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline3')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('userSetupHelp_promptedToChangePassword')}
                        </Text>
                    </Row>
                </Column>
            </Row>
        </Column>
    );
};

export { UserSetupHelp };
