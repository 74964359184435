import Column from '@amzn/meridian/column';
import React, { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation, WaitFor } from '../components/blocks';
import { Tab } from '../components/blocks/Tab';
import { ReturnOrderAuditHistory, ReturnOrderProblemShelf, usePageMessaging } from '../components/composites';
import ReturnOrderInfoBox from '../components/composites/ReturnOrderInfoBox';
import ReturnOrderItemList from '../components/composites/ReturnOrderItemList';
import { TabGroup } from '../components/composites/TabGroup';
import { getResellerDetails, getResellerItems } from '../redux/actions/resellerActionCreators';
import { getReturnOrder } from '../redux/actions/returnOrderActionCreators';
import { getReturnOrderAuditHistory } from '../redux/actions/returnOrderAuditHistoryActionCreators';
import { getReturnOrderProblemShelf } from '../redux/actions/returnOrderProblemShelfActionCreators';
import { RootState, ReturnOrder, ResellerItem } from '../redux/types';
import { Logger } from '../utils/logger';

const ReturnOrderViewPage: FC<ReturnOrderViewPageProps> = ({
    resellerId,
    resellerDetails,
    resellerItems,
    getResellerDetails,
    getResellerItems,
    getReturnOrder,
    getReturnOrderAuditHistory,
    getReturnOrderProblemShelf,
    returnOrder,
    returnOrderAuditHistory,
    returnOrderProblemShelf,
    stage,
}) => {
    const { t } = useTranslation(['returnOrder', 'selectResellerPage']);
    const pageMessaging = usePageMessaging();
    const [hasErrorBeenReportedResellerDetails, setHasErrorBeenReportedResellerDetails] = useState<boolean>(false);
    const [hasErrorBeenReportedResellerItems, setHasErrorBeenReportedResellerItems] = useState<boolean>(false);

    const { returnOrderId } = useParams<{ returnOrderId: string }>();

    useEffect(() => {
        if (resellerId) {
            switch (resellerDetails.status) {
                case 'Uninitialized':
                    getResellerDetails(resellerId);
                    setHasErrorBeenReportedResellerDetails(false);
                    break;
                case 'Failed':
                    Logger.error('getResellerDetails error: ', resellerDetails.error);
                    if (!hasErrorBeenReportedResellerDetails) {
                        pageMessaging.showError(t('selectResellerPage:failedToLoadResellerDetails-errorMessage'));
                        setHasErrorBeenReportedResellerDetails(false);
                    }
                    break;
                default:
                // do nothing
            }
        }
    }, [getResellerDetails, hasErrorBeenReportedResellerDetails, pageMessaging, resellerDetails, resellerId, t]);

    useEffect(() => {
        if (resellerId) {
            switch (resellerItems.status) {
                case 'Uninitialized':
                    getResellerItems(resellerId);
                    setHasErrorBeenReportedResellerItems(false);
                    break;
                case 'Failed':
                    Logger.error('getResellerItems error: ', resellerItems.error);
                    if (!hasErrorBeenReportedResellerItems) {
                        pageMessaging.showError(t('selectResellerPage:failedToLoadResellerItems-errorMessage'));
                        setHasErrorBeenReportedResellerItems(true);
                    }
                    break;
                default:
                // do nothing
            }
        }
    }, [resellerItems, resellerId, getResellerItems, pageMessaging, t, hasErrorBeenReportedResellerItems]);

    useEffect(() => {
        if (resellerId) {
            getReturnOrder(returnOrderId, resellerId);
        }
    }, [returnOrderId, resellerId, getReturnOrder]);

    useEffect(() => {
        if (resellerId && returnOrderId) {
            getReturnOrderAuditHistory(returnOrderId, resellerId);
        }
    }, [returnOrderId, resellerId, getReturnOrderAuditHistory]);

    useEffect(() => {
        if (resellerId && returnOrderId) {
            getReturnOrderProblemShelf(returnOrderId);
        }
    }, [returnOrderId, resellerId, getReturnOrderProblemShelf]);

    const getRPTabGroup = (returnOrder: ReturnOrder, resellerItems: ResellerItem[]) => {
        if (stage === 'Beta') {
            return (
                <TabGroup initialTabId={'orderItems'}>
                    <Tab tabId={'orderItems'} label={t('orderItems-tabLabel', { count: returnOrder.lineItems.length })}>
                        <ReturnOrderItemList
                            resellerId={resellerId!}
                            returnOrderId={returnOrder.returnOrderId}
                            items={{ status: 'Loaded', value: returnOrder.lineItems }}
                            resellerItems={resellerItems}
                            returnType={returnOrder.returnType}
                        />
                    </Tab>
                    <Tab
                        tabId={'auditHistory'}
                        label={t('auditHistory-tabLabel', { count: returnOrderAuditHistory.value?.length })}
                    >
                        <ReturnOrderAuditHistory
                            auditItems={{
                                status: returnOrderAuditHistory.status,
                                value: returnOrderAuditHistory.value,
                            }}
                        />
                    </Tab>
                    <Tab
                        tabId={'problemShelf'}
                        label={t('problemShelf-tabLabel', { count: returnOrderProblemShelf.value?.length ?? 0 })}
                    >
                        <ReturnOrderProblemShelf
                            returnOrderProblemShelf={returnOrderProblemShelf}
                            resellerItems={resellerItems}
                        />
                    </Tab>
                </TabGroup>
            );
        } else {
            return (
                <TabGroup initialTabId={'orderItems'}>
                    <Tab tabId={'orderItems'} label={t('orderItems-tabLabel', { count: returnOrder.lineItems.length })}>
                        <ReturnOrderItemList
                            resellerId={resellerId!}
                            returnOrderId={returnOrder.returnOrderId}
                            items={{ status: 'Loaded', value: returnOrder.lineItems }}
                            resellerItems={resellerItems}
                            returnType={returnOrder.returnType}
                        />
                    </Tab>
                    <Tab
                        tabId={'auditHistory'}
                        label={t('auditHistory-tabLabel', { count: returnOrderAuditHistory.value?.length })}
                    >
                        <ReturnOrderAuditHistory
                            auditItems={{
                                status: returnOrderAuditHistory.status,
                                value: returnOrderAuditHistory.value,
                            }}
                        />
                    </Tab>
                </TabGroup>
            );
        }
    };

    return (
        <Column width={'100%'}>
            <h2>{t('returnOrder-pageTitle')}</h2>
            <WaitFor lazyValue={returnOrder}>
                {(returnOrder) => (
                    <Column width={'100%'}>
                        <WaitFor lazyValue={resellerDetails}>
                            {(resellerDetails) => (
                                <ReturnOrderInfoBox
                                    returnOrderInfo={returnOrder}
                                    resellerLocations={resellerDetails.resellerLocations}
                                />
                            )}
                        </WaitFor>
                        <WaitFor lazyValue={resellerItems}>
                            {(resellerItems) => getRPTabGroup(returnOrder, resellerItems)}
                        </WaitFor>
                    </Column>
                )}
            </WaitFor>
        </Column>
    );
};

const mapStateToProps = ({
    resellerReducer,
    returnOrderReducer,
    returnOrderAuditHistoryReducer,
    returnOrderProblemShelfReducer,
    systemReducer,
}: RootState) => {
    return {
        resellerId: resellerReducer.selectedReseller.value?.resellerId,
        resellerDetails: resellerReducer.resellerDetails,
        resellerItems: resellerReducer.resellerItems,
        returnOrder: returnOrderReducer.returnOrder,
        returnOrderAuditHistory: returnOrderAuditHistoryReducer.returnOrderAuditHistory,
        returnOrderProblemShelf: returnOrderProblemShelfReducer.returnOrderProblemShelf,
        stage: systemReducer.stage.value,
    };
};

const mapDispatchToProps = {
    getResellerDetails,
    getResellerItems,
    getReturnOrder,
    getReturnOrderAuditHistory,
    getReturnOrderProblemShelf,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReturnOrderViewPageProps = ConnectedProps<typeof connector>;
export default connector(ReturnOrderViewPage);
