import styled from '@emotion/styled';
import React from 'react';

const Svg = styled.svg`
    width: 16px;
    height: 16px;
    color: cyan;
`;

export const StatusInfo = () => (
    <Svg width={'16px'} height={'16px'}>
        <g id={'icon'}>
            <g>
                <path
                    fill={'currentColor'}
                    d={
                        'M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,14c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6 S11.3,14,8,14z'
                    }
                />
                <polygon fill={'currentColor'} points={'9,7 6,7 6,9 7,9 7,10 6,10 6,12 10,12 10,10 9,10 '} />
                <polygon fill={'currentColor'} points={'9,6 9,4 7,4 7,6 '} />
            </g>
        </g>
    </Svg>
);
