import _ from 'lodash';
import { Permission, ResourceId, ResourceType, rolesPermissions } from '../constants/permissions';
import { roles } from '../constants/roles';
import { IUser } from '../redux/types';
import { RoleId } from '../types/role';

export type PermissionCheckResult = 'allowed' | 'signIn' | 'permissionDenied';

// create a flat list of permissions by traversing role->includesRole tree. optionally filter by resource type.
export function getAllPermissions(
    roles: RoleId[],
    resourceType?: ResourceType,
    permissions: Permission[] = []
): Permission[] {
    return roles.reduce((sum: Permission[], roleId) => {
        const rolePemissionObject = rolesPermissions[roleId];
        if (!rolePemissionObject) {
            return permissions;
        }
        if (rolePemissionObject.permissions) {
            if (resourceType) {
                permissions.push(..._.filter(rolePemissionObject.permissions, { type: resourceType }));
            } else {
                permissions.push(...rolePemissionObject.permissions);
            }
        }
        if (rolePemissionObject.includesRoles) {
            // eslint-disable-next-line no-param-reassign
            permissions = getAllPermissions(rolePemissionObject.includesRoles, resourceType, permissions);
        }
        return permissions;
    }, permissions);
}

function checkSinglePermission(
    permission: Permission,
    resourceId: string,
    permissionResource: ResourceId = permission.resourceId
) {
    const caseNormalizedPermissionResource = permissionResource.toLowerCase();
    const caseNormalizedResourceId = resourceId.toLowerCase();

    // check exact match
    if (caseNormalizedPermissionResource === caseNormalizedResourceId) {
        return true;
    }
    // check mask match
    else if (
        caseNormalizedPermissionResource.endsWith('*') &&
        caseNormalizedResourceId.startsWith(
            caseNormalizedPermissionResource.substring(0, caseNormalizedPermissionResource.length - 1)
        )
    ) {
        return true;
    }
    // no match
    else {
        return false;
    }
}

// check if all of given resources are in the user's permission set. optionally filter permission set by resource type.
export function checkPermission(
    resourceIds: ResourceId[],
    user?: IUser,
    resourceType?: ResourceType
): PermissionCheckResult {
    const roleIds: RoleId[] = user?.roles ?? [roles.unauthenticatedUser.id];
    const allPermissions: Permission[] = getAllPermissions(roleIds, resourceType);
    const isAllowed = resourceIds.every((resourceId) =>
        allPermissions.some((permission) => checkSinglePermission(permission, resourceId))
    );

    if (isAllowed) {
        return 'allowed';
    } else if (user) {
        return 'permissionDenied';
    } else {
        return 'signIn';
    }
}

export function cognitoGroupsToRoleIds(groups: string[]): RoleId[] {
    return _.chain(groups)
        .map((group) => _.findKey(roles, { cognitoGroup: group }) as RoleId)
        .compact()
        .value();
}
