import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from '../blocks';

const Svg = styled.svg`
    width: 16px;
    height: 16px;
    color: green;
`;

export const StatusPositive = () => {
    const { t } = useTranslation('common');
    return (
        <Svg width={'16px'} height={'16px'} aria-label={t('icon-statusPositiveLabel')}>
            <g id={'icon'}>
                <g>
                    <path
                        fill={'currentColor'}
                        d={
                            'M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,14c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6 S11.3,14,8,14z'
                        }
                    />
                    <polygon fill={'currentColor'} points={'7,8.6 5,6.6 3.6,8 7,11.4 11.9,6.5 10.5,5.1 '} />
                </g>
            </g>
        </Svg>
    );
};
