import { CalculateComplianceInput, CalculateComplianceOutput } from '../types';
import { Logger } from '../utils/logger';
import { HrpsApiClient } from './HrpsApiClient';

export const calculateContractTermsCompliance = async (
    calculateComplianceInput: CalculateComplianceInput
): Promise<CalculateComplianceOutput> => {
    Logger.debug(JSON.stringify(calculateComplianceInput));

    return await new HrpsApiClient()
        .callHrps('calculateContractTermsCompliance', calculateComplianceInput)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            Logger.debug('Error calculating contract terms compliance: ', error.message);
            throw error;
        });
};
