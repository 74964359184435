import Row from '@amzn/meridian/row';
import React, { FC, useEffect } from 'react';
import { AmplifyClient } from '../clients/AmplifyClient';
import { useTranslation } from '../components/blocks/localization';

export const SignOutPage: FC = () => {
    const { t } = useTranslation('signOut');

    useEffect(() => {
        AmplifyClient.signOut();
    });

    return <Row alignmentHorizontal={'center'}>{t('signedOutToast')}</Row>;
};
