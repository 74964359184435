import Button from '@amzn/meridian/button';
import { ButtonProps } from '@amzn/meridian/button/button';
import Loader from '@amzn/meridian/loader';
import React from 'react';
import { localizeWithRef } from './localize';

type BusyButtonProps = ButtonProps & { isBusy?: boolean };
const BusyButton = React.forwardRef((props: BusyButtonProps, ref) => (
    <Button {...props} disabled={props.disabled || props.isBusy} ref={ref}>
        {props.children}
        {props.isBusy ? <Loader size={'small'} /> : null}
    </Button>
));

export default localizeWithRef(BusyButton, ['children', 'label']);
