import { i18n, StringMap, TOptions } from 'i18next';
import { useCallback } from 'react';
import { Namespace, useTranslation, UseTranslationOptions } from 'react-i18next';

export interface TranslatedStringBrand extends String {
    _translatedStringBrand: any;
}

// a nominal type that is treated as a string, but signifies having already been translated
export type TranslatedString = TranslatedStringBrand & string;

export interface TFunction {
    (key: string, options?: TOptions<StringMap>): TranslatedString;
}

export type TranslationFunction = TFunction & {
    /**
     * Returns given string as a translated string.
     * This is used for cases where text is the same in any language, e.g. username.
     * @param text text to be marked as translated.
     */
    cognate: (text: string) => TranslatedString;

    /**
     * An alias for `t` function, to be used for cases where we do not pass a literal constant expression to the `t`.
     * Following a convention to only use t with constant expression would allow us to detect orphan and missing
     * translations automatically.
     */
    expression: TFunction;
};

type UseTranslationResponseCustom = [TFunction, i18n, boolean] & {
    t: TranslationFunction;
    i18n: i18n;
    ready: boolean;
};

export const cognate = (text: string): TranslatedString => text as TranslatedString;

// object or array return types, but this maps best to the Meridian props we are translating (strings)
function useTranslationCustom(ns?: Namespace, options?: UseTranslationOptions): UseTranslationResponseCustom {
    const ret: any = useTranslation(ns, options);
    const { t } = ret;
    ret.t = useCallback(
        (key: string | string[], options?: TOptions<StringMap>): TranslatedString => {
            const res: string = t(key, options);
            return res as TranslatedString;
        },
        [t]
    );
    ret.t.cognate = cognate;
    ret.t.expression = ret.t;
    return ret as UseTranslationResponseCustom;
}

export { useTranslationCustom as useTranslation };
