import _ from 'lodash';
import { AttachedDocument } from '../redux/types';
import {
    CreateReturnOrderDraftInput,
    CreateReturnOrderDraftOutput,
    GetCreditMemoContentInput,
    GetCreditMemoContentOutput,
    GetReturnOrderUploadTemplatesOutput,
    SubmitReturnOrderInput,
    SubmitReturnOrderOutput,
    UpdateReturnOrderDraftInput,
    UpdateReturnOrderDraftOutput,
    RequestAttachmentUploadUrlOutput,
    ProcessReturnOrderUploadInput,
    ProcessReturnOrderUploadOutput,
    RequestReturnOrderUploadUrlInput,
    RequestReturnOrderUploadUrlOutput,
    RequestAttachmentUploadUrlInput,
    DeleteAttachmentsInput,
    DeleteAttachmentsOutput,
    DeleteReturnOrderDraftInput,
    ReturnOrderProblemShelfInput,
    ReturnOrderProblemShelfOutput,
} from '../types';
import { Logger } from '../utils/logger';
import { buildObjectMapper } from '../utils/objectMapper';
import { HrpsApiClient } from './HrpsApiClient';

export const getReturnOrderDocuments = async (returnOrderId: string): Promise<AttachedDocument[]> => {
    type AttachedDocumentDto = {
        id: string;
        type: string;
        name: string;
        description: string;
        uploadedAt: number;
        uploadedBy: string;
        presignedURL: string;
    };

    const returnOrderDocumentMapper = buildObjectMapper<AttachedDocumentDto, AttachedDocument>({
        uploadedAt: 'date',
    });

    // FIXME: replace HrpsApiClient with an instance export. -- 4/9/22
    const response = await new HrpsApiClient().callHrps('getReturnOrderDocuments', {
        returnOrderId,
    });
    // FIXME: no need to expose .data from the callHrps. That's an internal implementation. -- 4/9/22
    const mappedDocuments = _.map(response.data.attachedDocuments, (document) => returnOrderDocumentMapper(document));
    Logger.debug(mappedDocuments);
    return mappedDocuments;
};

export const createReturnOrderDraft = async (
    createReturnOrderDraftInput: CreateReturnOrderDraftInput
): Promise<CreateReturnOrderDraftOutput> => {
    return await new HrpsApiClient()
        .callHrps('createReturnOrderDraft', createReturnOrderDraftInput)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            Logger.debug('Error creating return order draft: ', error.message);
            throw error;
        });
};

export const deleteReturnOrderDraft = async (input: DeleteReturnOrderDraftInput) => {
    return await new HrpsApiClient()
        .callHrps('deleteReturnOrderDraft', input)
        .then((response) => {
            Logger.debug(`Received response for delete return order draft request:<${JSON.stringify(response.data)}>`);
            return response.data;
        })
        .catch((error) => {
            Logger.debug(`Error deleting return order draft: ${error.message}`);
            throw error;
        });
};

export const updateReturnOrderDraft = async (
    updateReturnOrderDraftInput: UpdateReturnOrderDraftInput
): Promise<UpdateReturnOrderDraftOutput> => {
    return await new HrpsApiClient()
        .callHrps('updateReturnOrderDraft', updateReturnOrderDraftInput)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            Logger.debug('Error updating return order draft: ', error.message);
            throw error;
        });
};

export const submitReturnOrder = async (
    submitReturnOrderInput: SubmitReturnOrderInput
): Promise<SubmitReturnOrderOutput> => {
    return await new HrpsApiClient()
        .callHrps('submitReturnOrder', submitReturnOrderInput)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            Logger.debug('Error submitting return order: ', error.message);
            throw error;
        });
};

export const getReturnOrderProblemShelf = async (
    getProblemShelfInput: ReturnOrderProblemShelfInput
): Promise<ReturnOrderProblemShelfOutput> => {
    return await new HrpsApiClient()
        .callHrps('getRMAProblemShelfItems', getProblemShelfInput)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            Logger.debug('Error getting return order problem shelf: ', error.message);
            throw error;
        });
};

export const requestReturnOrderUploadUrl = async (
    requestReturnOrderUploadUrlInput: RequestReturnOrderUploadUrlInput
): Promise<RequestReturnOrderUploadUrlOutput> => {
    return await new HrpsApiClient()
        .callHrps('requestReturnOrderUploadUrl', requestReturnOrderUploadUrlInput)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            Logger.debug('Error getting return order upload URL: ', error.message);
            throw error;
        });
};

export const processReturnOrderUpload = async (
    processReturnOrderUploadInput: ProcessReturnOrderUploadInput
): Promise<ProcessReturnOrderUploadOutput> => {
    Logger.info(processReturnOrderUploadInput);
    return await new HrpsApiClient()
        .callHrps('processReturnOrderUpload', processReturnOrderUploadInput)
        .then((response) => {
            Logger.debug(response.data);
            return response.data;
        })
        .catch((error) => {
            Logger.debug('Error processing return order upload: ', error.message);
            throw error;
        });
};

export const getReturnOrderUploadTemplates = async (): Promise<GetReturnOrderUploadTemplatesOutput> => {
    Logger.info('Calling getReturnOrderUploadTemplates');
    return await new HrpsApiClient()
        .callHrps('getReturnOrderUploadTemplates', {})
        .then((response) => {
            Logger.debug(response.data);
            return {
                templates: response.data.returnOrderUploadTemplates.map((rouTemplate: any) => {
                    return {
                        id: rouTemplate.id,
                        type: rouTemplate.type,
                        name: rouTemplate.title,
                        description: rouTemplate.description,
                        uploadedAt: new Date(rouTemplate.uploadedAt * 1000),
                        uploadedBy: rouTemplate.uploadedBy,
                        presignedURL: rouTemplate.url,
                    };
                }),
            } as GetReturnOrderUploadTemplatesOutput;
        })
        .catch((error) => {
            Logger.debug('Error getting return order Upload Template: ', error.message);
            throw error;
        });
};

export const requestAttachmentUploadUrl = async (
    input: RequestAttachmentUploadUrlInput
): Promise<RequestAttachmentUploadUrlOutput> => {
    return await new HrpsApiClient()
        .callHrps('requestAttachmentUploadUrl', input)
        .then((response) => {
            Logger.debug(`Received response for attachment upload url request:<${JSON.stringify(response.data)}>`);
            return response.data;
        })
        .catch((error) => {
            Logger.debug(`Error requesting upload url for attachments with error: ${error.message}`);
            throw error;
        });
};

export const deleteAttachments = async (input: DeleteAttachmentsInput): Promise<DeleteAttachmentsOutput> => {
    return await new HrpsApiClient()
        .callHrps('deleteAttachments', input)
        .then((response) => {
            Logger.debug(`Received response for delete attachments request:<${JSON.stringify(response.data)}>`);
            return response.data;
        })
        .catch((error) => {
            Logger.debug(`Error deleting attachments: ${error.message}`);
            throw error;
        });
};

export const getCreditMemoContent = async (
    getCreditMemoContentInput: GetCreditMemoContentInput
): Promise<GetCreditMemoContentOutput> => {
    Logger.info('Calling getCreditMemoContent');
    return await new HrpsApiClient()
        .callHrps('getCreditMemoContent', getCreditMemoContentInput)
        .then((response) => {
            Logger.debug(response);
            return {
                contentType: response.data.contentType,
                content: response.data.content,
            } as GetCreditMemoContentOutput;
        })
        .catch((error) => {
            Logger.debug('Error getting Credit Memo Content', error.message);
            throw error;
        });
};
