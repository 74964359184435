import Box from '@amzn/meridian/box';
import Row from '@amzn/meridian/row';
import _ from 'lodash';
import React, { PropsWithChildren, ReactElement, useEffect, useMemo, useState } from 'react';
import { TableData } from '../../redux/types';
import { Debug } from '../../utils/debug';
import { Logger } from '../../utils/logger';
import { PropsWithTestId } from '../../utils/testId';
import { SearchField, Text, TranslatedString, useTranslation } from '../blocks';
import { PaginatedTable, RowKeyBuilderFunction } from './PaginatedTable';

type SortDirection = 'ascending' | 'descending';
type Sort = { column: string; direction: SortDirection };

export const ALL_COLUMNS_TOKEN = '*';

export type ColumnFormat = {
    render?: (cell: any, row: any) => TranslatedString | ReactElement | string;
    search?: (cell: any, row: any) => string;
    sort?: (cell: any, row: any) => string;
};

export type SuperPaginatedTableColumn = {
    id?: string;
    title: TranslatedString;
    isKey?: boolean;
    isVisible?: boolean;
    sourceProperty: string;
    format?: ColumnFormat;
    maxWidth?: number;
    defaultSort?: SortDirection;
    searchable?: boolean;
};

export type SearchCriteria = { columns: string[]; value?: string; matchWholeString?: boolean };

type SuperPaginatedTableProps = PropsWithTestId<
    PropsWithChildren<{
        search?: string | SearchCriteria | SearchCriteria[];
        data: unknown[];
        filter?: (row: any) => boolean;
        layout: {
            rowKey?: string | string[] | RowKeyBuilderFunction<any>;
            searchPlaceholder?: TranslatedString;
            pageSize?: number;
            columns: SuperPaginatedTableColumn[];
        };
    }>
>;

type FormatFunction = (fieldValue: any, row: any) => string;

export const SuperPaginatedTable = ({
    children,
    data,
    dataTestId,
    filter,
    layout,
    search,
}: SuperPaginatedTableProps) => {
    const { t } = useTranslation('superPaginatedTable');

    Debug.assert(layout.columns.length > 0, 'At least one column is required.');
    if (typeof layout.pageSize === 'number' && layout.pageSize <= 0) {
        Logger.warn('{SuperPaginatedTable}', 'Page size is not positive: ', layout.pageSize);
        // disable pagination if invalid page size is given
        // eslint-disable-next-line no-param-reassign
        layout.pageSize = undefined;
    }

    const rowKey: string | RowKeyBuilderFunction<any> = useMemo(() => {
        const COMPOSITE_KEY_DELIMITER = '##';
        if (typeof layout.rowKey === 'string') {
            return layout.rowKey;
        } else if (Array.isArray(layout.rowKey)) {
            return (row: any) => {
                return _.chain(row).pick(row, layout.rowKey).values().value().join(COMPOSITE_KEY_DELIMITER);
            };
        } else {
            const keyColumns = _.filter(layout.columns, { isKey: true });
            Debug.assert(
                keyColumns.length > 0,
                `{SuperPaginatedTable${
                    dataTestId ? `:${dataTestId}` : ''
                }} Neither layout.rowKey property nor isKey on at least one column was specified.`
            );
            return (rowAsArray: string[], rowAsObject: any) =>
                keyColumns.map((column) => rowAsObject[column.sourceProperty]).join(COMPOSITE_KEY_DELIMITER);
        }
    }, [layout.rowKey, layout.columns, dataTestId]);

    const { tableDataLayout, defaultSort, columnMap } = useMemo(() => {
        let defaultSort: Sort | undefined;

        const columnMap: Record<string, SuperPaginatedTableColumn> = {};
        let firstColumnId: string | undefined;

        const tableDataLayout = _.chain(layout.columns)
            .filter((column) => column.isVisible ?? true)
            .map((column) => {
                const columnId = column.id ?? column.sourceProperty;
                firstColumnId = firstColumnId || columnId;
                if (column.defaultSort) {
                    if (defaultSort !== undefined) {
                        Logger.warn(
                            '{SuperPaginatedTable}',
                            'More than one column is marked as sorted by default. Only first default-sort column will be used. Offending column:',
                            column
                        );
                    } else {
                        defaultSort = { column: columnId, direction: column.defaultSort };
                    }
                }
                columnMap[columnId] = column;
                return {
                    title: column.title,
                    uniqueId: columnId,
                    format: (row: any) => {
                        const cellValue = row[column.sourceProperty];
                        return (column.format?.render ?? defaultFormat)(cellValue, row);
                    },
                    maxWidth: column.maxWidth,
                };
            })
            .value();

        // set default sort
        Debug.assertExists(firstColumnId, 'First column id is not set. (Does table have no columns?)');
        if (!defaultSort) {
            defaultSort = { column: firstColumnId, direction: 'ascending' };
        }

        return { tableDataLayout: tableDataLayout, defaultSort: defaultSort as Sort, columnMap };
    }, [layout]);

    const [currentPage, setCurrentPage] = useState(1);
    const [currentSort, setCurrentSort] = useState<Sort>(defaultSort);

    const [builtInSearchValue, setBuiltInSearchValue] = useState('');
    const onSearchChange = (newSearch: string) => {
        setBuiltInSearchValue(newSearch);
    };

    useEffect(() => {
        setBuiltInSearchValue('');
    }, [layout.searchPlaceholder]);

    // reset to page 1 every time builtInSearchValue criteria changes
    useEffect(() => {
        setCurrentPage(1);
    }, [search, builtInSearchValue, filter, data, layout.pageSize, layout.columns]);

    const onPageChange = (newPage: any) => {
        Debug.assert(typeof newPage === 'number', 'Page number is not a number. Actual type:', typeof newPage);
        setCurrentPage(newPage as number);
    };

    const onSort: (sort: Sort) => void = (sort) => {
        setCurrentSort(sort);
    };

    const defaultFormat: FormatFunction = (fieldValue) => (fieldValue !== undefined ? `${fieldValue}` : '');

    // The search applies OR logic *inside* a criterion, but AND logic
    // across criteria. E.g.:
    // foo,bar = 1; zap = 2 will be handled as
    // Show rows that have "1" in EITHER foo OR bar columns AND "2" in zap column.
    // However: * semantic is different. * only searches columns that are not included in any other search.
    //
    // E.g. if we have 4 searchable columns: foo, bar, zap, derp
    // and search criteria
    // [foo, bar] = "1"; * = "2"
    // will include rows where:
    // column foo OR bar has "1" AND zap, derp = "2"
    // i.e. * = [foo, bar, zap, derp].excluding(foo, bar)
    const searchRowPredicate = useMemo(() => {
        return (row: any) => {
            if (filter && !filter(row)) {
                return false;
            }

            let searchCriteria: SearchCriteria[] = [];
            if (typeof search === 'string') {
                searchCriteria = [{ columns: [ALL_COLUMNS_TOKEN], value: search }];
            } else if (Array.isArray(search)) {
                searchCriteria = search;
            } else if (typeof search === 'object') {
                searchCriteria = [search];
            }

            if (builtInSearchValue) {
                searchCriteria = [...searchCriteria, { columns: [ALL_COLUMNS_TOKEN], value: builtInSearchValue }];
            }

            // drop empty-searches criteria
            _.remove(searchCriteria, (criteria) => criteria.value === '' || criteria.value === undefined);
            const nonEmptySearchCriteria = searchCriteria as {
                columns: string[];
                value: string;
                matchWholeString?: boolean;
            }[];

            if (nonEmptySearchCriteria.length === 0) {
                return true;
            }

            const restSearchCriteria = _.remove(nonEmptySearchCriteria, (criteria) => {
                return criteria.columns.includes(ALL_COLUMNS_TOKEN);
            });
            Debug.assert(
                _.every(restSearchCriteria, (criteria) => criteria.columns.length === 1),
                'Rest token must be an only column in a builtInSearchValue criteria.'
            );

            // check if given value is found in the given field
            // note: terms "column", "columnId" and "field" are used interchangeably
            function searchInField(columnId: string, searchValue: string, matchWholeString?: boolean) {
                const column = columnMap[columnId];
                const fieldValue = row[column.sourceProperty];
                const searchableValue = (column.format?.search || column.format?.render || defaultFormat)(
                    fieldValue,
                    row
                );

                Debug.assert(
                    typeof searchableValue === 'string',
                    'Searchable value must be a string. If search-format function is not specified - render function will be used instead, in which case it must return a string. Actual searchable value:',
                    searchableValue
                );

                // todo for some reason searchableValue is coming in as a React component when there are no matches,
                // if we call the string methods on it below the whole site crashes, until we have time to figure out
                // why we get a React component, this check will keep the site from crashing
                if (typeof searchableValue !== 'string') return false;

                const searchableValueAsString = searchableValue as string;

                if (searchableValueAsString === '') {
                    return false;
                } else if (matchWholeString) {
                    return searchableValueAsString.toLowerCase() === searchValue.toLowerCase();
                } else {
                    return searchableValueAsString.toLowerCase().includes(searchValue.toLowerCase());
                }
            }

            // explicitly specified columns
            const explicitColumns = _.chain(nonEmptySearchCriteria).map('columns').flatten().value();
            const allSearchableColumns = _.chain(columnMap)
                .pickBy((column) => {
                    return column.searchable === undefined || column.searchable === true;
                })
                .keys()
                .value();

            // "rest" columns are the columns that need to be searched, but were not
            // included explicitly in any searches.
            const restColumns = _.difference(allSearchableColumns, explicitColumns);

            // re-introduce rest-search criteria after expanding ALL_COLUMNS_TOKEN
            // to "rest" columns (columns that haven't been searched explicitly).
            nonEmptySearchCriteria.push(
                ...restSearchCriteria.map((restSearchCriterion) => ({
                    columns: restColumns,
                    value: restSearchCriterion.value,
                    matchWholeString: restSearchCriterion.matchWholeString,
                }))
            );

            const searchResult = _.every(nonEmptySearchCriteria, (criteria) => {
                return _.some(criteria.columns, (columnId) => {
                    return searchInField(columnId, criteria.value, criteria.matchWholeString);
                });
            });
            return searchResult;
        };
    }, [filter, search, builtInSearchValue, columnMap]);

    const filteredData = useMemo(() => {
        const filteredData = _.filter(data, searchRowPredicate);
        return filteredData;
    }, [searchRowPredicate, data]);

    const sortedData = useMemo(() => {
        // Note: .sort method does sorting in place, so we have to clone the array before sorting
        // otherwise react won't trigger re-rendering.
        const sortedData = _.clone(filteredData).sort((rowA: any, rowB: any) => {
            return _.reduce(
                _.compact([currentSort, defaultSort]),
                (sum: number, sort: Sort) => {
                    const column = columnMap[sort.column];
                    const sortFunction = column.format?.sort || column.format?.render || defaultFormat;
                    const fieldValueA = rowA[column.sourceProperty];
                    const sortValueA = sortFunction(fieldValueA, rowA);
                    const fieldValueB = rowB[column.sourceProperty];
                    const sortValueB = sortFunction(fieldValueB, rowB);

                    Debug.assert(
                        typeof sortValueA === 'string' && typeof sortValueB === 'string',
                        'Sort-format function must return a string value (or if undefined - render function must return a string value).'
                    );
                    const sortValueAAsString = sortValueA as string;
                    const sortValueBAsString = sortValueB as string;

                    // note: .localeCompare returns one of { -1, 0, +1 } so we just need to multiply value by 2
                    // to implement precedence.
                    const PRECEDENCE_MULTIPLIER = 2;
                    return (
                        sum * PRECEDENCE_MULTIPLIER +
                        sortValueAAsString.localeCompare(sortValueBAsString) * (sort.direction === 'ascending' ? 1 : -1)
                    );
                },
                0
            );
        });
        return sortedData;
    }, [filteredData, currentSort, defaultSort, columnMap]);

    const paginatedData = useMemo(() => {
        if (layout.pageSize) {
            return _.slice(sortedData, (currentPage - 1) * layout.pageSize, (currentPage - 1 + 1) * layout.pageSize);
        } else {
            return sortedData;
        }
    }, [layout.pageSize, sortedData, currentPage]);

    const tableDataData: unknown[] = useMemo(() => paginatedData, [paginatedData]);

    const numberOfPages = useMemo(
        () => (layout.pageSize === undefined ? 1 : Math.ceil(filteredData.length / layout.pageSize)),
        [filteredData, layout.pageSize]
    );

    return (
        <PaginatedTable
            data={new TableData(tableDataData, tableDataLayout)}
            currentPage={currentPage}
            numberOfPages={numberOfPages}
            onPageChange={onPageChange}
            rowKey={rowKey}
            onSort={(sort) =>
                onSort({
                    column: sort.sortColumn,
                    direction: sort.sortDirection as SortDirection,
                })
            }
            sortColumn={currentSort.column}
            sortDirection={currentSort.direction}
            dataTestId={dataTestId}
        >
            <Box width={'80%'} spacingInset={'none'} data-testid={'UserListSearchBox'}>
                <Row spacing={'small'}>
                    {layout.searchPlaceholder ? (
                        <SearchField
                            clearButton={true}
                            value={builtInSearchValue}
                            onChange={onSearchChange}
                            onSubmit={onSearchChange}
                            label={layout.searchPlaceholder}
                            width={350}
                        />
                    ) : null}
                    {children}
                    <Text>{t('matches-searchResultMessage', { count: filteredData.length })}</Text>
                </Row>
            </Box>
        </PaginatedTable>
    );
};
