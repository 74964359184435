import { Reducer } from 'redux';
import { INITIAL_USER_TERMS_AND_CONDITIONS_ACCEPTANCE } from '../../constants';
import {
    TermsAndConditionsActions,
    USER_TERMS_AND_CONDITIONS_CLEAR,
    USER_TERMS_AND_CONDITIONS_FAILED,
    USER_TERMS_AND_CONDITIONS_LOADED,
    USER_TERMS_AND_CONDITIONS_LOADING,
    USER_TERMS_AND_CONDITIONS_VALIDATED,
} from '../actions/termsAndConditionsActions';
import { IUserTermsAndConditionsAcceptance, IUserTermsAndConditionsAcceptancesState } from '../types';

export const termsAndConditionsReducer: Reducer<IUserTermsAndConditionsAcceptancesState, TermsAndConditionsActions> = (
    state = INITIAL_USER_TERMS_AND_CONDITIONS_ACCEPTANCE,
    action
): IUserTermsAndConditionsAcceptancesState => {
    switch (action.type) {
        case USER_TERMS_AND_CONDITIONS_LOADED:
            return {
                ...state,
                userTermsAndConditionsAcceptances: {
                    value: action.payload.value.map((item) => {
                        return {
                            termsAndConditionsId: item.termsAndConditionsId,
                            region: item.region,
                            version: item.version,
                            username: item.username,
                            acceptanceDate: item.acceptanceDate,
                        } as IUserTermsAndConditionsAcceptance;
                    }),
                    error: undefined,
                    status: 'Loaded',
                },
            };
        case USER_TERMS_AND_CONDITIONS_LOADING:
            return {
                ...state,
                userTermsAndConditionsAcceptances: {
                    value: undefined,
                    error: undefined,
                    status: 'Loading',
                },
            };
        case USER_TERMS_AND_CONDITIONS_FAILED:
            return {
                ...state,
                userTermsAndConditionsAcceptances: {
                    value: undefined,
                    error: action.payload.error,
                    status: 'Failed',
                },
            };
        case USER_TERMS_AND_CONDITIONS_VALIDATED:
            return {
                ...state,
                validUserTermsAndConditions: action.payload,
            };
        case USER_TERMS_AND_CONDITIONS_CLEAR:
            return {
                ...state,
                validUserTermsAndConditions: false,
                userTermsAndConditionsAcceptances: {
                    value: undefined,
                    error: undefined,
                    status: 'Uninitialized',
                },
            };
        default:
            return state;
    }
};
