import { filterXSS } from 'xss';

export class SanitizeHtmlUtil {
    static sanitizeString(htmlString = ''): string {
        return filterXSS(htmlString);
    }

    static sanitizeObject(data: any) {
        if (Array.isArray(data)) {
            const sanitizedArray = [...data];
            data.forEach((value, index) => {
                // If type is object or array (recursively sanitize content)
                if (typeof value === 'object') {
                    sanitizedArray[index] = this.sanitizeObject(value);
                } else if (typeof value === 'string') {
                    sanitizedArray[index] = filterXSS(value); // Clean xss
                }
            });
            return sanitizedArray;
        } else if (data?.constructor?.name === 'Object') {
            const sanitizedObject = { ...data };
            Object.keys(data).forEach((key) => {
                // If type is object or array (recursively sanitize content)
                if (typeof data[key] === 'object') {
                    sanitizedObject[key] = this.sanitizeObject(data[key]);
                } else if (typeof data[key] === 'string') {
                    sanitizedObject[key] = filterXSS(data[key]); // Clean xss
                }
            });
            return sanitizedObject;
        }
        return {};
    }
}
