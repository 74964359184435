import { Reducer } from 'redux';
import { INITIAL_RETURN_ORDER_AUDIT_HISTORY_STATE } from '../../constants';
import {
    RETURN_ORDER_AUDIT_HISTORY_GET,
    RETURN_ORDER_AUDIT_HISTORY_LOADED,
    RETURN_ORDER_AUDIT_HISTORY_CLEAR,
    ReturnOrderAuditHistoryActions,
} from '../actions/returnOrderAuditHistoryActions';
import { ReturnOrderAuditHistoryState } from '../types';

export const returnOrderAuditHistoryReducer: Reducer<ReturnOrderAuditHistoryState, ReturnOrderAuditHistoryActions> = (
    state = INITIAL_RETURN_ORDER_AUDIT_HISTORY_STATE,
    action
): ReturnOrderAuditHistoryState => {
    switch (action.type) {
        case RETURN_ORDER_AUDIT_HISTORY_GET:
            return {
                ...state,
                returnOrderAuditHistory: {
                    value: action.payload.value,
                    error: action.payload.error,
                    status: action.payload.status,
                },
            };
        case RETURN_ORDER_AUDIT_HISTORY_LOADED:
            return {
                ...state,
                returnOrderAuditHistory: {
                    value: action.payload.value,
                    error: action.payload.error,
                    status: action.payload.status,
                },
            };
        case RETURN_ORDER_AUDIT_HISTORY_CLEAR:
            return {
                ...state,
                ...INITIAL_RETURN_ORDER_AUDIT_HISTORY_STATE,
            };
        default:
            return state;
    }
};
