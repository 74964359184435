import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from '../blocks';

const Svg = styled.svg`
    width: 16px;
    height: 16px;
    color: blue;
`;

export const Help = () => {
    const { t } = useTranslation('common');
    return (
        <Svg width={'16px'} height={'16px'} aria-label={t('icon-helpLabel')}>
            <g id={'icons'}>
                <path
                    fill={'currentColor'}
                    d={
                        'M8,5c0.6,0,1,0.4,1,1c0,0.1-0.3,0.4-0.5,0.7C8,7.1,7.4,7.7,7.1,8.7C7,8.8,7,8.9,7,9v1h2V9.2 ' +
                        'c0.2-0.4,0.5-0.7,0.9-1.1C10.4,7.6,11,7,11,6c0-1.7-1.3-3-3-3S5,4.3,5,6h2C7,5.4,7.4,5,8,5z'
                    }
                />
                <rect fill={'currentColor'} width={'2'} height={'2'} x={'7'} y={'11'} ry={'1'} />
                <path
                    fill={'currentColor'}
                    d={
                        'M15,0H1C0.4,0,0,0.4,0,1v14c0,0.6,0.4,1,1,1h14c0.6,0,1-0.4,1-1V1C16,0.4,15.6,0,15,0z M14,14H2V2h12V14z'
                    }
                />
            </g>
        </Svg>
    );
};
