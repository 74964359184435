import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import { TextColor } from '@amzn/meridian/text/text';
import React from 'react';
import { SITE_NOTIFICATIONS } from '../../constants';
import { SystemNotification } from '../../redux/types';
import { TranslatedString, useTranslation } from '../blocks';

const SiteNotifications = () => {
    const { t } = useTranslation(['siteNotifications']);
    const currDateTime = new Date().getTime();
    const notifications = SITE_NOTIFICATIONS.filter((systemNotification) => {
        return systemNotification.startDate < currDateTime && currDateTime < systemNotification.endDate;
    });

    return (
        <>
            {notifications.map((systemNotification: SystemNotification) => {
                const label: TranslatedString = t.expression(
                    systemNotification.notificationId,
                    systemNotification.options
                );
                let colorVal: TextColor = 'error';
                if (systemNotification.severity === 'warn') {
                    colorVal = 'secondary';
                }
                if (systemNotification.severity === 'info') {
                    colorVal = 'primary';
                }
                return (
                    <Row
                        width={'100%'}
                        spacing={'small'}
                        spacingInset={'small'}
                        backgroundColor={'yellow'}
                        key={`Column_${systemNotification.startDate}+${systemNotification.endDate}`}
                    >
                        <Text
                            type={'b300'}
                            color={colorVal}
                            key={`Text_${systemNotification.startDate}+${systemNotification.endDate}`}
                        >
                            {label}
                        </Text>
                    </Row>
                );
            })}
        </>
    );
};

export default SiteNotifications;
