import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import React, { FC, useState } from 'react';
import { Address } from '../../redux/types';
import { formatAddress } from '../../utils/addressHelper';
import { Alert, Select, SelectOption, TranslatedString } from '../blocks';

const SearchableAddressSelect: FC<SearchableAddressSelectProps> = ({
    label,
    addressMap,
    value,
    onChange,
    error,
    dataTestId,
}) => {
    const [query, setQuery] = useState('');

    const addressSearchFilter = (query: string, addresses: [string, Address][]) => {
        return addresses.filter(([_, address]) => formatAddress(address).toLowerCase().includes(query.toLowerCase()));
    };

    return (
        <Column spacing={'xsmall'}>
            <Select value={value} onChange={onChange} searchQuery={query} onSearch={setQuery} label={label}>
                {addressSearchFilter(query, Object.entries(addressMap)).map(([locationCode, address]) => (
                    <SelectOption key={locationCode} value={locationCode} label={formatAddress(address)} />
                ))}
            </Select>
            <Row>
                {error && (
                    <div data-testid={dataTestId && `${dataTestId}.validationError`}>
                        <Alert type={'error'} size={'small'}>
                            {error}
                        </Alert>
                    </div>
                )}
            </Row>
        </Column>
    );
};

export type SearchableAddressSelectProps = {
    label: TranslatedString;
    addressMap: Record<string, Address>;
    value: string | undefined;
    onChange?(value: string): void;
    error?: TranslatedString;
    dataTestId?: string;
};

export { SearchableAddressSelect };
