import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Expander from '@amzn/meridian/expander';
import Row from '@amzn/meridian/row';
import styled from '@emotion/styled';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { BorderedColumn, GreyBox, Heading, SearchField, Text, useTranslation } from '../components/blocks';
import { PaginatedTable } from '../components/constructed';
import { NEWS_ARTICLES, newsFeedImages } from '../constants';
import { TableData } from '../redux/types';
import { NewsBlock } from '../redux/types/newsPage';
import { Logger } from '../utils/logger';

const NewsImage = styled.img`
    width: 100%;
    height: 100%;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const StyledColumn = styled(Column)`
    div[role='button']:focus {
        outline: thin dotted;
    }
`;

export const NewsPage: any = () => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [sortColumn, setSortColumn] = useState<string>('name');
    const [sortDirection, setSortDirection] = useState<'ascending' | 'descending'>('ascending');
    const [curSearchValue, setCurSearchValue] = useState<string>('');

    const ARTICLES_PER_PAGE: number = 10;

    const { t } = useTranslation('newsPage');

    const newsBlocks = new Map();

    // Populates news blocks
    NEWS_ARTICLES.filter((articleFilter) => {
        return articleFilter.startDate < new Date().getTime();
    }).map((article) => {
        const section: NewsBlock = {
            title: article.articleTitleId,
            date: new Date(article.articleDate),
            content: {
                videoUrl: article.videoUrls,
                picturesByRow: article.picturesByRow,
                text: article.articleTextIds,
            },
        };
        newsBlocks.set(section.title, section);
        return section;
    });

    const ControlledExpander = (props: any) => {
        const [open, setOpen] = useState();
        return <Expander open={open} onChange={setOpen} {...props} />;
    };

    function foundNews(element: NewsBlock) {
        const lowerCurSearchValue = curSearchValue.toLowerCase();

        const found = curSearchValue && t(element.title)?.toLowerCase().includes(lowerCurSearchValue);

        return found;
    }

    const formatSortColumn = (newsBlock: NewsBlock) => {
        switch (sortColumn) {
            case 'title':
                return `${newsBlock.title}`;
            case 'date':
                return `${newsBlock.date}`;
            default:
                return `${newsBlock.title}`;
        }
    };

    const getNewsBlocks = (blocks: NewsBlock[]) => {
        const compare = function (articleA: NewsBlock, articleB: NewsBlock) {
            const order = (a: NewsBlock, b: NewsBlock) =>
                (sortDirection === 'descending' ? -1 : 1) * formatSortColumn(a).localeCompare(formatSortColumn(b));
            return order(articleA, articleB);
        };

        const sortedArticles = blocks.sort(compare);

        const startPos = (currentPage - 1) * ARTICLES_PER_PAGE;
        const endPos = currentPage * ARTICLES_PER_PAGE;

        const pageArticles: NewsBlock[] = sortedArticles.slice(startPos, endPos);

        const tableData = new TableData(pageArticles, [
            {
                title: t('titleHeader'),
                uniqueId: 'title',
                format: (block: NewsBlock) => {
                    return (
                        <StyledColumn>
                            <ControlledExpander title={`${t(block.title)}`} type={'section'} key={`${block.title}-key`}>
                                <Column width={'fill'} maxWidth={'65em'} spacing={'400'}>
                                    <Column width={'fill'} maxWidth={'65em'} spacing={'200'}>
                                        {block.content.text.map((newsText) => {
                                            return (
                                                <>
                                                    <Text type={'b200'} alignment={'left'} key={newsText.textId}>
                                                        {t(newsText.textId)}
                                                    </Text>
                                                    {newsText.subtextIds?.map((subTextId) => {
                                                        return (
                                                            <Row
                                                                widths={['2em', 'fill']}
                                                                spacing={'none'}
                                                                key={subTextId}
                                                            >
                                                                <Box />
                                                                <Text type={'b200'} alignment={'left'}>
                                                                    {t(subTextId)}
                                                                </Text>
                                                            </Row>
                                                        );
                                                    })}
                                                </>
                                            );
                                        })}
                                    </Column>
                                    {block.content.videoUrl &&
                                        block.content.videoUrl.map((videoUrl) => {
                                            return (
                                                <iframe
                                                    width={'560'}
                                                    height={'315'}
                                                    src={videoUrl}
                                                    title={'YouTube video player'}
                                                    frameBorder={'0'}
                                                    allow={
                                                        'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                                    }
                                                    allowFullScreen={true}
                                                    key={`${videoUrl}_${block.title}`}
                                                />
                                            );
                                        })}
                                    {block.content.picturesByRow &&
                                        block.content.picturesByRow.map((pictureRow) => {
                                            return (
                                                <Row
                                                    spacing={'xsmall'}
                                                    maxWidth={'65em'}
                                                    widths={[..._.fill(Array(pictureRow.length), 'fit')]}
                                                    key={pictureRow.toString()}
                                                >
                                                    {pictureRow.map((picture) => {
                                                        return (
                                                            <Column
                                                                maxWidth={`${(1 / pictureRow.length) * 100}%`}
                                                                key={picture}
                                                            >
                                                                <NewsImage
                                                                    src={
                                                                        newsFeedImages[
                                                                            picture as keyof typeof newsFeedImages
                                                                        ]
                                                                    }
                                                                    key={picture}
                                                                />
                                                            </Column>
                                                        );
                                                    })}
                                                </Row>
                                            );
                                        })}
                                </Column>
                            </ControlledExpander>
                        </StyledColumn>
                    );
                },
            },
            {
                title: t('dateHeader'),
                uniqueId: 'date',
                format: (block: NewsBlock) => (
                    <Text key={`${block.title} - ${block.date} - articleDate`}>
                        {t('dateFormat', { dateTime: block.date })}
                    </Text>
                ),
            },
        ]);
        return tableData;
    };

    const onPageClick = (value: string) => {
        setCurrentPage(parseInt(value));
    };

    const onSort = useCallback(({ sortColumn, sortDirection }) => {
        setSortColumn(sortColumn);
        setSortDirection(sortDirection);
    }, []);

    const onSearch = (value: string) => {
        Logger.debug(`onSearch: ${JSON.stringify({ value })}`);
        setCurSearchValue(value);
        setCurrentPage(1);
    };

    const newsBlocksList: NewsBlock[] = Array.from(newsBlocks.values());
    const filteredNewsBlocks: NewsBlock[] = curSearchValue ? newsBlocksList.filter(foundNews) : newsBlocksList;
    const remainder = filteredNewsBlocks.length % ARTICLES_PER_PAGE;
    const numberOfPages = (filteredNewsBlocks.length - remainder) / ARTICLES_PER_PAGE + (remainder > 0 ? 1 : 0);
    const data = getNewsBlocks(filteredNewsBlocks);

    return (
        <BorderedColumn alignmentHorizontal={'center'} alignmentVertical={'top'} minWidth={'80em'} spacing={'none'}>
            <GreyBox type={'fill'} spacingInset={'small'} width={'100%'}>
                <Heading level={5} alignment={'left'} data-testid={'NewsHeader'}>
                    {t('news-tableTitle', { count: newsBlocks.size })}
                </Heading>
            </GreyBox>
            <PaginatedTable
                currentPage={currentPage}
                data={data}
                numberOfPages={numberOfPages}
                onPageChange={onPageClick}
                onSort={onSort}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                rowKey={(rowArray: string[], rowObject: NewsBlock) => {
                    return rowObject.title.toString();
                }}
            >
                <Box width={'80%'} spacingInset={'none'} data-testid={'NewsListSearchBox'}>
                    <Row spacing={'small'}>
                        <SearchField
                            value={curSearchValue}
                            onChange={onSearch}
                            label={t('searchBar-placeholder')}
                            onSubmit={onSearch}
                            size={'medium'}
                            searchButton={false}
                            data-testid={'newsListPage.searchField'}
                        />
                    </Row>
                </Box>
            </PaginatedTable>
        </BorderedColumn>
    );
};
