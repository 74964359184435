import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from '../blocks';

const Svg = styled.svg`
    width: 16px;
    height: 16px;
    color: #008080;
`;

export const Edit = () => {
    const { t } = useTranslation('createReturnOrderPage');
    return (
        <Svg width={'16px'} height={'16px'} aria-label={t('contactEmailAddresses-buttonLabel')}>
            <g id={'icons'}>
                <polyline
                    stroke={'currentColor'}
                    strokeWidth={'2'}
                    fill={'none'}
                    strokeLinecap={'round'}
                    strokeLinejoin={'round'}
                    points={'13 8 13 14 3 14 3 2 9.5 2'}
                />
                <line
                    stroke={'currentColor'}
                    strokeWidth={'2'}
                    fill={'none'}
                    strokeLinecap={'round'}
                    x1={'6'}
                    y1={'10'}
                    x2={'14'}
                    y2={'2'}
                />
            </g>
        </Svg>
    );
};
