import React from 'react';
import { Select, SelectOption, TranslatedString } from '../../../blocks/localization';
import { MultilineFormEditorColumnFormat } from '../MultilineFormEditor';

export const selectFormat = (options: {
    selectOptions: { value: string; label: TranslatedString }[];
    filterOptions?: (selectOption: { value: string; label: TranslatedString }, item: any) => boolean;
    isDisabled?: boolean | ((item: any) => boolean);
    placeholder?: TranslatedString;
    label?: TranslatedString;
}): MultilineFormEditorColumnFormat => ({
    render: (item, column, lineIndex, onChange) => {
        let isDisabledValue = false;
        if (typeof options.isDisabled === 'boolean') {
            isDisabledValue = options.isDisabled;
        } else if (typeof options.isDisabled === 'function') {
            isDisabledValue = options.isDisabled(item);
        }
        return (
            <Select
                value={item[column.sourceProperty]}
                onChange={onChange(column.sourceProperty, lineIndex)}
                disabled={isDisabledValue}
                placeholder={options.placeholder}
                label={options.label}
            >
                {options.selectOptions
                    .filter((option) => !options.filterOptions || options.filterOptions(option, item))
                    .map((option) => (
                        <SelectOption key={option.label} value={option.value} label={option.label} />
                    ))}
            </Select>
        );
    },
});
