import styled from '@emotion/styled';

const ItemImage = styled.img`
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 25em;
`;

export { ItemImage };
