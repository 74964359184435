import styled from '@emotion/styled';
import React from 'react';

const Svg = styled.svg`
    width: 16px;
    height: 16px;
    color: grey;
`;

export const External = () => (
    <Svg width={'16px'} height={'16px'}>
        <g id={'icons'}>
            <polyline stroke={'currentColor'} strokeWidth={'2px'} fill={'none'} points={'10 2 14 2 14 6'} />
            <line stroke={'currentColor'} strokeWidth={'2px'} fill={'none'} x1={'6'} y1={'10'} x2={'14'} y2={'2'} />
            <polyline
                stroke={'currentColor'}
                strokeWidth={'2px'}
                fill={'none'}
                points={'14 9.048 14 14 2 14 2 2 7 2'}
            />
        </g>
    </Svg>
);
