import Row from '@amzn/meridian/row';
import React, { FC } from 'react';
import { ClaimStatus } from '../../redux/types';
import { ClaimProcessingError } from '../../redux/types/claimList';
import { Text, TranslatedString, useTranslation } from '../blocks/localization';
import { HelpPopover } from '../constructed';
import { StatusPending, StatusPositive, StatusNegative, StatusWarning } from '../icons';

type StatusIconProps = {
    status: string;
    errors?: ClaimProcessingError[];
};

export const ClaimStatusIcon: FC<StatusIconProps> = ({ status, errors }: StatusIconProps) => {
    const matchedStatus =
        status !== null && status !== undefined ? status.toUpperCase().replace(' ', '_') : ClaimStatus.ERRORED;
    const { t } = useTranslation('claimStatus');
    const text: TranslatedString = t('claimStatus-value', { context: matchedStatus });
    const determineIcon = (status: string) => {
        switch (status) {
            case ClaimStatus.CREATED:
            case ClaimStatus.APPROVED:
                return <StatusPositive />;
            case ClaimStatus.IN_PROGRESS:
                return <StatusPending />;
            case ClaimStatus.ERRORED:
                return <StatusNegative />;
            default:
                return <StatusWarning />;
        }
    };

    return (
        <Row width={'100%'}>
            {determineIcon(matchedStatus)}
            <Text>{text}</Text>
            {errors! && errors.length > 0 && (
                <HelpPopover header={t('error-popoverTitle')}>
                    <Text tag={'ul'}>
                        {errors!.map((error) => (
                            <li key={error.errorMessage}>{t(`error-${error.errorCode}`, error.parameters)}</li>
                        ))}
                    </Text>
                </HelpPopover>
            )}
        </Row>
    );
};
