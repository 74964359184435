import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import React, { FC, FormEvent, useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AmplifyClient, SignInError } from '../../clients';
import { clearData, setRedirectURL } from '../../redux/actions/systemActionCreators';
import { SiteMapPage } from '../../types';
import { Logger } from '../../utils/logger';
import { useSiteMapRouter } from '../../utils/SiteMapRouter';
import { URLHelper } from '../../utils/URLHelper';
import { useTranslation, Button, Link } from '../blocks';
import { LabeledInput, Tile, usePageMessaging } from '../composites';

const SignIn: FC<SignInProps> = ({ clearData, setRedirectURL }: SignInProps) => {
    const { t } = useTranslation(['signIn', 'validation', 'forms']);
    useHistory();
    const pageMessaging = usePageMessaging();
    const { goto, getPath } = useSiteMapRouter();

    const [username, setUsername] = useState('');
    const [usernameError, setUsernameError] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);

    const [isBusy, setIsBusy] = useState(false);

    let redirect = URLHelper.getQueryParam('redirect')!;
    if (redirect === SiteMapPage.signOut || redirect === null) {
        redirect = SiteMapPage.home;
    }
    useEffect(() => {
        clearData();
        setRedirectURL(redirect);
    }, [clearData, setRedirectURL, redirect]);

    const handleUsernameChange = (value: string) => {
        setUsernameError(!value);
        setUsername(value);
    };

    const handlePasswordChange = (value: string) => {
        setPasswordError(!value);
        setPassword(value);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setUsernameError(!username);
        setPasswordError(!password);
        if (username && password) {
            Logger.debug('Form content:', { username: username });
            setIsBusy(true);

            try {
                // triggers a Hub auth signIn event on success
                const user = await AmplifyClient.signIn(username, password);
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    AmplifyClient.initiateCompleteNewPassword(user);
                    goto(SiteMapPage.changePassword);
                }
            } catch (error) {
                Logger.error('sign in error:', error);
                if (error === SignInError.PasswordResetRequiredException) {
                    pageMessaging.showError(t('signIn:passwordResetRequired-error'));
                } else {
                    pageMessaging.showError(t('signIn:sign-in-error'));
                }
            } finally {
                setIsBusy(false);
            }
        }
    };

    return (
        <Tile title={t('signIn:title')} width={'fill'} headingLevel={2}>
            <form onSubmit={handleSubmit} data-testid={'form'}>
                <Column spacing={'medium'} spacingInset={'none'} width={'fill'}>
                    <LabeledInput
                        label={t('forms:username-fieldLabel')}
                        value={username}
                        onChange={handleUsernameChange}
                        type={'text'}
                        autoFocus={true}
                        error={
                            usernameError
                                ? t('validation:required-field-alert', { label: t('forms:username-fieldLabel') })
                                : undefined
                        }
                        dataTestId={'signInPage.usernameInput'}
                    />
                    <LabeledInput
                        label={t('signIn:password')}
                        value={password}
                        onChange={handlePasswordChange}
                        type={'password'}
                        placeholder={t('signIn:password-placeholder')}
                        error={
                            passwordError
                                ? t('validation:required-field-alert', { label: t('signIn:password') })
                                : undefined
                        }
                        dataTestId={'signInPage.passwordInput'}
                        spellCheck={false}
                    />
                    <Row>
                        <Button type={'primary'} submit={true} isBusy={isBusy} data-testid={'signInPage.submitButton'}>
                            {t('signIn:submit-button')}
                        </Button>
                    </Row>
                    <Link
                        href={getPath(SiteMapPage.resetPassword)}
                        type={'secondary'}
                        data-testid={'signInPage.forgotPasswordLink'}
                    >
                        {t('signIn:password-forgot-message')}
                    </Link>
                </Column>
            </form>
        </Tile>
    );
};

const mapDispatchToProps = { clearData, setRedirectURL };

const connector = connect(undefined, mapDispatchToProps);
export type SignInProps = ConnectedProps<typeof connector>;
export default connector(SignIn);
