import { Dispatch } from 'redux';
import { getUserTermsAndConditionsAcceptancesApi } from '../../clients/HrpsUserTermsAndConditionsApi';
import { GetUserTermsAndConditionsAcceptancesOutput, UserTermsAndConditionsAcceptance } from '../../types';
import {
    TermsAndConditionsActions,
    USER_TERMS_AND_CONDITIONS_FAILED,
    USER_TERMS_AND_CONDITIONS_LOADED,
    USER_TERMS_AND_CONDITIONS_LOADING,
    USER_TERMS_AND_CONDITIONS_VALIDATED,
    USER_TERMS_AND_CONDITIONS_CLEAR,
} from './termsAndConditionsActions';

export function getUserTermsAndConditionsLoading(): TermsAndConditionsActions {
    return {
        type: USER_TERMS_AND_CONDITIONS_LOADING,
    };
}

export function getUserTermsAndConditionsLoaded(
    termsAndConditionsAcceptances: UserTermsAndConditionsAcceptance[]
): TermsAndConditionsActions {
    return {
        type: USER_TERMS_AND_CONDITIONS_LOADED,
        payload: {
            value: termsAndConditionsAcceptances,
        },
    };
}

export function getUserTermsAndConditionsFailed(error: any): TermsAndConditionsActions {
    return {
        type: USER_TERMS_AND_CONDITIONS_FAILED,
        payload: {
            error,
        },
    };
}

export function userTermsAndConditionsValidated(isValid: boolean): TermsAndConditionsActions {
    return {
        type: USER_TERMS_AND_CONDITIONS_VALIDATED,
        payload: isValid,
    };
}

export function userTermsAndConditionsClear(): TermsAndConditionsActions {
    return {
        type: USER_TERMS_AND_CONDITIONS_CLEAR,
    };
}

export function getUserTermsAndConditionsAcceptances() {
    return (dispatch: Dispatch) => {
        dispatch(getUserTermsAndConditionsLoading());
        return getUserTermsAndConditionsAcceptancesApi()
            .then(async (userTermsAndConditionsList: GetUserTermsAndConditionsAcceptancesOutput) => {
                dispatch(getUserTermsAndConditionsLoaded(userTermsAndConditionsList.userTermsAndConditionsAcceptance));
            })
            .catch((err: any) => {
                dispatch(getUserTermsAndConditionsFailed(err));
            });
    };
}

export function setUserTermsAndConditionsValid(isValid: boolean) {
    return (dispatch: Dispatch) => {
        dispatch(userTermsAndConditionsValidated(isValid));
    };
}

export function clearUserTermsAndConditions() {
    return (dispatch: Dispatch) => {
        dispatch(userTermsAndConditionsClear());
    };
}
