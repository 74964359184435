import { IRole, RoleId } from '../types/role';

export const roles: Record<RoleId, IRole> = {
    resellerAdmin: { id: 'resellerAdmin', name: 'Reseller Admin', cognitoGroup: 'ResellerAdminsUserGroup' },
    resellerCreator: { id: 'resellerCreator', name: 'Reseller Creator', cognitoGroup: 'ResellerCreatorsUserGroup' },
    resellerViewer: { id: 'resellerViewer', name: 'Reseller Viewer', cognitoGroup: 'ResellerViewersUserGroup' },
    claimCreator: { id: 'claimCreator', name: 'Cliams Creator', cognitoGroup: 'ClaimsCreatorsUserGroup' },
    claimViewer: { id: 'claimViewer', name: 'Claims Viewer', cognitoGroup: 'ClaimsViewersUserGroup' },
    amazonAdmin: { id: 'amazonAdmin', name: 'Amazon Admin', cognitoGroup: 'AmazonAdminsUserGroup' },
    amazonCreator: { id: 'amazonCreator', name: 'Amazon Creator', cognitoGroup: 'AmazonCreatorsUserGroup' },
    unauthenticatedUser: { id: 'unauthenticatedUser', name: 'Unauthenticated User' },
    authenticatedUser: { id: 'authenticatedUser', name: 'Authenticated User' },
};

export const INTERNAL_ROLE_IDS = ['amazonAdmin', 'amazonCreator'];
