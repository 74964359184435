import Box from '@amzn/meridian/box';
import Divider from '@amzn/meridian/divider';
import { HeadingLevel } from '@amzn/meridian/heading/heading';
import Row from '@amzn/meridian/row';
import _ from 'lodash';
import React, { FC } from 'react';
import { Button, Heading, TranslatedString } from '../blocks';

export interface TileProps {
    title: TranslatedString;
    width: string;
    children: React.ReactNode;
    dataTestId?: string;
    contentSpacingInset?: any;
    headingLevel?: HeadingLevel;
    actionButtons?: {
        id: string;
        title: TranslatedString;
        callback: (id: string) => void;
        isDisabled?: boolean;
        type?: 'link' | 'secondary' | 'primary' | 'tertiary' | 'icon' | undefined;
    }[];
}

const Tile: FC<TileProps> = ({
    title,
    width,
    children,
    dataTestId,
    contentSpacingInset,
    actionButtons,
    headingLevel,
}: TileProps) => {
    return (
        <Box type={'outline'} spacingInset={'none'} width={width} data-testid={dataTestId}>
            <Row
                width={'100%'}
                widths={['fill', ..._.fill(Array(actionButtons?.length ?? 0), 'fit')]}
                spacingInset={'medium'}
                spacing={'medium'}
                backgroundColor={'secondary'}
            >
                <Heading level={headingLevel || 3} data-testid={`${dataTestId}.heading`}>
                    {title}
                </Heading>
                {actionButtons?.map((button) => (
                    <Button
                        data-testid={`${dataTestId}.actionButton[${button.id}]`}
                        key={button.id}
                        onClick={() => button.callback(button.id)}
                        type={button.type}
                        disabled={button.isDisabled}
                    >
                        {button.title}
                    </Button>
                ))}
            </Row>
            <Divider />
            <Row width={'100%'}>
                <Box spacingInset={contentSpacingInset || 'large'} width={'100%'} data-testid={`${dataTestId}.body`}>
                    {children}
                </Box>
            </Row>
        </Box>
    );
};

export { Tile };
