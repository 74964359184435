import Link from '@amzn/meridian/link';
import Popover, { PopoverHeader } from '@amzn/meridian/popover';
import { PopoverPosition } from '@amzn/meridian/popover/popover';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import React, { useRef, useState } from 'react';
import { TranslatedString } from '../blocks/localization';
import { Help } from '../icons';

export interface HelpPopoverProps {
    children?: React.ReactNode;
    dataTestId?: string;
    label?: TranslatedString;
    header?: TranslatedString;
    text?: TranslatedString;
    maxWidth?: string;
    position?: PopoverPosition;
}

const HelpPopover = ({ header, text, label, maxWidth, dataTestId, position, children }: HelpPopoverProps) => {
    const buttonRef = useRef();
    const [open, setOpen] = useState(false);
    const onClickButton = () => setOpen(true);
    const onClose = () => setOpen(false);
    return (
        <>
            <Link onClick={onClickButton} ref={buttonRef} type={'secondary'} data-testid={`${dataTestId}.link`}>
                <Row spacing={'none'}>
                    <Help />
                    {label && <Text>{label}</Text>}
                </Row>
            </Link>
            <Popover
                anchorNode={buttonRef.current}
                open={open}
                onClose={onClose}
                position={position || 'right'}
                maxWidth={maxWidth || '30em'}
                shrinkToFit={true}
                data-testid={`${dataTestId}.popover`}
            >
                {header && (
                    <PopoverHeader closeLabel={'Close'} data-testid={`${dataTestId}.popoverHeader`}>
                        {header}
                    </PopoverHeader>
                )}
                {text && <Text>{text}</Text>}
                {children}
            </Popover>
        </>
    );
};

export { HelpPopover };
