import { PackageType } from '../../types';
import { IAsynchronouslyLoadedValue } from './asynchronouslyLoadedValue';

export type ReturnOrderState = {
    returnOrder: IAsynchronouslyLoadedValue<ReturnOrder>;
};

export enum RmaStatus {
    CREATED = 'CREATED',
    RELEASING = 'RELEASING',
    RELEASED = 'RELEASED',
    REVIEWED = 'REVIEWED',
    EDITED = 'EDITED',
    EDITING = 'EDITING',
    RECEIVING = 'RECEIVING',
    PARTIALLY_RECEIVED = 'PARTIALLY_RECEIVED',
    FORCE_CLOSED = 'FORCE_CLOSED',
    AGED_OUT = 'AGED_OUT',
    COMPLETE = 'COMPLETE',
    DRAFTED = 'DRAFTED',
    SUBMITTED = 'SUBMITTED',
    EXPIRED = 'EXPIRED',
    DELETED = 'DELETED',
    COMPLETED = 'COMPLETED',
}

export type ReturnOrder = {
    returnOrderId: string;
    resellerId: string;
    customerReferenceId: string;
    returnChannel: string;
    shippingAddress: string;
    billingAddress: string;
    totalCharge: TotalCharge;
    creationDate: number;
    contactEmailAddress: string[];
    destinationWarehouseId: string;
    returnType: ReturnOrderType;
    status: ReturnOrderStatus;
    createdBy: string;
    lineItems: ReturnOrderLineItem[];
    version: number;
};

export interface AttachedDocument {
    id: string;
    type: string;
    name: string;
    description: string;
    uploadedAt: Date;
    uploadedBy?: string;
    presignedURL: string;
}

export const returnOrderTypes = ['REFUSAL', 'RETURN', 'BUYBACK'];

export type ReturnOrderType = typeof returnOrderTypes[number];

export const returnOrderStatuses = [
    'SUBMITTED',
    'REVIEWED',
    'RELEASED',
    'COMPLETED',
    'FORCE_CLOSED',
    'EXPIRED',
    'DRAFTED',
];

export type ReturnOrderStatus = typeof returnOrderStatuses[number];

export const returnOrderLineItemStatuses = [
    'APPROVED',
    'PARTIALLY_RECEIVED',
    'RECEIVED',
    'RECEIVED_DISPOSED',
    'ON_CREDIT_MEMO',
    'SUBMITTED',
    'REJECTED',
    'REVIEWED',
    'PENDING',
    'DELETED',
    'SIDELINED',
    'EXPIRED',
    'DRAFT',
];

export type ReturnOrderLineItemStatus = typeof returnOrderLineItemStatuses[number];

export const returnOrderLineItemComplianceStatuses = ['Not Checked', 'Compliant', 'Not Compliant'];

export type ReturnOrderLineItemComplianceStatus = typeof returnOrderLineItemComplianceStatuses[number];

export type ComplianceReview = {
    complianceStatus: ReturnOrderLineItemComplianceStatus;
    eligibleReturnQuantity?: number;
};

export type ReturnOrderLineItem = {
    lineItemId: string;
    itemId: string;
    requestedCount: number;
    approvedCount: number;
    estimatedCredit: number;
    receivedCount: number;
    status: ReturnOrderLineItemStatus;
    reason?: string;
    complianceReview: ComplianceReview;
    creditMemoNumber?: string;
    complianceResolution?: string;
    packageType?: PackageType;
    packageQuantity?: number;
};

export type TotalCharge = {
    amountWithTaxes: string;
    currencyCode: string;
};
