import Column from '@amzn/meridian/column';
import Divider from '@amzn/meridian/divider';
import Link from '@amzn/meridian/link';
import Popover, { PopoverHeader } from '@amzn/meridian/popover';
import Row from '@amzn/meridian/row';
import Textarea from '@amzn/meridian/textarea';
import React, { useRef, useState } from 'react';
import { useHrpsApi } from '../../clients/useHrpsApi';
import { allTemplates } from '../../constants';
import { Logger } from '../../utils/logger';
import { Button, TranslatedString, useTranslation } from '../blocks/localization';
import { LabeledInput, usePageMessaging } from '../composites';
import LabeledField from '../composites/LabeledField';

export interface CreateNotificationPopoverProps {
    dataTestId?: string;
    label: TranslatedString;
    labelType?: 'primary' | 'secondary';
    header?: TranslatedString;
    username: string;
    resellerId: string;
    template?: string;
    pageData?: {};
    useButton?: boolean;
    onSubmitSuccessCallback?: any;
}

const CreateNotificationPopover = ({
    label,
    labelType = 'primary',
    header,
    username,
    resellerId,
    template,
    dataTestId,
    pageData,
    useButton,
    onSubmitSuccessCallback,
}: CreateNotificationPopoverProps) => {
    const translationNSs = ['createNotificationPopover', ...allTemplates];
    const { t } = useTranslation(translationNSs);
    const buttonRef = useRef();
    const [open, setOpen] = useState(false);
    const [subject, setSubject] = useState('');
    const [description, setDescription] = useState('');
    const [issueType, setIssueType] = useState('');
    const [componentIsBusy, setComponentIsBusy] = useState(false);

    const {
        issues: { createIssue },
    } = useHrpsApi();
    const { showSuccess, showError } = usePageMessaging();

    const onClickButton = () => {
        if (template) {
            Logger.debug(`**** Loading Templated values ${template}`);
            setSubject(t(`${template}:issue-subject`, { ...pageData }));
            setDescription(t(`${template}:issue-description`, { ...pageData }));
            setIssueType(t(`${template}:issue-type`));
        } else {
            Logger.debug(`**** No Template Using Default Values`);
            setSubject('');
            setDescription('');
            setIssueType('GENERAL_ISSUE');
        }

        setOpen(true);
    };
    const onClose = () => {
        setSubject('');
        setDescription('');
        setOpen(false);
    };

    async function submitNotification() {
        if (subject && description) {
            setComponentIsBusy(true);

            try {
                await createIssue({
                    resellerId: resellerId,
                    issueTitle: subject,
                    issueBody: description,
                    issueType: issueType,
                });
                showSuccess(t('createNotification-successMessage'));
                if (onSubmitSuccessCallback) onSubmitSuccessCallback();
                setOpen(false);
            } catch {
                showError(t('createNotification-errorMessage'));
            } finally {
                setComponentIsBusy(false);
            }
        }
    }

    return (
        <>
            {useButton ? (
                <Button onClick={onClickButton} ref={buttonRef} type={labelType} data-testid={`${dataTestId}.button`}>
                    {label}
                </Button>
            ) : (
                <Link onClick={onClickButton} ref={buttonRef} type={labelType} data-testid={`${dataTestId}.link`}>
                    {label}
                </Link>
            )}
            <Popover
                anchorNode={buttonRef.current}
                open={open}
                onClose={onClose}
                position={'top'}
                maxWidth={'60%'}
                data-testid={`${dataTestId}.popover`}
            >
                {header && (
                    <PopoverHeader closeLabel={'Close'} data-testid={`${dataTestId}.popoverHeader`}>
                        {header}
                    </PopoverHeader>
                )}
                <Column>
                    <LabeledField label={t('reseller-label')} text={t.cognate(resellerId)} />
                    <LabeledField label={t('createdByUser-label')} text={t.cognate(username)} />
                    <LabeledInput
                        label={t('notificationSubject-label')}
                        type={'text'}
                        onChange={setSubject}
                        value={subject}
                        dataTestId={'createNotificationPopover.subjectField'}
                    />
                    <Textarea
                        value={description}
                        label={t('notificationText-label')}
                        onChange={setDescription}
                        data-testid={'createNotificationPopover.bodyTextarea'}
                    />
                    <Divider />
                    <Row alignmentHorizontal={'right'}>
                        <Button
                            type={'secondary'}
                            onClick={onClose}
                            data-testid={'createNotificationPopover.cancelButton'}
                        >
                            {t('cancelNotification-button')}
                        </Button>
                        <Button
                            type={'primary'}
                            onClick={submitNotification}
                            data-testid={'createNotificationPopover.createButton'}
                            disabled={componentIsBusy}
                        >
                            {t('createNotification-button')}
                        </Button>
                    </Row>
                </Column>
            </Popover>
        </>
    );
};

export { CreateNotificationPopover };
