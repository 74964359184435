import Column from '@amzn/meridian/column';
import { TabGroup as MeridianTabGroup } from '@amzn/meridian/tab';
import React, { FC, ReactElement, ReactNode, useMemo, useState } from 'react';
import { TabProps } from '../blocks/Tab';

const TabGroup: FC<TabGroupProps> = ({ initialTabId, children }) => {
    const [currentTab, setCurrentTab] = useState<string>(initialTabId ?? children[0].props.tabId);

    const tabContentMap = useMemo(() => {
        const map = new Map<string, ReactNode>();
        children.forEach((tab) => {
            map.set(tab.props.tabId, tab.props.children);
        });
        return map;
    }, [children]);

    const tabContent = useMemo(() => {
        return tabContentMap.get(currentTab);
    }, [currentTab, tabContentMap]);

    return (
        <Column width={'100%'}>
            <MeridianTabGroup value={currentTab} onChange={setCurrentTab}>
                {children}
            </MeridianTabGroup>
            {tabContent}
        </Column>
    );
};

export type TabGroupProps = {
    initialTabId?: string;
    children: ReactElement<TabProps>[];
};
export { TabGroup };
