import { BreadcrumbGroup } from '@amzn/meridian/breadcrumb';
import Row from '@amzn/meridian/row';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { color } from '../../theme/colors';
import { useSiteMapRouter } from '../../utils/SiteMapRouter';
import { Breadcrumb } from '../blocks';

const BreadcrumbRow = styled(Row)`
    padding: 0.75rem 0 0.75rem 0.5rem;
    background-color: ${color.gray[0]};
    border-bottom: 1px solid ${color.gray[400]};
    margin-bottom: 1rem;
`;

const Breadcrumbs: FC = () => {
    const history = useHistory();

    const { breadcrumbs } = useSiteMapRouter();
    return (
        <BreadcrumbRow>
            <BreadcrumbGroup>
                {breadcrumbs.map((breadcrumb) => (
                    <Breadcrumb
                        key={breadcrumb.link}
                        href={breadcrumb.link}
                        onClick={(href: string) => history.push(href)}
                    >
                        {breadcrumb.title}
                    </Breadcrumb>
                ))}
            </BreadcrumbGroup>
        </BreadcrumbRow>
    );
};

export { Breadcrumbs };
