import _ from 'lodash';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ConditionalRedirect, TranslationFunction } from './components/blocks';
import {
    ChangePasswordPage,
    CreateUserPage,
    EditUserPage,
    ResetPasswordPage,
    SelectResellerPage,
    SignOutPage,
    SignInPage,
    UserListPage,
    ReturnOrderViewPage,
    ReturnOrdersListPage,
    CreateReturnOrderPage,
    CreateReturnOrderConfirmationPage,
    UploadReturnOrderPage,
    NotificationListPage,
    TermsAndConditionsPage,
    ContactListPage,
    ContactForm,
    NewsPage,
    UploadClaimPage,
    ClaimListPage,
} from './pages';
import { clearRedirectURL } from './redux/actions/systemActionCreators';
import { IAsynchronouslyLoadedValue, IAuthorizedReseller, IUser, RootState } from './redux/types';
import { SiteMapPage } from './types';
import { SiteMap } from './utils/SiteMapRouter';

// FIXME: Add conditions as first-class rules to SiteMap to handle a requirement to have a selected reseller for example. -- 5/31/22
// HACK: redirection was handled in the router, so keeping it here for now.
const SelectResellerWithConditionalRedirection = connect((rootState: RootState) => ({
    selectedReseller: rootState.resellerReducer.selectedReseller,
    authorizedResellers: rootState.resellerReducer.authorizedResellers,
}))((props: { selectedReseller: IAsynchronouslyLoadedValue<IAuthorizedReseller> }) => {
    return (
        <ConditionalRedirect
            condition={props.selectedReseller.status === 'Loaded'}
            to={'/account/accept-termsAndConditions'}
        >
            <SelectResellerPage />
        </ConditionalRedirect>
    );
});

const TermsAndConditionsRedirection = connect((rootState: RootState) => ({
    selectedReseller: rootState.resellerReducer.selectedReseller,
    userTermsAndConditionsAcceptances: rootState.termsAndConditionsReducer.validUserTermsAndConditions,
}))(
    (props: {
        selectedReseller: IAsynchronouslyLoadedValue<IAuthorizedReseller>;
        validUserTermsAndConditions: boolean;
    }) => {
        const tAndCValid: boolean = props.selectedReseller.status === 'Loaded' && props.validUserTermsAndConditions;
        return (
            <ConditionalRedirect condition={tAndCValid} to={'/'}>
                <TermsAndConditionsPage />
            </ConditionalRedirect>
        );
    }
);

// HACK: redirection was handled in the router, so keeping it here for now.
const SignInWithConditionalRedirection = connect((rootState: RootState) => ({
    isAuthenticated: rootState.userReducer.isAuthenticated,
}))((props: { isAuthenticated: boolean }) => {
    return (
        <ConditionalRedirect condition={props.isAuthenticated} to={'/account/select-reseller'}>
            <SignInPage />
        </ConditionalRedirect>
    );
});

// HACK: redirection was handled in the router, so keeping it here for now.
const RedirectToHome = connect((rootState: RootState) => ({
    user: rootState.userReducer.user,
    redirectURL: rootState.systemReducer.redirectURL,
}))((props: { user?: IUser; redirectURL: string }) => {
    const dispatch = useDispatch();
    const adminRoles = ['amazonAdmin', 'resellerAdmin'];
    const claimRoles = ['claimCreator', 'claimViewer'];
    const isAdminUser = props.user !== undefined && _.intersection(props.user.roles, adminRoles).length > 0;
    const isClaimUser = props.user !== undefined && _.intersection(props.user.roles, claimRoles).length > 0;
    const url = props.redirectURL;

    dispatch(clearRedirectURL());
    return (
        <ConditionalRedirect condition={url !== SiteMapPage.home} to={url}>
            <ConditionalRedirect condition={isAdminUser} to={'/admin/list/users'}>
                <ConditionalRedirect condition={isClaimUser} to={'/claims/view/claims'}>
                    <ConditionalRedirect condition={props.user !== undefined} to={'/returns/list/return-orders'}>
                        <Redirect to={SiteMapPage.signIn} />
                    </ConditionalRedirect>
                </ConditionalRedirect>
            </ConditionalRedirect>
        </ConditionalRedirect>
    );
});

const t = (key: string) => (tFunction: TranslationFunction, params: Record<string, any>) => tFunction(key, params);

export const siteMap: SiteMap = {
    areas: [
        {
            name: '',
            resources: [
                {
                    name: '',
                    pages: [
                        // this is a "/" path
                        {
                            action: '',
                            title: (t) => t.cognate(''),
                            component: RedirectToHome,
                        },
                    ],
                },
            ],
        },
        {
            name: 'admin',
            resources: [
                {
                    name: 'users',
                    breadcrumbAction: 'list',
                    pages: [
                        { action: 'list', title: t('users-breadcrumbTitle'), component: <UserListPage /> },
                        { action: 'create', title: t('createUser-breadcrumbTitle'), component: <CreateUserPage /> },
                    ],
                    resources: [
                        {
                            name: 'user',
                            param: 'username',
                            pages: [
                                {
                                    action: 'edit',
                                    title: (t, params) => t('editUser-breadcrumbTitle', { username: params.username }),
                                    component: <EditUserPage />,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            name: 'account',
            resources: [
                {
                    name: '',
                    pages: [
                        {
                            action: 'sign-in',
                            title: t('signIn-breadcrumbTitle'),
                            component: SignInWithConditionalRedirection,
                        },
                        { action: 'sign-out', title: t('signOut-breadcrumbTitle'), component: <SignOutPage /> },
                        {
                            action: 'reset-password',
                            title: t('resetPassword-breadcrumbTitle'),
                            component: <ResetPasswordPage />,
                        },
                        {
                            action: 'change-password',
                            title: t('changePassword-breadcrumbTitle'),
                            component: <ChangePasswordPage />,
                        },
                        {
                            action: 'select-reseller',
                            title: t('selectReseller-breadcrumbTitle'),
                            component: SelectResellerWithConditionalRedirection,
                        },
                        {
                            action: 'accept-termsAndConditions',
                            title: t('termsAndConditions-breadcrumbTitle'),
                            component: TermsAndConditionsRedirection,
                        },
                        // HACK: (pretty much a hack) the "real" select-reseller page will redirect when reseller is already selected,
                        // having this `/account/change-reseller` page allows to bypass that redirection rule.
                        // We arrive to this `/account/change-reseller` page when click on selected reseller in the navbar.
                        // FIXME: Change the select-reseller page so it can handle redirect-on-submit. -- 5/7/22
                        // Note: currently select-reseller page relies on router to change the page, because async redux action is used to change selected reseller
                        // if we simply execute useSiteMapRouter().goto('/') on submit click - it is likely that user will be sent right back to the select-reseller page, because
                        // by the time redirect-to page is rendered - redux state might have not been updated yet.
                        // I don't know of a good way to wait in the page until redux change is propagated before redirecting.
                        // Perhaps we could do something like: `if (newSelectedReseller === currentlySelectedResellerFromRedux) siteMapRouter.goto(whatever next page);`
                        // perhaps we can even encapsulate that into a helper method of some sort: `waitForActionComplete`.
                        {
                            action: 'change-reseller',
                            title: t('selectReseller-breadcrumbTitle'),
                            component: <SelectResellerPage />,
                        },
                    ],
                },
            ],
        },
        {
            name: 'errors',
            resources: [
                {
                    name: '',
                    pages: [
                        {
                            action: 'access-denied',
                            component: (
                                <div style={{ backgroundColor: 'white', textAlign: 'center', fontSize: 20 }}>
                                    {t('unauthorizedMessage')}
                                </div>
                            ),
                            title: t('permissionDenied-breadcrumbTitle'),
                        },
                        {
                            action: 'page-not-found',
                            component: (
                                // TODO : build a real 404 page.
                                <div style={{ backgroundColor: 'white', textAlign: 'center', fontSize: 20 }}>
                                    {t('pageNotFound')}
                                    <br />
                                    <a href={'/'}>{'go home'}</a>
                                </div>
                            ),
                            title: t('pageNotFound-breadcrumbTitle'),
                        },
                    ],
                },
            ],
        },
        {
            name: 'returns',
            resources: [
                {
                    name: 'return-orders',
                    breadcrumbAction: 'list',
                    pages: [
                        {
                            action: 'list',
                            component: ReturnOrdersListPage,
                            title: t('returnOrders-breadcrumbTitle'),
                        },
                        {
                            action: 'create',
                            component: CreateReturnOrderPage,
                            title: t('createReturnOrder-breadcrumbTitle'),
                        },
                        {
                            action: 'confirm',
                            component: CreateReturnOrderConfirmationPage,
                            title: t('createReturnOrderConfirmation-breadcrumbTitle'),
                        },
                        {
                            action: 'upload',
                            component: UploadReturnOrderPage,
                            title: t('uploadReturnOrderPage-breadcrumbTitle'),
                        },
                    ],
                    resources: [
                        {
                            name: 'return-order',
                            param: 'returnOrderId',
                            breadcrumbAction: 'view',
                            pages: [
                                {
                                    action: 'view',
                                    component: ReturnOrderViewPage,
                                    title: (t, params) =>
                                        t('returnOrder-breadcrumbTitle', { returnOrderId: params.returnOrderId }),
                                },
                                {
                                    action: 'update',
                                    component: CreateReturnOrderPage,
                                    title: (t, params) =>
                                        t('returnOrder-breadcrumbTitle', { returnOrderId: params.returnOrderId }),
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            name: 'claims',
            resources: [
                {
                    name: 'claims',
                    breadcrumbAction: 'view',
                    pages: [
                        {
                            action: 'view',
                            component: ClaimListPage,
                            title: t('viewClaims-breadcrumbTitle'),
                        },
                        {
                            action: 'upload',
                            component: UploadClaimPage,
                            title: t('uploadClaimPage-breadcrumbTitle'),
                        },
                    ],
                },
            ],
        },
        {
            name: 'notifications',
            resources: [
                {
                    name: 'notifications',
                    breadcrumbAction: 'list',
                    pages: [
                        {
                            action: 'list',
                            component: NotificationListPage,
                            title: t('notification-breadcrumbTitle'),
                        },
                    ],
                },
            ],
        },
        {
            name: 'news',
            resources: [
                {
                    name: 'news',
                    breadcrumbAction: 'list',
                    pages: [
                        {
                            action: 'list',
                            component: NewsPage,
                            title: t('news-breadcrumbTitle'),
                        },
                    ],
                },
            ],
        },
        {
            name: 'reseller',
            resources: [
                {
                    name: 'contacts',
                    breadcrumbAction: 'list',
                    pages: [
                        {
                            action: 'list',
                            component: ContactListPage,
                            title: t('contactList-breadcrumbTitle'),
                        },
                        {
                            action: 'create',
                            component: ContactForm,
                            title: t('createContact-breadcrumbTitle'),
                        },
                    ],
                    resources: [
                        {
                            name: 'contact',
                            param: 'contactId',
                            breadcrumbAction: 'view',
                            pages: [
                                {
                                    action: 'edit',
                                    component: ContactForm,
                                    title: (t, params) => t('contact-breadcrumbTitle', { contactId: params.contactId }),
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
};
