import Background from '../assets/Background-1.jpg';
import BackgroundBeta from '../assets/Background-1Beta.jpg';
import BackgroundGamma from '../assets/Background-1Gamma.jpg';
import Logo from '../assets/Logo.png';
import { Stage, SystemNotification } from '../redux/types';

export const APPLICATION_NAME = 'Reseller Portal';
export const APPLICATION_SUB_NAME = 'Selected Reseller';
export const APPLICATION_IMAGE_URL = Logo;
export const APPLICATION_BACKGROUND_IMAGE_URL = Background;
export const APPLICATION_BACKGROUND_BETA_IMAGE_URL = BackgroundBeta;
export const APPLICATION_BACKGROUND_GAMMA_IMAGE_URL = BackgroundGamma;
export const DEFAULT_STAGE: Stage = { status: 'Uninitialized' } as Stage;
export const SITE_NOTIFICATIONS: SystemNotification[] = [
    {
        startDate: 1646179200000,
        endDate: 1646308800000,
        notificationId: 'maintenanceMessage',
        severity: 'warn',
        options: {
            startTime: '2022-03-03 02:00:00 UTC',
            endTime: '2022-03-03 03:00:00 UTC',
        },
    },
];

export const WEBSITE_SETTINGS_PATH = '/settings.json';
