import Column from '@amzn/meridian/column';
import Link from '@amzn/meridian/link';
import Popover, { PopoverHeader } from '@amzn/meridian/popover';
import Text from '@amzn/meridian/text';
import styled from '@emotion/styled';
import React, { useRef, useState } from 'react';
import { ItemThumbnail, useTranslation } from '../blocks';
import { TranslatedString } from '../blocks/localization';

export interface ItemImagePopoverProps {
    thumbnailUrl?: string;
    linkLabel?: TranslatedString;
    children?: React.ReactNode;
    dataTestId?: string;
    header?: TranslatedString;
    text?: TranslatedString;
    maxWidth?: string;
}

const FocusableColumn = styled(Column)`
    a:focus {
        outline: thin dotted;
    }
`;

const ItemImagePopover = ({
    thumbnailUrl,
    linkLabel,
    header,
    text,
    maxWidth,
    dataTestId,
    children,
}: ItemImagePopoverProps) => {
    const buttonRef = useRef();
    const [open, setOpen] = useState(false);
    const onClickButton = () => setOpen(true);
    const onClose = () => setOpen(false);
    const { t } = useTranslation('createReturnOrderPage');
    return (
        <FocusableColumn>
            <Link
                onClick={onClickButton}
                ref={buttonRef}
                type={'secondary'}
                data-testid={`${dataTestId}.link`}
                aria-label={t('image-columnLabel')}
            >
                {linkLabel}
                {thumbnailUrl && (
                    <ItemThumbnail src={thumbnailUrl} data-testid={`${dataTestId}.icon`} aria-label={header} />
                )}
            </Link>
            <Popover
                anchorNode={buttonRef.current}
                open={open}
                onClose={onClose}
                position={'right'}
                maxWidth={maxWidth || '30em'}
                data-testid={`${dataTestId}.popover`}
            >
                {header && (
                    <PopoverHeader closeLabel={'Close'} data-testid={`${dataTestId}.popoverHeader`}>
                        {header}
                    </PopoverHeader>
                )}
                {text && <Text>{text}</Text>}
                {children}
            </Popover>
        </FocusableColumn>
    );
};

export { ItemImagePopover };
