import Amplify from '@aws-amplify/core';
import { FetchClient } from '../clients/FetchClient';
import { WEBSITE_SETTINGS_PATH } from '../constants';
import { ExpiringSessionCookieStorage } from '../utils/storage';

interface WebsiteSettings extends Record<string, string> {
    awsAccountId: string;
    region: string;
    domain: string;

    // Note: WebsiteSettings structure can not be nested, so we use underscore to group attributes.
    // eslint-disable-next-line camelcase
    cognito_identityPoolId: string;
    // eslint-disable-next-line camelcase
    cognito_userPoolId: string;
    // eslint-disable-next-line camelcase
    cognito_userPoolAppClientId: string;
}

export class AmplifyConfigurator {
    private static configured: boolean = false;

    public static isConfigured(): boolean {
        return this.configured;
    }

    private static async fetchSettings(): Promise<WebsiteSettings> {
        return await FetchClient.fetch<WebsiteSettings>(WEBSITE_SETTINGS_PATH);
    }

    private static buildAmplifyConfig(settings: WebsiteSettings): object {
        return {
            // Note: suppressing camelcase rule because we have no control over Amplify property names.
            // eslint-disable-next-line camelcase
            aws_project_region: settings.region,
            // eslint-disable-next-line camelcase
            aws_cognito_identity_pool_id: settings.cognito_identityPoolId,
            // eslint-disable-next-line camelcase
            aws_cognito_region: settings.region,
            // eslint-disable-next-line camelcase
            aws_user_pools_id: settings.cognito_userPoolId,
            // eslint-disable-next-line camelcase
            aws_user_pools_web_client_id: settings.cognito_userPoolAppClientId,
            oauth: {
                // fixme import from infra cdk in website cdk
                domain: `hamlet-reseller-portal-${settings.awsAccountId}.auth.${settings.region}.amazoncognito.com`,
                scope: ['openid'],
                redirectSignIn: `${settings.domain}account/sign-in`,
                redirectSignOut: `${settings.domain}account/sign-in`,
                responseType: 'code',
            },
            federationTarget: 'COGNITO_USER_POOLS',
            Auth: {
                storage: ExpiringSessionCookieStorage,
            },
        };
    }

    public static async configure() {
        AmplifyConfigurator.configured = false;

        const settings = await AmplifyConfigurator.fetchSettings();

        Amplify.configure(AmplifyConfigurator.buildAmplifyConfig(settings));

        AmplifyConfigurator.configured = true;
    }
}
