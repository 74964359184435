export const locales = [
    'ar-AE',
    'bn-IN',
    'cs-CZ',
    'da-DK',
    'de-DE',
    'el-GR',
    'en-AE',
    'en-AU',
    'en-CA',
    'en-GB',
    'en-IN',
    'en-NG',
    'en-SG',
    'en-US',
    'en-ZA',
    'es-CL',
    'es-CO',
    'es-ES',
    'es-MX',
    'es-US',
    'fr-BE',
    'fr-CA',
    'fr-FR',
    'he-IL',
    'hi-IN',
    'it-IT',
    'ja-JP',
    'kn-IN',
    'ko-KR',
    'ml-IN',
    'mr-IN',
    'ms-MY',
    'nl-BE',
    'nl-NL',
    'pl-PL',
    'pt-BR',
    'pt-PT',
    'sv-SE',
    'ta-IN',
    'te-IN',
    'tr-TR',
    'zh-CN',
    'zh-TW',
];
