import darkTokens from '@amzn/meridian-tokens/theme/blue-dark';
import styled from '@emotion/styled';
import React from 'react';

const Svg = styled.svg`
    width: 24px;
    height: 24px;
`;

export const Language = () => (
    <Svg width={'48'} height={'48'} viewBox={'0 0 12.7 12.7'}>
        <g id={'icon'}>
            <ellipse
                fill={'none'}
                stroke={'currentColor'}
                strokeWidth={'.5'}
                id={'path31'}
                cx={'6.4822917'}
                cy={'6.3499999'}
                rx={'5.4239583'}
                ry={'5.6885414'}
            />
            <path
                stroke={'currentColor'}
                strokeWidth={'.5'}
                d={'M 1.2585584,4.7566106 C 11.660174,4.7381024 11.641666,4.7195943 11.641666,4.7195943'}
            />
            <path
                stroke={'currentColor'}
                strokeWidth={'.5'}
                d={'M 1.2798805,8.1063799 C 11.681496,8.0878717 11.662988,8.0693636 11.662988,8.0693636'}
            />
            <path
                fill={'none'}
                stroke={'currentColor'}
                strokeWidth={'.5'}
                d={
                    'M 5.8706739,0.76504623 C 4.8077194,2.4877781 3.744799,4.2104546 3.7980694,6.0778699 c 0.05327,1.8674154 1.2224594,3.8792153 2.3914804,5.8907261'
                }
            />
            <path
                fill={'none'}
                stroke={'currentColor'}
                strokeWidth={'.5'}
                d={
                    'M 6.7308129,0.6609563 C 8.0042715,2.454563 9.2774985,4.2478436 9.3428321,6.1413384 9.4081657,8.0348331 8.2659218,10.028721 7.1236943,12.02258'
                }
            />
            <rect
                stroke={darkTokens.themeForegroundInvertedDefault}
                strokeWidth={'.5'}
                fill={'currentColor'}
                width={'6.9035635'}
                height={'6.2002511'}
                x={'4.3864465'}
                y={'1.9988874'}
                ry={'1.1475093'}
            />
            <path
                fill={'currentColor'}
                stroke={darkTokens.themeForegroundInvertedDefault}
                strokeWidth={'.5'}
                d={
                    'm 5.7190377,8.18063 c 0.024696,0.3827835 0.049373,0.7652864 -0.1665503,1.1322146 -0.2159237,0.3669282 -0.6724505,0.7185774 -0.9036897,0.8914674 -0.2312393,0.17289 -0.2374109,0.166717 0.1327464,0.123387 0.3701573,-0.04333 1.11664,-0.12353 1.677919,-0.4813093 C 7.0207422,9.4886109 7.3970684,8.8531749 7.7889836,8.532324 8.1808988,8.2114731 8.5880712,8.2053039 8.9949914,8.1991385'
                }
            />
            <path
                fill={'currentColor'}
                stroke={'none'}
                strokeWidth={'.5'}
                d={
                    'M 5.9932574,7.8925163 8.1591925,7.9114601 6.066679,9.559564 5.9932583,7.8925163 c 0,0 0,0 -9e-7,0 z'
                }
            />
            <text
                fill={darkTokens.themeForegroundInvertedDefault}
                strokeWidth={'.25'}
                fontSize={'3.52777px'}
                fontFamily={'serif'}
                fontVariant={'MS PMincho'}
            >
                <tspan x={'8.2'} y={'6.75'}>
                    {'A'}
                </tspan>
            </text>
            <path
                stroke={darkTokens.themeForegroundInvertedDefault}
                strokeWidth={'.33'}
                d={'M 4.9416927,3.5165604 C 7.8104658,3.4795438 7.8104658,3.4795438 7.8104658,3.4795438'}
            />
            <path
                fill={'none'}
                stroke={darkTokens.themeForegroundInvertedDefault}
                strokeWidth={'.33'}
                d={
                    'M 7.3107441,3.4980522 C 7.0947608,3.9608734 6.8788316,4.4235787 6.5025651,4.802917 6.1262986,5.1822552 5.5895712,5.4783807 5.052742,5.7745624'
                }
            />
            <path
                fill={'none'}
                stroke={darkTokens.themeForegroundInvertedDefault}
                strokeWidth={'.33'}
                d={
                    'm 5.5524637,3.5535769 c 0.1975486,0.4938715 0.3949697,0.987424 0.6726916,1.2957906 0.2777219,0.3083666 0.6355401,0.4317522 0.9930476,0.5550306'
                }
            />
            <path
                fill={'none'}
                stroke={darkTokens.themeForegroundInvertedDefault}
                strokeWidth={'.33'}
                d={'M 6.4408578,3.4240194 C 6.3485313,3.2947622 6.2559902,3.1652046 6.1632348,3.0353468'}
            />
        </g>
    </Svg>
);
