import React, { FC } from 'react';
import { COUNTRIES_TO_STATES } from '../../constants/locations/states';
import { TranslatedString, useTranslation } from '../blocks/localization';
import { LabeledInput, LabeledSearchableSelect } from '.';

const StateSelect: FC<StateSelectProps> = ({ value, onChange, selectedCountry, isDisabled, error, dataTestId }) => {
    const { t } = useTranslation('addressForm');

    const fieldLabel = t('administrativeDivision-fieldLabel', { context: selectedCountry });

    if (selectedCountry in COUNTRIES_TO_STATES) {
        const options = COUNTRIES_TO_STATES[selectedCountry].map((state) => {
            return { value: state, label: t.cognate(state) };
        });

        return (
            <LabeledSearchableSelect
                label={fieldLabel}
                value={value}
                onChange={onChange}
                selectOptions={options}
                isDisabled={isDisabled}
                error={error}
                dataTestId={dataTestId}
            />
        );
    }

    return (
        <LabeledInput
            label={fieldLabel}
            value={value}
            onChange={onChange}
            isDisabled={isDisabled}
            error={error}
            dataTestId={dataTestId}
        />
    );
};

type StateSelectProps = {
    value: any;
    onChange?(value: string): void;
    selectedCountry: string;
    isDisabled?: boolean;
    error?: TranslatedString;
    dataTestId?: string;
};

export { StateSelect };
