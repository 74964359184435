import Column from '@amzn/meridian/column';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import React from 'react';
import { Text, useTranslation } from '../../blocks';

const USTermsAndConditions = () => {
    const { t } = useTranslation('usTermsAndConditions');

    return (
        <>
            <Column spacing={'medium'}>
                <Text type={'h300'} alignment={'left'}>
                    {t('conditionsOfUse_paragraphHeader')}
                </Text>
                <Text type={'b200'} alignment={'left'}>
                    {t('conditionsOfUse_paragraph')}
                </Text>
                <Text type={'h300'} alignment={'left'}>
                    {t('privacy_paragraphHeaders')}
                </Text>
                <Row spacing={'xsmall'}>
                    <Text type={'b200'} alignment={'left'}>
                        {t('privacy_paragraph_preLink')}
                        <Link
                            href={'https://www.amazon.com/gp/help/customer/display.html?nodeId=GX7NJQ4ZB8MHFRNJ'}
                            type={'primary'}
                            data-testid={`privacyNotice.link`}
                        >
                            {t('privacy_paragraph_linkLabel')}
                        </Link>
                        {t('privacy_paragraph_postLink')}
                    </Text>
                </Row>
                <Text type={'h300'} alignment={'left'}>
                    {t('sanctionsAndExportPolicy_header')}
                </Text>
                <Text type={'b200'} alignment={'left'}>
                    {t('sanctionsAndExportPolicy_paragraph')}
                </Text>
                <Text type={'h300'} alignment={'left'}>
                    {t('disclaimerOfWarranties_header')}
                </Text>
                <Text type={'b200'} alignment={'left'}>
                    {t('disclaimerOfWarranties_paragraph1')}
                </Text>
                <Text type={'b300'} alignment={'left'}>
                    {t('disclaimerOfWarranties_paragraph2')}
                </Text>
                <Text type={'h300'} alignment={'left'}>
                    {t('sitePolicies_header')}
                </Text>
                <Text type={'b300'} alignment={'left'}>
                    {t('sitePolicies_paragraph')}
                </Text>
                <Text type={'h300'} alignment={'left'}>
                    {t('amazonSoftwareTerms_header')}
                </Text>
                <Text type={'b300'} alignment={'left'}>
                    {t('amazonSoftwareTerms_paragraph')}
                </Text>
                <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                    <Text type={'b200'} alignment={'left'}>
                        {t.cognate('1')}
                    </Text>
                    <Text type={'b200'} alignment={'left'}>
                        {t('amazonSoftwareTerms_bullet1')}
                    </Text>
                </Row>
                <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                    <Text type={'b200'} alignment={'left'}>
                        {t.cognate('2')}
                    </Text>
                    <Text type={'b200'} alignment={'left'}>
                        {t('amazonSoftwareTerms_bullet2')}
                    </Text>
                </Row>
                <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                    <Text type={'b200'} alignment={'left'}>
                        {t.cognate('3')}
                    </Text>
                    <Text type={'b200'} alignment={'left'}>
                        {t('amazonSoftwareTerms_bullet3')}
                    </Text>
                </Row>
                <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                    <Text type={'b200'} alignment={'left'}>
                        {t.cognate('4')}
                    </Text>
                    <Text type={'b200'} alignment={'left'}>
                        {t('amazonSoftwareTerms_bullet4')}
                    </Text>
                </Row>
                <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                    <Text type={'b200'} alignment={'left'}>
                        {t.cognate('5')}
                    </Text>
                    <Text type={'b200'} alignment={'left'}>
                        {t('amazonSoftwareTerms_bullet5')}
                    </Text>
                </Row>
                <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                    <Text type={'b200'} alignment={'left'}>
                        {t.cognate('6')}
                    </Text>
                    <Text type={'b200'} alignment={'left'}>
                        {t('amazonSoftwareTerms_bullet6')}
                    </Text>
                </Row>
            </Column>
        </>
    );
};

export { USTermsAndConditions };
