import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from '../blocks';

const Svg = styled.svg`
    width: 16px;
    height: 16px;
    color: blue;
`;

export const StatusPending = () => {
    const { t } = useTranslation('common');
    return (
        <Svg width={'16px'} height={'16px'} aria-label={t('icon-statusPendingLabel')}>
            <g id={'icon'}>
                <g>
                    <circle fill={'none'} stroke={'currentColor'} strokeWidth={'2px'} cx={'8'} cy={'8'} r={'7'} />
                    <polyline fill={'none'} stroke={'currentColor'} strokeWidth={'2px'} points={'8 5 8 9 5 9'} />
                </g>
            </g>
        </Svg>
    );
};
