import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Loader from '@amzn/meridian/loader';
import Row from '@amzn/meridian/row';
import { SelectOption } from '@amzn/meridian/select';
import React, { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHrpsApi } from '../../clients/useHrpsApi';
import { RETURN_TYPE } from '../../constants/returnType';
import { CreateReturnOrderFormErrors } from '../../pages/CreateReturnOrderPage/CreateReturnOrderPage';
import { ReferenceIdHelpPopover } from '../../pages/CreateReturnOrderPage/ReferenceIdHelpPopover';
import { ReturnOrderTypeHelpPopover } from '../../pages/CreateReturnOrderPage/ReturnOrderTypeHelpPopover';
import { getResellerDetails } from '../../redux/actions/resellerActionCreators';
import { Address, Location, RootState, ReturnOrderType } from '../../redux/types';
import { TimeWindow } from '../../types';
import { Logger } from '../../utils/logger';
import { Alert, Select, useTranslation } from '../blocks/localization';
import { MultiColumnInfoBox } from '../blocks/MultiColumnInfoBoxTile';
import { MultilineFieldEntry } from '../constructed';
import { ModifyAddressPopup } from '../constructed/ModifyAddressPopup/ModifyAddressPopup';
import { LabeledInput } from './LabeledInput';
import { SearchableAddressSelect } from './SearchableAddressSelect';

const CreateReturnOrderInfoBox: FC<CreateReturnOrderInfoBoxProps> = ({
    referenceId,
    setReferenceId,
    returnOrderType,
    setReturnOrderType,
    enabledReturnOrderTypes,
    shippingAddressLocationCode,
    setShippingAddressLocationCode,
    billingAddressLocationCode,
    setBillingAddressLocationCode,
    contactEmails,
    setContactEmails,
    errors,
    locations,
    resellerId,
}) => {
    const { t } = useTranslation(['createReturnOrderPage', 'modifyAddressPopup']);

    const {
        reseller: { getResellerReturnTimeWindows },
    } = useHrpsApi();

    const [isBuybackAllowed, setIsBuybackAllowed] = useState(false);
    const [isRefusalAllowed, setIsRefusalAllowed] = useState(false);

    const [areTimeWindowsLoading, setAreTimeWindowsLoading] = useState(false);

    const shippingAddresses: Record<string, Address> = {};
    const billingAddresses: Record<string, Address> = {};

    locations.forEach((location) => {
        if (location.shippingAddress) {
            shippingAddresses[location.code] = location.shippingAddress;
        }

        if (location.billingAddress) {
            billingAddresses[location.code] = location.billingAddress;
        }
    });

    useEffect(() => {
        (async () => {
            try {
                setAreTimeWindowsLoading(true);

                const response = await getResellerReturnTimeWindows({
                    resellerId: resellerId,
                });

                const currentTimestampInSeconds = new Date().getTime() / 1000;

                setIsBuybackAllowed(
                    response.timeWindows.some((timeWindow: TimeWindow) => {
                        return (
                            timeWindow.returnType === RETURN_TYPE.BUYBACK &&
                            timeWindow.startDate <= currentTimestampInSeconds &&
                            (!timeWindow.endDate || timeWindow.endDate >= currentTimestampInSeconds)
                        );
                    })
                );

                setIsRefusalAllowed(
                    response.timeWindows.some((timeWindow: TimeWindow) => {
                        return (
                            timeWindow.returnType === RETURN_TYPE.REFUSAL &&
                            timeWindow.startDate <= currentTimestampInSeconds &&
                            (!timeWindow.endDate || timeWindow.endDate >= currentTimestampInSeconds)
                        );
                    })
                );
            } catch (e) {
                Logger.debug(e);
            } finally {
                setAreTimeWindowsLoading(false);
            }
        })();
    }, [getResellerReturnTimeWindows, resellerId]);

    return (
        <MultiColumnInfoBox widths={['grid-6', 'grid-6']}>
            <Column width={'fill'} spacing={'large'}>
                <LabeledInput
                    label={t('referenceId-inputLabel')}
                    value={referenceId}
                    onChange={setReferenceId}
                    error={errors.referenceId}
                    dataTestId={'createReturnOrder.referenceId'}
                    helpBox={<ReferenceIdHelpPopover />}
                />

                {areTimeWindowsLoading ? (
                    <Loader size={'small'} />
                ) : (
                    <Column>
                        <Row widths={['fill', 'fit']}>
                            <Select
                                data-testid={'createReturnOrder.returnOrderType'}
                                value={returnOrderType}
                                onChange={setReturnOrderType}
                                placeholder={t('selectType-fieldPlaceholder')}
                                label={t('selectType-fieldLabel')}
                            >
                                {enabledReturnOrderTypes.find((type) => type === RETURN_TYPE.RETURN) && (
                                    <SelectOption
                                        key={RETURN_TYPE.RETURN}
                                        value={RETURN_TYPE.RETURN}
                                        data-testid={`createReturnOrder.returnOrderType.${RETURN_TYPE.RETURN}`}
                                        label={t('return-radioButtonLabel')}
                                    />
                                )}
                                {isBuybackAllowed &&
                                    enabledReturnOrderTypes.find((type) => type === RETURN_TYPE.BUYBACK) && (
                                        <SelectOption
                                            key={RETURN_TYPE.BUYBACK}
                                            value={RETURN_TYPE.BUYBACK}
                                            data-testid={`createReturnOrder.returnOrderType.${RETURN_TYPE.BUYBACK}`}
                                            label={t('goodStockReturn-radioButtonLabel')}
                                        />
                                    )}
                                {isRefusalAllowed &&
                                    enabledReturnOrderTypes.find((type) => type === RETURN_TYPE.REFUSAL) && (
                                        <SelectOption
                                            key={RETURN_TYPE.REFUSAL}
                                            value={RETURN_TYPE.REFUSAL}
                                            data-testid={`createReturnOrder.returnOrderType.${RETURN_TYPE.REFUSAL}`}
                                            label={t('refusal-radioButtonLabel')}
                                        />
                                    )}
                            </Select>
                            <ReturnOrderTypeHelpPopover />
                        </Row>
                        <Row>
                            {errors.returnOrderType && (
                                <div data-testid={'createReturnOrder.returnOrderType.validationError'}>
                                    <Alert type={'error'} size={'small'}>
                                        {errors.returnOrderType}
                                    </Alert>
                                </div>
                            )}
                        </Row>
                    </Column>
                )}
            </Column>
            <Column width={'fill'} spacing={'medium'}>
                <Box>
                    <SearchableAddressSelect
                        label={t('shippingAddress-selectLabel')}
                        value={shippingAddressLocationCode}
                        onChange={setShippingAddressLocationCode}
                        addressMap={shippingAddresses}
                        error={errors.shippingAddress}
                        dataTestId={'createReturnOrder.shippingAddress'}
                    />
                    <Row>
                        <ModifyAddressPopup
                            title={t('modifyAddressPopup:addShippingAddress-modalTitle')}
                            linkText={t('modifyAddressPopup:addAddress-linkLabel')}
                            type={'shipping'}
                            mode={'add'}
                        />
                        {shippingAddressLocationCode && (
                            <ModifyAddressPopup
                                title={t('modifyAddressPopup:editShippingAddress-modalTitle')}
                                linkText={t('modifyAddressPopup:editSelectedAddress-linkLabel')}
                                type={'shipping'}
                                mode={'edit'}
                                selectedLocationCode={shippingAddressLocationCode}
                            />
                        )}
                    </Row>
                </Box>
                <Box>
                    <SearchableAddressSelect
                        label={t('billingAddress-selectLabel')}
                        value={billingAddressLocationCode}
                        onChange={setBillingAddressLocationCode}
                        addressMap={billingAddresses}
                        error={errors.billingAddress}
                        dataTestId={'createReturnOrder.BillingAddress'}
                    />
                    <Row>
                        <ModifyAddressPopup
                            title={t('modifyAddressPopup:addBillingAddress-modalTitle')}
                            linkText={t('modifyAddressPopup:addAddress-linkLabel')}
                            type={'billing'}
                            mode={'add'}
                        />
                        {billingAddressLocationCode && (
                            <ModifyAddressPopup
                                title={t('modifyAddressPopup:editBillingAddress-modalTitle')}
                                linkText={t('modifyAddressPopup:editSelectedAddress-linkLabel')}
                                type={'billing'}
                                mode={'edit'}
                                selectedLocationCode={billingAddressLocationCode}
                            />
                        )}
                    </Row>
                </Box>
                <MultilineFieldEntry
                    label={t('contactEmailAddresses-inputLabel')}
                    value={contactEmails ?? []}
                    onChange={setContactEmails}
                    placeholderText={t('noEmail-placeholderText')}
                    error={errors.contactEmails}
                    infoMessage={t('useSemicolon-contactEmailAddressInputHint')}
                    dataTestId={'createReturnOrder.contactEmails'}
                />
            </Column>
        </MultiColumnInfoBox>
    );
};

const mapStateToProps = ({ resellerReducer }: RootState) => {
    return {
        resellerId: resellerReducer.selectedReseller.value!.resellerId,
    };
};

const mapDispatchToProps = { getResellerDetails };

export type CreateReturnOrderInfoBoxProps = {
    referenceId: string;
    setReferenceId: (referenceId: string) => void;
    returnOrderType: ReturnOrderType;
    setReturnOrderType: (returnType: ReturnOrderType) => void;
    enabledReturnOrderTypes: ReturnOrderType[];
    shippingAddressLocationCode?: string;
    setShippingAddressLocationCode: (locationCode: string) => void;
    billingAddressLocationCode?: string;
    setBillingAddressLocationCode: (locationCode: string) => void;
    contactEmails?: string[];
    setContactEmails: (emails: string[]) => void;
    errors: CreateReturnOrderFormErrors;
    locations: Location[];
} & ConnectedProps<typeof connector>;

const connector = connect(mapStateToProps, mapDispatchToProps);
const connectedCreateReturnOrderInfoBox = connector(CreateReturnOrderInfoBox);
export { connectedCreateReturnOrderInfoBox as CreateReturnOrderInfoBox };
