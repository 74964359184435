import MeridianLink from '@amzn/meridian/link';
import { LinkPropsWithRef } from '@amzn/meridian/link/link';
import React from 'react';
import { localizeWithRef } from './localize';

/**
 * Implements default "reload-page" link behavior.
 * Adds rel (per https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types) in case we want to style
 * these links differently.
 * ExternalLink is in contrast to Link which implements HTML5/SPA link navigation.
 */
const ExternalLink = React.forwardRef<any, Omit<LinkPropsWithRef, 'rel'>>((props, ref) => {
    return <MeridianLink {...props} ref={ref} rel={'external'} />;
});

const LocalizedExternalLink = localizeWithRef(ExternalLink, ['children']);

export default LocalizedExternalLink;
