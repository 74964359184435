import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import styled from '@emotion/styled';
import React, { FC, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../redux/types';
import { color } from '../../theme/colors';
import { IMenuItem, locales } from '../../types';
import { RPMenu } from '../composites';
import { Language } from '../icons';
import { Clickable } from './Clickable';
import { useTranslation } from './localization';

type DivProps = { backgroundImage: string };
type UnauthenticatedAppLayoutProps = ConnectedProps<typeof connector>;

const Div = styled.div<DivProps>`
    width: 100%;
    min-height: 100vh;
    background-image: ${(props) => `url(${props.backgroundImage})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const Logo = styled.img`
    width: 30px;
`;

const LightHeading = styled(Heading)`
    color: ${color.gray[100]};
`;

const UnauthenticatedAppLayout: FC<UnauthenticatedAppLayoutProps> = ({
    applicationImgUrl,
    applicationBackgroundImgUrl,
    children,
}) => {
    const { t } = useTranslation(['pageTitles', 'languages']);

    const menu: IMenuItem[] = [
        {
            linkId: 'changeLanguage',
            path: '/account/change-language',
            icon: Language,
            dropdown: locales.map((key) => {
                return {
                    linkId: key,
                    path: `/account/change-language/${key}`,
                    linkTitle: t('languages:locale', { context: key }),
                };
            }),
        },
    ];

    const determineCurrentTab = () => {
        return menu.filter((i) => window.location.pathname.startsWith(i.path))[0] || menu[0];
    };

    const [currentTab, setCurrentTab] = useState(determineCurrentTab());
    const specialItemContent = null;

    const updateCurrentTab = () => {
        setCurrentTab(determineCurrentTab());
    };

    return (
        <Div backgroundImage={applicationBackgroundImgUrl}>
            <Row spacing={'xlarge'} width={'100%'} spacingInset={'xlarge'} alignmentHorizontal={'center'}>
                <Column
                    spacing={'medium'}
                    width={'50%'}
                    spacingInset={'none'}
                    alignmentHorizontal={'left'}
                    heights={['fit', 'fill']}
                >
                    <Row width={'100%'} widths={['fill', 'fit']}>
                        <Row alignmentHorizontal={'left'}>
                            <LightHeading level={1}>{'Reseller Portal'}</LightHeading>
                            <Logo src={applicationImgUrl} alt={'Reseller Portal Logo'} />
                        </Row>
                        <Row alignmentHorizontal={'right'}>
                            {menu.map((item) => {
                                return (
                                    <Clickable key={item.linkId} onClick={updateCurrentTab}>
                                        <RPMenu
                                            key={`${item.linkId}RPMenu`}
                                            isSelected={currentTab.linkId === item.linkId}
                                            includePadding={true}
                                            item={item}
                                        >
                                            {specialItemContent}
                                        </RPMenu>
                                    </Clickable>
                                );
                            })}
                        </Row>
                    </Row>
                    <Column width={'100%'}>{children}</Column>
                </Column>
            </Row>
        </Div>
    );
};

const mapStateToProps = ({ systemReducer }: RootState) => {
    return {
        applicationImgUrl: systemReducer.applicationImgUrl,
        applicationBackgroundImgUrl: systemReducer.applicationBackgroundImgUrl,
    };
};

const connector = connect(mapStateToProps);
const ConnectedUnauthenticatedAppLayout = connector(UnauthenticatedAppLayout);

export { ConnectedUnauthenticatedAppLayout as UnauthenticatedAppLayout };
