import { Action } from 'redux';
import { IAsynchronouslyLoadedValue, ClaimListClaim, ClaimsListOutput, ClaimTableSortDirection } from '../types';

export const CLAIM_LIST_CLEAR = 'claimList/clear';
export const CLAIM_LIST_ERROR = 'claimList/error';
export const CLAIM_LIST_GET = 'claimList/get';
export const CLAIM_LIST_LOADED = 'claimList/loaded';
export const CLAIM_LIST_SORT_CHANGE = 'claimList/sortChange';
export const CLAIM_LIST_QUERY_CHANGE = 'claimList/queryChange';
export const CLAIM_LIST_DATE_RANGE_CHANGE = 'claimList/dateRangeChange';
export const CLAIM_LIST_PAGE_CHANGE = 'claimList/pageChange';
export const CLAIM_LIST_LOAD_MORE_DATA = 'claimList/loadMoreData';

export interface ClaimListGetAction extends Action {
    type: typeof CLAIM_LIST_GET;
    payload: IAsynchronouslyLoadedValue<ClaimListClaim>;
}

export interface ClaimListLoadedAction extends Action {
    type: typeof CLAIM_LIST_LOADED;
    payload: ClaimsListOutput;
}

export interface ClaimListErrorAction extends Action {
    type: typeof CLAIM_LIST_ERROR;
    payload: IAsynchronouslyLoadedValue<ClaimListClaim>;
}

export interface ClaimListClearAction extends Action {
    type: typeof CLAIM_LIST_CLEAR;
    payload: IAsynchronouslyLoadedValue<ClaimListClaim>;
}

export interface ClaimListSortAction extends Action {
    type: typeof CLAIM_LIST_SORT_CHANGE;
    payload: {
        sortColumn: string;
        sortDirection: ClaimTableSortDirection;
    };
}

export interface ClaimListQueryAction extends Action {
    type: typeof CLAIM_LIST_QUERY_CHANGE;
    payload: {
        query: string;
    };
}

export interface ClaimListDateRangeAction extends Action {
    type: typeof CLAIM_LIST_DATE_RANGE_CHANGE;
    payload: {
        dateRange: string[];
    };
}

export interface ClaimListPageAction extends Action {
    type: typeof CLAIM_LIST_PAGE_CHANGE;
    payload: {
        currentPage: number;
        currentRecordNumber: number;
    };
}

export interface ClaimListLoadMoreDataAction extends Action {
    type: typeof CLAIM_LIST_LOAD_MORE_DATA;
    payload: {
        currentPage: number;
        currentRecordNumber: number;
    };
}

export type ClaimListActions =
    | ClaimListGetAction
    | ClaimListLoadedAction
    | ClaimListErrorAction
    | ClaimListClearAction
    | ClaimListSortAction
    | ClaimListQueryAction
    | ClaimListDateRangeAction
    | ClaimListPageAction
    | ClaimListLoadMoreDataAction;
