import { AlertType } from '@amzn/meridian/alert/alert';
import Toaster, { ToasterToasts } from '@amzn/meridian/toaster/toaster';
import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { Logger } from '../../utils/logger';
import { Alert, TranslatedString } from '../blocks';

export type MessageType = AlertType;

export type Message = ToasterToasts & { message: { text: TranslatedString; type: AlertType } };

type PageMessagingContextType = { showMessage: (message: { type: MessageType; text: TranslatedString }) => void };

export const PageMessagingContext = React.createContext<PageMessagingContextType>({
    showMessage: () => Logger.error('You must be inside <PageMessaging> element to call this method.'),
});

export const usePageMessaging = () => {
    const { showMessage } = React.useContext(PageMessagingContext);

    return useMemo(
        () => ({
            showSuccess: (text: TranslatedString) => {
                showMessage({ type: 'success', text });
            },
            showError: (text: TranslatedString) => {
                showMessage({ type: 'error', text });
            },
        }),
        [showMessage]
    );
};

const successMessageTimeout_ms = 3000;

const PageMessaging = ({ children }: PropsWithChildren<{}>) => {
    const [toasts, setToasts] = useState<Record<string, Message>>({});

    const showMessage = useCallback(({ type, text }: { type: MessageType; text: TranslatedString }) => {
        const id = `${Math.random() * 1e10}`;
        setToasts((toasts) => ({
            [id]: { id, timeout: type === 'success' ? successMessageTimeout_ms : undefined, message: { text, type } },
            ...toasts,
        }));
    }, []);

    function removeToast(id: string) {
        setToasts((toasts) => {
            const { [id]: removedToast, ...remainingToasts } = toasts;
            Logger.debug(`Removed toast Id: ${removedToast}`);
            return remainingToasts;
        });
    }

    return (
        <PageMessagingContext.Provider value={{ showMessage }}>
            <Toaster toasts={Object.values(toasts)} onCloseToast={removeToast}>
                {(toast) => (
                    <Alert toast={true} onClose={toast.onClose} type={toast.message.type}>
                        {toast.message.text}
                    </Alert>
                )}
            </Toaster>
            {children}
        </PageMessagingContext.Provider>
    );
};

export { PageMessaging };
