import { Reducer } from 'redux';
import { INITIAL_RETURN_ORDER_STATE } from '../../constants';
import {
    RETURN_ORDER_CLEAR,
    RETURN_ORDER_GET,
    RETURN_ORDER_LOADED,
    ReturnOrderActions,
} from '../actions/returnOrderActions';
import { ReturnOrderState } from '../types';

export const returnOrderReducer: Reducer<ReturnOrderState, ReturnOrderActions> = (
    state = INITIAL_RETURN_ORDER_STATE,
    action
): ReturnOrderState => {
    switch (action.type) {
        case RETURN_ORDER_GET:
            return {
                ...state,
                returnOrder: {
                    value: action.payload.value,
                    error: action.payload.error,
                    status: action.payload.status,
                },
            };
        case RETURN_ORDER_LOADED:
            return {
                ...state,
                returnOrder: {
                    value: action.payload.value,
                    error: action.payload.error,
                    status: action.payload.status,
                },
            };
        case RETURN_ORDER_CLEAR:
            return {
                ...state,
                ...INITIAL_RETURN_ORDER_STATE,
            };
        default:
            return state;
    }
};
