import React, { ReactElement, ReactPortal, ReactNodeArray } from 'react';
import { TranslatedString } from './useTranslation';

// HACK: react type library included `{}` in definition of ReactNode which complicates things
// pretty much everything can be reduced to {} so it is impossible(?) to remove {} without
// removing everything else from the type.
// So instead we check if type allows for ReactElement - and if it does, we assume it is meant to accept
// all "react-ish" types (and we swap out string for TranslatedString)
type LocalizedElementProps<T, S extends (keyof T)[]> = {
    [K in keyof T]: K extends keyof Pick<T, S[number]>
        ? ReactElement extends T[K]
            ? Exclude<T[K], {}> | ReactNodeArray | ReactPortal | ReactElement | TranslatedString
            : TranslatedString
        : T[K];
};

// checks the types of translatedProps for the WrappedComponent to be of type TranslatedString before passing them down
// as T -- we lose type safety on these so be careful defining only translatable props (strings) in translatedProps
const localize = <T extends Object, S extends (keyof T)[]>(
    WrappedComponent: React.ComponentType<T>,
    // eslint-disable-next-line unused-imports/no-unused-vars
    translatedProps: S
) => {
    const LocalizedElement = (props: LocalizedElementProps<T, S>) => {
        return <WrappedComponent {...(props as T)} />;
    };
    return LocalizedElement;
};

// use with components that implement ref-forwarding -- note we lose type safety on whether ref is allowed for component
const localizeWithRef = <T extends Object, S extends (keyof T)[]>(
    WrappedComponent: React.ComponentType<T>,
    // eslint-disable-next-line unused-imports/no-unused-vars
    translatedProps: S
) => {
    const LocalizedElement = React.forwardRef<any, LocalizedElementProps<T, S>>((props, ref) => {
        return <WrappedComponent ref={ref} {...(props as T)} />;
    });
    return LocalizedElement;
};

export { localize, localizeWithRef };
