import { IAsynchronouslyLoadedValue } from './asynchronouslyLoadedValue';

export type ClaimState = {
    claim: IAsynchronouslyLoadedValue<Claim>;
};

export enum ClaimStatus {
    APPROVED = 'APPROVED',
    CREATED = 'CREATED',
    IN_PROGRESS = 'IN_PROGRESS',
    ERRORED = 'ERRORED',
}

export type Claim = {
    resellerClaimNumber: string;
    resellerId: string;
    fileId: string;
    submissionDate: number;
    errorList: string[];
    claimType: ClaimType;
    status: ClaimStatus;
    createdBy: string;
    version: number;
};

export interface ClaimAttachedDocument {
    id: string;
    type: string;
    name: string;
    description: string;
    uploadedAt: Date;
    uploadedBy?: string;
    presignedURL: string;
}

export const claimTypes = ['TPR'];

export type ClaimType = typeof claimTypes[number];

export const claimStatuses = ['CREATED', 'APPROVED', 'IN_PROGRESS', 'ERRORED'];

export enum ClaimReceiptMethods {
    EMAIL_NON_PARAGON = 'Email (Non-Paragon)',
    PARAGON = 'Paragon',
    PARAGON_TRANSFORMATION_REQUIRED = 'Paragon - Transformation Required',
    PARAGON_SSP = 'Paragon SSP',
    REMITTANCE = 'Remittance',
    WORKDOCS = 'WorkDocs',
    TICKET = 'Ticket',
    TICKET_TRANSFORMATION_REQUIRED = 'Ticket - Transformation Required',
    CUSTOMER_PORTAL = 'Customer Portal',
    EMAIL_RING = 'Email (Ring)',
    OTHER = 'Other',
    CLAIM_SCHEDULE = 'Claim Schedule',
    CLAIM_TEMPLATE_UPLOAD = 'Claim Template Upload',
}

export type ClaimUploadProgress = {
    fileName: string;
    progress: number;
};

export type ClaimProcessingStatus = typeof claimStatuses[number];
