import Icon from '@amzn/meridian/icon';
import Menu from '@amzn/meridian/menu';
import React, { FC, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IMenuItem } from '../../types';
import { Logger } from '../../utils/logger';
import { Clickable, MenuItem, useTranslation } from '../blocks';
import { RPMenuItem } from '.';

export interface RPMenuProps {
    item: IMenuItem;
    isSelected: boolean;
    includePadding: boolean;
    children?: React.ReactNode;
}

const RPMenu: FC<RPMenuProps> = ({ item, isSelected, includePadding, children }: RPMenuProps) => {
    const [openDropdown, setOpenDropdown] = useState(false);
    const dropdownRef = useRef();
    const history = useHistory();
    const { i18n } = useTranslation(['pageTitle']);

    const onClickDropdownLink = (path: string) => {
        history.push(path);
        setOpenDropdown(false);
    };

    const changeLanguage = (path: string) => {
        const lng = path.substr(25);
        Logger.debug(`Change Language to ${lng}`);
        i18n.changeLanguage(lng);
        setOpenDropdown(false);
    };

    if (item.dropdown) {
        if (item.icon) {
            Logger.debug(`Icon Type = ${typeof item.icon}`);
        }
        return (
            <React.Fragment key={item.linkId}>
                <Clickable key={item.linkId} onClick={() => setOpenDropdown(true)}>
                    <RPMenuItem
                        menuItem={item}
                        isSelected={isSelected}
                        includePadding={includePadding}
                        ref={dropdownRef}
                    >
                        {children || item.linkTitle}
                        {item.icon && typeof item.icon === 'object' && <Icon tokens={item.icon} />}
                        {item.icon && typeof item.icon === 'function' && React.createElement(item.icon)}
                    </RPMenuItem>
                </Clickable>
                <Menu
                    anchorNode={dropdownRef.current}
                    open={openDropdown}
                    position={'bottom'}
                    onClose={() => setOpenDropdown(false)}
                >
                    {item.dropdown.map((menuItem) => {
                        return (
                            <MenuItem
                                key={menuItem.linkId}
                                onClick={
                                    menuItem.path.startsWith('/account/change-language/')
                                        ? () => changeLanguage(menuItem.path)
                                        : () => onClickDropdownLink(menuItem.path)
                                }
                            >
                                {menuItem.linkTitle}
                            </MenuItem>
                        );
                    })}
                </Menu>
            </React.Fragment>
        );
    }
    return (
        <Clickable key={item.linkId} onClick={() => history.push(item.path)}>
            <RPMenuItem menuItem={item} isSelected={isSelected} includePadding={includePadding}>
                {children || item.linkTitle}
                {item.icon && <Icon tokens={item.icon} />}
            </RPMenuItem>
        </Clickable>
    );
};

export { RPMenu };
