import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import React, { FC } from 'react';
import { Alert, TranslatedString } from '../blocks/localization';
import { SearchableSelect } from '.';

const LabeledSearchableSelect: FC<LabeledSearchableSelectProps> = ({
    label,
    value,
    onChange,
    selectOptions,
    isDisabled,
    placeholder,
    error,
    dataTestId,
}) => {
    return (
        <Column spacing={'xsmall'}>
            <SearchableSelect
                value={value}
                label={label}
                onChange={onChange}
                selectOptions={selectOptions}
                isDisabled={isDisabled}
                placeholder={placeholder}
                dataTestId={dataTestId}
            />
            <Row>
                {error && (
                    <div data-testid={dataTestId && `${dataTestId}.validationError`}>
                        <Alert type={'error'} size={'small'}>
                            {error}
                        </Alert>
                    </div>
                )}
            </Row>
        </Column>
    );
};

type LabeledSearchableSelectProps = {
    label: TranslatedString;
    value: any;
    onChange?(value: string): void;
    selectOptions: { value: string; label: TranslatedString }[];
    isDisabled?: boolean;
    placeholder?: TranslatedString;
    error?: TranslatedString;
    dataTestId?: string;
};

export { LabeledSearchableSelect };
