import Column from '@amzn/meridian/column';
import Link from '@amzn/meridian/link';
import Popover, { PopoverHeader } from '@amzn/meridian/popover';
import Row from '@amzn/meridian/row';
import Textarea from '@amzn/meridian/textarea';
import React, { useRef, useState } from 'react';
import { useHrpsApi } from '../../clients/useHrpsApi';
import { Button, useTranslation, Text, TranslatedString } from '../blocks/localization';
import { usePageMessaging } from '../composites';

export interface DelightAdditionalFeedbackPopoverProps {
    label: TranslatedString;
    issueId: string;
    username: string;
    resellerId: string;
    dataTestId?: string;
}

const DelightAdditionalFeedbackPopover = ({
    label,
    issueId,
    resellerId,
    dataTestId,
}: DelightAdditionalFeedbackPopoverProps) => {
    const { t } = useTranslation('delight');
    const buttonRef = useRef();
    const [open, setOpen] = useState(false);
    const [additionalFeedback, setAdditionalFeedback] = useState('');
    const [isBusy, setIsBusy] = useState(false);

    const { showSuccess, showError } = usePageMessaging();
    const { issues } = useHrpsApi();

    const onClickButton = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    async function submit() {
        setIsBusy(true);
        try {
            await issues.addCorrespondence({
                resellerId: resellerId,
                issueId: issueId,
                correspondence: additionalFeedback,
            });
            showSuccess(t('submitAdditionalFeedback-successMessage'));
        } catch {
            showError(t('submitAdditionalFeedback-errorMessage'));
        } finally {
            setIsBusy(false);
        }
        setOpen(false);
    }

    return (
        <>
            <Link onClick={onClickButton} ref={buttonRef} type={'primary'} data-testid={`${dataTestId}.link`}>
                <Text>{label}</Text>
            </Link>
            <Popover
                anchorNode={buttonRef.current}
                open={open}
                onClose={onClose}
                position={'bottom'}
                alignment={'right'}
                minWidth={600}
                maxWidth={600}
            >
                <PopoverHeader closeLabel={'Close'} data-testid={`${dataTestId}.popoverHeader`}>
                    {t('additionalFeedbackPopover-headerText')}
                </PopoverHeader>
                <Column spacing={'medium'} spacingInset={'small'}>
                    <Textarea
                        label={t('additionalFeedbackTextarea-placeholderText')}
                        value={additionalFeedback}
                        onChange={setAdditionalFeedback}
                    />
                    <Row>
                        <Button
                            type={'secondary'}
                            onClick={submit}
                            isBusy={isBusy}
                            data-testid={`${dataTestId}.submitButton`}
                            disabled={additionalFeedback === ''}
                        >
                            {t('submitAdditionalFeedback-buttonText')}
                        </Button>
                        <Button type={'secondary'} onClick={onClose} data-testid={`${dataTestId}.cancelButton`}>
                            {t('cancelAdditionalFeedback-buttonText')}
                        </Button>
                    </Row>
                </Column>
            </Popover>
        </>
    );
};

export { DelightAdditionalFeedbackPopover };
