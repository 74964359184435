import { Reducer } from 'redux';
import { INITIAL_MANAGED_USERS_STATE } from '../../constants';
import {
    MANAGED_USER_GET,
    MANAGED_USER_LOADED,
    MANAGED_USERS_CLEAR,
    MANAGED_USERS_GET,
    MANAGED_USERS_LOADED,
    ManagedUserActions,
} from '../actions/managedUserActions';
import { IManagedUsersState } from '../types/index';

export const managedUserReducer: Reducer<IManagedUsersState, ManagedUserActions> = (
    state = INITIAL_MANAGED_USERS_STATE,
    action
): IManagedUsersState => {
    switch (action.type) {
        case MANAGED_USERS_GET:
            return {
                ...state,
                managedUsers: {
                    value: action.payload.managedUsers,
                    error: action.payload.error,
                    status: action.payload.status,
                },
            };
        case MANAGED_USERS_LOADED:
            return {
                ...state,
                managedUsers: {
                    value: action.payload.managedUsers,
                    error: action.payload.error,
                    status: action.payload.status,
                },
            };
        case MANAGED_USER_GET:
            return {
                ...state,
                selectedManagedUser: {
                    value: action.payload.managedUser,
                    error: action.payload.error,
                    status: action.payload.status,
                },
            };
        case MANAGED_USER_LOADED:
            return {
                ...state,
                selectedManagedUser: {
                    value: action.payload.managedUser,
                    error: action.payload.error,
                    status: action.payload.status,
                },
            };
        case MANAGED_USERS_CLEAR:
            return {
                ...state,
                ...INITIAL_MANAGED_USERS_STATE,
            };
        default:
            return state;
    }
};
