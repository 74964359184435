import Cookies, { CookieAttributes } from 'js-cookie';
import { Logger } from './logger';

export const EXPIRE_TIME_COOKIE_KEY = 'session-expire-time';
export const DEFAULT_SESSION_EXPIRATION_IN_MS = 5 * 60000;

export class ExpiringSessionCookieStorage {
    private static options: CookieAttributes = {
        path: '/',
        domain: window.location.hostname,
        secure: true,
        sameSite: 'strict',
    };

    private static validateUserSession() {
        if (ExpiringSessionCookieStorage.timeToSessionExpiration() <= 0) {
            ExpiringSessionCookieStorage.clear();
        }
    }

    // defined as the removal of the last of any cookies set by Amplify from storage
    private static sessionHasEnded() {
        const cookies = Object.keys(Cookies.get());
        return cookies.length === 1 && cookies[0] === EXPIRE_TIME_COOKIE_KEY;
    }

    static timeToSessionExpiration() {
        const expireTime = Cookies.get(EXPIRE_TIME_COOKIE_KEY);
        if (expireTime === undefined) return -Infinity;
        return parseInt(expireTime) - Date.now();
    }

    static refreshSessionExpiration(timeoutInMs: number) {
        Logger.debug('{CookieStorage}', 'Refreshing expiration cookie. Added number of min:', timeoutInMs / 60000);
        const expireTime = Date.now() + timeoutInMs;
        Cookies.set(EXPIRE_TIME_COOKIE_KEY, expireTime.toString(), ExpiringSessionCookieStorage.options);
    }

    // The following methods are used by Amplify for user session management
    static setItem(key: string, value: string) {
        Logger.debug('{CookieStorage}', 'Adding item to cookie storage. Key:', key, 'Value:', value);
        if (Cookies.get(EXPIRE_TIME_COOKIE_KEY) === undefined) {
            ExpiringSessionCookieStorage.refreshSessionExpiration(DEFAULT_SESSION_EXPIRATION_IN_MS);
        }
        Cookies.set(key, value, ExpiringSessionCookieStorage.options);
        return Cookies.get(key);
    }

    static getItem(key: string) {
        Logger.debug('{CookieStorage}', 'Retrieving item from cookie storage. Key:', key);
        ExpiringSessionCookieStorage.validateUserSession();
        return Cookies.get(key);
    }

    static removeItem(key: string) {
        Logger.debug('{CookieStorage}', 'Removing item from cookie storage. Key:', key);
        Cookies.remove(key, ExpiringSessionCookieStorage.options);
        if (ExpiringSessionCookieStorage.sessionHasEnded()) {
            Logger.debug('{CookieStorage}', 'Removing item from cookie storage. Key:', EXPIRE_TIME_COOKIE_KEY);
            Cookies.remove(EXPIRE_TIME_COOKIE_KEY, ExpiringSessionCookieStorage.options);
        }
    }

    static clear() {
        Logger.debug('{CookieStore}', 'Clearing cookie store.');
        Object.keys(Cookies.get()).forEach((cookie) => {
            Cookies.remove(cookie, ExpiringSessionCookieStorage.options);
        });
    }
}
