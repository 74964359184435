import { useCallback } from 'react';
import { useHrpsApi } from '../../clients/useHrpsApi';
import { COMPLIANCE_RESOLUTION } from '../../constants';
import { ResellerItem, CreateReturnOrderLineItem, PriceListItem } from '../../redux/types';
import { CalculateComplianceLineItem } from '../../types';
import { Logger } from '../../utils/logger';

export const useComplianceCalculator = () => {
    const {
        contractTerms: { calculateContractTermsCompliance },
    } = useHrpsApi();

    const getValidLineItems = useCallback((orderLines: CreateReturnOrderLineItem[]): CreateReturnOrderLineItem[] => {
        Logger.debug('getValidLineItems');
        Logger.debug(orderLines);
        return orderLines.filter(
            (lineItem) =>
                lineItem.externalSku &&
                lineItem.quantity > 0 &&
                lineItem.reason !== COMPLIANCE_RESOLUTION.ACCEPT &&
                lineItem.reason !== COMPLIANCE_RESOLUTION.DISPOSE &&
                lineItem.reason !== COMPLIANCE_RESOLUTION.REQUEST
        );
    }, []);

    const buildComplianceCalculationInputLines = useCallback(
        (validOrderLines: CreateReturnOrderLineItem[], items: ResellerItem[]) => {
            Logger.debug('buildComplianceCalculationInputLines');
            Logger.debug(validOrderLines);
            const calculateComplianceLineItems: CalculateComplianceLineItem[] = [];
            const invalidSkus: string[] = [];

            validOrderLines.forEach((lineItem) => {
                const matchedItem = items.find((item) => item.externalSku === lineItem.externalSku);

                if (!matchedItem) {
                    invalidSkus.push(lineItem.externalSku!);
                } else {
                    calculateComplianceLineItems.push({
                        itemId: matchedItem.itemId,
                        asin: matchedItem.asin,
                        quantity: parseInt((lineItem.quantity as unknown) as string),
                        complianceResolution: lineItem.complianceResolution
                            ? lineItem.complianceResolution
                            : COMPLIANCE_RESOLUTION.NONE,
                    });
                }
            });

            return { calculateComplianceLineItems, invalidSkus };
        },
        []
    );

    const calculateOrderLinesCompliance = useCallback(
        async (
            priceListItems: PriceListItem[],
            resellerId: string,
            externalSkuToItemMap: Map<string, ResellerItem>,
            validOrderLines: CreateReturnOrderLineItem[],
            calculateComplianceLineItems: CalculateComplianceLineItem[],
            returnType: string
        ): Promise<Record<number, CreateReturnOrderLineItem>> => {
            Logger.debug('calculateOrderLinesCompliance');
            Logger.debug(calculateComplianceLineItems);

            const complianceOutput = await calculateContractTermsCompliance({
                resellerId: resellerId,
                calculateComplianceLineItems: calculateComplianceLineItems,
                returnType: returnType,
            });

            const complianceResults: Record<number, CreateReturnOrderLineItem> = {};
            const complianceMap = new Map(
                complianceOutput.ComplianceCalculationResults.map((item) => [item.asin, { ...item }])
            );

            validOrderLines.forEach((line) => {
                const complianceResult = complianceMap.get(externalSkuToItemMap.get(line.externalSku!)!.asin)!;
                complianceResults[line.orderLineId] = {
                    ...line,
                    approvedCount: Math.min(complianceResult.complianceEligibleReturnQuantity, line.totalQuantity!),
                    estimatedCredit: (
                        (priceListItems.find((item) => item.itemId === line.itemId)?.basePrice ?? 0) *
                        (Math.min(complianceResult.complianceEligibleReturnQuantity, line.totalQuantity!) ?? 0)
                    ).toString(),
                    reason:
                        complianceResult.complianceEligibleReturnQuantity < line.totalQuantity!
                            ? (complianceResult.reasonNotCompliant as COMPLIANCE_RESOLUTION)
                            : undefined,
                    eligibility: complianceResult.complianceEligibleReturnQuantity,
                    complianceCalculationStatus: 'Complete',
                };
                complianceResult.complianceEligibleReturnQuantity -= Math.min(
                    complianceResult.complianceEligibleReturnQuantity,
                    line.totalQuantity!
                );
            });

            Logger.debug('calculateOrderLinesCompliance results:');
            Logger.debug(complianceResults);
            return complianceResults;
        },
        [calculateContractTermsCompliance]
    );

    return { getValidLineItems, buildComplianceCalculationInputLines, calculateOrderLinesCompliance };
};
