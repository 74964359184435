import Column from '@amzn/meridian/column';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import React, { FC } from 'react';
import actionDropdown from '../../../assets/actionDropdown.png';
import createReturnOrderNavigation from '../../../assets/createReturnOrderNavigation.png';
import createReturnOrderScreen from '../../../assets/createReturnOrderScreen.png';
import { SiteHelpCallback } from '../../../constants';
import { ItemImage, Text, useTranslation } from '../../blocks';
import { IntroductionHelp } from './IntroductionHelp';

const CreateReturnOrderHelp: FC<SiteHelpCallback> = ({ callback }: SiteHelpCallback) => {
    const { t } = useTranslation(['createReturnOrderHelp', 'siteHelpPopover']);

    return (
        <Column spacing={'medium'} maxWidth={'25em'} overflowY={'auto'} maxHeight={'45em'}>
            <Row width={'100%'} widths={['85%', '15%']}>
                <Text type={'h200'} alignment={'center'}>
                    {t('createReturnOrderHelp_Title')}
                </Text>
                <Link
                    onClick={() => {
                        callback(IntroductionHelp);
                    }}
                >
                    {t('siteHelpPopover:siteHelpPopover_helpHomePage')}
                </Link>
            </Row>
            <Text type={'b300'} alignment={'left'}>
                {t('createReturnOrderHelp_purpose')}
            </Text>
            <Row spacing={'medium'}>
                <Column width={'2%'} />
                <Column spacing={'xsmall'} width={'90%'}>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline1')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('createReturnOrderHelp_navigateToCreateReturnOrder')}
                        </Text>
                    </Row>
                    <Row>
                        <ItemImage src={createReturnOrderNavigation} />
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline2')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('createReturnOrderHelp_enterReferenceId')}
                        </Text>
                    </Row>
                    <Row>
                        <ItemImage src={createReturnOrderScreen} />
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline3')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('createReturnOrderHelp_locationContactData')}
                        </Text>
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline4')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('createReturnOrderHelp_inputItemData')}
                        </Text>
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline5')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('createReturnOrderHelp_actions')}
                        </Text>
                    </Row>
                    <Row spacing={'medium'} widths={['2%', '70%', '28%']}>
                        <Column width={'2%'} />
                        <Column spacing={'xsmall'}>
                            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                                <Text type={'b200'} alignment={'left'}>
                                    {t.cognate('•')}
                                </Text>
                                <Text type={'b200'} alignment={'left'}>
                                    {t('createReturnOrderHelp_override')}
                                </Text>
                            </Row>
                            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                                <Text type={'b200'} alignment={'left'}>
                                    {t.cognate('•')}
                                </Text>
                                <Text type={'b200'} alignment={'left'}>
                                    {t('createReturnOrderHelp_requestManualReview')}
                                </Text>
                            </Row>
                            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                                <Text type={'b200'} alignment={'left'}>
                                    {t.cognate('•')}
                                </Text>
                                <Text type={'b200'} alignment={'left'}>
                                    {t('createReturnOrderHelp_noCredit')}
                                </Text>
                            </Row>
                        </Column>
                        <ItemImage src={actionDropdown} />
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline6')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('createReturnOrderHelp_reviewReturnRequest')}
                        </Text>
                    </Row>
                </Column>
            </Row>
        </Column>
    );
};

export { CreateReturnOrderHelp };
