import { IAsynchronouslyLoadedValue } from './asynchronouslyLoadedValue';

export type ClaimListState = {
    currentPage: number;
    query: string;
    sortColumn: string;
    sortDirection: ClaimTableSortDirection;
    dateRange: string[];
    startAt: number;
    currentRecordNumber: number;
    totalRecords: number | undefined;
    claimListData: IAsynchronouslyLoadedValue<ClaimListClaim[]>;
};

export type ClaimProcessingError = {
    errorCode: string;
    errorMessage: string;
    parameters: Object;
};

export enum CreditMemoStatus {
    COMPLETED = 'COMPLETED',
    PROCESSING = 'PROCESSING',
    NOT_PROCESSED = 'NOT_PROCESSED',
    NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export type ClaimListClaim = {
    resellerClaimNumber: string;
    createdByUser: string;
    submissionDate: number;
    resellerId: string;
    fileId: string;
    status: string;
    errorList: ClaimProcessingError[];
    creditMemoLink: string;
    creditMemoStatus: CreditMemoStatus;
};

export type ClaimFilter = {
    searchValueMap?: {
        resellerClaimNumber: string[];
    };
    dateRangeSearchMap?: {
        submissionDate: DateRange;
    };
};

export type DateRange = {
    fromDate: number;
    toDate: number;
};

export type ClaimSortAttribute = {
    key: string;
    value: string;
};

export type ClaimsListOutput = {
    claimsList: ClaimListClaim[];
    totalResults: number;
};

export enum ClaimTableSortDirection {
    ascending = 'ascending',
    descending = 'descending',
}
