import Column from '@amzn/meridian/column';
import Divider from '@amzn/meridian/divider';
import Row from '@amzn/meridian/row';
import { css } from 'emotion';
import _ from 'lodash';
import React, { PropsWithChildren, useMemo, useState } from 'react';
import { PropsWithTestId } from '../../utils/testId';
import { Button, SearchField, Text, useTranslation } from '../blocks';

export interface Document {
    documentId: string;
    name: string;
    description: string;
    uploadTimestamp: Date;
}

const linkButton = css`
    padding: 0 !important;
    height: 20px !important;
`;

type DocumentDownloadFunction = (documentId: string) => void;
type DocumentListPopupProps = PropsWithTestId<{ documents: Document[]; onDocumentDownload: DocumentDownloadFunction }>;
export const DocumentListPopup = (props: DocumentListPopupProps) => {
    const { t } = useTranslation('documentListPopup');
    const [search, onSearchChange] = useState<string>('');
    const { dataTestId, documents } = props;

    const buildTestId = (componentId: string) => dataTestId && `${props.dataTestId}.${componentId}`;

    const documentsToRender = useMemo(() => {
        const normalizedSearch = search.toLowerCase();
        const filteredDocuments =
            search.length === 0
                ? documents
                : _.filter(
                      documents,
                      (document) =>
                          document.name.toLowerCase().includes(normalizedSearch) ||
                          document.description.toLowerCase().includes(normalizedSearch)
                  );
        // Note: sort is an in-place sort.
        filteredDocuments.sort((a, b) => a.name.localeCompare(b.name));
        return filteredDocuments;
    }, [documents, search]);

    if (documents.length === 0) {
        return (
            <Column
                alignmentHorizontal={'center'}
                spacingInset={'large'}
                data-testid={buildTestId('noDocumentsPlaceholder')}
            >
                {t('noDocuments-noDocumentsPlaceholder')}
            </Column>
        );
    }

    const NoResultsPlaceholder = ({
        children,
        dataTestId,
        noResults,
    }: PropsWithTestId<PropsWithChildren<{ noResults: boolean }>>) => {
        if (noResults) {
            return (
                <Column alignmentHorizontal={'center'} spacingInset={'large'} data-testid={dataTestId}>
                    {t('searchReturnedNoResults-noResultsPlaceholder')}
                </Column>
            );
        } else return <>{children}</>;
    };

    return (
        <Column>
            <SearchField
                label={t('findDocuments-searchPlaceholder')}
                value={search}
                onChange={onSearchChange}
                onSubmit={() => {}}
                dataTestId={buildTestId('searchField')}
            />
            <NoResultsPlaceholder
                noResults={documentsToRender.length === 0}
                dataTestId={buildTestId('noResultsPlaceholder')}
            >
                {documentsToRender.map((document: Document) => [
                    <Row key={`${document.documentId}_document`} alignmentVertical={'top'}>
                        <Column spacing={'none'} alignmentHorizontal={'left'}>
                            <Button
                                type={'link'}
                                size={'small'}
                                className={linkButton}
                                onClick={() => props.onDocumentDownload(document.documentId)}
                                data-testid={buildTestId(`documentLink[${document.documentId}]`)}
                            >
                                {t.cognate(document.name)}
                            </Button>
                            <Text type={'b100'}>{t.cognate(document.description)}</Text>
                            {document.uploadTimestamp && (
                                <Text type={'b100'}>
                                    {t('lastTouchedTimestampFormat-documentListItem', {
                                        dateTime: document.uploadTimestamp,
                                    })}
                                </Text>
                            )}
                        </Column>
                    </Row>,
                    <Divider size={'small'} spacingBefore={'small'} key={`${document.documentId}_divider`} />,
                ])}
            </NoResultsPlaceholder>
        </Column>
    );
};
