import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import i18next from 'i18next';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHrpsApi } from '../clients/useHrpsApi';
import { TranslatedString, useTranslation, ItemImage, WaitFor, Alert } from '../components/blocks';
import { Text, Button } from '../components/blocks/localization';
import { Tile, TruncatedText, usePageMessaging } from '../components/composites';
import { CreateNotificationPopover, HelpPopover, ItemImagePopover } from '../components/constructed';
import {
    ColumnFormat,
    SuperPaginatedTable,
    SuperPaginatedTableColumn,
} from '../components/constructed/SuperPaginatedTable';
import { viewImageFormat } from '../components/constructed/SuperPaginatedTable.formats';
import { COMPLIANCE_RESOLUTION } from '../constants';
import { fetchAttachmentsByReturnId } from '../redux/actions/attachmentsActions';
import { getResellerDetails, getResellerItems, getResellerPriceList } from '../redux/actions/resellerActionCreators';
import { clearReturnOrderDraft } from '../redux/actions/returnOrderDraftActionCreators';
import { clearReturnOrderList } from '../redux/actions/returnOrderListActionCreators';
import {
    ASIN_MISSING_PLACEHOLDER,
    CreateReturnOrderLineItem,
    DEFAULT_ITEM_IMAGE_URL,
    IAsynchronouslyLoadedValue,
    RootState,
} from '../redux/types';
import { SiteMapPage, PackageType } from '../types';
import { formatAddress } from '../utils/addressHelper';
import { Logger } from '../utils/logger';
import { useSiteMapRouter } from '../utils/SiteMapRouter';
import { useComplianceCalculator } from './CreateReturnOrderPage/useComplianceCalculator';

type BuybackRefusalConfirmationOrderLine = CreateReturnOrderLineItem & { compliantQuantity?: number };

// eslint-disable-next-line max-lines-per-function
const CreateReturnOrderConfirmationPage: FC<CreateReturnOrderConfirmationPageProps> = ({
    resellerId,
    resellerDetails,
    resellerItems,
    resellerPriceList,
    returnOrderDraft,
    getResellerDetails,
    getResellerItems,
    getResellerPriceList,
    clearReturnOrderDraft,
    clearReturnOrderList,
    asinToImageUrls,
    username,
    attachments,
    fetchAttachmentsByReturnId,
}) => {
    const { t } = useTranslation(['createReturnOrderConfirmationPage', 'uploadImageModal']);
    const pageMessaging = usePageMessaging();
    const { goto } = useSiteMapRouter();
    const { showSuccess, showError } = usePageMessaging();
    const {
        returnOrders: { submitReturnOrder },
    } = useHrpsApi();
    const [hasErrorBeenReportedResellerDetails, setHasErrorBeenReportedResellerDetails] = useState<boolean>(false);
    const [hasErrorBeenReportedResellerItems, setHasErrorBeenReportedResellerItems] = useState<boolean>(false);
    const [hasErrorBeenReportedResellerPriceList, setHasErrorBeenReportedResellerPriceList] = useState<boolean>(false);

    const [isPageBusy, setIsPageBusy] = useState(false);

    const [isCalculatingCompliance, setIsCalculatingCompliance] = useState(false);

    const isBuybackRefusalNonCompliant = useRef(false);

    const [buybackRefusalOrderLines, setBuybackRefusalOrderLines] = useState<
        IAsynchronouslyLoadedValue<BuybackRefusalConfirmationOrderLine[]>
    >({
        status: 'Uninitialized',
    });

    const { calculateOrderLinesCompliance } = useComplianceCalculator();

    let shippingAddress: string = '';
    let billingAddress: string = '';
    if (resellerDetails.value) {
        const { locations } = resellerDetails.value.resellerLocations;
        const shippingAddr = locations.find((address) => address.code === returnOrderDraft.value?.shippingLocationCode);
        if (shippingAddr) {
            shippingAddress = formatAddress(shippingAddr.shippingAddress!);
        }
        const billingAddr = locations.find((address) => address.code === returnOrderDraft.value?.billingLocationCode);
        if (billingAddr) {
            billingAddress = formatAddress(billingAddr.billingAddress!);
        }
    }

    let totalEstimatedReturnCredit: number = 0;
    let currencyCode;
    if (resellerPriceList.value && returnOrderDraft.value) {
        currencyCode = resellerPriceList.value.currencyCode;
        returnOrderDraft.value!.orderLines.forEach((lineItem) => {
            const quantity =
                returnOrderDraft.value?.returnType !== 'RETURN'
                    ? lineItem?.totalQuantity ?? 0
                    : lineItem?.approvedCount ?? 0;
            totalEstimatedReturnCredit +=
                (resellerPriceList.value?.priceListItems.find((item) => item.itemId === lineItem.itemId)?.basePrice ??
                    0) * quantity;
        });
    }

    const dispute =
        returnOrderDraft.value &&
        returnOrderDraft.value.orderLines.find((line) => line.reason === COMPLIANCE_RESOLUTION.REQUEST);

    useEffect(() => {
        if (resellerId) {
            switch (resellerDetails.status) {
                case 'Uninitialized':
                    getResellerDetails(resellerId);
                    setIsPageBusy(true);
                    setHasErrorBeenReportedResellerDetails(false);
                    break;
                case 'Failed':
                    Logger.error('getResellerDetails error: ', resellerDetails.error);
                    if (!hasErrorBeenReportedResellerDetails) {
                        pageMessaging.showError(t('selectResellerPage:failedToLoadResellerDetails-errorMessage'));
                        setHasErrorBeenReportedResellerDetails(true);
                    }
                    break;
                default:
                // do nothing
            }
        }
    }, [getResellerDetails, hasErrorBeenReportedResellerDetails, pageMessaging, resellerDetails, resellerId, t]);

    useEffect(() => {
        if (resellerId) {
            switch (resellerItems.status) {
                case 'Uninitialized':
                    getResellerItems(resellerId);
                    setIsPageBusy(true);
                    setHasErrorBeenReportedResellerItems(false);
                    break;
                case 'Failed':
                    Logger.error('getResellerItems error: ', resellerItems.error);
                    if (!hasErrorBeenReportedResellerItems) {
                        pageMessaging.showError(t('selectResellerPage:failedToLoadResellerItems-errorMessage'));
                        setHasErrorBeenReportedResellerItems(true);
                    }
                    break;
                default:
                // do nothing
            }
        }
    }, [resellerItems, resellerId, getResellerItems, pageMessaging, t, hasErrorBeenReportedResellerItems]);

    useEffect(() => {
        if (resellerId) {
            switch (resellerPriceList.status) {
                case 'Uninitialized':
                    getResellerPriceList(resellerId);
                    setIsPageBusy(true);
                    setHasErrorBeenReportedResellerItems(false);
                    break;
                case 'Failed':
                    Logger.error('getResellerPriceList error: ', resellerPriceList.error);
                    if (!hasErrorBeenReportedResellerPriceList) {
                        pageMessaging.showError(t('selectResellerPage:failedToLoadResellerPriceList-errorMessage'));
                        setHasErrorBeenReportedResellerPriceList(true);
                    }
                    break;
                default:
                // do nothing
            }
        }
    }, [resellerPriceList, resellerId, getResellerPriceList, pageMessaging, t, hasErrorBeenReportedResellerPriceList]);

    const itemMap = useMemo(() => {
        if (!resellerItems.value) return undefined;
        return new Map(resellerItems.value.map((item) => [item.externalSku, item]));
    }, [resellerItems]);

    const getItemImageUrlFromStore = useCallback(
        (sku: string): string | undefined => {
            if (!itemMap) return t('loading');
            const asin = itemMap.get(sku)?.asin || ASIN_MISSING_PLACEHOLDER;
            return asinToImageUrls.value?.get(asin) || DEFAULT_ITEM_IMAGE_URL;
        },
        [asinToImageUrls, itemMap, t]
    );

    const getTitle = useCallback(
        (sku: string): TranslatedString => {
            if (!itemMap) return t('loading');
            const title = itemMap.get(sku)?.title;
            return title ? t.cognate(title) : t('title-value_missing');
        },
        [itemMap, t]
    );

    const asinFormat: ColumnFormat = useMemo(() => {
        const getText = (sku: string) => {
            if (!itemMap) return t('loading');
            const asin = itemMap.get(sku)?.asin;
            return asin ? t.cognate(asin) : t('asin-value_missing');
        };

        return {
            render: getText,
        };
    }, [itemMap, t]);

    const upcFormat: ColumnFormat = useMemo(() => {
        const getText = (sku: string) => {
            if (!itemMap) return t('loading');
            const upc = itemMap.get(sku)?.upc;
            return upc ? t.cognate(upc) : t('upc-value_missing');
        };

        return {
            render: getText,
        };
    }, [itemMap, t]);

    const itemFormat: ColumnFormat = useMemo(() => {
        const getText = (sku: string) => getTitle(sku);

        return {
            render: (sku: string) => {
                // limit to first 60 characters
                return <TruncatedText text={getText(sku)} maxWidth={60} />;
            },
            sort: getText,
            search: getText,
        };
    }, [getTitle]);

    const reasonFormat: ColumnFormat = useMemo(() => {
        const getText = (reason: string) => {
            if (reason === COMPLIANCE_RESOLUTION.NONE || reason === undefined) {
                return t.cognate('');
            }
            return t('complianceResolutionReason', { context: reason });
        };

        return {
            render: getText,
        };
    }, [t]);

    const imagePopupFormat: ColumnFormat = useMemo(() => {
        const getText = (sku: string) => getTitle(sku);

        return {
            render: (sku: string) => {
                // limit to first 60 characters
                const translatedTitle = getText(sku);
                const labelText: TranslatedString =
                    translatedTitle.length > 60
                        ? (`${translatedTitle.substr(0, 60)}\u2026` as TranslatedString)
                        : translatedTitle;
                const url = getItemImageUrlFromStore(sku);
                return (
                    <ItemImagePopover thumbnailUrl={url} header={labelText} dataTestId={`${sku}-itemImagePopover`}>
                        <Column alignmentHorizontal={'left'} alignmentVertical={'top'} spacing={'xxsmall'}>
                            <Text>{t.cognate(sku)}</Text>
                            <ItemImage src={url} data-testid={`${sku}-Image`} />
                        </Column>
                    </ItemImagePopover>
                );
            },
            sort: getText,
            search: getText,
        };
    }, [getTitle, getItemImageUrlFromStore, t]);

    const packageTypeFormat: ColumnFormat = useMemo(() => {
        const getText = (packageType: PackageType) => {
            return t('packageType', { context: packageType });
        };

        return {
            render: getText,
        };
    }, [t]);

    const standardColumns: SuperPaginatedTableColumn[] = [
        {
            sourceProperty: 'externalSku',
            title: t('externalSku-columnHeader'),
        },
        {
            id: 'upc',
            sourceProperty: 'externalSku',
            title: t('upc-columnHeader'),
            format: upcFormat,
        },
        {
            id: 'asin',
            sourceProperty: 'externalSku',
            title: t('asin-columnHeader'),
            format: asinFormat,
        },
        {
            id: 'title',
            sourceProperty: 'externalSku',
            title: t('item-columnHeader'),
            format: itemFormat,
        },
        {
            id: 'image',
            sourceProperty: 'externalSku',
            title: t.cognate(' '),
            format: imagePopupFormat,
        },
        {
            sourceProperty: 'quantity',
            title: t('quantity-columnHeader'),
        },
        {
            sourceProperty: 'approvedCount',
            title: t('approved-columnHeader'),
        },
        {
            sourceProperty: 'reason',
            title: t('reason-columnHeader'),
            format: reasonFormat,
        },
    ];

    const buybackRefusalColumns: SuperPaginatedTableColumn[] = [
        {
            sourceProperty: 'externalSku',
            title: t('externalSku-columnHeader'),
        },
        {
            id: 'asin',
            sourceProperty: 'externalSku',
            title: t('asin-columnHeader'),
            format: asinFormat,
        },
        {
            id: 'title',
            sourceProperty: 'externalSku',
            title: t('item-columnHeader'),
            format: itemFormat,
        },
        {
            id: 'image',
            sourceProperty: 'externalSku',
            title: t.cognate(' '),
            format: imagePopupFormat,
        },
        {
            sourceProperty: 'packaging',
            title: t('packageType-columnHeader'),
            format: packageTypeFormat,
        },
        {
            sourceProperty: 'quantity',
            title: t('packageQuantity-columnHeader'),
        },
        {
            sourceProperty: 'totalQuantity',
            title: t('totalQuantity-columnHeader'),
        },
        {
            sourceProperty: 'compliantQuantity',
            title: t('eligibleQuantity-columnHeader'),
        },
        {
            sourceProperty: 'lineItemId',
            title: t('uploadImageModal:uploadedImages-columnHeader'),
            format: viewImageFormat(attachments),
            searchable: false,
        },
    ];

    useEffect(() => {
        (async () => {
            if (returnOrderDraft.value && resellerPriceList.value) {
                if (
                    returnOrderDraft.value.returnType === 'BUYBACK' ||
                    returnOrderDraft.value.returnType === 'REFUSAL'
                ) {
                    if (buybackRefusalOrderLines.status === 'Uninitialized') {
                        try {
                            setIsCalculatingCompliance(true);

                            setBuybackRefusalOrderLines({
                                status: 'Loading',
                            });

                            const complianceCalculationOutput = await calculateOrderLinesCompliance(
                                resellerPriceList.value.priceListItems,
                                resellerId,
                                itemMap!,
                                returnOrderDraft.value.orderLines,
                                returnOrderDraft.value.orderLines.map((orderLine) => ({
                                    itemId: itemMap!.get(orderLine.externalSku!)!.itemId,
                                    asin: itemMap!.get(orderLine.externalSku!)!.asin,
                                    quantity: orderLine.totalQuantity!,
                                    complianceResolution: orderLine.reason
                                        ? orderLine.reason
                                        : COMPLIANCE_RESOLUTION.NONE,
                                })),
                                returnOrderDraft.value.returnType
                            );

                            const buybackRefusalOrderLinesValue = returnOrderDraft.value.orderLines.map((line) => {
                                if (line.totalQuantity! > complianceCalculationOutput[line.orderLineId].eligibility!) {
                                    isBuybackRefusalNonCompliant.current = true;
                                }

                                return {
                                    ...line,
                                    compliantQuantity: complianceCalculationOutput[line.orderLineId].approvedCount,
                                };
                            });

                            setBuybackRefusalOrderLines({
                                status: 'Loaded',
                                value: buybackRefusalOrderLinesValue,
                            });
                        } catch {
                            setBuybackRefusalOrderLines({
                                status: 'Failed',
                            });

                            pageMessaging.showError(t('complianceCalculationFailed-errorMessage'));
                        } finally {
                            setIsCalculatingCompliance(false);
                        }
                    }
                }
            }
        })();
    }, [
        itemMap,
        resellerId,
        returnOrderDraft.value,
        resellerPriceList.value,
        calculateOrderLinesCompliance,
        buybackRefusalOrderLines,
        pageMessaging,
        t,
    ]);

    const [hasRequiredAttachments, setHasRequiredAttachments] = useState<Boolean>();

    useEffect(() => {
        if (!returnOrderDraft.value || !returnOrderDraft.value.returnOrderDraftId) {
            // do nothing
        } else if (attachments.status === 'Uninitialized' || attachments.status === 'Uploading') {
            fetchAttachmentsByReturnId({ returnOrderId: returnOrderDraft.value.returnOrderDraftId, delaySeconds: 2 });
        } else if (attachments.status === 'Loaded') {
            if (returnOrderDraft.value.returnType === 'BUYBACK') {
                const attachmentsLineItemIds: Set<string> = new Set();
                attachments.value!.forEach((attachment) => {
                    attachmentsLineItemIds.add(attachment.lineItemId);
                });
                setHasRequiredAttachments(
                    returnOrderDraft.value.orderLines.every((orderLine) =>
                        attachmentsLineItemIds.has(orderLine.lineItemId!)
                    )
                );
            } else {
                setHasRequiredAttachments(true);
            }
        }
    }, [attachments, fetchAttachmentsByReturnId, returnOrderDraft, setHasRequiredAttachments]);

    if (!returnOrderDraft.value) {
        goto(SiteMapPage.createReturnOrder);
        return <></>;
    }

    async function cancel() {
        clearReturnOrderDraft();
        goto(SiteMapPage.listReturnOrders);
    }

    async function submit() {
        setIsPageBusy(true);

        if (returnOrderDraft.value && returnOrderDraft.value.returnOrderDraftId && returnOrderDraft.value.version) {
            try {
                await submitReturnOrder({
                    returnOrderIdAndVersion: {
                        returnOrderDraftId: returnOrderDraft.value.returnOrderDraftId,
                        version: returnOrderDraft.value.version,
                    },
                    resellerId,
                });

                showSuccess(t('returnOrderSubmitted-successMessage'));
                clearReturnOrderDraft();
                clearReturnOrderList();
                goto(SiteMapPage.listReturnOrders);
            } catch {
                showError(t('failedToSubmitReturnOrder-errorMessage'));
            } finally {
                setIsPageBusy(false);
            }
        }
    }

    async function disputeSubmitted() {
        goto(SiteMapPage.listReturnOrders);
    }
    return (
        <Column>
            <Row>
                <h2>{t('confirmReturnOrder-pageTitle')}</h2>
            </Row>
            <Row>
                <Column width={'50%'}>
                    <h4>{t('referenceId-text', { referenceId: returnOrderDraft.value!.referenceId })}</h4>
                    <Text>
                        {t('returnOrderId-text', { returnOrderDraftId: returnOrderDraft.value!.returnOrderDraftId })}
                    </Text>
                    <Row>
                        <Text>{t('returnType-label')}</Text>
                        <Text>{t('returnType-value', { context: returnOrderDraft.value!.returnType })}</Text>
                    </Row>
                    <Row>
                        <Row>
                            <Text>
                                <h4>{t('totalEstimatedReturnCredit-label')}</h4>
                            </Text>
                            <HelpPopover
                                header={t('estimatedReturnCreditHelpHeader')}
                                dataTestId={'estimatedReturnCreditHelpPopover'}
                                text={t('estimatedReturnCreditHelpDetails_ESTIMATED')}
                            />
                        </Row>
                        <Text>
                            <h3>
                                {i18next.t('totalEstimatedReturnCredit-value', {
                                    ns: 'createReturnOrderConfirmationPage',
                                    totalEstimatedReturnCredit: new Intl.NumberFormat(i18next.language, {
                                        style: 'currency',
                                        currency: currencyCode,
                                    }).format(totalEstimatedReturnCredit),
                                })}
                            </h3>
                        </Text>
                    </Row>
                </Column>
                <Column>
                    <Text>
                        {t('contactEmails-text', {
                            contactEmails: returnOrderDraft.value!.contactEmailAddresses.join('; '),
                        })}
                    </Text>
                    <Text>
                        {t('shippingLocation-text', {
                            shippingLocationCode: shippingAddress,
                        })}
                    </Text>
                    <Text>
                        {t('billingLocation-text', {
                            billingLocationCode: billingAddress,
                        })}
                    </Text>
                </Column>
            </Row>
            <Row>
                <Tile
                    width={'100%'}
                    title={t('orderLines-tableHeading', { count: returnOrderDraft.value!.orderLines.length })}
                    contentSpacingInset={'none'}
                    actionButtons={[
                        {
                            id: 'editReturnOrderDraft',
                            title: t('editReturnOrderDraft-button'),
                            callback: () =>
                                goto(SiteMapPage.updateDraftReturnOrder, {
                                    returnOrderId: returnOrderDraft.value!.returnOrderDraftId,
                                }),
                            type: 'tertiary',
                        },
                    ]}
                >
                    <>
                        {returnOrderDraft.value!.returnType === 'RETURN' && (
                            <SuperPaginatedTable
                                data={returnOrderDraft.value!.orderLines}
                                layout={{
                                    pageSize: 10,
                                    searchPlaceholder: t('findReturnOrderLine-searchBoxPlaceholder'),
                                    columns: standardColumns,
                                }}
                            />
                        )}
                        {returnOrderDraft.value!.returnType !== 'RETURN' && (
                            <WaitFor lazyValue={buybackRefusalOrderLines}>
                                {(orderLines: BuybackRefusalConfirmationOrderLine[]) => (
                                    <SuperPaginatedTable
                                        data={orderLines}
                                        layout={{
                                            pageSize: 10,
                                            searchPlaceholder: t('findReturnOrderLine-searchBoxPlaceholder'),
                                            columns: buybackRefusalColumns,
                                        }}
                                    />
                                )}
                            </WaitFor>
                        )}
                    </>
                </Tile>
            </Row>
            <Row alignmentHorizontal={'right'}>
                {hasRequiredAttachments === false && (
                    <Alert key={'missingImageUploads'} type={'warning'} size={'small'}>
                        <Text color={'secondary'}>{t('missingImageUploads-alertMessage')}</Text>
                    </Alert>
                )}

                <Button type={'secondary'} onClick={cancel}>
                    {t('cancelCreateReturnOrder-button')}
                </Button>
                {dispute || isBuybackRefusalNonCompliant.current ? (
                    <CreateNotificationPopover
                        header={t('createReturnOrderDisputeTicket-popoverHeader')}
                        label={t('createReturnOrderDisputeTicket-button')}
                        username={username}
                        resellerId={resellerId}
                        dataTestId={'createDisputeTicketPopover'}
                        template={'returnOrderDisputeIssue'}
                        pageData={{ returnOrderDraft: returnOrderDraft.value }}
                        useButton={true}
                        onSubmitSuccessCallback={disputeSubmitted}
                    />
                ) : (
                    <Button
                        type={'primary'}
                        onClick={submit}
                        isBusy={isPageBusy}
                        data-testid={'submitReturnOrder-button'}
                        disabled={isCalculatingCompliance || !hasRequiredAttachments}
                    >
                        {t('submitReturnOrder-button')}
                    </Button>
                )}
            </Row>
        </Column>
    );
};

const mapStateToProps = ({ resellerReducer, returnOrderDraftReducer, userReducer, attachmentsReducer }: RootState) => {
    return {
        resellerId: resellerReducer.selectedReseller.value!.resellerId,
        resellerDetails: resellerReducer.resellerDetails,
        resellerItems: resellerReducer.resellerItems,
        resellerPriceList: resellerReducer.resellerPriceList,
        returnOrderDraft: returnOrderDraftReducer.returnOrderDraft,
        asinToImageUrls: resellerReducer.asinToImageUrls,
        username: userReducer.user!.username!,
        attachments: attachmentsReducer,
    };
};

const mapDispatchToProps = {
    getResellerDetails,
    getResellerItems,
    getResellerPriceList,
    clearReturnOrderDraft,
    clearReturnOrderList,
    fetchAttachmentsByReturnId,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CreateReturnOrderConfirmationPageProps = ConnectedProps<typeof connector>;
export default connector(CreateReturnOrderConfirmationPage);
