import { Action } from 'redux';
import { IAsynchronouslyLoadedValue, ReturnOrder } from '../types';

export const RETURN_ORDER_CLEAR = 'returnOrder/clear';
export const RETURN_ORDER_GET = 'returnOrder/get';
export const RETURN_ORDER_LOADED = 'returnOrder/loaded';

export interface ReturnOrderGetAction extends Action {
    type: typeof RETURN_ORDER_GET;
    payload: IAsynchronouslyLoadedValue<ReturnOrder>;
}

export interface ReturnOrderLoadedAction extends Action {
    type: typeof RETURN_ORDER_LOADED;
    payload: IAsynchronouslyLoadedValue<ReturnOrder>;
}

export interface ReturnOrderClearAction extends Action {
    type: typeof RETURN_ORDER_CLEAR;
    payload: IAsynchronouslyLoadedValue<ReturnOrder>;
}

export type ReturnOrderActions = ReturnOrderGetAction | ReturnOrderLoadedAction | ReturnOrderClearAction;
