import Column from '@amzn/meridian/column';
import { FileDetails } from '@amzn/meridian/file-input';
import { FileDetailsFile } from '@amzn/meridian/file-input/file-details';
import Input from '@amzn/meridian/input';
import Row from '@amzn/meridian/row';
import Select, { SelectOption } from '@amzn/meridian/select';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { PutClient } from '../clients';
import { useHrpsApi } from '../clients/useHrpsApi';
import { Heading, Link, useTranslation } from '../components/blocks';
import { Text, Button, FileInput } from '../components/blocks/localization';
import { Tile, usePageMessaging } from '../components/composites';
import { DocumentListPopup } from '../components/composites/DocumentListPopup';
import { useModal } from '../components/composites/Modal';
import { HelpPopover } from '../components/constructed';
import {
    ColumnFormat,
    SuperPaginatedTable,
    SuperPaginatedTableColumn,
} from '../components/constructed/SuperPaginatedTable';
import { textFormat } from '../components/constructed/SuperPaginatedTable.formats';
import { AttachedDocument, RootState } from '../redux/types';
import { ClaimReceiptMethods, ClaimUploadProgress } from '../redux/types/claim';
import { ClaimProcessingError } from '../redux/types/claimList';
import { ProcessedClaimStatus, ProcessClaimUploadOutput } from '../types/ProcessClaimUpload';
import { delay } from '../utils/delay';
import { Logger } from '../utils/logger';
import { useSiteMapRouter } from '../utils/SiteMapRouter';
import { useDownloader } from '../utils/useDownloader';

/* eslint max-lines-per-function: ["off"] */
const UploadClaimPage: FC<UploadClaimPageProps> = ({ user, resellerId, countryCode }) => {
    const { t } = useTranslation('uploadClaimPage');
    const errorT = useTranslation('claimStatus')[0];
    const { cancel } = useSiteMapRouter();
    const modal = useModal();
    const { download } = useDownloader();
    const { showError } = usePageMessaging();

    const [isPageBusy, setIsPageBusy] = useState(false);
    const [isOverride, setIsOverride] = useState(false);
    const [receiptDates, setReceiptDates] = useState<Date[]>([]);
    const [receiptMethods, setReceiptMethods] = useState<ClaimReceiptMethods[]>([]);
    const [progress, setProgress] = useState<ClaimUploadProgress[]>([]);
    const [files, setFiles] = useState<File[]>([]);
    const [processResults, setProcessResults] = useState<ProcessedClaimStatus[]>([]);
    const [templates, setTemplates] = useState<AttachedDocument[]>([]);
    const amazonInternalRoles = ['amazonAdmin', 'amazonCreator'];

    const {
        claims: { requestClaimUploadUrl, processClaimUpload, getClaimUploadTemplates },
    } = useHrpsApi();

    const loadTemplates = useCallback(async () => {
        const result = await getClaimUploadTemplates();
        const countrySpecificTemplates = result.templates.filter((t) => t.countryCode === countryCode);
        const defaultTemplates = result.templates.filter((t) => t.countryCode === null);
        setTemplates(countrySpecificTemplates.length ? countrySpecificTemplates : defaultTemplates);
    }, [getClaimUploadTemplates, countryCode]);

    useEffect(() => {
        if (templates.length === 0) {
            loadTemplates();
            if (user?.roles.some((role) => amazonInternalRoles.includes(role))) {
                setIsOverride(true);
            } else {
                setIsOverride(false);
            }
        }
    }, [loadTemplates, templates.length, user, amazonInternalRoles]);

    const setFileProgress = (fileName: string, progressStat: number) => {
        const newProgress = progress.filter((f) => f.fileName !== fileName);
        newProgress.push({
            fileName: fileName,
            progress: progressStat,
        });
        setProgress(newProgress);
    };

    const setOrUpdateFiles = async (inputFiles: File[]) => {
        if (!files.length) {
            setFiles(inputFiles);
            setReceiptDates(new Array(inputFiles.length).fill(isOverride ? undefined : new Date()));
            setReceiptMethods(
                new Array(inputFiles.length).fill(isOverride ? undefined : ClaimReceiptMethods.CLAIM_TEMPLATE_UPLOAD)
            );
        } else {
            const filteredFiles = inputFiles.filter((file) => !files.some((f) => f.name === file.name));
            const newFiles = [...files, ...filteredFiles];
            const newReceiptDates = [
                ...receiptDates,
                ...new Array(filteredFiles.length).fill(isOverride ? undefined : new Date()),
            ];
            const newReceiptMethods = [
                ...receiptMethods,
                ...new Array(filteredFiles.length).fill(
                    isOverride ? undefined : ClaimReceiptMethods.CLAIM_TEMPLATE_UPLOAD
                ),
            ];
            setFiles(newFiles);
            setReceiptDates(newReceiptDates);
            setReceiptMethods(newReceiptMethods);
        }
    };

    const deleteFile = async (fileIndex: number) => {
        const newFiles = [...files];
        const newReceiptDates = [...receiptDates];
        const newReceiptMethods = [...receiptMethods];
        newFiles.splice(fileIndex, 1);
        newReceiptDates.splice(fileIndex, 1);
        newReceiptMethods.splice(fileIndex, 1);
        setFiles(newFiles);
        setReceiptDates(newReceiptDates);
        setReceiptMethods(newReceiptMethods);
    };

    const updateReceiptDate = async (date: Date, index: number) => {
        const newReceiptDates = [...receiptDates];
        newReceiptDates[index] = date;
        setReceiptDates(newReceiptDates);
    };

    const updateReceiptMethod = async (method: ClaimReceiptMethods, index: number) => {
        const newReceiptMethods = [...receiptMethods];
        newReceiptMethods[index] = method;
        setReceiptMethods(newReceiptMethods);
    };

    const uploadClaim = async () => {
        if (!files || !files.length) return;

        const localProcessingResults: ProcessedClaimStatus[] = processResults;

        setIsPageBusy(true);
        await files.reduce(async (promise, file) => {
            await promise;
            const uploadClaimResult = await requestClaimUploadUrl({
                resellerId,
                filename: file.name,
                mimeType: file.type,
            });

            // upload file
            try {
                setFileProgress(file.name, 0);
                Logger.debug(`uploading File: ${uploadClaimResult.url}`);
                await PutClient.put(uploadClaimResult.url, file);
                Logger.debug(`File Uploaded Successfully`);
                setFileProgress(file.name, 33);
                // between uploading the file and processing it. HDSS needs time to move the file
                await delay(3000);
                setFileProgress(file.name, 66);
                const results = await submitClaim(
                    uploadClaimResult.id,
                    files.findIndex((match) => match.name === file.name)
                );
                localProcessingResults.push({ ...results[0], fileName: file.name });
                setProcessResults(localProcessingResults);
                setFileProgress(file.name, 100);
            } catch (error) {
                Logger.debug(`failed to upload file with error: ${error}`);
                showError(t('uploadClaim-errorMessage'));
            }
        }, Promise.resolve());
        setProgress([]);
        setFiles([]);
        setIsPageBusy(false);
        Logger.info('statusFormat', statusFormat);
        Logger.info('processed results', localProcessingResults);
    };

    const submitClaim = async (uploadedClaimFileId: string, fileIndex: number): Promise<ProcessedClaimStatus[]> => {
        const processClaimResult = await submitClaimRetry(uploadedClaimFileId, fileIndex, 10);
        return processClaimResult.claimUploadedRequests;
    };

    const delayPeriod = 250;
    const submitClaimRetry = async (
        uploadedClaimFileId: string,
        fileIndex: number,
        retryCount: number,
        delayMultiplier = 1
    ): Promise<ProcessClaimUploadOutput> => {
        try {
            Logger.info('Receipt Date:', receiptDates[fileIndex]);
            const results = await processClaimUpload({
                resellerId,
                uploadId: uploadedClaimFileId,
                isOverride: isOverride,
                receiptDate: new Date(receiptDates[fileIndex]).getTime() / 1000,
                receiptMethod: receiptMethods[fileIndex],
            });
            return results;
        } catch (e) {
            if (e.errorCode !== 'UploadNotAvailable' || retryCount === 0) {
                Logger.debug(`error code ${e} not retryable throwing`);
                return Promise.reject(e);
            }
            await delay(delayPeriod * delayMultiplier);
            return submitClaimRetry(uploadedClaimFileId, retryCount - 1, delayMultiplier + 1);
        }
    };

    const downloadTemplate = async (templateId: string) => {
        const template = templates.find((template) => template.id === templateId);
        if (template) {
            download(template.presignedURL);
        } else {
            Logger.error(`template id <${templateId}> not found`);
        }
    };

    const showDocumentListPopup = () => {
        modal.prompt({
            title: t('uploadTemplates-documentsPopupTitle'),
            content: (
                <DocumentListPopup
                    documents={templates.map((template) => ({
                        documentId: template.id,
                        name: template.name,
                        description: template.description,
                        uploadTimestamp: template.uploadedAt,
                    }))}
                    onDocumentDownload={(templateId) => downloadTemplate(templateId)}
                />
            ),
        });
    };

    const statusFormat: ColumnFormat = useMemo(() => {
        const getText = (status: string) => t(`processedClaimStatus_${status}`, { context: status });

        return {
            render: getText,
        };
    }, [t]);

    const messageFormat: ColumnFormat = useMemo(() => {
        const getText = (status: string) =>
            status === null ? t.cognate(' ') : t(`processedClaimMessage_${status}`, { context: status });

        return {
            render: getText,
        };
    }, [t]);

    const errorFormat: ColumnFormat = useMemo(() => {
        const getErrors = (errors: ClaimProcessingError[]) => {
            if (!errors) return <></>;

            return (
                <>
                    <HelpPopover header={errorT('error-popoverTitle')}>
                        <Text tag={'ul'}>
                            {errors!.map((error) => (
                                <li key={error.errorMessage}>
                                    {errorT(`uploadError-${error.errorCode}`, error.parameters)}
                                </li>
                            ))}
                        </Text>
                    </HelpPopover>
                </>
            );
        };

        return {
            render: getErrors,
        };
    }, [errorT]);

    const columns: SuperPaginatedTableColumn[] = [
        {
            sourceProperty: 'fileName',
            title: t('uploadedClaim-columnHeader'),
            format: textFormat,
            isKey: true,
        },
        {
            sourceProperty: 'status',
            title: t('uploadedClaimStatus-columnHeader'),
            format: statusFormat,
        },
        {
            sourceProperty: 'processingErrors',
            title: t('uploadedClaimErrors-columnHeader'),
            format: errorFormat,
        },
        {
            sourceProperty: 'status',
            title: t('uploadedClaimMessage-columnHeader'),
            format: messageFormat,
        },
    ];

    return (
        <Column>
            <Row>
                <Heading level={4}>
                    <Text>{[`Upload Claims`]}</Text>
                </Heading>
            </Row>
            <Row>
                <Text>{t('uploadClaim-instruction')}</Text>
            </Row>
            <Row>
                <Link onClick={() => showDocumentListPopup()}>{t('downloadTemplates-buttonLabel')}</Link>
            </Row>
            <Row>
                <Tile title={t('uploadClaim-tileTitle')} width={'100%'}>
                    <Column>
                        <FileInput
                            type={'multiple'}
                            uploadButtonLabel={t('chooseFile-buttonLabel')}
                            uploadButtonDisabled={isPageBusy}
                            accept={'.xlsx'}
                            onFileAttached={(acceptedFiles) => {
                                Logger.debug(acceptedFiles);
                                setOrUpdateFiles(acceptedFiles);
                            }}
                        />
                        <Text>{t('chooseFile-buttonHint')}</Text>
                        {files && files.length ? (
                            <>
                                <Table headerRows={1}>
                                    <TableRow>
                                        <TableCell>{t('uploadClaim-file')}</TableCell>
                                        {isOverride && (
                                            <>
                                                <TableCell />
                                                <TableCell />
                                            </>
                                        )}
                                        <TableCell>{t('uploadClaim-actions')}</TableCell>
                                    </TableRow>
                                    {files.map((file, index) => (
                                        <TableRow key={`${file.name}tablerow`}>
                                            <TableCell>
                                                <FileDetails
                                                    file={(file as unknown) as FileDetailsFile}
                                                    uploadPercentage={
                                                        progress.find((f) => f.fileName === file.name)?.progress || 0
                                                    }
                                                    key={((file as unknown) as FileDetailsFile).name}
                                                    uploadComplete={
                                                        progress.find((f) => f.fileName === file.name)?.progress === 100
                                                    }
                                                />
                                            </TableCell>
                                            {isOverride && (
                                                <>
                                                    <TableCell>
                                                        <Input
                                                            type={'date'}
                                                            onChange={(date) => updateReceiptDate(date, index)}
                                                            label={t('uploadClaim-receiptDate')}
                                                            value={
                                                                receiptDates &&
                                                                receiptDates.length &&
                                                                receiptDates[index] !== undefined
                                                                    ? receiptDates[index].toString()
                                                                    : ''
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Select
                                                            onChange={(method) => updateReceiptMethod(method, index)}
                                                            label={t('uploadClaim-receiptMethod')}
                                                            value={
                                                                receiptMethods &&
                                                                receiptMethods.length &&
                                                                receiptMethods[index] !== undefined
                                                                    ? receiptMethods[index]
                                                                    : ''
                                                            }
                                                        >
                                                            {Object.entries(ClaimReceiptMethods).map((k) => {
                                                                return (
                                                                    <SelectOption
                                                                        label={k[1]}
                                                                        key={k[0]}
                                                                        value={k[1]}
                                                                    />
                                                                );
                                                            })}
                                                        </Select>
                                                    </TableCell>
                                                </>
                                            )}
                                            <TableCell>
                                                <Button type={'secondary'} onClick={() => deleteFile(index)}>
                                                    {t('delete-buttonLabel')}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </Table>
                            </>
                        ) : undefined}
                    </Column>
                </Tile>
            </Row>
            <Row>
                <Button type={'secondary'} onClick={cancel}>
                    {t('cancel-buttonLabel')}
                </Button>
                <Button
                    type={'primary'}
                    isBusy={isPageBusy}
                    disabled={
                        !files.length ||
                        (isOverride &&
                            (receiptMethods.some((obj) => obj === undefined) ||
                                receiptDates.some((obj) => obj === undefined)))
                    }
                    onClick={() => uploadClaim()}
                >
                    {t('submit-buttonLabel')}
                </Button>
            </Row>
            {processResults.length ? (
                <Row>
                    <Tile
                        width={'100%'}
                        title={t('processUploadedClaimsResults-tableHeading')}
                        contentSpacingInset={'none'}
                    >
                        <SuperPaginatedTable
                            data={processResults}
                            layout={{
                                pageSize: 10,
                                searchPlaceholder: t('findResources-searchBoxPlaceholder'),
                                columns,
                            }}
                        />
                    </Tile>
                </Row>
            ) : undefined}
        </Column>
    );
};

const mapStateToProps = ({ resellerReducer, userReducer }: RootState) => {
    return {
        user: userReducer.user,
        resellerId: resellerReducer.selectedReseller.value!.resellerId,
        countryCode: resellerReducer.selectedReseller.value!.countryCode,
    };
};

const connector = connect(mapStateToProps);
export type UploadClaimPageProps = ConnectedProps<typeof connector>;
export default connector(UploadClaimPage);
