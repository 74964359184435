import Column from '@amzn/meridian/column';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import React, { FC } from 'react';
import viewingContact from '../../../assets/viewingContact.png';
import viewingContactNavigation from '../../../assets/viewingContactNavigation.png';
import { SiteHelpCallback } from '../../../constants';
import { ItemImage, Text, useTranslation } from '../../blocks';
import { IntroductionHelp } from './IntroductionHelp';

const ViewingContactsHelp: FC<SiteHelpCallback> = ({ callback }: SiteHelpCallback) => {
    const { t } = useTranslation(['viewingContactsHelp', 'siteHelpPopover']);

    return (
        <Column spacing={'medium'} maxWidth={'25em'}>
            <Row width={'100%'} widths={['85%', '15%']}>
                <Text type={'h200'} alignment={'center'}>
                    {t('viewingContactsHelp_Title')}
                </Text>
                <Link
                    onClick={() => {
                        callback(IntroductionHelp);
                    }}
                >
                    {t('siteHelpPopover_helpHomePage')}
                </Link>
            </Row>
            <Text type={'b300'} alignment={'left'}>
                {t('viewingContactsHelp_purpose')}
            </Text>
            <ItemImage src={viewingContactNavigation} />
            <Text type={'b300'} alignment={'left'}>
                {t('viewingContactsHelp_overview')}
            </Text>
            <ItemImage src={viewingContact} />
        </Column>
    );
};

export { ViewingContactsHelp };
