import { useTranslation } from '../../components/blocks/localization';
import { RETURN_TYPE } from '../../constants/returnType';
import { ResellerItem, CreateReturnOrderLineItem, ReturnOrderType } from '../../redux/types';
import { packageTypes } from '../../types/PackageType';
import { CreateReturnOrderFormErrors, customerRefRegEx } from './CreateReturnOrderPage';

export interface CreateReturnOrderFormFields {
    referenceId?: string;
    shippingAddress?: string;
    billingAddress?: string;
    contactEmails?: string[];
    orderLines: CreateReturnOrderLineItem[];
    returnOrderType?: ReturnOrderType;
}

export const useCreateReturnOrderFormValidator = (fields: CreateReturnOrderFormFields, items?: ResellerItem[]) => {
    const { t } = useTranslation('createReturnOrderPage');

    const validateReferenceId = () => {
        if (!fields.referenceId) {
            return t('referenceIdRequired-errorMessage');
        } else if (fields.referenceId.length > 30) {
            return t('referenceIdTooLong-errorMessage');
        } else if (!customerRefRegEx.test(fields.referenceId)) {
            return t('referenceId-characterTypeConstraint');
        } else {
            return undefined;
        }
    };

    const validateOrderLines = () => {
        if (!items) {
            return false;
        }

        const orderLinesValidationResult = fields.orderLines.every(
            (line) =>
                items.find((item) => item.externalSku === line.externalSku) &&
                line.externalSku &&
                line.quantity > 0 &&
                line.totalQuantity &&
                line.totalQuantity > 0 &&
                (fields.returnOrderType !== RETURN_TYPE.RETURN || line.complianceCalculationStatus === 'Complete') &&
                (fields.returnOrderType === RETURN_TYPE.RETURN ||
                    (line.packaging && packageTypes.includes(line.packaging)))
        );

        return fields.orderLines.length > 0 && orderLinesValidationResult;
    };

    const validateForm = () => {
        const formErrors: CreateReturnOrderFormErrors = {
            referenceId: validateReferenceId(),
            shippingAddress: fields.shippingAddress ? undefined : t('shippingAddressRequired-errorMessage'),
            billingAddress: fields.billingAddress ? undefined : t('billingAddressRequired-errorMessage'),
            contactEmails: fields.contactEmails?.some(Boolean)
                ? undefined
                : t('contactEmailAddressRequired-errorMessage'),
            orderLines: validateOrderLines() ? undefined : t('invalidOrderLines-alertMessage'),
            returnOrderType: fields.returnOrderType ? undefined : t('returnOrderTypeRequired-errorMessage'),
        };

        let formIsValid: boolean;
        if (
            formErrors.billingAddress ||
            formErrors.shippingAddress ||
            formErrors.referenceId ||
            formErrors.contactEmails ||
            formErrors.orderLines ||
            formErrors.returnOrderType
        ) {
            formIsValid = false;
        } else {
            formIsValid = true;
        }

        return { formIsValid, formErrors };
    };

    return validateForm();
};
