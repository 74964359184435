import Column from '@amzn/meridian/column';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import React, { FC } from 'react';
import uploadClaimDownloadTemplateLink from '../../../assets/upload-claim-download-template-link.png';
import uploadClaimDownloadTemplate from '../../../assets/upload-claim-download-template.png';
import uploadClaimDefaultView from '../../../assets/upload-claims-default-view.png';
import uploadClaimNavigation from '../../../assets/upload-claims-navigation.png';
import uploadClaimResultError from '../../../assets/upload-claims-result-error.png';
import uploadClaimResultTable from '../../../assets/upload-claims-result-table.png';
import uploadClaimUploadBox from '../../../assets/upload-claims-upload-box.png';
import uploadClaimUploadProgress from '../../../assets/upload-claims-upload-progress.png';
import viewClaimsClaimNumber from '../../../assets/view-claims-claim-number.png';
import viewClaimsFilters from '../../../assets/view-claims-filters.png';
import viewClaimsNavigation from '../../../assets/view-claims-navigation.png';
import viewClaimsTable from '../../../assets/view-claims-table.png';
import viewClaimsValidationError from '../../../assets/view-claims-validation-error.png';
import { SiteHelpCallback } from '../../../constants';
import { ItemImage, Text, useTranslation } from '../../blocks';
import { IntroductionHelp } from './IntroductionHelp';

const ViewingClaimHelp: FC<SiteHelpCallback> = ({ callback }: SiteHelpCallback) => {
    const { t } = useTranslation(['viewingClaimHelp', 'siteHelpPopover']);

    return (
        <Column spacing={'medium'} maxWidth={'25em'}>
            <Row width={'100%'} widths={['85%', '15%']}>
                <Text type={'h200'} alignment={'center'}>
                    {t('uploadClaimHelp_Title')}
                </Text>
                <Link
                    onClick={() => {
                        callback(IntroductionHelp);
                    }}
                >
                    {t('siteHelpPopover:siteHelpPopover_helpHomePage')}
                </Link>
            </Row>
            <Row spacing={'medium'}>
                <Column width={'2%'} />
                <Column spacing={'xsmall'} width={'90%'}>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline1')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('uploadClaimHelp_Navigate')}
                        </Text>
                    </Row>
                    <Row>
                        <ItemImage src={uploadClaimNavigation} />
                    </Row>
                    <Row>
                        <ItemImage src={uploadClaimDefaultView} />
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline2')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('uploadClaimHelp_Template')}
                        </Text>
                    </Row>
                    <Row>
                        <ItemImage src={uploadClaimDownloadTemplateLink} />
                    </Row>
                    <Row>
                        <ItemImage src={uploadClaimDownloadTemplate} />
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline3')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('uploadClaimHelp_ChooseFile')}
                        </Text>
                    </Row>
                    <Row>
                        <ItemImage src={uploadClaimUploadBox} />
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline4')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('uploadClaimHelp_Submit')}
                        </Text>
                    </Row>
                    <Row>
                        <Text type={'b200'} alignment={'left'}>
                            {t('uploadClaimHelp_WaitForProcessing')}
                        </Text>
                    </Row>
                    <Row>
                        <ItemImage src={uploadClaimUploadProgress} />
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline5')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('uploadClaimHelp_FinishedProcessing')}
                        </Text>
                    </Row>
                    <Row>
                        <ItemImage src={uploadClaimResultTable} />
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline6')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('uploadClaimHelp_IfStatusSuccess')}
                        </Text>
                    </Row>
                    <Row>
                        <ItemImage src={viewClaimsNavigation} />
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('uploadClaimHelp_IfStatusError')}
                        </Text>
                    </Row>
                    <Row>
                        <ItemImage src={uploadClaimResultError} />
                    </Row>
                </Column>
            </Row>
            <Row width={'100%'} widths={['85%', '15%']}>
                <Text type={'h200'} alignment={'center'}>
                    {t('viewClaimHelp_ViewClaimTitle')}
                </Text>
            </Row>
            <Row spacing={'medium'}>
                <Column width={'2%'} />
                <Column spacing={'xsmall'} width={'90%'}>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline1')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('viewClaimHelp_ViewClaims')}
                        </Text>
                    </Row>
                    <Row>
                        <ItemImage src={viewClaimsFilters} />
                    </Row>
                    <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline2')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('viewClaimHelp_ViewDownloadFile')}
                        </Text>
                    </Row>
                    <Row>
                        <ItemImage src={viewClaimsClaimNumber} />
                    </Row>
                    <Row>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline3')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('viewClaimHelp_PossibleStatus')}
                        </Text>
                    </Row>
                    <Row>
                        <ItemImage src={viewClaimsTable} />
                    </Row>
                    <Row>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline4')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('viewClaimHelp_ClaimError')}
                        </Text>
                    </Row>
                    <Row>
                        <ItemImage src={viewClaimsValidationError} />
                    </Row>
                    <Row>
                        <Text type={'b200'} alignment={'left'}>
                            {t('siteHelpPopover:siteHelpPopover_outline5')}
                        </Text>
                        <Text type={'b200'} alignment={'left'}>
                            {t('viewClaimHelp_ClaimSuccess')}
                        </Text>
                    </Row>
                </Column>
            </Row>
        </Column>
    );
};

export { ViewingClaimHelp };
