import React from 'react';
import { useTranslation } from '../components/blocks';
import { Tile } from '../components/composites';
import { ChangePassword } from '../components/constructed';

const ChangePasswordPage = () => {
    const { t } = useTranslation('changePassword');
    return (
        <Tile title={t('changePassword:title')} width={'100%'} data-testid={'changePassword'}>
            <ChangePassword />
        </Tile>
    );
};

export { ChangePasswordPage };
