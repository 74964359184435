import Column from '@amzn/meridian/column';
import Divider from '@amzn/meridian/divider';
import Row from '@amzn/meridian/row';
import React, { FC, FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router-dom';
import { HrpsApiClient } from '../clients';
import { Button, Text, useTranslation, WaitFor } from '../components/blocks';
import { Tile, usePageMessaging } from '../components/composites';
import { useModal } from '../components/composites/Modal';
import { USER_STATUS } from '../constants/userStatus';
import { clearManagedUser, clearManagedUsers, getManagedUser } from '../redux/actions/managedUserActionCreators';
import { IAsynchronouslyLoadedValue, RootState } from '../redux/types';
import { IRole, SiteMapPage } from '../types';
import { Logger } from '../utils/logger';
import { useSiteMapRouter } from '../utils/SiteMapRouter';
import { UserDetails as UserDetailsFormUserDetails, UserDetailsForm, UserDetailsFormApi } from './UserDetailsForm';

// FIXME: Fix the suppression: function is waaay too big. -- 3/31/22
// eslint-disable-next-line max-lines-per-function
const EditUserPage: FC<EditUserPageProps> = ({
    managedUser,
    getManagedUser,
    clearManagedUser,
    clearManagedUsers,
    loggedInUser,
}: EditUserPageProps) => {
    const { t } = useTranslation(['editUserPage', 'roles', 'validation', 'forms']);

    const { username } = useParams<{ username: string }>();
    const { goto, cancel } = useSiteMapRouter();

    const [isPageBusy, setPageIsBusy] = useState(false);
    const [originalEmail, setOriginalEmail] = useState<string>();
    const [isAccountEnabled, setIsAccountEnabled] = useState(true);

    const [userDetails, setUserDetails] = useState<IAsynchronouslyLoadedValue<UserDetailsFormUserDetails>>({
        status: 'Uninitialized',
    });

    const userDetailsFormApiRef = useRef<UserDetailsFormApi>();

    const { showSuccess, showError } = usePageMessaging();

    useEffect(() => {
        (async () => {
            Logger.debug('getManagedUser: ', username);
            getManagedUser(username);
        })();
    }, [getManagedUser, username]);

    // ensure that we are looking at the current user in the redux state and
    // that it isn't in the process of reloading the data
    if (managedUser && managedUser.username === username && !userDetails.value) {
        const userDetailsFormUserDetails: UserDetailsFormUserDetails = {
            username: managedUser.username,
            familyName: managedUser.familyName,
            givenName: managedUser.givenName,
            email: managedUser.email,
            phone: managedUser.phoneNumber,
            locale: managedUser.locale ?? loggedInUser?.locale ?? 'en-US',
            resellers: managedUser.resellers.map((reseller) => {
                return reseller.id;
            }),
            role: managedUser.role,
        };

        setUserDetails({
            status: 'Loaded',
            value: userDetailsFormUserDetails,
        });
        setIsAccountEnabled(managedUser.enabled);
        setOriginalEmail(managedUser.email);
    }

    function cancelEditing() {
        cancel();
    }

    async function submitForm(event: FormEvent) {
        event.preventDefault();

        if (userDetailsFormApiRef.current?.validateForm() === 'invalid') {
            return;
        }

        setPageIsBusy(true);

        if (userDetails.value) {
            const hrpsApiClient = new HrpsApiClient();
            try {
                await hrpsApiClient.updateUser({
                    ...userDetails.value,
                    role: userDetails.value.role as string,
                });
                await reportSuccessAndReloadUsers('userWasUpdated-successMessage');
                goto(SiteMapPage.listUsers);
            } catch (e) {
                reportError(e.code, 'failedToUpdateAUser-errorMessage');
                setPageIsBusy(false);
            }
        }
    }

    async function resendUserInvite() {
        setPageIsBusy(true);
        try {
            await new HrpsApiClient().resendUserInvite(username);
            await reportSuccessAndReloadUsers('inviteWasResent-successMessage');
        } catch (e) {
            reportError(e.code, 'failedToResendInvite-failureMessage');
        } finally {
            setPageIsBusy(false);
        }
    }

    async function reportSuccessAndReloadUsers(successResponse: string, context?: {}) {
        showSuccess(t(successResponse, context));
        clearManagedUser();
        clearManagedUsers();
        getManagedUser(username);
    }

    function reportError(errorCode: string, defaultErrorResponse: string, options?: {}) {
        switch (errorCode) {
            case 'AccessDenied':
                showError(t('accessDenied-failureMessage', options));
                break;
            default:
                showError(t(defaultErrorResponse, options));
                break;
        }
    }

    const canResendUserInvite = useMemo(
        () =>
            originalEmail &&
            userDetails.value &&
            userDetails.value.email &&
            userDetails.value?.email === originalEmail &&
            isAccountEnabled,
        [originalEmail, userDetails.value, isAccountEnabled]
    );

    const canResetPassword = useMemo(
        () =>
            originalEmail &&
            userDetails.value &&
            userDetails.value.email &&
            userDetails.value?.email === originalEmail &&
            isAccountEnabled &&
            managedUser?.userStatus !== USER_STATUS.FORCE_CHANGE_PASSWORD,
        [originalEmail, userDetails.value, isAccountEnabled, managedUser]
    );

    async function updateAccountStatus(status: { enabled: boolean }) {
        setPageIsBusy(true);
        try {
            await new HrpsApiClient().updateAccountStatus(username, status);
            setIsAccountEnabled(status.enabled);
            await reportSuccessAndReloadUsers('accountStatusWasUpdated-successMessage', {
                context: status.enabled ? 'enabled' : 'disabled',
            });
        } catch (e) {
            reportError(e.code, 'failedToUpdateAccountStatus-failureMessage', {
                context: status.enabled ? 'enabled' : 'disabled',
            });
        } finally {
            setPageIsBusy(false);
        }
    }

    const modal = useModal();
    function resetPassword() {
        modal
            .confirm(
                t('resetPassword-popupConfirmationTitle'),
                t('areYouSureYouWantToResetPasswordForUser-popupConfirmationMessage', {
                    user: userDetails.value!.username,
                }),
                t('resetPassword-popupConfirmButtonLabel')
            )
            .applied(async () => {
                setPageIsBusy(true);
                try {
                    await new HrpsApiClient().resetPassword(username);
                    await reportSuccessAndReloadUsers('accountPasswordWasReset-successMessage');
                } catch (e) {
                    reportError(e.code, 'failedToResetPassword-failureMessage');
                } finally {
                    setPageIsBusy(false);
                }
            });
    }

    return (
        <div data-testid={'editUserPage'}>
            <Tile title={t('editUser-pageTitle')} width={'100%'}>
                <WaitFor lazyValue={userDetails}>
                    {(userDetailsValue) => (
                        <Row widths={['grid-6', 'grid-6']} width={'100%'} alignmentVertical={'top'}>
                            <form onSubmit={submitForm}>
                                <Column spacing={'small'} spacingInset={'none'} width={'90%'}>
                                    <UserDetailsForm
                                        formApiRef={userDetailsFormApiRef}
                                        userDetails={userDetailsValue}
                                        onUserDetailsChange={(updatedValue) =>
                                            setUserDetails({ status: 'Loaded', value: updatedValue })
                                        }
                                        mode={'edit'}
                                    />
                                    <Row alignmentHorizontal={'right'}>
                                        <Button
                                            type={'secondary'}
                                            data-testid={'editUserPage.cancelButton'}
                                            onClick={cancelEditing}
                                        >
                                            {t('forms:cancel-buttonLabel')}
                                        </Button>

                                        <Button
                                            type={'primary'}
                                            submit={true}
                                            disabled={isPageBusy}
                                            data-testid={'editUserPage.updateUserButton'}
                                        >
                                            {t('updateUser-buttonLabel')}
                                        </Button>
                                    </Row>
                                </Column>
                            </form>
                            <Column spacing={'medium'} spacingInset={'none'}>
                                <Row widths={['grid-6', 'grid-6']}>
                                    <Column spacing={'xsmall'}>
                                        <Text type={'b400'}>{t('creationDateIn-fieldLabel')}</Text>
                                        <Row widths={'fill'}>
                                            {managedUser && (
                                                <Text type={'b300'} data-testid={'editUserPage.creationDateIn'}>
                                                    {t('creationDateIn-fieldValue', {
                                                        dateTime: new Date(managedUser?.creationDate * 1000),
                                                    })}
                                                </Text>
                                            )}
                                        </Row>
                                    </Column>
                                </Row>
                                <Row widths={['grid-6', 'grid-6']}>
                                    <Column spacing={'xsmall'}>
                                        <Text type={'b400'}>{t('lastUpdateDateIn-fieldLabel')}</Text>
                                        <Row widths={'fill'}>
                                            {managedUser && (
                                                <Text type={'b300'} data-testid={'editUserPage.lastModifiedDateIn'}>
                                                    {t('lastModifiedDateIn-fieldValue', {
                                                        dateTime: new Date(managedUser?.lastModifiedDate * 1000),
                                                    })}
                                                </Text>
                                            )}
                                        </Row>
                                    </Column>
                                </Row>
                                <Row widths={['grid-6', 'grid-6']}>
                                    <Column spacing={'xsmall'}>
                                        <Text type={'b400'}>{t('currentStatusIn-fieldLabel')}</Text>
                                        <Row widths={'fill'}>
                                            {managedUser && (
                                                <Text type={'b300'} data-testid={'editUserPage.currentStatusIn'}>
                                                    {t(`userStatusType-${managedUser?.userStatus}`)}
                                                </Text>
                                            )}
                                        </Row>
                                    </Column>
                                </Row>
                                <Divider />
                                <Row alignmentHorizontal={'right'}>
                                    {managedUser?.userStatus === 'FORCE_CHANGE_PASSWORD' && (
                                        <Button
                                            type={'primary'}
                                            disabled={isPageBusy || !canResendUserInvite}
                                            data-testid={'editUserPage.resendUserInvite'}
                                            onClick={resendUserInvite}
                                        >
                                            {t('resendUserInvite-buttonLabel')}
                                        </Button>
                                    )}
                                    {/* TODO: maybe it would be better to show an error message when can't */}
                                    {/* reset password instead of disabling the button with no explanation. */}
                                    {/* I don't think popup or a tooltip would work on a disabled control. */}
                                    {managedUser?.userStatus !== undefined &&
                                        managedUser?.userStatus !== 'FORCE_CHANGE_PASSWORD' && (
                                            <Button
                                                type={'primary'}
                                                disabled={isPageBusy || !canResetPassword}
                                                data-testid={'editUserPage.resetPasswordButton'}
                                                onClick={resetPassword}
                                            >
                                                {t('resetPassword-buttonLabel')}
                                            </Button>
                                        )}
                                    {isAccountEnabled && (
                                        <Button
                                            type={'primary'}
                                            disabled={isPageBusy}
                                            data-testid={'editUserPage.disableAccountButton'}
                                            onClick={() => updateAccountStatus({ enabled: false })}
                                        >
                                            {t('disableAccount-buttonLabel')}
                                        </Button>
                                    )}
                                    {!isAccountEnabled && (
                                        <Button
                                            type={'primary'}
                                            disabled={isPageBusy}
                                            data-testid={'editUserPage.enableAccountButton'}
                                            onClick={() => updateAccountStatus({ enabled: true })}
                                        >
                                            {t('enableAccount-buttonLabel')}
                                        </Button>
                                    )}
                                </Row>
                            </Column>
                        </Row>
                    )}
                </WaitFor>
            </Tile>
        </div>
    );
};

const mapStateToProps = ({ resellerReducer, systemReducer, managedUserReducer, userReducer }: RootState) => {
    return {
        authorizedResellers: resellerReducer.authorizedResellers,
        validRoles: systemReducer.roles as Record<string, IRole>,
        managedUser: managedUserReducer.selectedManagedUser.value,
        status: managedUserReducer.selectedManagedUser.status,
        error: managedUserReducer.selectedManagedUser.error,
        loggedInUser: userReducer.user,
    };
};

const mapDispatchToProps = { getManagedUser, clearManagedUser, clearManagedUsers };

const connector = connect(mapStateToProps, mapDispatchToProps);
export type EditUserPageProps = ConnectedProps<typeof connector>;
export default connector(EditUserPage);
