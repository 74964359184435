import React, { FC, useState } from 'react';
import { Select, SelectOption, TranslatedString } from '../blocks/localization';

const SearchableSelect: FC<SearchableSelectProps> = ({
    value,
    onChange,
    selectOptions,
    dataTestId,
    isDisabled,
    placeholder,
    label,
    width,
    isVisible,
}) => {
    const [query, setQuery] = useState('');
    const filteredOptions = selectOptions.filter((option) => option.label.toLowerCase().includes(query.toLowerCase()));
    return (
        <>
            {(isVisible === undefined || isVisible) && (
                <Select
                    value={value}
                    onChange={onChange}
                    disabled={isDisabled}
                    label={label}
                    placeholder={placeholder}
                    searchQuery={query}
                    onSearch={setQuery}
                    width={width}
                    data-testid={dataTestId}
                >
                    {filteredOptions.map((option) => (
                        <SelectOption key={option.value} value={option.value} label={option.label} />
                    ))}
                </Select>
            )}
        </>
    );
};

type SearchableSelectProps = {
    value: any;
    onChange?(value: string): void;
    selectOptions: { value: string; label: TranslatedString }[];
    dataTestId?: string;
    isDisabled?: boolean;
    placeholder?: TranslatedString;
    label?: TranslatedString;
    width?: number;
    isVisible?: boolean;
};

export { SearchableSelect };
