import { Reducer } from 'redux';
import { INITIAL_RETURN_ORDER_PROBLEM_SHELF_STATE } from '../../constants';
import {
    RETURN_ORDER_PROBLEM_SHELF_CLEAR,
    RETURN_ORDER_PROBLEM_SHELF_GET,
    RETURN_ORDER_PROBLEM_SHELF_LOADED,
    ReturnOrderProblemShelfActions,
} from '../actions/returnOrderProblemShelfActions';
import { ReturnOrderProblemShelfState } from '../types';

export const returnOrderProblemShelfReducer: Reducer<ReturnOrderProblemShelfState, ReturnOrderProblemShelfActions> = (
    state = INITIAL_RETURN_ORDER_PROBLEM_SHELF_STATE,
    action
): ReturnOrderProblemShelfState => {
    switch (action.type) {
        case RETURN_ORDER_PROBLEM_SHELF_GET:
        case RETURN_ORDER_PROBLEM_SHELF_LOADED:
            return {
                ...state,
                returnOrderProblemShelf: action.payload,
            };
        case RETURN_ORDER_PROBLEM_SHELF_CLEAR:
            return {
                ...state,
                ...INITIAL_RETURN_ORDER_PROBLEM_SHELF_STATE,
            };
        default:
            return state;
    }
};
