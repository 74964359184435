import _ from 'lodash';
import { AnyAction } from 'redux';

type ActionObject = {
    action: AnyAction;
    expires?: string;
};

type ActionsObject = { [key: string]: ActionObject };

type MetadataObject = {
    version: string;
};

export type UserActionsObject = {
    actions: ActionsObject;
    metadata: MetadataObject;
};

type ActionProps = 'type' | 'payload';

const actionPropsToPersist: ActionProps[] = ['type', 'payload'];

export const VERSION_NUMBER = '1.0';

export const generateUserKey = (username: string): string => {
    return `user_actions_${username}`;
};

export const daysToMs = (days: number): number => {
    return days * 24 * 60 * 60 * 1000;
};

const getUserActionsObject = (username: string): UserActionsObject => {
    return JSON.parse(localStorage.getItem(generateUserKey(username)) ?? '{}');
};

const setUserActionsObject = (username: string, userActionsObject: UserActionsObject) => {
    localStorage.setItem(generateUserKey(username), JSON.stringify(userActionsObject));
};

const removeUserActionsObject = (username: string) => {
    localStorage.removeItem(generateUserKey(username));
};

const hasInvalidVersionMetadata = (metadata: MetadataObject): boolean => {
    return !metadata || metadata.version !== VERSION_NUMBER;
};

const transformAction = (action: AnyAction): ActionObject => {
    const actionObject: ActionObject = { action: _.pick(action, actionPropsToPersist) };
    if (action.timeToLiveInDays) {
        actionObject.expires = new Date(Date.now() + daysToMs(action.timeToLiveInDays)).toISOString();
    }
    return actionObject;
};

const actionIsUnexpired = (expirationDate: string | undefined): boolean => {
    // date string parsing is safe (consistent) since date string is created with ISO format
    return !expirationDate || Date.now() < Date.parse(expirationDate);
};

export const storeUserAction = (username: string, action: AnyAction) => {
    let userActionsAndMetadata = getUserActionsObject(username);

    // reinitialize user actions object if version is expired or metadata/object does not exist
    if (hasInvalidVersionMetadata(userActionsAndMetadata.metadata)) {
        userActionsAndMetadata = {
            actions: {},
            metadata: { version: VERSION_NUMBER },
        };
    }

    userActionsAndMetadata.actions[action.type] = transformAction(action);
    setUserActionsObject(username, userActionsAndMetadata);
};

export const fetchAllUserActions = (username: string): AnyAction[] => {
    const userActionsAndMetadata = getUserActionsObject(username);

    // clear user actions object if version is expired or metadata/object does not exist
    if (hasInvalidVersionMetadata(userActionsAndMetadata.metadata)) {
        removeUserActionsObject(username);
        return [];
    }

    // remove any expired actions from the user actions object
    const unexpiredUserActionObjects: ActionObject[] = _.filter(userActionsAndMetadata.actions, (obj: ActionObject) =>
        actionIsUnexpired(obj.expires)
    );
    userActionsAndMetadata.actions = _.keyBy(
        unexpiredUserActionObjects,
        (obj: ActionObject) => obj.action.type
    ) as ActionsObject;
    setUserActionsObject(username, userActionsAndMetadata);

    return _.map(unexpiredUserActionObjects, 'action');
};
