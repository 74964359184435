import { Dispatch } from 'redux';
import { HrpsApiClient } from '../../clients';
import { ResellerSelectionError } from '../../types';
import {
    ASIN_MISSING_PLACEHOLDER,
    AsinToImageUrlMap,
    DEFAULT_ITEM_IMAGE_URL,
    IAuthorizedReseller,
    IReseller,
    Location,
    ResellerDetails,
    ResellerItem,
    ResellerPriceList,
} from '../types';
import {
    AUTHORIZED_RESELLERS_CHANGED,
    ITEM_IMAGE_URLS_CHANGED,
    RESELLER_DETAILS_CHANGED,
    RESELLER_DETAILS_LOCATION_ADDED,
    RESELLER_DETAILS_LOCATION_EDITED,
    RESELLER_ITEMS_CHANGED,
    RESELLER_UNMAPPED_ITEMS_CLEAR,
    RESELLER_UNMAPPED_ITEMS_GET,
    RESELLER_UNMAPPED_ITEMS_LOADED,
    ResellerActions,
    SELECTED_RESELLER_CHANGED,
    RESELLER_PRICELIST_CHANGED,
} from './resellerActions';

export function getAuthorizedResellersLoading(): ResellerActions {
    return {
        type: AUTHORIZED_RESELLERS_CHANGED,
        payload: {
            status: 'Loading',
            error: undefined,
            value: undefined,
        },
    };
}

export function clearAuthorizedResellers(): ResellerActions {
    return {
        type: AUTHORIZED_RESELLERS_CHANGED,
        payload: {
            status: 'Uninitialized',
            error: undefined,
            value: undefined,
        },
    };
}

export function getAuthorizedResellersLoaded(resellers: IAuthorizedReseller[]): ResellerActions {
    return {
        type: AUTHORIZED_RESELLERS_CHANGED,
        payload: {
            status: 'Loaded',
            error: undefined,
            value: resellers,
        },
    };
}

export function getAuthorizedResellersFailed(error: any): ResellerActions {
    return {
        type: AUTHORIZED_RESELLERS_CHANGED,
        payload: {
            status: 'Failed',
            error,
            value: undefined,
        },
    };
}

export function selectedResellerIsLoading(): ResellerActions {
    return {
        type: SELECTED_RESELLER_CHANGED,
        payload: {
            status: 'Loading',
            error: undefined,
            value: undefined,
        },
    };
}

export function clearSelectedReseller(): ResellerActions {
    return {
        type: SELECTED_RESELLER_CHANGED,
        payload: {
            status: 'Uninitialized',
            error: undefined,
            value: undefined,
        },
    };
}

export function selectedResellerLoaded(reseller: IReseller): ResellerActions {
    return {
        type: SELECTED_RESELLER_CHANGED,
        payload: {
            status: 'Loaded',
            error: undefined,
            value: reseller,
        },
        replayOnLogin: true,
        timeToLiveInDays: 30,
    };
}

export function selectedResellerLoadingFailed(error: any): ResellerActions {
    return {
        type: SELECTED_RESELLER_CHANGED,
        payload: {
            status: 'Failed',
            error,
            value: undefined,
        },
    };
}

export function getResellerDetailsLoading(): ResellerActions {
    return {
        type: RESELLER_DETAILS_CHANGED,
        payload: {
            status: 'Loading',
            error: undefined,
            value: undefined,
        },
    };
}

export function getResellerDetailsLoaded(resellerDetails: ResellerDetails): ResellerActions {
    return {
        type: RESELLER_DETAILS_CHANGED,
        payload: {
            status: 'Loaded',
            error: undefined,
            value: resellerDetails,
        },
    };
}

export function getResellerDetailsFailed(error: any): ResellerActions {
    return {
        type: RESELLER_DETAILS_CHANGED,
        payload: {
            status: 'Failed',
            error,
            value: undefined,
        },
    };
}

export function clearResellerDetails(): ResellerActions {
    return {
        type: RESELLER_DETAILS_CHANGED,
        payload: {
            status: 'Uninitialized',
            error: undefined,
            value: undefined,
        },
    };
}

export function getResellerPriceListLoading(): ResellerActions {
    return {
        type: RESELLER_PRICELIST_CHANGED,
        payload: {
            status: 'Loading',
            error: undefined,
            value: undefined,
        },
    };
}

export function getResellerPriceListLoaded(PriceList: ResellerPriceList): ResellerActions {
    return {
        type: RESELLER_PRICELIST_CHANGED,
        payload: {
            status: 'Loaded',
            error: undefined,
            value: PriceList,
        },
    };
}

export function getResellerPriceListFailed(error: any): ResellerActions {
    return {
        type: RESELLER_PRICELIST_CHANGED,
        payload: {
            status: 'Failed',
            error,
            value: undefined,
        },
    };
}

export function getResellerItemsLoading(): ResellerActions {
    return {
        type: RESELLER_ITEMS_CHANGED,
        payload: {
            status: 'Loading',
            error: undefined,
            value: undefined,
        },
    };
}

export function getResellerItemsLoaded(resellerItems: ResellerItem[]): ResellerActions {
    return {
        type: RESELLER_ITEMS_CHANGED,
        payload: {
            status: 'Loaded',
            error: undefined,
            value: resellerItems,
        },
    };
}

export function getResellerItemsFailed(error: any): ResellerActions {
    return {
        type: RESELLER_ITEMS_CHANGED,
        payload: {
            status: 'Failed',
            error,
            value: undefined,
        },
    };
}

export function getUnmappedItemsClear(): ResellerActions {
    return {
        type: RESELLER_UNMAPPED_ITEMS_CLEAR,
    };
}

export function getUnmappedItemsGet(): ResellerActions {
    return {
        type: RESELLER_UNMAPPED_ITEMS_GET,
    };
}

export function getUnmappedItemsLoaded(resellerItems: ResellerItem[]): ResellerActions {
    return {
        type: RESELLER_UNMAPPED_ITEMS_LOADED,
        payload: {
            error: undefined,
            value: resellerItems,
        },
    };
}

export function getUnmappedItemsFailed(error: any): ResellerActions {
    return {
        type: RESELLER_UNMAPPED_ITEMS_LOADED,
        payload: {
            error: error,
            value: [],
        },
    };
}

export function getItemImageUrlsLoading(): ResellerActions {
    return {
        type: ITEM_IMAGE_URLS_CHANGED,
        payload: {
            status: 'Loading',
            error: undefined,
            value: undefined,
        },
    };
}

export function getItemImageUrlsLoaded(asinToImageUrlMap: AsinToImageUrlMap): ResellerActions {
    return {
        type: ITEM_IMAGE_URLS_CHANGED,
        payload: {
            status: 'Loaded',
            error: undefined,
            value: asinToImageUrlMap,
        },
    };
}

export function getItemImageUrlsFailed(error: any): ResellerActions {
    return {
        type: ITEM_IMAGE_URLS_CHANGED,
        payload: {
            status: 'Failed',
            error: error,
            value: undefined,
        },
    };
}

export function resellerDetailsLocationAdded(location: Location): ResellerActions {
    return {
        type: RESELLER_DETAILS_LOCATION_ADDED,
        payload: location,
    };
}

export function resellerDetailsLocationEdited(location: Location): ResellerActions {
    return {
        type: RESELLER_DETAILS_LOCATION_EDITED,
        payload: location,
    };
}

export function getAuthorizedResellers() {
    return (dispatch: Dispatch) => {
        const hrpsApiClient = new HrpsApiClient();
        dispatch(getAuthorizedResellersLoading());
        return hrpsApiClient
            .getAuthorizedResellers()
            .then(async (resellersList) => {
                if (resellersList.length > 0) {
                    dispatch(getAuthorizedResellersLoaded(resellersList));
                    if (resellersList.length === 1) {
                        const { resellerId } = resellersList[0];
                        dispatch(selectedResellerIsLoading());
                        hrpsApiClient
                            .getResellerDetails([resellerId])
                            .then((resellerDetails) => {
                                dispatch(selectedResellerLoaded(resellerDetails[0]));
                            })
                            .catch((err) => {
                                dispatch(selectedResellerLoadingFailed(err));
                            });
                    }
                } else {
                    throw new ResellerSelectionError('User has no authorized resellers', 'NoAuthorizedResellers');
                }
            })
            .catch((err) => {
                dispatch(getAuthorizedResellersFailed(err));
            });
    };
}

export function selectReseller(resellerId: string) {
    return (dispatch: Dispatch<ResellerActions>, getState: () => any) => {
        const { authorizedResellers } = getState().resellerReducer;
        const reseller = authorizedResellers.value.find(
            (curReseller: IReseller) => curReseller.resellerId === resellerId
        );
        if (reseller) {
            dispatch(selectedResellerLoaded(reseller));
            getResellerDetails(resellerId)(dispatch);
            getResellerItems(resellerId)(dispatch);
            getResellerPriceList(resellerId)(dispatch);
        } else {
            dispatch(selectedResellerLoadingFailed('resellerNotFound'));
        }
    };
}

export function getResellerDetails(resellerId: string) {
    return (dispatch: Dispatch<ResellerActions>) => {
        const hrpsApiClient = new HrpsApiClient();
        dispatch(getResellerDetailsLoading());
        return hrpsApiClient
            .getAdvancedResellerDetails(resellerId)
            .then((resellerDetails) => {
                dispatch(getResellerDetailsLoaded(resellerDetails));
            })
            .catch((error) => {
                dispatch(getResellerDetailsFailed(error));
            });
    };
}

export function getResellerItems(resellerId: string) {
    return (dispatch: Dispatch<ResellerActions>) => {
        const hrpsApiClient = new HrpsApiClient();
        dispatch(getResellerItemsLoading());
        return hrpsApiClient
            .getResellerItems(resellerId)
            .then((resellerItems) => {
                dispatch(getResellerItemsLoaded(resellerItems));
                getItemImageUrls(resellerItems.map((item) => item.asin).filter((item) => item))(dispatch);
            })
            .catch((error) => {
                dispatch(getResellerItemsFailed(error));
            });
    };
}

export function getUnmappedItems(resellerId: string, itemIds: string[]) {
    return (dispatch: Dispatch<ResellerActions>) => {
        const hrpsApiClient = new HrpsApiClient();
        dispatch(getUnmappedItemsGet());
        return hrpsApiClient
            .getItemsByItemIds(resellerId, itemIds)
            .then((resellerItems) => {
                dispatch(getUnmappedItemsLoaded(resellerItems));
            })
            .catch((error) => {
                dispatch(getUnmappedItemsFailed(error));
            });
    };
}

export function getItemImageUrls(asins: string[]) {
    return (dispatch: Dispatch<ResellerActions>) => {
        const hrpsApiClient = new HrpsApiClient();
        dispatch(getItemImageUrlsLoading());
        return hrpsApiClient
            .getItemImageUrls(asins)
            .then((urlMap) => {
                const resultMap = new Map<string, string>(Object.entries(urlMap));
                resultMap.set(ASIN_MISSING_PLACEHOLDER, DEFAULT_ITEM_IMAGE_URL);
                dispatch(getItemImageUrlsLoaded(resultMap));
            })
            .catch((error) => {
                dispatch(getItemImageUrlsFailed(error));
            });
    };
}

export function getResellerPriceList(resellerId: string) {
    return (dispatch: Dispatch<ResellerActions>) => {
        const hrpsApiClient = new HrpsApiClient();
        dispatch(getResellerPriceListLoading());
        return hrpsApiClient
            .getResellerPriceList(resellerId)
            .then((PriceList) => {
                dispatch(getResellerPriceListLoaded(PriceList));
            })
            .catch((error) => {
                dispatch(getResellerPriceListFailed(error));
            });
    };
}
