import MeridianModal, { ModalFooter } from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import _ from 'lodash';
import React, {
    PropsWithChildren,
    ReactElement,
    ReactNode,
    useEffect,
    useMemo,
    useRef,
    useState,
    useCallback,
} from 'react';
import { Debug } from '../../utils/debug';
import { Logger } from '../../utils/logger';
import { Button, TranslatedString, useTranslation } from '../blocks';

type PromptButton = {
    id: string;
    label: TranslatedString;
    isPrimary?: boolean;
    isCancel?: boolean;
    isDefault?: boolean;
};

export type ContentRenderFunction<T = any> = (initialData: T, setFinalData: (data: T) => void) => ReactNode;

type PromptSettings<T = any> = {
    title: TranslatedString;
    content: TranslatedString | ReactElement | ContentRenderFunction<T>;
    buttons?: PromptButton[];
    options?: { enableCornerCloseButton?: boolean };
    initialData?: T;
};

type PromptFunction = (settings: PromptSettings) => PromptFuture;

export const ModalContext = React.createContext<{ prompt: PromptFunction; onDismount: () => void }>({
    prompt: () => {
        Logger.error('You must be inside <Modal> element to call this method.');
        return {} as PromptFuture;
    },
    onDismount: () => {
        Logger.error('You must be inside <Modal> element to call this method.');
    },
});

export type AppliedCallback = (button: string, data: any) => void;
export type CancelledCallback = (button: string) => void;
export type ClosedCallback = (button: string) => void;

// eslint-disable-next-line unused-imports/no-unused-vars
export type PromptFuture<T = any> = {
    applied: (callback: AppliedCallback) => PromptFuture;
    cancelled: (callback: CancelledCallback) => PromptFuture;
    closed: (callback: ClosedCallback) => PromptFuture;
};

type CallbacksCollection = {
    applied?: AppliedCallback;
    cancelled?: CancelledCallback;
    closed?: ClosedCallback;
};
type CallbackTypes = keyof CallbacksCollection;

type ModalState = {
    callbacks: CallbacksCollection;
    title: string;
    isCornerCloseButtonEnabled: boolean;
    content: string | ReactNode | ContentRenderFunction;
    buttons: PromptButton[];
    data: any;
};

// the X button rendered in the top right corner of the modal (if enabled)
const cornerCloseButtonId = '_cornerCloseButton';

export function Modal({ children }: PropsWithChildren<{}>) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const modalStateRef = useRef<ModalState>();

    // eslint-disable-next-line unused-imports/no-unused-vars
    const prompt: PromptFunction = <T extends any = {}>({
        title,
        content,
        buttons,
        options,
        initialData,
    }: PromptSettings) => {
        Debug.assert(_.filter(buttons, 'isDefault').length <= 1, 'At most one button modal can be marked as default.');

        modalStateRef.current = {
            title: title,
            isCornerCloseButtonEnabled: options?.enableCornerCloseButton !== false,
            content: content,
            buttons: buttons ?? [],
            callbacks: {},
            data: initialData,
        };
        const modalConfig = modalStateRef.current;

        setIsModalOpen(true);

        function assertCallbackNotSet(callbackType: CallbackTypes) {
            Debug.assert(
                !modalConfig.callbacks[callbackType],
                'Callback has already been set. Only one callback per type is supported. Callback type:',
                callbackType
            );
        }

        return {
            applied(this: PromptFuture, callback: AppliedCallback): PromptFuture {
                assertCallbackNotSet('applied');
                modalConfig.callbacks.applied = callback;
                return this;
            },
            cancelled(this: PromptFuture, callback: CancelledCallback): PromptFuture {
                assertCallbackNotSet('cancelled');
                modalConfig.callbacks.cancelled = callback;
                return this;
            },
            closed(this: PromptFuture, callback: ClosedCallback): PromptFuture {
                assertCallbackNotSet('closed');
                modalConfig.callbacks.closed = callback;
                return this;
            },
        };
    };

    function closeModal(cancel: undefined | boolean, buttonId: string) {
        Debug.assertExists(modalStateRef.current);
        const modalState = modalStateRef.current;

        const {
            applied: appliedCallback,
            cancelled: cancelledCallback,
            closed: closedCallback,
        } = modalStateRef.current.callbacks;

        if (cancel) {
            if (cancelledCallback) {
                try {
                    cancelledCallback(buttonId);
                } catch (e) {
                    Logger.error('Exception while processing modal prompt cancelled callback.', e);
                }
            }
        } else {
            try {
                Debug.assertExists(
                    appliedCallback,
                    'Modal prompt is being used without <applied> callback being specified.'
                );
                appliedCallback(buttonId, modalState.data);
            } catch (e) {
                Logger.error('Exception while processing modal prompt applied callback.', e);
            }
        }

        if (closedCallback) {
            try {
                closedCallback(buttonId);
            } catch (e) {
                Logger.error('Exception while processing modal prompt closed callback.', e);
            }
        }

        setIsModalOpen(false);
        modalStateRef.current = undefined;
    }

    function onButtonClick(button: PromptButton) {
        const cancel = button.isCancel;
        const buttonId = button.id;
        closeModal(cancel, buttonId);
    }

    const onClose: (() => void) | undefined = useMemo(
        () =>
            modalStateRef.current?.isCornerCloseButtonEnabled
                ? () => {
                      closeModal(true, cornerCloseButtonId);
                  }
                : undefined,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [modalStateRef.current?.isCornerCloseButtonEnabled]
    );

    const title = modalStateRef.current?.title;

    const onDismount = useCallback(() => {
        if (modalStateRef.current) {
            setIsModalOpen(false);
            modalStateRef.current = undefined;
        }
    }, []);

    // eslint-disable-next-line no-nested-ternary
    const content = modalStateRef.current
        ? _.isFunction(modalStateRef.current.content)
            ? (modalStateRef.current.content as ContentRenderFunction)(modalStateRef.current.data, (data) => {
                  modalStateRef.current!.data = data;
              })
            : modalStateRef.current.content
        : undefined;

    const buttons = modalStateRef.current
        ? modalStateRef.current.buttons.map((button: PromptButton) => (
              <Button
                  type={button.isPrimary ? 'primary' : 'secondary'}
                  key={button.id}
                  ref={(instance) => {
                      if (button.isDefault && instance) {
                          instance.focus();
                      }
                  }}
                  size={'small'}
                  onClick={() => onButtonClick(button)}
                  data-testid={`modal.buttons[${button.id}]`}
              >
                  {button.label}
              </Button>
          ))
        : undefined;
    return (
        <ModalContext.Provider value={{ prompt, onDismount }}>
            <MeridianModal
                title={title}
                open={isModalOpen}
                onClose={onClose}
                scrollContainer={'viewport'}
                closeLabel={'Close'}
                describedById={'modal-description'}
                data-testid={'modal'}
            >
                <div id={'modal-description'}>{content}</div>
                {buttons?.length ? (
                    <ModalFooter>
                        <Row alignmentHorizontal={'right'} widths={'fit'}>
                            {buttons}
                        </Row>
                    </ModalFooter>
                ) : null}
            </MeridianModal>
            {children}
        </ModalContext.Provider>
    );
}

export type UseModalResult = {
    confirm: (
        actionToConfirm: TranslatedString,
        confirmationQuestion: TranslatedString,
        confirmActionButtonLabel: TranslatedString,
        cancelActionButtonLabel?: TranslatedString
    ) => PromptFuture;
    prompt: PromptFunction;
};

export function useModal() {
    const { t } = useTranslation('modal');
    const modalContext = React.useContext(ModalContext);

    useEffect(
        () => () => {
            modalContext.onDismount();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * Create a standard confirmation prompt.
     * @param actionToConfirm is a title-capitalized name of the action that is being confirmed
     * @param confirmationQuestion a question asking for confirmation, formatted as a sentence.
     * @param confirmActionButtonLabel a answer with the re-statement of the action
     * @param cancelActionButtonLabel a negative response, defaults to "No, cancel"
     * @example useModal().confirm("Delete File", "Are you sure you want to delete file foo.bar?", "Yes, delete the
     *     file", "Cancel"); Note: our convention is to use Title Capitalization for short button labels (1-3 words),
     *     and sentence capitalization for [occasional] longer labels
     */
    function confirm(
        actionToConfirm: TranslatedString,
        confirmationQuestion: TranslatedString,
        confirmActionButtonLabel: TranslatedString,
        cancelActionButtonLabel: TranslatedString = t('noCancel-defaultCancelButtonLabel')
    ): PromptFuture {
        return modalContext.prompt({
            title: actionToConfirm,
            content: confirmationQuestion,
            buttons: [
                { id: 'confirm', label: confirmActionButtonLabel, isPrimary: true },
                { id: 'cancel', label: cancelActionButtonLabel, isPrimary: false, isDefault: true, isCancel: true },
            ],
        });
    }

    return {
        prompt: modalContext.prompt,
        confirm: confirm,
    };
}
