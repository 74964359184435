import { rootReducer } from '../reducers';

export * from './system';
export * from './tableData';
export * from './reseller';
export * from './returnOrder';
export * from './issueList';
export * from './user';
export * from './managedUsers';
export * from './returnOrderAuditHistory';
export * from './returnOrderList';
export * from './returnOrderProblemShelf';
export * from './asynchronouslyLoadedValue';
export * from './returnOrderDraft';
export * from './userTermsAndConditionsAcceptance';
export * from './newsPage';
export * from './claimList';
export * from './claim';

export type RootState = ReturnType<typeof rootReducer>;
