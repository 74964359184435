import styled from '@emotion/styled';

const ItemThumbnail = styled.img`
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 2.5em;
`;

export { ItemThumbnail };
