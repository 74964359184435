import { PopoverPosition } from '@amzn/meridian/popover/popover';
import Table, { TableCell, TableRow } from '@amzn/meridian/table';
import Text from '@amzn/meridian/text';
import React from 'react';

import { TranslatedString } from '../blocks/localization';
import { HelpPopover } from './HelpPopover';

export type TableRowItem = {
    type: string;
    description: string;
};

export interface HelpTablePopoverProps {
    dataTestId?: string;
    label?: TranslatedString;
    header?: TranslatedString;
    text?: TranslatedString;
    maxWidth?: string;
    position?: PopoverPosition;
    tableRowItems: Array<TableRowItem>;
}

const HelpTablePopover = ({
    header,
    text,
    label,
    maxWidth,
    dataTestId,
    position,
    tableRowItems,
}: HelpTablePopoverProps) => {
    const tableRows = Object.values(tableRowItems).map((pair) => {
        return (
            <TableRow key={pair.type}>
                <TableCell>
                    <Text type={'h100'}>{pair.type}</Text>
                </TableCell>
                <TableCell>
                    <Text>{pair.description}</Text>
                </TableCell>
            </TableRow>
        );
    });

    return (
        <>
            <HelpPopover
                header={header}
                text={text}
                label={label}
                maxWidth={maxWidth}
                position={position}
                dataTestId={dataTestId}
            >
                <Table headerRows={0} showDividers={false} spacing={'small'}>
                    {tableRows}
                </Table>
            </HelpPopover>
        </>
    );
};

export { HelpTablePopover };
