import bulkReturn1 from '../assets/BulkReturn1.png';
import bulkReturn2 from '../assets/BulkReturn2.png';
import bulkReturn3 from '../assets/BulkReturn3.png';
import bulkReturn4 from '../assets/BulkReturn4.png';
import bulkReturn5 from '../assets/BulkReturn5.png';
import bulkReturn6 from '../assets/BulkReturn6.png';
import createReturn1 from '../assets/CreateReturn1.png';
import createReturn2 from '../assets/CreateReturn2.png';
import createReturn3 from '../assets/CreateReturn3.png';
import createReturn4 from '../assets/CreateReturn4.png';
import createReturn5 from '../assets/CreateReturn5.png';
import createUser1 from '../assets/CreateUser1.png';
import createUser2 from '../assets/CreateUser2.png';
import createUser3 from '../assets/CreateUser3.png';
import creditMemo3 from '../assets/CreditMemo3.png';
import creditMemo1 from '../assets/CreditMemo4.png';
import creditMemo2 from '../assets/CreditMemoUI2.png';
import manageMultiUser1 from '../assets/ManageMultiUser1.png';
import manageMultiUser2 from '../assets/ManageMultiUser2.png';
import manageMultiUser3 from '../assets/ManageMultiUser3.png';
import { NewsArticle } from '../redux/types';

export const newsFeedImages = {
    creditMemo1: creditMemo1,
    creditMemo2: creditMemo2,
    creditMemo3: creditMemo3,
    createReturn1: createReturn1,
    createReturn2: createReturn2,
    createReturn3: createReturn3,
    createReturn4: createReturn4,
    createReturn5: createReturn5,
    bulkReturn1: bulkReturn1,
    bulkReturn2: bulkReturn2,
    bulkReturn3: bulkReturn3,
    bulkReturn4: bulkReturn4,
    bulkReturn5: bulkReturn5,
    bulkReturn6: bulkReturn6,
    createUser1: createUser1,
    createUser2: createUser2,
    createUser3: createUser3,
    manageMultiUser1: manageMultiUser1,
    manageMultiUser2: manageMultiUser2,
    manageMultiUser3: manageMultiUser3,
};

export const NEWS_ARTICLES: NewsArticle[] = [
    {
        startDate: 1661731200000,
        endDate: 1664496000000,
        articleDate: 1661904000000,
        articleTitleId: 'article1_title',
        articleTextIds: [
            { textId: 'article1_bullet1_text' },
            { textId: 'article1_bullet2_text' },
            { textId: 'article1_bullet3_text' },
            { textId: 'article1_bullet4_text' },
            { textId: 'article1_bullet5_text' },
            { textId: 'article1_bullet6_text' },
            { textId: 'article1_bullet7_text' },
            { textId: 'article1_bullet8_text' },
        ],
        picturesByRow: [['creditMemo1', 'creditMemo2', 'creditMemo3']],
    },
    {
        startDate: 1690761600000, // 7-31-2023 00:00:00 UTC
        endDate: 1693785600000, // 9-4-2023 00:00:00 UTC
        articleDate: 1691107200000, // 8-4-2023 00:00:00 UTC
        articleTitleId: 'article2_title',
        articleTextIds: [
            { textId: 'article2_bullet1_text' },
            { textId: 'article2_bullet2_text' },
            { textId: 'article2_bullet3_text' },
            { textId: 'article2_bullet4_text' },
            { textId: 'article2_bullet5_text' },
            { textId: 'article2_bullet6_text' },
            { textId: 'article2_bullet7_text' },
            { textId: 'article2_bullet8_text' },
            {
                textId: 'article2_bullet9_text',
                subtextIds: ['article2_bullet9_subtext_a', 'article2_bullet9_subtext_b', 'article2_bullet9_subtext_c'],
            },
            { textId: 'article2_bullet10_text' },
        ],
        picturesByRow: [
            ['createReturn1', 'createReturn2', 'createReturn3'],
            ['createReturn4', 'createReturn5'],
        ],
    },
    {
        startDate: 1690761600000, // 7-31-2023 00:00:00 UTC
        endDate: 1693785600000, // 9-4-2023 00:00:00 UTC
        articleDate: 1691107200000, // 8-4-2023 00:00:00 UTC
        articleTitleId: 'article3_title',
        articleTextIds: [
            { textId: 'article3_bullet1_text' },
            { textId: 'article3_bullet2_text' },
            { textId: 'article3_bullet3_text' },
            {
                textId: 'article3_bullet4_text',
                subtextIds: ['article3_bullet4_subtext_a', 'article3_bullet4_subtext_b', 'article3_bullet4_subtext_c'],
            },
            { textId: 'article3_bullet5_text' },
            { textId: 'article3_bullet6_text' },
            { textId: 'article3_bullet7_text' },
            { textId: 'article3_bullet8_text' },
            {
                textId: 'article3_bullet9_text',
                subtextIds: ['article3_bullet9_subtext_a', 'article3_bullet9_subtext_b'],
            },
            { textId: 'article3_bullet10_text' },
            { textId: 'article3_bullet11_text' },
            { textId: 'article3_bullet12_text' },
            { textId: 'article3_bullet13_text' },
        ],
        picturesByRow: [
            ['bulkReturn1', 'bulkReturn2', 'bulkReturn3'],
            ['bulkReturn4', 'bulkReturn5', 'bulkReturn6'],
        ],
    },
    {
        startDate: 1692662400000, // 8-22-2023 00:00:00 UTC
        endDate: 1695859200000, // 9-28-2023 00:00:00 UTC
        articleDate: 1692662400000, // 8-22-2023 00:00:00 UTC
        articleTitleId: 'article4_title',
        articleTextIds: [
            { textId: 'article4_bullet1_text' },
            { textId: 'article4_bullet2_text' },
            { textId: 'article4_bullet3_text' },
            {
                textId: 'article4_bullet4_text',
                subtextIds: [
                    'article4_bullet4_subtext_a',
                    'article4_bullet4_subtext_b',
                    'article4_bullet4_subtext_c',
                    'article4_bullet4_subtext_d',
                    'article4_bullet4_subtext_e',
                ],
            },
            { textId: 'article4_bullet5_text' },
            { textId: 'article4_bullet6_text' },
            { textId: 'article4_bullet7_text' },
        ],
        picturesByRow: [['createUser1'], ['createUser2'], ['createUser3']],
    },
    {
        startDate: 1692662400000, // 8-22-2023 00:00:00 UTC
        endDate: 1695859200000, // 9-28-2023 00:00:00 UTC
        articleDate: 1692662400000, // 8-22-2023 00:00:00 UTC
        articleTitleId: 'article5_title',
        articleTextIds: [
            { textId: 'article5_bullet1_text' },
            { textId: 'article5_bullet2_text' },
            { textId: 'article5_bullet3_text' },
            { textId: 'article5_bullet4_text' },
            { textId: 'article5_bullet5_text' },
            { textId: 'article5_bullet6_text' },
            { textId: 'article5_bullet7_text' },
            { textId: 'article5_bullet8_text' },
        ],
        picturesByRow: [['manageMultiUser1'], ['manageMultiUser2'], ['manageMultiUser3']],
    },
];
