import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Attachment } from '../../types';
import { fetchAttachmentsByReturnId } from '../actions/attachmentsActions';
import { AsynchronouslyLoadedValueStatus, IAsynchronouslyLoadedValue } from '../types';

type attachmentsState = IAsynchronouslyLoadedValue<Attachment[]> & {
    returnOrderId: string;
};

const initialState: attachmentsState = {
    status: 'Uninitialized',
    value: [],
    error: undefined,
    returnOrderId: '',
};

const attachmentsSlice = createSlice({
    name: 'attachments',
    initialState,
    reducers: {
        setStatus(state, action: PayloadAction<AsynchronouslyLoadedValueStatus>) {
            return {
                ...state,
                status: action.payload,
            };
        },
        clearAttachments() {
            return {
                status: 'Uninitialized',
                value: [],
                error: undefined,
                returnOrderId: '',
            };
        },
        setAttachments(state, action: PayloadAction<Attachment[]>) {
            return {
                ...state,
                value: [...action.payload],
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAttachmentsByReturnId.pending, (state, action) => {
                return {
                    ...state,
                    status: 'Loading',
                    returnOrderId: action.meta.arg.returnOrderId,
                };
            })
            .addCase(fetchAttachmentsByReturnId.fulfilled, (state, action) => {
                if (state.status === 'Loading' && state.returnOrderId === action.payload.returnOrderId) {
                    const storedAttachmentIds = action.payload.attachments.map((attachment) => attachment.id);
                    const isAllAttachmentsStored = state
                        .value!.filter((attachment) => attachment.status === 'Uploading')
                        .every((attachment) => storedAttachmentIds.includes(attachment.id));
                    if (isAllAttachmentsStored) {
                        return {
                            ...state,
                            status: 'Loaded',
                            value: action.payload.attachments,
                        };
                    } else {
                        return {
                            ...state,
                            status: 'Uploading',
                        };
                    }
                }
                return state;
            })
            .addCase(fetchAttachmentsByReturnId.rejected, (state) => {
                return {
                    ...state,
                    status: 'Failed',
                };
            });
    },
});

export const { setStatus, clearAttachments, setAttachments } = attachmentsSlice.actions;
export default attachmentsSlice.reducer;
