import { Reducer } from 'redux';
import { INITIAL_RETURN_ORDER_LIST_STATE } from '../../constants';
import {
    RETURN_ORDER_LIST_CLEAR,
    RETURN_ORDER_LIST_DATE_RANGE_CHANGE,
    RETURN_ORDER_LIST_ERROR,
    RETURN_ORDER_LIST_GET,
    RETURN_ORDER_LIST_LOADED,
    RETURN_ORDER_LIST_LOAD_MORE_DATA,
    RETURN_ORDER_LIST_PAGE_CHANGE,
    RETURN_ORDER_LIST_QUERY_CHANGE,
    RETURN_ORDER_LIST_SORT_CHANGE,
    ReturnOrderListActions,
} from '../actions/returnOrderListActions';
import { ReturnOrderListState } from '../types';

export const resellerReturnOrderListReducer: Reducer<ReturnOrderListState, ReturnOrderListActions> = (
    state = INITIAL_RETURN_ORDER_LIST_STATE,
    action
): ReturnOrderListState => {
    switch (action.type) {
        case RETURN_ORDER_LIST_SORT_CHANGE:
            return {
                ...state,
                currentPage: 1,
                sortColumn: action.payload.sortColumn,
                sortDirection: action.payload.sortDirection,
                startAt: 0,
                currentRecordNumber: 0,
                returnOrderData: {
                    value: undefined,
                    status: 'Uninitialized',
                    error: undefined,
                },
            };
        case RETURN_ORDER_LIST_QUERY_CHANGE:
            return {
                ...state,
                currentPage: 1,
                query: action.payload.query,
                startAt: 0,
                currentRecordNumber: 0,
                returnOrderData: {
                    value: undefined,
                    status: 'Uninitialized',
                    error: undefined,
                },
            };
        case RETURN_ORDER_LIST_DATE_RANGE_CHANGE:
            return {
                ...state,
                dateRange: action.payload.dateRange,
                startAt: 0,
                currentRecordNumber: 0,
                returnOrderData: {
                    value: undefined,
                    status: 'Uninitialized',
                    error: undefined,
                },
            };
        case RETURN_ORDER_LIST_LOADED:
            return {
                ...state,
                totalRecords: action.payload.totalResults,
                returnOrderData: {
                    value: action.payload.returnOrdersList,
                    status: 'Loaded',
                    error: undefined,
                },
            };
        case RETURN_ORDER_LIST_GET:
            return {
                ...state,
                totalRecords: 0,
                returnOrderData: {
                    value: undefined,
                    status: 'Loading',
                    error: undefined,
                },
            };
        case RETURN_ORDER_LIST_ERROR:
            return {
                ...state,
                totalRecords: 0,
                returnOrderData: {
                    value: undefined,
                    status: 'Failed',
                    error: action.payload.error,
                },
            };
        case RETURN_ORDER_LIST_CLEAR:
            return {
                ...state,
                totalRecords: 0,
                returnOrderData: {
                    value: undefined,
                    status: 'Uninitialized',
                    error: undefined,
                },
            };
        case RETURN_ORDER_LIST_PAGE_CHANGE:
            return {
                ...state,
                currentPage: action.payload.currentPage,
                currentRecordNumber: action.payload.currentRecordNumber,
            };
        case RETURN_ORDER_LIST_LOAD_MORE_DATA:
            return {
                ...state,
                currentPage: action.payload.currentPage,
                startAt: action.payload.currentRecordNumber,
                currentRecordNumber: action.payload.currentRecordNumber,
                returnOrderData: {
                    value: undefined,
                    status: 'Uninitialized',
                    error: undefined,
                },
            };
        default:
            return state;
    }
};
