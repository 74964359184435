import { Action } from 'redux';
import { ReturnOrderProblemShelfItem } from '../../types/ReturnOrderProblemShelf';
import { IAsynchronouslyLoadedValue } from '../types';

export const RETURN_ORDER_PROBLEM_SHELF_CLEAR = 'returnOrderProblemShelf/clear';
export const RETURN_ORDER_PROBLEM_SHELF_GET = 'returnOrderProblemShelf/get';
export const RETURN_ORDER_PROBLEM_SHELF_LOADED = 'returnOrderProblemShelf/loaded';

export interface ReturnOrderProblemShelfGetAction extends Action {
    type: typeof RETURN_ORDER_PROBLEM_SHELF_GET;
    payload: IAsynchronouslyLoadedValue<ReturnOrderProblemShelfItem[]>;
}

export interface ReturnOrderProblemShelfLoadedAction extends Action {
    type: typeof RETURN_ORDER_PROBLEM_SHELF_LOADED;
    payload: IAsynchronouslyLoadedValue<ReturnOrderProblemShelfItem[]>;
}

export interface ReturnOrderProblemShelfClearAction extends Action {
    type: typeof RETURN_ORDER_PROBLEM_SHELF_CLEAR;
    payload: IAsynchronouslyLoadedValue<ReturnOrderProblemShelfItem[]>;
}

export type ReturnOrderProblemShelfActions =
    | ReturnOrderProblemShelfGetAction
    | ReturnOrderProblemShelfLoadedAction
    | ReturnOrderProblemShelfClearAction;
