import Alert from '@amzn/meridian/alert';
import Box from '@amzn/meridian/box';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Divider from '@amzn/meridian/divider';
import Heading from '@amzn/meridian/heading';
import Loader from '@amzn/meridian/loader';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import styled from '@emotion/styled';
import { css } from 'emotion';
import React, { FormEvent, KeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { IssueMap } from '../../constants';
import { getAuthorizedResellers, selectReseller } from '../../redux/actions/resellerActionCreators';
import { clearReturnOrderList } from '../../redux/actions/returnOrderListActionCreators';
import { clearUserTermsAndConditions } from '../../redux/actions/termsAndConditionsActionCreators';
import { RootState } from '../../redux/types';
import { color } from '../../theme/colors';
import { SiteMapPage } from '../../types';
import { Logger } from '../../utils/logger';
import { useSiteMapRouter } from '../../utils/SiteMapRouter';
import { usePageMessaging } from '../composites/PageMessaging';
import { CreateNotificationPopover } from './CreateNotificationPopover';
import { ResellerTable } from './ResellerTable';

const DarkText = styled(Text)`
    color: ${color.gray[800]};
`;

const LightText = styled(Text)`
    color: ${color.gray[100]};
`;

const scrollClass = css`
    overflow-y: auto;
`;

const SelectReseller = ({
    authorizedResellers,
    selectedReseller,
    getAuthorizedResellers,
    selectReseller,
    clearReturnOrderList,
    clearUserTermsAndConditions,
    user,
}: SelectResellerProps) => {
    const { t } = useTranslation(['selectResellerPage', 'forms']);

    const { goto } = useSiteMapRouter();
    const pageMessaging = usePageMessaging();

    const [checkedReseller, setCheckedReseller] = useState('');
    const [formSubmittedResellerId, setFormSubmittedResellerId] = useState('');
    const [noResellerSelectedError, setNoResellerSelectedError] = useState(false);
    const username: string = user?.username || t('unknownUsername');
    const resellerId = t('unknownResellerId');
    const data = {
        user,
        authorizedResellers: authorizedResellers.value?.map((reseller) => reseller.name).join(', '),
        selectedReseller,
    };

    useEffect(() => {
        (async () => {
            if (authorizedResellers.status === 'Uninitialized') {
                getAuthorizedResellers();
            }
        })();

        switch (authorizedResellers.status) {
            case 'Loaded':
                if (authorizedResellers.value && authorizedResellers.value.length === 1) {
                    pageMessaging.showSuccess(t('singleAuthorizedReseller-successMessage'));
                    goto(SiteMapPage.home);
                }
                break;
            case 'Failed':
                Logger.error('getAuthorizedResellers error: ', authorizedResellers.error);
                if (authorizedResellers.error?.code === 'NoAuthorizedResellers') {
                    pageMessaging.showError(t('noAuthorizedResellers-errorMessage'));
                    goto(SiteMapPage.signOut);
                } else {
                    pageMessaging.showError(t('failedToRetrieveResellers-errorMessage'));
                }
                break;
            default:
            // do Nothing
        }
    }, [authorizedResellers, getAuthorizedResellers, t, pageMessaging, goto]);

    useEffect(() => {
        if (
            selectedReseller.status === 'Loaded' &&
            selectedReseller.value &&
            formSubmittedResellerId === selectedReseller.value.resellerId
        ) {
            clearUserTermsAndConditions();
            goto(SiteMapPage.termsAndConditions);
        }

        if (selectedReseller.status === 'Failed') {
            pageMessaging.showError(t('failedToSetSelectedReseller-errorMessage'));
        }
    }, [selectedReseller, formSubmittedResellerId, t, goto, pageMessaging, clearUserTermsAndConditions]);

    function setSelectReseller(value: string) {
        setCheckedReseller(value);
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (checkedReseller) {
            Logger.debug('Selected reseller id: ', checkedReseller);
            setFormSubmittedResellerId(checkedReseller);
            selectReseller(checkedReseller);
            clearReturnOrderList();
        } else {
            setNoResellerSelectedError(true);
        }
    };

    // This workaround is necessary because Enter press is explicitly prevented from bubbling up in Meridian <TableRow> onClick handler:
    // https://code.amazon.com/packages/MeridianComponents/blobs/a5299401def8c7c87a2a7aa01c9b2e3b8cdda091/--/src/components/table/table-row.js#L107-L115
    const submitOnEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            e.currentTarget.dispatchEvent(new Event('submit'));
        }
    };

    const cancelSelectReseller = () => {
        goto(SiteMapPage.home);
    };

    if (authorizedResellers.status === 'Loading' || selectedReseller.status === 'Loading') {
        return (
            <Column
                spacing={'medium'}
                spacingInset={'large'}
                alignmentHorizontal={'center'}
                alignmentVertical={'center'}
            >
                <Loader type={'circular'} data-testid={'selectResellerPage.loader'} />
            </Column>
        );
    }

    return (
        <form onSubmit={handleSubmit} data-testid={'selectResellerPage.form'} onKeyDown={submitOnEnter}>
            <Column spacing={'medium'} spacingInset={'none'}>
                <Box type={'outline'} spacingInset={'none'} width={'100%'}>
                    <Row width={'100%'} widths={'fill'} spacingInset={'none'} spacing={'none'}>
                        <Box type={'fill'} spacingInset={'medium'} width={'100%'}>
                            <Heading level={selectedReseller.value ? 3 : 2}>{t('selectReseller-promptHeader')}</Heading>
                        </Box>
                    </Row>
                    <Row width={'100%'} widths={'fill'} spacingInset={'none'} alignmentVertical={'top'}>
                        <Divider />
                    </Row>
                    <Row width={'100%'} widths={'fill'} spacingInset={'small'}>
                        {noResellerSelectedError && (
                            <Alert type={'error'}>{t('noResellerSelected-errorMessage')}</Alert>
                        )}
                    </Row>
                    <Row width={'100%'} widths={'fill'}>
                        <Box className={scrollClass} spacingInset={'small'} width={'100%'}>
                            <ResellerTable
                                availableResellers={authorizedResellers.value}
                                selectedReseller={checkedReseller}
                                onSelectionChange={(value) => {
                                    setSelectReseller(value);
                                }}
                                useRadioComponent={true}
                            />
                        </Box>
                    </Row>
                </Box>
                <Row>
                    <Button type={'primary'} submit={true} data-testid={'selectReseller.selectButton'}>
                        {t('select-buttonLabel')}
                    </Button>
                    {selectedReseller.value && (
                        <Button type={'secondary'} onClick={cancelSelectReseller}>
                            {t('forms:cancel-buttonLabel')}
                        </Button>
                    )}
                </Row>
            </Column>
            <br />
            {selectedReseller.value && <DarkText>{t('havingTroubleFindingYourReseller-informationText')}</DarkText>}
            {!selectedReseller.value && <LightText>{t('havingTroubleFindingYourReseller-informationText')}</LightText>}
            <CreateNotificationPopover
                header={t('createTroubleTicket-popoverHeader')}
                label={t('reportAnIssue-linkText')}
                username={username}
                useButton={!selectedReseller.value}
                resellerId={resellerId}
                dataTestId={'resellerSelectReportAnIssue'}
                template={IssueMap['/resellers/selection']}
                pageData={data}
            />
        </form>
    );
};

const mapStateToProps = ({ resellerReducer, userReducer }: RootState) => {
    return {
        authorizedResellers: resellerReducer.authorizedResellers,
        selectedReseller: resellerReducer.selectedReseller,
        user: userReducer.user,
    };
};

const mapDispatchToProps = {
    getAuthorizedResellers,
    selectReseller,
    clearReturnOrderList,
    clearUserTermsAndConditions,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type SelectResellerProps = ConnectedProps<typeof connector>;
const connectedPage = connector(SelectReseller);
export { connectedPage as SelectReseller };
