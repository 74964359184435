import Column from '@amzn/meridian/column';
import styled from '@emotion/styled';
import { color } from '../../theme/colors';

const BorderedColumn = styled(Column)`
    border: 1px solid ${color.gray[300]};
    box-shadow: 0px 0px 3px ${color.gray[300]};
`;

export { BorderedColumn };
