import Column from '@amzn/meridian/column';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import React, { FC } from 'react';
import sampleWarehouseEmail from '../../../assets/sampleWarehouseEmail.png';
import { SiteHelpCallback } from '../../../constants';
import { ItemImage, Text, useTranslation } from '../../blocks';
import { IntroductionHelp } from './IntroductionHelp';

const IneligibleReturnsHelp: FC<SiteHelpCallback> = ({ callback }: SiteHelpCallback) => {
    const { t } = useTranslation(['ineligibleReturnsHelp', 'siteHelpPopover']);

    return (
        <Column spacing={'medium'} maxWidth={'25em'}>
            <Row width={'100%'} widths={['85%', '15%']}>
                <Text type={'h200'} alignment={'center'}>
                    {t('ineligibleReturnsHelp_Title')}
                </Text>
                <Link
                    onClick={() => {
                        callback(IntroductionHelp);
                    }}
                >
                    {t('siteHelpPopover:siteHelpPopover_helpHomePage')}
                </Link>
            </Row>
            <Text type={'h300'} alignment={'left'}>
                {t('ineligibleReturnsHelp_introduction')}
            </Text>
            <Text type={'b200'} alignment={'left'}>
                {t('ineligibleReturnsHelp_purpose')}
            </Text>
            <Column spacing={'xsmall'}>
                <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                    <Text type={'b200'} alignment={'left'}>
                        {t.cognate('•')}
                    </Text>
                    <Text type={'b200'} alignment={'left'}>
                        {t('ineligibleReturnsHelp_itemsExceeded')}
                    </Text>
                </Row>
                <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                    <Text type={'b200'} alignment={'left'}>
                        {t.cognate('•')}
                    </Text>
                    <Text type={'b200'} alignment={'left'}>
                        {t('ineligibleReturnsHelp_accessories')}
                    </Text>
                </Row>
                <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                    <Text type={'b200'} alignment={'left'}>
                        {t.cognate('•')}
                    </Text>
                    <Text type={'b200'} alignment={'left'}>
                        {t('ineligibleReturnsHelp_demoUnits')}
                    </Text>
                </Row>
                <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                    <Text type={'b200'} alignment={'left'}>
                        {t.cognate('•')}
                    </Text>
                    <Text type={'b200'} alignment={'left'}>
                        {t('ineligibleReturnsHelp_fraud')}
                    </Text>
                </Row>
                <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                    <Text type={'b200'} alignment={'left'}>
                        {t.cognate('•')}
                    </Text>
                    <Text type={'b200'} alignment={'left'}>
                        {t('ineligibleReturnsHelp_emptyBox')}
                    </Text>
                </Row>
                <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                    <Text type={'b200'} alignment={'left'}>
                        {t.cognate('•')}
                    </Text>
                    <Text type={'b200'} alignment={'left'}>
                        {t('ineligibleReturnsHelp_nonAmazonProduct')}
                    </Text>
                </Row>
            </Column>
            <Text type={'h300'} alignment={'left'}>
                {t('ineligibleReturnsHelp_handlingOptions')}
            </Text>
            <Text type={'b200'} alignment={'left'}>
                {t('ineligibleReturnsHelp_handlingOption1')}
            </Text>
            <Text type={'b200'} alignment={'left'}>
                {t('ineligibleReturnsHelp_handlingOption2')}
            </Text>
            <Text type={'h300'} alignment={'left'}>
                {t('ineligibleReturnsHelp_process')}
            </Text>
            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                <Text type={'b200'} alignment={'left'}>
                    {t('siteHelpPopover:siteHelpPopover_outline1')}
                </Text>
                <Text>{t('ineligibleReturnsHelp_contactUs')}</Text>
            </Row>
            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                <Text type={'b200'} alignment={'left'}>
                    {t('siteHelpPopover:siteHelpPopover_outline2')}
                </Text>
                <Text>{t('ineligibleReturnsHelp_warehouseCollects')}</Text>
            </Row>
            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                <Text type={'b200'} alignment={'left'}>
                    {t('siteHelpPopover:siteHelpPopover_outline3')}
                </Text>
                <Text>{t('ineligibleReturnsHelp_warehouseEmail')}</Text>
            </Row>
            <Row spacing={'small'} alignmentVertical={'top'} alignmentHorizontal={'left'}>
                <Text type={'b200'} alignment={'left'}>
                    {t('siteHelpPopover:siteHelpPopover_outline4')}
                </Text>
                <Text>{t('ineligibleReturnsHelp_resellerResponsible')}</Text>
            </Row>
            <ItemImage src={sampleWarehouseEmail} />
        </Column>
    );
};

export { IneligibleReturnsHelp };
